import {
    HttpError,
    useResource,
    useOne,
    BaseKey,
    useGetIdentity,
    useList,
    useNavigation,
} from "@refinedev/core";

import { IconPencil, IconX } from "@tabler/icons";

import {
TextField, //
EditButton, useForm, useSelect, Create
} from "@refinedev/mantine";

import { createStyles } from "@mantine/styles";

import {
// Edit,
Box, Autocomplete, Tabs, Grid, // Tab,
// Typography,
Textarea, Text, Select, TextInput, Group, Avatar, CloseButton, Button, Stack, Paper, ActionIcon, Table, Title, Divider, Switch, Chip
} from "@mantine/core";

import { Create as SwizzledCreate } from 'components/crud/create';
import { Edit as SwizzledEdit } from 'components/crud/edit';

import { DatePicker } from '@mantine/dates';
import dayjs from "dayjs";

import { datePickerValue, isAffectrixClientId } from "utilities";

import { IFilterz } from "interfaces";
import { detail_fields, resource } from './common'
import React, { useEffect, useState } from "react";

export const ReferralEdit: React.FC = () => {

    const { listUrl, push } = useNavigation()
  
    const { id } = useResource();

    // push(listUrl('operations/referral'));

    const { 
        data: {
            data: initialValues = {
              patient: {
                referrals: []
              }
            }
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields: detail_fields
        }
    });

    const {
        data: {
          // @ts-ignore
            claims: {
            "x-hasura-user-id": uid = '',
            "x-hasura-client-id": clientId = ''
            } = {}
        } ={}
    } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    const {
        saveButtonProps,
        refineCore: { queryResult, onFinish, formLoading },
        getInputProps,
        setFieldValue,
        values,
        setValues,
        insertListItem,
        removeListItem,
        reset,
        refineCore: {
          setId
        }
    } = useForm<IFilterz, HttpError>({
        refineCoreProps: {
            resource,
            id,
            metaData: {
                fields: detail_fields,
                // headers: {

                // }
            },
            onMutationSuccess(data, variables, context) {
                console.log('referralEdit')
            },
            autoSave: {
              enabled: true,
            }
        },
        initialValues,
        // },
        // TODO
        // validate: {
        //     title: (value) => (value.length < 2 ? "Too short title" : null),
        //     status: (value) =>
        //         value.length <= 0 ? "Status is required" : null,
        //     category: {
        //         id: (value) =>
        //             value.length <= 0 ? "Category is required" : null,
        //     },
        // },
        
        transformValues: (values) => {
            const {
                claim_type,
                client,
                document_status,
                equipment,

                extensions,
                resupplies,

                insurance,
                insurance_status,
                insurance_type,
                patient,
                patient_status,
                pickup_status,
                product,
                product_type,
                provider,
                reauth_status,
                referral_injury_sites,
                referral_notes,
                result_of_referral_automations,
                result_of_referral_events,
                rx_length,
                submissions,

                automated_email_status,

                id,
                ...rest
            } = values
            return rest
        },
    });

    const editReferralForm = {
      values,
      setValues,
      getInputProps,
      saveButtonProps,
      reset,
      setFieldValue,
      setId
    };

    // const handleSubmitPostEdit = (values:any) => {
    //     onFinish({
    //         ...values,
    //     });
    // };
    const { selectProps: patientStatusSelectProps } = useSelect({
        resource: "patient_status",
        defaultValue: values?.patient_status_id as BaseKey || '',
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: insuranceStatusSelectProps } = useSelect({
        resource: "insurance_status",
        defaultValue: values?.insurance_status_id as BaseKey || '',
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: productTypeSelectProps } = useSelect({
        resource: "product_type",
        // defaultValue: values?.product_type_id as BaseKey  || '',

        // queryOptions: {
        //     enabled: values?.product_type_id !== null
        // },
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: claimTypeSelectProps } = useSelect({
        resource: "claim_type",

        // defaultValue: values?.claim_type_id as BaseKey  || '',
        // queryOptions: {
        //     enabled: values?.claim_type_id !== null
        // },
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: insuranceSelectProps } = useSelect({
        resource: "insurance",
        // defaultValue: values?.insurance_id as BaseKey || '',

        // queryOptions: {
        //     enabled: values?.insurance_id !== null
        // },
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: documentStatusSelectProps } = useSelect({
        resource: "document_status",
        // defaultValue: values?.document_status_id as BaseKey || '',

        // queryOptions: {
        //     enabled: values?.document_status_id !== null
        // },
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: reauthStatusSelectProps } = useSelect({
        resource: "reauth_status",

        // defaultValue: values?.document_status_id as BaseKey || '',
        // queryOptions: {
        //     enabled: values?.document_status_id !== null
        // },
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    // const { selectProps: noteTypeSelectProps } = useSelect({
    //     resource: "note_type",
    //     defaultValue: undefined,
    //     optionLabel: 'title',
    //     metaData: {
    //         fields: ["id", "title"],
    //     },
    // });

    const {
      data: {
        // @ts-ignore
        data: productTypeLookup = []
      } = {}
    } = useList({
        resource: 'product_type',

        meta:{
          fields: [ 'id', 'title'],
        },

        pagination: {
          pageSize: 1000
        }
    })

    const {
      data: {
        // @ts-ignore
        data: rxLengthLookup = []
      } = {}
    } = useList({
        resource: 'rx_length',

        meta:{
          fields: [ 'id', 'title'],
        },

        pagination: {
          pageSize: 1000
        }
    })

    useEffect( ()=> {
      let product_type='';
      const pt_matches = productTypeLookup?.filter( x => x.id == values?.product_type_id );
      if (pt_matches.length == 1){
        [{title: product_type}] = pt_matches;
      }

      let injury_site = '';
      if (values?.injury_sites){
        // @ts-ignore
        injury_site = values?.injury_sites.join()
      }

      let rx_length='';
      const rx_matches = rxLengthLookup?.filter( x => x.id == values?.rx_length_id);
      if (rx_matches.length == 1){
        [{title: rx_length}] = rx_matches;
      }
      values.product_title = [ product_type, injury_site, rx_length ].filter(x => x).join('-')
    }, [values]);

    const { selectProps: extensionStatusSelectProps } = useSelect({
        resource: "extension_status",
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    // add extension form
    const {
        values: extensionValues,
        getInputProps: getInputPropsForExtension,
        onSubmit: onSubmitOfExtension,
        saveButtonProps: extensionSaveButtonProps,
        reset: resetOfExtension,
        setFieldValue: setFieldValueUponExtension
    } = useForm({
        initialValues: {
            referral_id: initialValues?.referral_id,
            extended_to_date: null,
            extension_status_id: null,
            rx_expiration_date: null,
        },
        refineCoreProps:{
            onMutationSuccess: ({data}, variables, context) => {
                insertListItem('extensions',data)
                resetOfExtension()
            },
            action: "create",
            resource: "extension",
            redirect: false,
            // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
            metaData: {
                fields: [
                    'id',
                    'extended_to_date',
                    {
                        extension_status: [ 'title' ]
                    },
                    {
                        insurance_override: [ 'title' ]
                    },
                    // {
                    //     reauth_lookup: [ 'title' ]
                    // },
                ]
            }
        },
        validate: (values) => {
            const {
                extension_status_id = '',
                extended_to_date
            } = values;
            return {
                extended_to_date: extended_to_date ? null : "Select a date",
                extension_status_id: extension_status_id?.length ? null : "Select a status"
            }
        }
    })

    const addExtensionForm = {
      values: extensionValues,
      getInputProps: getInputPropsForExtension,
      onSubmit: onSubmitOfExtension,
      saveButtonProps: extensionSaveButtonProps,
      reset: resetOfExtension,
      setFieldValue: setFieldValueUponExtension
    };

    // // @ts-ignore
    // const extensions = values?.extensions
    //     // @ts-ignore
    //     ?.sort((a,b) => {
    //     const {
    //         extended_to_date: aDate
    //     } = a;
    //     const {
    //         extended_to_date: bDate
    //     } = b;
    //     // @ts-ignore
    //     return new Date(bDate) - new Date(aDate)
    // })
    // // @ts-ignore
    // ?.map( (item, index) => {

    //     // TODO unify destructuring via resolving dumb error below
    //     const insurance_override = item?.insurance_override?.title;
    //     const reauth_lookup = item?.reauth_lookup?.title;
    //     const extension_status = item?.extension_status?.title;

    //     const {
    //         id,
    //         extended_to_date,

    //         // For whatever dumb reason, this destructuring code does not work
    //         // i.e. Cannot read properties of null (reading 'title')
    //         // even if there is a default value

    //         // insurance_override: { title: insurance_override = "" } ={ title: ""},
    //         // reauth_lookup: { title: reauth_lookup = ""} ={},
    //         rx_expiration_date,
    //     } = item

    //     //TODO: LEFT MERGE INSURANCE OF REFERRAL TO OVERRIDE INSURANCE

    //     return (
    //         <tr key={id}>
    //             <td>
    //                 <EditButton
    //                     recordItemId={id}
    //                     resource='operations/extension'
    //                 >
    //                 </EditButton>
    //             </td>
    //             <td>{extended_to_date}</td>
    //             <td>{extension_status}</td>
    //             <td>{insurance_override}</td>
    //             {/* <td>{reauth_lookup}</td> */}
    //             <td>{rx_expiration_date}</td>
    //         </tr>
    //     );
        
    // });

    const {
      values: submissionValues,
      getInputProps: getInputPropsForSubmission,
      onSubmit: onSubmitOfSubmission,
      saveButtonProps: submissionSaveButtonProps,
      reset: resetOfSubmission,
      setFieldValue: setFieldValueUponSubmission,
      removeListItem: removeListItemOfSubmission,
      insertListItem: insertListItemOfSubmission
  } = useForm({
      initialValues: {
          referral_id: initialValues?.id,
          items:[''],
          submission_type: '',
          fitter_id: null,
          date_shipped: null,
          tracking_number: ''
      },
      refineCoreProps:{
          onMutationSuccess: ({data}, variables, context) => {
              insertListItem('submissions',data)
              setSubmisisonFormVisibility(false);
              resetOfSubmission();
              setFieldValue('submisison_type',data.submission_type)
          },
          action: "create",
          resource: "submission",
          redirect: false,
          // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
          metaData: {
              fields: [
                  'id',
                  'submission_type', 
                  'items', 
                  'date_shipped', 
                  'tracking_number', 
                  'fitter_id', //TODO fields of fitter,
                  {
                    'fitter': [ 'title' ]
                  },
                  'urgency'
              ]
          }
      },
      // @ts-ignore
      transformValues: (values) => {
        const { submission_type } = values;

        if (submission_type == 'shipment_request'){
          const {
            fitter_id,
            // DOI,  template  TODO
            // EMPLOYER, template WC only
            // ATTORNEY, tempate
            ...rest
          } = values;
          // @ts-ignore
          values = rest;
        }
        if (submission_type == 'delivery_ticket'){
          const {
            date_shipped,
            tracking_number,
            ...rest
          } = values;
          // @ts-ignore
          values = rest;
        }

        // @ts-ignore
        if ([ 'TCT', 'SCOOTER' ].includes(values.product_type?.title)){
          // @ts-ignore
          const { serial_number, ...rest } = values;
          // @ts-ignore
          values = rest;
        } else {
          // @ts-ignore
          const { product_id, ...rest } = values;
          // @ts-ignore
          values = rest;
        }

        return values
      },
      validate: (values) => {
          const {
              submission_type,
              fitter_id,
              items
          } = values;

          // @ts-ignore
          const itemsValid = items.length > 0 && items.every( item => item.length > 0 )

          if (submission_type == 'delivery_ticket'){
            return {
              fitter_id: fitter_id ? null : "Select a fitter",
              items: itemsValid ? null : "Define the item(s) or remove the undefined"
            }
          }
          return {
            items: itemsValid ? null : "Define the item(s) or remove the undefined"
          }
      }
  });

  const addSubmissionForm = {
    values: submissionValues,
    getInputProps: getInputPropsForSubmission,
    onSubmit: onSubmitOfSubmission,
    saveButtonProps: submissionSaveButtonProps,
    reset: resetOfSubmission,
    setFieldValue: setFieldValueUponSubmission,
    removeListItem: removeListItemOfSubmission,
    insertListItem: insertListItemOfSubmission
  }

    // add resupply form
    const {
        values: addResupplyValues,
        getInputProps: getInputPropsForAddResupply,
        onSubmit: onSubmitOfAddResupply,
        saveButtonProps: addResupplySaveButtonProps,
        reset: resetOfAddResupply,
        setFieldValue: setFieldValueUponAddResupply
    } = useForm({
        initialValues: {
            referral_id: initialValues?.referral_id,
            resupply_date: null,
        },
        refineCoreProps:{
            onMutationSuccess: ({data}, variables, context) => {
                insertListItem('resupplies',data)
                resetOfAddResupply()
            },
            action: "create",
            resource: "resupplies",
            redirect: false,
            // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
            metaData: {
                fields: [
                    'id',
                    'resupply_date',
                ]
            }
        },
        validate: (values) => {
            const {
                resupply_date
            } = values;
            return {
                resupply_date: resupply_date ? null : "Select a date",
            }
        }
    })

    const addResupplyForm = {
      values: addResupplyValues,
      getInputProps: getInputPropsForAddResupply,
      onSubmit: onSubmitOfAddResupply,
      saveButtonProps: addResupplySaveButtonProps,
      reset: resetOfAddResupply,
      setFieldValue: setFieldValueUponAddResupply
    };

    // // @ts-ignore
    // const resupply_dates = values?.resupplies
    // // @ts-ignore
    // ?.sort((a,b) => {
    //     const {
    //         resupply_date: aDate
    //     } = a;
    //     const {
    //         resupply_date: bDate
    //     } = b;
    //     // @ts-ignore
    //     return new Date(bDate) - new Date(aDate)
    //     // @ts-ignore
    // })?.map( (item, index) => {
    //     const {
    //         id,
    //         resupply_date
    //     } = item
    //     return (
    //         <tr key={id}>
    //             <td>{resupply_date}</td>
    //         </tr>
    //     )
    // });

    const {
      data: {
        // @ts-ignore
        data: itemLookup
      } = {}
    } = useList({
        resource: 'selection',

        meta:{
          fields: [ 'id', 'title'],
        },

        pagination: {
          pageSize: 1000
        }
    });

    // @ts-ignore
    // const resupply_items = values?.resupply_items?.map((item, index) => (
    //     <Group mt="xs">
    //       {/* TODO fix the lookup to not use mzResupplyLookupItemSelectProps */}
    //       <Select
    //           mt={8}
    //           label="Resupply Item"
    //           placeholder="Type in a Resupply Item."
    //           {...getInputProps(`resupply_items.${index}`)}
    //           searchable={true}
    //           clearable={true}
    //           filterDataOnExactSearchMatch={true}
    //           data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
    //           style={{ maxWidth: 300 }}
    //       />
    //       <ActionIcon color="red" onClick={() => removeListItem('resupply_items', index)}>
    //         <CloseButton 
    //         // @ts-ignore
    //         size="1rem" />
    //       </ActionIcon>
    //     </Group>
    //   ));
    // TODO select Props into a json item

  // @ts-ignore
  // const itemsOfSubmissionForm = submissionValues.items.map( (item,index) => <Group>
  //     <Divider my="xs" label={`Item #${index+1}`} />
  //     {/* TODO when moving logic of referral view to patient-referrals view, consider below */}
  //     {/* <Select
  //       mt={8}
  //       label="Product Type"
  //       {...getInputPropsForSubmission(`items.${index}.product_type`)}
  //       {...productTypeSelectProps2}
  //       style={{ maxWidth: 300 }}
  //     /> */}
  //     {/* @ts-ignore */}
  //     {/* {['SCOOTER','TCT'].includes(submissionValues?.items[index]?.product_type) && <>
  //       Product Lookup
  //     </>}  */}
  //     <Select
  //         mt={8}
  //         // label="Item"
  //         placeholder="Search for an Item."
  //         {...getInputPropsForSubmission(`items.${index}`)}
  //         searchable={true}
  //         clearable={true}
  //         filterDataOnExactSearchMatch={true}
  //         data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
  //         style={{ maxWidth: 300 }}
  //     />
  //     <ActionIcon color="red" onClick={() => removeListItemOfSubmission('items', index)}>
  //       <CloseButton 
  //       // @ts-ignore
  //       size="1rem" />
  //     </ActionIcon>
  //   </Group>
  // )

  // let labelForSubmission = '';
  // switch (submissionValues.submission_type){
  //   case 'shipment_request':
  //     labelForSubmission = 'Shipment Request';
  //     break;
  //   case 'delivery_ticket':
  //     labelForSubmission = 'Delivery Ticket';
  //     break;
  // }

  const { selectProps: fitterSelectProps } = useSelect({
      resource: "fitter",

      // defaultValue: null,
      // queryOptions: {
      //     enabled: values?.patient_status_id !== null
      // },
      optionLabel: 'title',

      meta: {
          fields: ["id", "title"],
      },

      pagination: {
          mode: "server"
      }
  });

const [ submissionFormVisibility , setSubmisisonFormVisibility ] = useState(false);

// const submissionFormVisibilityButton = labelForSubmission && (!submissionFormVisibility ? 
//   <Button onClick={() => setSubmisisonFormVisibility(true)}>
//     Add a {labelForSubmission}
//   </Button> :
//   <Button onClick={() => setSubmisisonFormVisibility(false)} color='red'>
//     Abort this {labelForSubmission}
//   </Button>)


  // const submissionForm = (submissionValues.submission_type && submissionFormVisibility) && <>
  //   <Text fz='lg' fw='600'>Add Another Submisison</Text>
  //   <Select
  //     mt={8}
  //     label="URGENCY"
  //     {...getInputPropsForSubmission('urgency')}
  //     style={{ maxWidth: 300 }}
  //     data={[
  //       {value:'SURGICAL', label:'SURGICAL'},
  //       {value:'IMEDIATE', label:'IMEDIATE'},
  //       {value:null as any, label:'-/-'}
  //     ]}
  //   />
  //   {/* <DatePicker
  //       mt="md"
  //       label="DATE FIT"
  //       withinPortal
  //       {...getInputPropsForSubmission(`date_fit`)}
  //       value={datePickerValue(submissionValues)(`date_fit`)}
  //       style={{ maxWidth: 300 }}
  //   /> */}
  //   {/* @ts-ignore */}
  //   {/* { [ 'TCT', 'SCOOTER' ].includes(values.product_type?.title) ? <>
  //     <Select
  //       mt={8}
  //       label="SERIAL #"
  //       {...getInputPropsForSubmission('product_id')}
  //       style={{ maxWidth: 300 }}
  //       {...productSelectProps}
  //       // data={productSelectProps.data}
  //     />
  //   </> : <>
  //     <TextInput
  //         mt={8}
  //         label="SERIAL #"
  //         // type="date"
  //         // placeholder="Serial Number"
  //         {...getInputPropsForSubmission("serial_number")}
  //         disabled
  //         style={{ maxWidth: 300 }}
  //     />
  //   </>} */}
  //   { labelForSubmission == 'Shipment Request' && <>
  //     {/* <DatePicker
  //           mt="md"
  //           label="DATE SHIPPED"
  //           withinPortal
  //           {...getInputPropsForSubmission(`date_shipped`)}
  //           value={datePickerValue(submissionValues)(`date_shipped`)}
  //           style={{ maxWidth: 300 }}
  //       />
  //     <TextInput
  //         mt={8}
  //         label="TRACKING #"
  //         // type="date"
  //         // placeholder="Serial Number"
  //         {...getInputPropsForSubmission("tracking_number")}
  //         style={{ maxWidth: 300 }}
  //     /> */}
  //   </> }
  //   { labelForSubmission == 'Delivery Ticket' && <>
  //     <Select
  //       mt={8}
  //       label="FITTER"
  //       {...getInputPropsForSubmission('fitter_id')}
  //       style={{ maxWidth: 300 }}
  //       {...fitterSelectProps}
  //       // data={productSelectProps.data}
  //     />
  //   </>}
  //   <br/>
  //   <Text>Items*</Text>
  //   {itemsOfSubmissionForm}
  //   <Group mt="md">
  //       <Button
  //       onClick={() =>{
  //         insertListItemOfSubmission('items', '');
  //       }}
  //       >
  //         {/* @ts-ignore */}
  //       Add { itemsOfSubmissionForm.length > 0 ? 'another ' : ''} 
  //       {/* @ts-ignore */}
  //       Item to this {values.product_type?.title} {labelForSubmission}
  //       </Button>
  //   </Group>
  //   <br/>
  //   <Divider/>
  //   <br/>
  //   {/* @ts-ignore */}
  //   <Button {...submissionSaveButtonProps}>Submit {values.product_type?.title} {labelForSubmission}</Button>
  // </>

  // // @ts-ignore
  // const shipmentRequestSubmissions = values?.submissions?.filter( (submission) => {
  //   return submission.submission_type == 'shipment_request'
  //   // @ts-ignore
  // })?.map((thing, index) => {
  //   const urgency = thing?.urgency;
  //   const serial_number = thing?.serial_number;
  //   const date_shipped = thing?.date_shipped;
  //   const date_fit = thing?.referral?.date_fit;
  //   const tracking_number = thing?.tracking_number;

  //   let status = 'READY TO SHIP';
  //   if ( serial_number && date_shipped && date_fit && tracking_number){
  //     status = 'COMPLETE'
  //   } else if ( serial_number && date_shipped && !date_fit && tracking_number ){
  //     status = 'IN TRANSIT'
  //   }

  //   // @ts-ignore
  //   const items = thing?.items?.map?.( (item) => {
  //     // @ts-ignore
  //     const matchedItems = itemLookup.filter( x => x.id == item );
  //     if (matchedItems.length == 1){
  //       const [{title: matchedItem}] = matchedItems;
  //       return matchedItem 
  //     }
  //     return 'something'
  //   }).join(', ')
  //   return <tr key={index}>
  //     <td>{urgency}</td>
  //     <td>{status}</td>
  //     <td>{date_shipped}</td>
  //     <td>{items}</td>
  //   </tr>
  // });

  // // @ts-ignore
  // const shipmentRequestSubmissionsTable = values?.submissions?.length > 0 ? <>
  // <Text fz='lg' fw='600'>Shipment Requests</Text>
  // <Table>
  //   <thead>
  //     <tr>
  //       <th>URGENCY</th>
  //       <th>STATUS</th>
  //       <th>DATE FIT</th>
  //       <th>ITEMS</th>
  //     </tr>
  //   </thead>
  //   <tbody>{shipmentRequestSubmissions}</tbody>
  // </Table></> : <Text color="dimmed" align="center">
  //   {/* @ts-ignore */}
  //   No Shipment Requests for this {values?.product_type?.title} referral.
  // </Text>

  // // @ts-ignore
  // const deliveryTicketSubmissions = values?.submissions?.filter( (submission) => {
  //   return submission.submission_type == 'delivery_ticket'
  //   // @ts-ignore
  // })?.map((thing, index) => {
  //   const urgency = thing?.urgency;
  //   const serial_number = thing?.serial_number;
  //   // const date_shipped = thing?.date_shipped;

  //   //TODO
  //   // @ts-ignore
  //   const date_fit = values?.referral?.date_fit as String;
  //   const fitter = thing?.fitter?.title;
  //   // const tracking_number = thing?.tracking_number;

  //   // TODO audit
  //   let status = 'NEEDS SERIAL #';
  //   if ( serial_number && !date_fit ){
  //     status = 'INCOMPLETE';
  //   } else if ( serial_number && date_fit ){
  //     status = 'COMPLETE';
  //   }

  //   // @ts-ignore
  //   const items = thing?.items?.map?.( (item) => {
  //     // @ts-ignore
  //     const matchedItems = itemLookup.filter( x => x.id == item );
  //     if (matchedItems.length == 1){
  //       const [{title: matchedItem}] = matchedItems;
  //       return matchedItem 
  //     }
  //     return 'something'
  //   }).join(', ')
  //   return <tr key={index}>
  //     <td>{urgency}</td>
  //     <td>{status}</td>
  //     <td>{fitter}</td>
  //     <td>{date_fit}</td>
  //     <td>{items}</td>
  //   </tr>
  // })

  // @ts-ignore
  // const deliveryTicketSubmissionsTable = values?.submissions?.length > 0 ? <>
  // <Text fz='lg' fw='600'>Delivery Tickets</Text>
  // <Table>
  //   <thead>
  //     <tr>
  //       <th>URGENCY</th>
  //       <th>STATUS</th>
  //       <th>FITTER</th>
  //       <th>DATE FIT</th>
  //       <th>ITEMS</th>
  //     </tr>
  //   </thead>
  //   <tbody>{deliveryTicketSubmissions}</tbody>
  // </Table></> : <Text color="dimmed" align="center">
  //   {/* @ts-ignore */}
  //   No Delivery Tickets for this {values?.product_type?.title} referral.
  // </Text>

    const [ extensionVisibility, setExtensionVisibility ] = useState(false);
    const [ resupplyVisibility, setResupplyVisibility ] = useState(false);

  //   const {
  //       values: patientNoteValues,
  //       getInputProps: getInputPropsForPatientNote,
  //       onSubmit: onSubmitOfPatientNote,
  //       saveButtonProps: patientNoteSaveButtonProps,
  //       reset,
  //       setFieldValue: setFieldValueUponPatientNote
  //   } = useForm({
  //       initialValues: {
  //           patient_id: initialValues?.patient_id,
  //           note: {
  //               data: {
  //                   content: '',
  //                   uid,
  //                   note_type_id: null,
  //                   metadata: {
  //                     note_type: [],
  //                     product_type: []
  //                   }
  //               }
  //           },
  //           product_type_id: null
  //       },
  //       refineCoreProps:{
  //           onMutationSuccess: ({data}, variables, context) => {
  //               insertListItem('patient.patient_notes',data);
  //               setNoteVisibility(false);
  //               reset()
  //           },
  //           action: "create",
  //           resource: "patient_note",
  //           redirect: false,
  //           // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
  //           metaData: {
  //               fields: [
  //                   {
  //                       note: [
  //                         'id',
  //                         'content',
  //                         'created_at',
  //                         {
  //                           authed_user: [
  //                             'id',
  //                             'display_name',
  //                             'photoURL'
  //                           ]
  //                         },
  //                         'metadata'
  //                         // {
  //                         //   note_type: ['code']
  //                         // }
  //                       ]
  //                   }

  //               ]
  //           }
  //       },
  //       validate: (values) => {
  //           const {
  //               note: {
  //                   data: {
  //                       metadata,
  //                       content = ''
  //                   }
  //               }
  //           } = values;

  //           // @ts-ignore
  //           const product_type = metadata?.product_type || [];
  //           // @ts-ignore
  //           const note_type = metadata?.note_type || [];

  //           return {
  //               'note.data.content': content.trim().length > 0 ? null : "Note cannot be empty.",
  //               'note.data.metadata.note_type': note_type.length > 0 ? null : 'Select some tags.',
  //               'note.data.metadata.product_type': product_type.length > 0 ? null : 'Select some tags.',
  //               // note: {
  //               //   data: {
  //               //     content: content.trim().length > 0 ? null : "Note cannot be empty.",
  //               //     metadata: {
  //               //       // product_type: product_type.length > 0 ? null : "Select at least one tag.",
  //               //       product_type: (value: any) => value.length > 0 ? null : "Select at least one tag.",
  //               //       // note_type: note_type.length > 0 ? null : "Select at least one tag."
  //               //       note_type: (value: any) => note_type.length > 0 ? null : "Select at least one tag."
  //               //     }
  //               //   }
  //               // }
  //           }
  //       }
  //   })

  //   const [ editNote, setEditNote ] = useState({});

  //   const {
  //     values: editNoteValues,
  //     getInputProps: getInputPropsForEditNote,
  //     onSubmit: onSubmitOfEditNote,
  //     saveButtonProps: editNoteSaveButtonProps,
  //     reset: editNoteReset,
  //     setFieldValue: setFieldValueUponEditNote,
  //     refineCore:{
  //       setId: setIdOfEditNote
  //     }
  // } = useForm({
  //     initialValues: {
  //         // patient_id: initialValues?.patient_id,
  //         // note: {
  //         //     data: {
  //         id: null,
  //         content: '',
  //         // uid,
  //             // }
  //         // },
  //         // product_type_id: null
  //     },
  //     refineCoreProps:{
  //         onMutationSuccess: ({data}, variables, context) => {
  //             const { id: idOfThisNote } = data;
  //             // @ts-ignore
  //             const patientNoteIndex = values?.patient?.patient_notes?.findIndex( x => {
  //               const { note: {id: idOfSomeNote}} = x;
  //               return idOfSomeNote == idOfThisNote
  //             } )
  //             if (patientNoteIndex > -1){
  //               setFieldValue(`patient.patient_notes.${patientNoteIndex}.note`, data)
  //             }

  //             setEditNote({
  //               ...editNote,
  //               // @ts-ignore
  //               [idOfThisNote]: false
  //             });
  //             //then setFieldValue for that note
  //             editNoteReset()
  //         },
  //         action: "edit",
  //         resource: "note",
  //         redirect: false,
  //         // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
  //         metaData: {
  //             fields: [
  //                 // {
  //                 //     note: [
  //                       'id',
  //                       'content',
  //                       'created_at',
  //                       {
  //                         authed_user: [
  //                           'id',
  //                           'display_name',
  //                           'photoURL'
  //                         ]
  //                       },
  //                       'metadata'
  //                       // {
  //                       //   note_type: ['code']
  //                       // },
  //                 //     ]
  //                 // }

  //             ]
  //         }
  //     },
  //     // validate: (values) => {
  //     //     const {
  //     //         note: {
  //     //             data: {
  //     //                 note_type_id = ''
  //     //             }
  //     //         }
  //     //     } = values;
  //     //     return {
  //     //         note_type_id: note_type_id?.length ? null : "Select a note type"
  //     //     }
  //     // }
  // })

  //   const {
  //     values: patientValues,
  //     getInputProps: getInputPropsForPatient,
  //     onSubmit: onSubmitOfPatient,
  //     saveButtonProps: patientSaveButtonProps,
  //     // reset,
  //     setFieldValue: setFieldValueOfPatient,
  // } = useForm({
  //     initialValues: {
  //         id: initialValues?.patient_id,
  //         patient_info: initialValues?.patient?.patient_info,
  //         date_of_surgery: initialValues?.patient?.date_of_surgery,
  //         surgery: initialValues?.patient?.surgery,
  //         next_call_date: initialValues?.patient?.next_call_date,
  //         // date_fit: initialValues?.date_fit,
  //         condition: initialValues?.patient?.condition
  //     },
  //     refineCoreProps:{
  //         onMutationSuccess: ({data}, variables, context) => {
  //             // insertListItem('patient.patient_notes',data)
  //             // reset()
  //         },
  //         id: initialValues?.patient_id,
  //         queryOptions: {
  //             enabled: initialValues?.patient_id !== null
  //         },
  //         action: "edit",
  //         resource: "patient",
  //         redirect: false,
  //         // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
  //         metaData: {
  //             fields: [
  //               'patient_info',
  //               "date_of_surgery",
  //               'surgery',
  //               "next_call_date",
  //               "condition",
  //             ]
  //         }
  //     },
  // })

    const useStyles = createStyles((theme) => ({
        comment: {
          padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
        },
      
        body: {
        //   paddingLeft: rem(54),
        //   paddingTop: theme.spacing.sm,
          padding: theme.spacing.lg,
          fontSize: theme.fontSizes.sm,
        },
      
        content: {
          '& > p:last-child': {
            marginBottom: 0,
          },
        },
      }));

      const { classes } = useStyles();

    // // @ts-ignore
    // const productTypesForPatient = values.patient.referrals?.map( x => {
    //   const {
    //     id,
    //     product_type: {
    //       title
    //     },
    //     rx_received_date
    //   } = x;
    //   console.log(`${title} ${rx_received_date}`)
    //   return {
    //     id,
    //     title: `${title} ${rx_received_date}`
    //   }
    // // @ts-ignore
    // }).reduce( (prev, curr) => {
    //   const { id } = curr;
    //   // @ts-ignore
    //   const exists = prev.some( x => x.id == id);
    //   if (!exists){
    //     prev.push(curr);
    //   }
    //   return prev;
    // }, []);

    // @ts-ignore
    // const productTypesOfChips = productTypesForPatient.map( ({id,title}) => 
    // <Chip value={id} key={id}>
    //   {title}
    // </Chip>)

    // const noteTypes = [
    //   { id: 'COMM', title: 'Communication' },
    //   { id: 'VER', title: 'Verification' },
    //   { id: 'BILL', title: 'Billing'}
    // ];

    // const noteTypesOfChips = noteTypes.map(({id, title}) => )

    // const [ noteFilter, setNoteFilter ] = useState({
    //   note_type: noteTypes.map(({id}) => id),
    //   // @ts-ignore
    //   product_type: productTypesForPatient.map(({id}) => id)
    // });

    // const [ noteVisibility, setNoteVisibility ] = useState(false);
    // const triggerNoteVisibility = () => setNoteVisibility(!noteVisibility)

    // const addNoteSection = !noteVisibility ? <>
    //   <Button onClick={triggerNoteVisibility}>Add Note</Button>
    // </> : <>
    //   <Stack>
    //     {/* note.metadata (note_type, product_type) */}
    //     Select the type(s) of note and associated referral(s)
    //     <Chip.Group multiple
    //       // @ts-ignore 
    //       // value={productTypesForPatient.map(({id}) => id).filter( x => x == thisProductId)}
    //       // onChange={(e)=>{
    //       //   setFieldValueUponPatientNote(
    //       //     'note.data.metadata.product_type',
    //       //     e
    //       //   )
    //       // }}
    //       {...getInputPropsForPatientNote('note.data.metadata.product_type')}
    //     >
    //       {productTypesOfChips}
    //     </Chip.Group>
    //     <Chip.Group multiple
    //       // onChange={(e)=>{
    //       //   setFieldValueUponPatientNote(
    //       //     'note.data.metadata.note_type',
    //       //     e
    //       //   )
    //       // }}
    //       {...getInputPropsForPatientNote('note.data.metadata.note_type')}
    //     >
    //       <Chip value="COMM">Communication</Chip>
    //       <Chip value="VER">Verification</Chip>
    //       <Chip value="BILL">Billing</Chip>
    //     </Chip.Group>
    //     <Textarea
    //         label="Content"
    //         placeholder="Add content.  Note is automatically timestamped and associated to you."
    //         {...getInputPropsForPatientNote('note.data.content')}
    //     >
    //     </Textarea>
    //   </Stack>
    //   <br/>
    //   {/* @ts-ignore */}
    //   <Button {...patientNoteSaveButtonProps}>Submit Note</Button>
    //   &nbsp;
    //   <Button onClick={triggerNoteVisibility}>Cancel Note</Button>
    // </>

    // // @ts-ignore
    // let notesOfPatient = values?.patient?.patient_notes?.map( thing => {
    //     const {
    //         note: {
    //             id,
    //             content,
    //             created_at,
    //             authed_user: {
    //                 id: uid,
    //                 display_name,
    //                 photoURL
    //             },
    //             metadata,
    //             // note_type: {
    //             //     code  
    //             // }
    //         }
    //     } = thing;
    //     return {
    //         id,
    //         content,
    //         created_at,
    //         uid,
    //         display_name,
    //         photoURL,
    //         metadata
    //     }
    // })
    // // @ts-ignore
    // .filter( thing => {
    //     const {
    //         // content,
    //         // created_at,
    //         // display_name,
    //         // photoURL,
    //         metadata
    //     } = thing;

    //     const noteType = metadata?.note_type || [];
    //     let noteBoolean = noteFilter.note_type.some( x => {
    //       return noteType.some( (y:any) => x == y )
    //     });
        
    //     const productType = metadata?.product_type || [];
    //     let productBoolean = noteFilter.product_type.some( (x:any) => {
    //       return productType.some( (y:any) => x == y)
    //     })

    //     // if (noteFilter && noteFilter.note_type && Array.isArray(noteFilter.note_type)){
    //     //     return noteFilter.note_type.includes(code)
    //     // }

    //     // return any notes that have any of the tags matched
    //     return noteBoolean || productBoolean;
    // })
    // // @ts-ignore
    // .sort( ({created_at: a}, {created_at: b}) => {
    //     const aDay = dayjs(a);
    //     const bDay = dayjs(b);
    //     if (aDay.isAfter(bDay)){
    //         return -1
    //     }
    //     if (aDay.isBefore(bDay)){
    //         return 1
    //     }
    //     return 0
    // }).map( (thing:any,index:number) => {
    //     const {
    //         content,
    //         created_at,
    //         display_name,
    //         photoURL,
    //         metadata
    //     } = thing;
    //     const formattedDate = dayjs(created_at).format('MM-DD-YYYY HH:mm:ss');

    //     const { id: idOfNote, uid: uidOfNote } = thing;
    //     const { note_type = [], product_type = [] } = metadata;

    //     const mappedProductTypes = productTypesForPatient.filter( (x:any) => {
    //       const { id } = x;
    //       return product_type.includes(id);
    //     }).map( ({id,title}:any) => 
    //     <Chip value={id} key={id}>
    //       {title}
    //     </Chip>);

    //     const mappedNoteTypes = noteTypes.filter( (x:any) => {
    //       const { id } = x;
    //       return note_type.includes(id);
    //     }).map(({id, title}) => 
    //     <Chip value={id} key={id}>
    //       {title}
    //     </Chip>)

    //     return (
    //         <Paper withBorder radius="lg" className={classes.comment}>
    //             <Group style={{padding:20}}>
    //                 <Avatar src={photoURL} alt={display_name} radius="xl" />
    //                 <>
    //                     <Text fz="sm">{display_name}</Text>
    //                     <Text fz="xs" c="dimmed">
    //                         {formattedDate}
    //                     </Text>
    //                 </>
    //                 {uidOfNote == uid ? <Button size='xs' onClick={ () => {
    //                   const allEditNotesOff = 
    //                     Object.keys(editNote)
    //                     .filter(x => x !== idOfNote)
    //                     .map( key => [key, false])
    //                   setEditNote({
    //                     ...Object.fromEntries(allEditNotesOff),
    //                     // @ts-ignore
    //                     [idOfNote]: !editNote[idOfNote]
    //                   });
    //                   setIdOfEditNote(idOfNote);
    //                   setFieldValueUponEditNote('content', content);
    //                 }}>
    //                   {/* @ts-ignore */}
    //                   { !!!editNote[idOfNote] || editNote[idOfNote] == false ? <IconPencil></IconPencil> : <IconX></IconX>}
    //                 </Button> : <></>}
    //                 {/* transform metadata into chips */}
    //                 <Chip.Group value={product_type}>
    //                   {mappedProductTypes}
    //                 </Chip.Group>
    //                 <Chip.Group value={note_type}>
    //                   {mappedNoteTypes}
    //                 </Chip.Group>
    //                 {/* <pre>{JSON.stringify(metadata,null,2)}</pre> */}
    //             </Group>
    //             {/* @ts-ignore */}
    //             { !!!editNote[idOfNote] || editNote[idOfNote] == false ? 
    //             <Text className={classes.body} size="md">
    //                 {content}
    //             </Text> : <Textarea
    //               {...getInputPropsForEditNote('content')}
    //             />
    //             }
                
    //             {/* @ts-ignore */}
    //             { editNote[idOfNote] == true ? <Button {...editNoteSaveButtonProps}>Submit Edit</Button> : <></>}
    //         </Paper>
    //     )
    // });

    //for whatever reason, need to do this to ensure that the patient_id 
    //is defined upon the patient_note form
    useEffect(() => {
        if(initialValues && initialValues.patient_id){
            // patientNoteValues.patient_id = initialValues.patient_id
        }
        if (initialValues && initialValues.id){
            extensionValues.referral_id = initialValues.id
            addResupplyValues.referral_id = initialValues.id
        }
    }, [initialValues])



    const {
      data: {
        // @ts-ignore
        data: injurySiteLookup
      } = {}
    } = useList({
        resource: 'injury_site',

        meta:{
          fields: [ 'id', 'title'],
        },

        pagination: {
          pageSize: 1000
        }
    });

    // @ts-ignore
    const injurySites = values?.injury_sites?.map((item, index) => (
      <Group mt="xs">
        <Select
            mt={8}
            label="Injury Site"
            placeholder="Type in an Injury Site."
            {...getInputProps(`injury_sites.${index}`)}
            searchable={true}
            clearable={true}
            filterDataOnExactSearchMatch={true}
            data={injurySiteLookup?.map(({id,title}) => ({value:title, label:title})) as any}
            style={{ maxWidth: 300 }}
        />
        <ActionIcon color="red" onClick={() => removeListItem('injury_sites', index)}>
          {/* @ts-ignore */}
          <CloseButton size="1rem" />
        </ActionIcon>
      </Group>
    ));
  
      // @ts-ignore
      const icd10_codes = values?.icd10_codes?.map((item, index) => (
          <Group mt="xs">
            <TextInput
                mt={8}
                label="ICD 10 code"
                placeholder="Type in a Code."
                {...getInputProps(`icd10_codes.${index}`)}
                // {...injurySiteProps}
                style={{ maxWidth: 300 }}
            />
            <ActionIcon color="red" onClick={() => removeListItem('icd10_codes', index)}>
              <CloseButton 
              // @ts-ignore
              size="1rem" />
            </ActionIcon>
          </Group>
        ));

        // to add back
    // const {
    //     values: patientTemplateAnswersValues,
    //     getInputProps: getInputPropsForPatientTemplateAnswers,
    //     onSubmit: onSubmitOfPatientTemplateAnswers,
    //     saveButtonProps: patientTemplateAnswersSaveButtonProps,
    //     // reset,
    //     setFieldValue: setFieldValueOfPatientTemplateAnswers
    // } = useForm({
    //     initialValues: {
    //         id: initialValues?.patient_id,
    //         template_answers: initialValues?.patient?.template_answers,
    //     },
    //     refineCoreProps:{
    //         onMutationSuccess: ({data}, variables, context) => {
    //             // insertListItem('patient.patient_notes',data)
    //             // reset()
    //         },
    //         id: initialValues?.patient_id,
    //         queryOptions: {
    //             enabled: initialValues?.patient_id !== null
    //         },
    //         action: "edit",
    //         resource: "patient",
    //         redirect: false,
    //         // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
    //         metaData: {
    //             fields: [
    //                 'template_answers'
    //             ]
    //         }
    //     },
    // })

    // to add back
    // const { 
    //     data: {
    //         data: templateForms = []
    //     } = {}
    // } = useOne({
    //     resource: "patient_template",
    //     id: initialValues?.patient_id || "",
    //     metaData:{
    //         fields: [],
    //     },
    //     queryOptions: { enabled: initialValues?.patient_id !== null },
    // })

    // const TemplateTabItems = templateForms
    // // @ts-ignore
    // .filter(({title}) => {
    //   if (title == 'VERIFICATION'){
    //     return clientId == '279a1514-9914-49b2-af74-027610efd80f'
    //   }
    //   return true
    // })
    // .map( (template: any) => (
    //     <Tabs.Tab value={template.title}>{template.title}</Tabs.Tab>
    // ))

    // @ts-ignore
    const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    );

    // const dp = datePickerValue(values);

    // //@ts-ignore to add back
    // const generateField = ({id, type, title}) => {
    //     switch (type){
    //         case 'boolean':
    //             return (
    //                 <Select
    //                     mt={8}
    //                     label={title}
    //                     // type="date"
    //                     {...getInputPropsForPatientTemplateAnswers(`template_answers.${id}`)}
    //                     style={{ maxWidth: 400 }}
    //                     data={[
    //                         {value: true, label: "Yes"},
    //                         {value: false, label: "No"},
    //                         {value: null, label: "-/-"}
    //                     ]}
    //                 />
    //             )
    //         case 'date':
    //             return (
    //                 <DatePicker
    //                     mt="md"
    //                     label={title}
    //                     withinPortal
    //                     {...getInputPropsForPatientTemplateAnswers(`template_answers.${id}`)}
    //                     value={dp(`template_answers.${id}`)}
    //                     style={{ maxWidth: 400 }}
    //                 />
    //             )
    //         default:
    //             return (
    //                 <TextInput
    //                     mt={8}
    //                     label={title}
    //                     {...getInputPropsForPatientTemplateAnswers(`template_answers.${id}`)}
    //                     style={{ maxWidth: 400 }}
    //                 />
    //             ) 
    //     }
    // }

    // to add back
    // const TemplateTabPanels = templateForms.map( (template: any) => (
    //     <Tabs.Panel value={template.title}>
    //         {/* <pre>{JSON.stringify(template.sections,null,2)}</pre> */}
    //         {template.sections.map( (section: any) => 
    //             (
    //                 <>
    //                     <br/>
    //                     <Title order={3}>{section.title}</Title>
    //                     <Paper withBorder p="sm" shadow="md" radius="md">
    //                         {chunk(section.fields, 2).map( (thing: any) => {
    //                             const [
    //                                 {
    //                                     id: id0,
    //                                     field: { title: title0, type: type0 }
    //                                 },
    //                                 {
    //                                     id: id1 = null,
    //                                     field: { title: title1 = null, type: type1 = null } = {}
    //                                 } = {}
    //                             ] = thing
    //                             // return renderedTemplateQuestions[id]
    //                             return (
    //                                 <Grid>
    //                                     <Grid.Col span={6}>
    //                                         {generateField({id: id0, type: type0, title: title0})}
    //                                         {/* <TextInput
    //                                             mt={8}
    //                                             label={`${title0}`}
    //                                             // type="date"
    //                                             // placeholder={title0}
    //                                             {...getInputPropsForPatientTemplateAnswers(`template_answers.${id0}`)}
    //                                             style={{ maxWidth: 400 }}
    //                                         /> */}
    //                                     </Grid.Col>
    //                                     { id1 && generateField({id: id1, type: type1, title: title1})
    //                                     // (
    //                                     //    <Grid.Col span={6}>
    //                                     //         <TextInput
    //                                     //             mt={8}
    //                                     //             label={`${title1}`}
    //                                     //             // type="date"
    //                                     //             // placeholder={title1}
    //                                     //             {...getInputPropsForPatientTemplateAnswers(`template_answers.${id1}`)}
    //                                     //             style={{ maxWidth: 400 }}
    //                                     //         />
    //                                     //     </Grid.Col> 
    //                                     // )
    //                                     }
    //                                 </Grid>
                                    
    //                             )
    //                         })}
    //                     </Paper>
    //                 </>
    //             )
    //         )}
    //     </Tabs.Panel>
    // ) )

    // // @ts-ignore
    // const oldNotesTab = values?.patient?.rep_communication_notes || values?.patient?.verification_notes ? (
    //     <Tabs.Tab value="old_notes">
    //         Old Notes
    //     </Tabs.Tab>) : null;
    
    //     // @ts-ignore
    // const oldNotesTabPanel = values?.patient?.rep_communication_notes || values?.patient?.verification_notes ? (
    // <Tabs.Panel value="old_notes">
    //     <Tabs defaultValue='verification_and_billing'>
    //         <Tabs.List>
    //             <Tabs.Tab value='verification_and_billing'>Verification and Billing</Tabs.Tab>
    //             <Tabs.Tab value="rep_com_notes">Rep and Com</Tabs.Tab>
    //         </Tabs.List>
    //         <Tabs.Panel value='verification_and_billing'>
    //             <Textarea
    //                 mt={8}
    //                 label="Verification and Billing Notes"
    //                 placeholder="Verification Notes"
    //                 {...getInputProps('patient.verification_notes')}
    //                 minRows={8}
    //                 style={{ maxWidth: 900 }}
    //             />
    //         </Tabs.Panel>
    //         <Tabs.Panel value='rep_com_notes'>
    //             <Textarea
    //                 mt={8}
    //                 label="Rep Communication Notes"
    //                 placeholder="Rep Communication Notes"
    //                 {...getInputProps('patient.rep_communication_notes')}
    //                 minRows={8}
    //                 style={{ maxWidth: 900 }}
    //             />
    //         </Tabs.Panel>
    //     </Tabs>
    // </Tabs.Panel>
    // ) : null;

    // const automated_emails = <>
    //   {/* @ts-ignore */}
    //   <Switch size='lg' onLabel='SENT' offLabel='NOT' label='NEW' checked={values?.automated_email_status?.newReferral}/>
    //   {/* @ts-ignore */}
    //   <Switch size='lg' onLabel='SENT' offLabel='NOT' label='RTF' checked={values?.automated_email_status?.readyToFit}/>
    //   {/* @ts-ignore */}
    //   <Switch size='lg' onLabel='SENT' offLabel='NOT' label='USDL' checked={values?.automated_email_status?.usdl}/>
    //   {/* @ts-ignore */}
    //   <Switch size='lg' onLabel='SENT' offLabel='NOT' label='DD' checked={values?.automated_email_status?.deadDeal}/>
    // </>;

    // const otherReferrals = 
    // // @ts-ignore
    // values?.patient?.referrals?.filter( (referral: any) => {
    //   const idOfOtherReferral = referral?.id;
    //   return idOfOtherReferral !== values?.id
    // })?.map( (referral:any) => {
  
    //   // TODO figure out why this hack has to be used rather then
    //   // just plain old destructuring
    //   const product_type = referral?.product_type?.title;
    //   const insurance = referral?.insurance?.title;
    //   const insurance_status = referral?.insurance_status?.title;
    //   const patient_status = referral?.patient_status?.title;
    //   const claim_type = referral?.claim_type?.title;
    //   const rx_length = referral?.rx_length?.title;
  
    //   const {
    //     id,
    //   } = referral
  
    //   return (
    //       <tr key={id}>
    //         <td>
    //           <EditButton
    //             recordItemId={id}
    //             resource='operations/referral'
    //           >
    
    //           </EditButton>
    //         </td>
    //         <td>{product_type}</td>
    //         <td>{rx_length}</td>
    //         <td>{insurance}</td>
    //         <td>{claim_type}</td>
    //         <td>{insurance_status}</td>
    //         <td>{patient_status}</td>
    //       </tr>
    //   );
    // });

    // const selectProps = {
    //   productTypeSelectProps,
    //   claimTypeSelectProps,
    //   insuranceSelectProps,
    //   documentStatusSelectProps,
    //   reauthStatusSelectProps,
    //   extensionStatusSelectProps,
    // };

    // const lookups = {
    //   itemLookup
    // };

    // const forms = {
    //   editReferralForm,
    //   addExtensionForm,
    //   addSubmissionForm,
    //   addResupplyForm,
    // };

    // const state = {
    //   submissionFormVisibility , setSubmisisonFormVisibility,
    //   extensionVisibility, setExtensionVisibility,
    //   resupplyVisibility, setResupplyVisibility,
    // };

    // const SelectedReferralComponent = ({
    //   //@ts-ignore
    //   referral,
    //   //@ts-ignore
    //   forms,
    //   //@ts-ignore
    //   selectProps,
    //   //@ts-ignore
    //   lookups
    // }) => {

      // !! STATE !!
      // const {
      //   submissionFormVisibility , setSubmisisonFormVisibility,
      //   extensionVisibility, setExtensionVisibility,
      //   resupplyVisibility, setResupplyVisibility,
      // } = state;

      // !! FORMS !!
      // const {
      //   editReferralForm,
      //   addExtensionForm,
      //   addSubmissionForm,
      //   addResupplyForm,
      // } = forms;

      const referral = values;

      // editReferralForm
      const {
        values: editReferralValues,
        setValues: editReferralSetValues,
        getInputProps: getInputPropsForEditReferral,
        saveButtonProps: saveButtonPropsForEditReferral,
        reset: resetOfEditReferral,
        setFieldValue: setFieldValueUponEditReferral,
        setId: setIdOfEditReferral
      } = editReferralForm;

      // addExtensionForm
      const {
        // values: extensionValues,
        // getInputProps: getInputPropsForExtension,
        // onSubmit: onSubmitOfExtension,
        // saveButtonProps: extensionSaveButtonProps,
        // reset: resetOfExtension,
        // setFieldValue: setFieldValueUponExtension
      } = addExtensionForm

      // addSubmissionForm
      const {
        // values: submissionValues,
        // getInputProps: getInputPropsForSubmission,
        // onSubmit: onSubmitOfSubmission,
        // saveButtonProps: submissionSaveButtonProps,
        // reset: resetOfSubmission,
        // setFieldValue: setFieldValueUponSubmission,
        // removeListItem: removeListItemOfSubmission,
        // insertListItem: insertListItemOfSubmission
      } = addSubmissionForm;

      // addResupplyForm
      const {
        // values: addResupplyValues,
        // getInputProps: getInputPropsForAddResupply,
        // onSubmit: onSubmitOfAddResupply,
        // saveButtonProps: addResupplySaveButtonProps,
        // reset: resetOfAddResupply,
        // setFieldValue: setFieldValueUponAddResupply
      } = addResupplyForm;

      // !! SELECTPROPS !!
      // const {
      //   productSelectTypes,
      //   claimTypeSelectProps,
      //   insuranceSelectProps,
      //   documentStatusSelectProps,
      //   reauthStatusSelectProps,
      //   extensionStatusSelectProps,
      //   fitterSelectProps
      // } = selectProps;

      // !! LOOKUPS !!
      // const {
      //   itemLookup
      // } = lookups;

      // !! COMPONENT PARTIALS !!

      // extensions
      // @ts-ignore
      const extensions = referral?.extensions
      // @ts-ignore
      ?.sort((a,b) => {
          const {
              extended_to_date: aDate
          } = a;
          const {
              extended_to_date: bDate
          } = b;
          // @ts-ignore
          return new Date(bDate) - new Date(aDate)
      })
      // @ts-ignore
      ?.map( (item, index) => {

          // TODO unify destructuring via resolving dumb error below
          const insurance_override = item?.insurance_override?.title;
          const reauth_lookup = item?.reauth_lookup?.title;
          const extension_status = item?.extension_status?.title;

          const {
              id,
              extended_to_date,

              // For whatever dumb reason, this destructuring code does not work
              // i.e. Cannot read properties of null (reading 'title')
              // even if there is a default value

              // insurance_override: { title: insurance_override = "" } ={ title: ""},
              // reauth_lookup: { title: reauth_lookup = ""} ={},
              rx_expiration_date,
          } = item

          //TODO: LEFT MERGE INSURANCE OF REFERRAL TO OVERRIDE INSURANCE

          return (
              <tr key={id}>
                  <td>
                      <EditButton
                          recordItemId={id}
                          resource='operations/extension'
                      >
                      </EditButton>
                  </td>
                  <td>{extended_to_date}</td>
                  <td>{extension_status}</td>
                  <td>{insurance_override}</td>
                  {/* <td>{reauth_lookup}</td> */}
                  <td>{rx_expiration_date}</td>
              </tr>
          );
          
      });

      // @ts-ignore
      const resupply_dates = referral?.resupplies
      // @ts-ignore
      ?.sort((a,b) => {
          const {
              resupply_date: aDate
          } = a;
          const {
              resupply_date: bDate
          } = b;
          // @ts-ignore
          return new Date(bDate) - new Date(aDate)
          // @ts-ignore
      })?.map( (item, index) => {
          const {
              id,
              resupply_date
          } = item
          return (
              <tr key={id}>
                  <td>{resupply_date}</td>
              </tr>
          )
      });

      // @ts-ignore
      const resupply_items = referral?.resupply_items?.map((item, index) => (
        <Group mt="xs">
          {/* TODO fix the lookup to not use mzResupplyLookupItemSelectProps */}
          <Select
              mt={8}
              label="Resupply Item"
              placeholder="Type in a Resupply Item."
              {...getInputProps(`resupply_items.${index}`)}
              searchable={true}
              clearable={true}
              filterDataOnExactSearchMatch={true}
              // @ts-ignore
              data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
              style={{ maxWidth: 300 }}
          />
          <ActionIcon color="red" onClick={() => removeListItem('resupply_items', index)}>
            <CloseButton 
            // @ts-ignore
            size="1rem" />
          </ActionIcon>
        </Group>
      ));

      // @ts-ignore
      const itemsOfSubmissionForm = submissionValues.items.map( (item,index) => <Group>
        <Divider my="xs" label={`Item #${index+1}`} />
        {/* TODO when moving logic of referral view to patient-referrals view, consider below */}
        {/* <Select
          mt={8}
          label="Product Type"
          {...getInputPropsForSubmission(`items.${index}.product_type`)}
          {...productTypeSelectProps2}
          style={{ maxWidth: 300 }}
        /> */}
        {/* @ts-ignore */}
        {/* {['SCOOTER','TCT'].includes(submissionValues?.items[index]?.product_type) && <>
          Product Lookup
        </>}  */}
        <Select
            mt={8}
            // label="Item"
            placeholder="Search for an Item."
            {...getInputPropsForSubmission(`items.${index}`)}
            searchable={true}
            clearable={true}
            filterDataOnExactSearchMatch={true}
            // @ts-ignore
            data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
            style={{ maxWidth: 300 }}
        />
        <ActionIcon color="red" onClick={() => removeListItemOfSubmission('items', index)}>
          <CloseButton 
          // @ts-ignore
          size="1rem" />
        </ActionIcon>
      </Group>);

      let labelForSubmission = '';
      switch (submissionValues.submission_type){
        case 'shipment_request':
          labelForSubmission = 'Shipment Request';
          break;
        case 'delivery_ticket':
          labelForSubmission = 'Delivery Ticket';
          break;
      }

      const submissionFormVisibilityButton = labelForSubmission && (!submissionFormVisibility ? 
      <Button onClick={() => setSubmisisonFormVisibility(true)}>
        Add a {labelForSubmission}
      </Button> :
      <Button onClick={() => setSubmisisonFormVisibility(false)} color='red'>
        Abort this {labelForSubmission}
      </Button>);

      const submissionForm = (submissionValues.submission_type && submissionFormVisibility) && <>
        <Text fz='lg' fw='600'>Add Another Submisison</Text>
        <Select
          mt={8}
          label="URGENCY"
          {...getInputPropsForSubmission('urgency')}
          style={{ maxWidth: 300 }}
          data={[
            {value:'SURGICAL', label:'SURGICAL'},
            {value:'IMEDIATE', label:'IMEDIATE'},
            {value:null as any, label:'-/-'}
          ]}
        />
        { labelForSubmission == 'Shipment Request' && <>
          {/* <DatePicker
                mt="md"
                label="DATE SHIPPED"
                withinPortal
                {...getInputPropsForSubmission(`date_shipped`)}
                value={datePickerValue(submissionValues)(`date_shipped`)}
                style={{ maxWidth: 300 }}
            />
          <TextInput
              mt={8}
              label="TRACKING #"
              // type="date"
              // placeholder="Serial Number"
              {...getInputPropsForSubmission("tracking_number")}
              style={{ maxWidth: 300 }}
          /> */}
        </> }
        { labelForSubmission == 'Delivery Ticket' && <>
          <Select
            mt={8}
            label="FITTER"
            {...getInputPropsForSubmission('fitter_id')}
            style={{ maxWidth: 300 }}
            {...fitterSelectProps}
            // data={productSelectProps.data}
          />
        </>}
        <br/>
        <Text>Items*</Text>
        {itemsOfSubmissionForm}
        <Group mt="md">
            <Button
            onClick={() =>{
              insertListItemOfSubmission('items', '');
            }}
            >
              {/* @ts-ignore */}
            Add { itemsOfSubmissionForm.length > 0 ? 'another ' : ''} 
            {/* @ts-ignore */}
            Item to this {referral.product_type?.title} {labelForSubmission}
            </Button>
        </Group>
        <br/>
        <Divider/>
        <br/>
        {/* @ts-ignore */}
        <Button {...submissionSaveButtonProps}>Submit {referral.product_type?.title} {labelForSubmission}</Button>
      </>;

      // @ts-ignore
      const shipmentRequestSubmissions = referral?.submissions?.filter( (submission) => {
        return submission.submission_type == 'shipment_request'
        // @ts-ignore
      })?.map((thing, index) => {
        const urgency = thing?.urgency;
        const serial_number = thing?.serial_number;
        const date_shipped = thing?.date_shipped;
        const date_fit = thing?.referral?.date_fit;
        const tracking_number = thing?.tracking_number;

        let status = 'READY TO SHIP';
        if ( serial_number && date_shipped && date_fit && tracking_number){
          status = 'COMPLETE'
        } else if ( serial_number && date_shipped && !date_fit && tracking_number ){
          status = 'IN TRANSIT'
        }

        // @ts-ignore
        const items = thing?.items?.map?.( (item) => {
          // @ts-ignore
          const matchedItems = itemLookup.filter( x => x.id == item );
          if (matchedItems.length == 1){
            const [{title: matchedItem}] = matchedItems;
            return matchedItem 
          }
          return 'something'
        }).join(', ')
        return <tr key={index}>
          <td>{urgency}</td>
          <td>{status}</td>
          <td>{date_shipped}</td>
          <td>{items}</td>
        </tr>
      });

      // @ts-ignore
      const shipmentRequestSubmissionsTable = referral?.submissions?.length > 0 ? <>
      <Text fz='lg' fw='600'>Shipment Requests</Text>
      <Table>
        <thead>
          <tr>
            <th>URGENCY</th>
            <th>STATUS</th>
            <th>DATE FIT</th>
            <th>ITEMS</th>
          </tr>
        </thead>
        <tbody>{shipmentRequestSubmissions}</tbody>
      </Table></> : <Text color="dimmed" align="center">
        {/* @ts-ignore */}
        No Shipment Requests for this {referral?.product_type?.title} referral.
      </Text>;

      // @ts-ignore
      const deliveryTicketSubmissions = referral?.submissions?.filter( (submission) => {
        return submission.submission_type == 'delivery_ticket'
        // @ts-ignore
      })?.map((thing, index) => {
        const urgency = thing?.urgency;
        const serial_number = thing?.serial_number;
        // const date_shipped = thing?.date_shipped;

        //TODO
        // @ts-ignore
        const date_fit = referral?.date_fit as String;
        const fitter = thing?.fitter?.title;
        // const tracking_number = thing?.tracking_number;

        // TODO audit
        let status = 'NEEDS SERIAL #';
        if ( serial_number && !date_fit ){
          status = 'INCOMPLETE';
        } else if ( serial_number && date_fit ){
          status = 'COMPLETE';
        }

        // @ts-ignore
        const items = thing?.items?.map?.( (item) => {
          // @ts-ignore
          const matchedItems = itemLookup.filter( x => x.id == item );
          if (matchedItems.length == 1){
            const [{title: matchedItem}] = matchedItems;
            return matchedItem 
          }
          return 'something'
        }).join(', ')
        return <tr key={index}>
          <td>{urgency}</td>
          <td>{status}</td>
          <td>{fitter}</td>
          <td>{date_fit}</td>
          <td>{items}</td>
        </tr>
      });

      // @ts-ignore
      const deliveryTicketSubmissionsTable = referral?.submissions?.length > 0 ? <>
        <Text fz='lg' fw='600'>Delivery Tickets</Text>
        <Table>
          <thead>
            <tr>
              <th>URGENCY</th>
              <th>STATUS</th>
              <th>FITTER</th>
              <th>DATE FIT</th>
              <th>ITEMS</th>
            </tr>
          </thead>
          <tbody>{deliveryTicketSubmissions}</tbody>
        </Table>
      </> : <Text color="dimmed" align="center">
        {/* @ts-ignore */}
        No Delivery Tickets for this {referral?.product_type?.title} referral.
      </Text>;

      // const [ extensionVisibility, setExtensionVisibility ] = useState(false);
      // const [ resupplyVisibility, setResupplyVisibility ] = useState(false);

      const dp = datePickerValue(referral);

      const automated_emails = <>
        {/* @ts-ignore */}
        <Switch size='lg' onLabel='SENT' offLabel='NOT' label='NEW' checked={referral?.automated_email_status?.newReferral}/>
        {/* @ts-ignore */}
        <Switch size='lg' onLabel='SENT' offLabel='NOT' label='RTF' checked={referral?.automated_email_status?.readyToFit}/>
        {/* @ts-ignore */}
        <Switch size='lg' onLabel='SENT' offLabel='NOT' label='USDL' checked={referral?.automated_email_status?.usdl}/>
        {/* @ts-ignore */}
        <Switch size='lg' onLabel='SENT' offLabel='NOT' label='DD' checked={referral?.automated_email_status?.deadDeal}/>
      </>;

      // !! PANELS OF REFERRAL !!

      const mainPanel = <Tabs.Panel value="main">
        <TextInput
            mt={8}
            label="Product"
            disabled
            {...getInputProps("product_title")}
            style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Product Type"
            // placeholder="Client"
            {...getInputProps('product_type_id')}
            {...productTypeSelectProps}
            style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Insurance"
            // placeholder="Client"
            {...getInputProps('insurance_id')}
            {...insuranceSelectProps}
            // onChange={(value) => {
            //   console.log(value)
            // }}
            style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Claim Type"
            // placeholder="Client"
            {...getInputProps('claim_type_id')}
            {...claimTypeSelectProps}
            style={{ maxWidth: 300 }}
        />
        <DatePicker
            mt="md"
            label="Rx Rec'd Date"
            placeholder="Rx Rec'd Date"
            withinPortal
            {...getInputProps("rx_received_date")}
            value={dp("rx_received_date")}
            style={{ maxWidth: 300 }}
        />
        <Select
          mt={8}
          label="RX Length"
          {...getInputProps(`rx_length_id`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          data={rxLengthLookup?.map(({id,title}) => ({value:id, label:title})) as any}
          style={{ maxWidth: 300 }}
        />
        <DatePicker
            mt="md"
            label="Rx Expiration Date"
            placeholder="Rx Expiration Date"
            withinPortal
            {...getInputProps("rx_expiration_date")}
            value={dp("rx_expiration_date")}
            style={{ maxWidth: 300 }}
        />
        <DatePicker
            mt="md"
            label="Date Fit"
            // placeholder="TCT five day call"
            withinPortal
            // TODO figure out why new values can not be set (maybe a stray useEffect)
            {...getInputProps("date_fit")}
            value={dp("date_fit")}
            style={{ maxWidth: 300 }}
            onChange={(value)=>{
                if (value){
                    const aDate = dayjs(value);
                    if (!values.five_day_call){
                        setFieldValue('five_day_call', aDate.add(5, 'day').toISOString());
                    }
                    if (!values.fourteen_day_call){
                        setFieldValue('fourteen_day_call', aDate.add(14, 'day').toISOString());
                    }
                    setFieldValue('date_fit', aDate.toISOString());
                } else {
                  setFieldValue('date_fit', null);
                }
            }}
        />
        {/* TODO */}
        <DatePicker
            mt="md"
            label="Date of Injury"
            placeholder="Date of Injury"
            withinPortal
            {...getInputProps("date_of_injury")}
            value={dp("date_of_injury")}
            style={{ maxWidth: 300 }}
        />
        {  isAffectrixClientId(clientId) && <>
          <Select
            mt={8}
            label="X (# of days notified)"
            placeholder="# of days notified"
            {...getInputProps("column_b")}
            style={{ maxWidth: 300 }}
            data={[
              {value: 30 as any, label:'30'},
              {value: 20 as any, label:'20'},
              {value: 10 as any, label:'10'},
              {value: 5 as any, label: '5'},
              {value: null as any, label: 'NONE'}
            ]}
          />
          <Select
            mt={8}
            label="!"
            {...getInputProps("column_d")}
            style={{ maxWidth: 300 }}
            clearable
            data={[
              {value: '!', label: '!'},
              {value: 'AC', label: 'AC'},
              {value: 'ACP', label: 'ACP'},
              {value: 'BV', label: 'BV'},
              {value: 'CB', label: 'CB'},
              {value: 'G', label: 'G'},
              {value: 'RA', label: 'RA'},
              {value: 'P', label: 'P'}
            ]}
          />
        </>}
      </Tabs.Panel>;

      const contactPanel = <Tabs.Panel value="contact">
        <DatePicker
            mt="md"
            label="Five Day Call"
            // placeholder="TCT five day call"
            withinPortal
            {...getInputProps("five_day_call")}
            value={dp("five_day_call")}
            style={{ maxWidth: 300 }}
        />
        <TextInput
            mt={8}
            label="Five Day Call Status"
            // type="date"
            // placeholder="Document Status"
            {...getInputProps("five_day_call_status")}
            style={{ maxWidth: 300 }}
        />
        <DatePicker
            mt="md"
            label="Fourteen Day Call"
            // placeholder="TCT fourteen day call"
            withinPortal
            {...getInputProps("fourteen_day_call")}
            value={dp("fourteen_day_call")}
            style={{ maxWidth: 300 }}
        />
        <TextInput
            mt={8}
            label="Fourteen Day Call Status"
            // type="date"
            // placeholder="Document Status"
            {...getInputProps("fourteen_day_call_status")}
            style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Document Status"
            // placeholder="Client"
            {...getInputProps('document_status_id')}
            {...documentStatusSelectProps}
            style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Survey Complete"
            // type="date"
            style={{ maxWidth: 300 }}
            {...getInputProps("survey_complete")}
            data={[
                {value: true as any, label: "Yes"},
                {value: false as any, label: "No"},
                {value: null as any, label: "-/-"}
            ]}
        />
      </Tabs.Panel>;

      const providerPanel = <Tabs.Panel value='provider'>
        <TextInput
            mt={8}
            label="Provider Name"
            // type="date"
            // placeholder="Serial Number"
            {...getInputProps("provider.name")}
            disabled
            style={{ maxWidth: 300 }}
        />
        <TextInput
            mt={8}
            label="NPI"
            // type="date"
            // placeholder="Serial Number"
            {...getInputProps("provider.npi")}
            disabled
            style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>;

      const billingPanel = <Tabs.Panel value="billing">
        
      </Tabs.Panel>

      // @ts-ignore
      const extensionPanel = referral?.product_type?.title == "TCT" && <Tabs.Panel value="extensions">          
        <Select
          mt={8}
          label="REAUTH STATUS"
          {...getInputPropsForSubmission('reauth_status_id')}
          {...reauthStatusSelectProps}
          style={{ maxWidth: 300 }}
        />

        <br/>
        { extensionVisibility == false ? <>
            <></>
            <Button
            onClick={
                () => setExtensionVisibility(true)
            }>Add Extension</Button>
            <br></br>
        </>: <>
        <></>
            <Button
            onClick={
                () => {
                    setExtensionVisibility(false)
                    resetOfExtension()
                }
            }>Cancel Extension</Button>
            <SwizzledCreate 
                saveButtonProps={{
                    ...extensionSaveButtonProps,
                    style:{ 
                        maxWidth: 300 ,
                        // @ts-ignore
                        ta:'left'
                    },
                    children: <Text>Add Extension</Text>
                }}
                breadcrumb={<></>}
                title={<></>}
                goBack={false} 
            >
                <Stack>  
                    <DatePicker
                        mt="md"
                        label="Extension Date"
                        placeholder="Extension Date"
                        withinPortal
                        {...getInputPropsForExtension("extended_to_date")}
                        value={datePickerValue(extensionValues)('extended_to_date')}
                        style={{ maxWidth: 300 }}
                    />
                    <Select
                        mt={8}
                        label="Extension Status"
                        // placeholder="Client"
                        {...getInputPropsForExtension('extension_status_id')}
                        {...extensionStatusSelectProps}
                        style={{ maxWidth: 300 }}
                    />
                    <DatePicker
                        mt="md"
                        label="Rx Expiration Date"
                        placeholder="Rx Expiration Date"
                        withinPortal
                        {...getInputPropsForExtension("rx_expiration_date")}
                        value={datePickerValue(extensionValues)('rx_expiration_date')}
                        style={{ maxWidth: 300 }}
                    />
                </Stack>
            </SwizzledCreate>
        </>}
        {extensions?.length > 0 ? (
            <Table>
                <thead>
                    <tr>
                        <th>Edit</th>
                        <th>Extension Date</th>
                        <th>Status</th>
                        <th>Insurance</th>
                        {/* <th>Reauth</th> */}
                        <th>Rx Expiration Date</th>
                    </tr>
                </thead>
                <tbody>{extensions}</tbody>
            </Table>
            ) : (
                <Text color="dimmed" align="center">
                    No Extensions for this patient.
                </Text>
            )}
      </Tabs.Panel>;

      // @ts-ignore
      const resuppliesPanel = referral?.product_type?.title == "NMES" && <Tabs.Panel value="resupplies">
        <Grid>
            <Grid.Col span={6}>
                {resupply_items?.length > 0 ? (
                    <Group mb="xs">
                        <Text weight={500}>
                            Resupply Item(s)
                        </Text>
                    </Group>
                ) : <Text color="dimmed" align="center">
                        No resupply items defined
                </Text>}

                {resupply_items}

                <Group mt="md">
                    <Button
                    disabled={resupply_items?.length >= 2 ? true : false}
                    onClick={() =>
                        insertListItem('resupply_items', '')
                    }
                    >
                    Add Another Resupply Item
                    </Button>
                </Group>
            </Grid.Col>
            <Grid.Col span={6}>
            { resupplyVisibility == false ? <>
        <></>
        <Button
        onClick={
            () => setResupplyVisibility(true)
        }>Add Resupply</Button>
        <br></br>
    </> : <>
        <Button
            onClick={
                () => {
                    setResupplyVisibility(false)
                    resetOfAddResupply()
                }
            }>Cancel Resupply</Button>
            <SwizzledCreate 
                saveButtonProps={{
                    ...addResupplySaveButtonProps,
                    style:{ 
                        maxWidth: 300 ,
                        // @ts-ignore
                        ta:'left'
                    },
                    children: <Text>Add Resupply Date</Text>
                }}
                breadcrumb={<></>}
                title={<></>}
                goBack={false}
                
            >
            <Stack>  
                <DatePicker
                    mt="md"
                    label="Resupply Date"
                    placeholder="Resupply Date"
                    withinPortal
                    {...getInputPropsForAddResupply("resupply_date")}
                    value={datePickerValue(addResupplyValues)('resupply_date')}
                    style={{ maxWidth: 300 }}
                />
            </Stack>
        </SwizzledCreate>
    </>}
    {/* @ts-ignore */}
    { values?.resupplies?.length ? (
            <Text color="dimmed" align="center">
                No Resupply Dates for this patient.
            </Text>
        ) : <Text color="dimmed" align="center">
        Resupply Dates for this patient.
    </Text>}
    
    {resupply_dates?.length > 0 ?? (
        <Table>
            <thead>
                <tr>
                    <th>Resupply Date</th>
                </tr>
            </thead>
            <tbody>{resupply_dates}</tbody>
        </Table>
        )}
            </Grid.Col>
        </Grid>
    
      </Tabs.Panel>;

      const fulfillmentPanel = <Tabs.Panel value="fulfillment">
          <Tabs onTabChange={(tab) => {
            if (tab == 'shipment_requests'){
              setFieldValueUponSubmission('submission_type','shipment_request')
            } else if (tab == 'delivery_tickets'){
              setFieldValueUponSubmission('submission_type','delivery_ticket')
            }
          }}>
            <Tabs.List>
                <Tabs.Tab value="shipment_requests">Shipment Request(s)</Tabs.Tab>
                <Tabs.Tab value="delivery_tickets">Delivery Ticket For Fitter(s)</Tabs.Tab>
            </Tabs.List>
            
            {submissionForm}
            <br/>
          <Tabs.Panel value="shipment_requests">
            <br/>                       
            {submissionFormVisibilityButton}
            <br/>
            <br/>
            {shipmentRequestSubmissionsTable}
            <br/>
            {/* // shipped through courier

            items get filled out first

            //once items are defined, and end user specifies the type of shipment Request (NMES|TCT|SCOOTER), and submits

            // then karen sees it in her queue
            SERIAL NUMBER (free-form v. lookup based on product type), DATE SHIPPED,	TRACKING NUMBER

            // also pops up in another queue
            DATE FIT

            //Produces a different delivery ticket FOR BILLING */}
          </Tabs.Panel>
          <Tabs.Panel value="delivery_tickets">
            <br/>
            {submissionFormVisibilityButton}
            <br/>
            <br/>
            {deliveryTicketSubmissionsTable}
            <br/>
          {/*   
            // delivered via fitter

            // DATE FIT, FITTER, // ClAIM	TYPE,	EMPLOYER,	DOI, ATTORNEY

            // SERIAL NUMBER*/}
          </Tabs.Panel>
        </Tabs>
        
      </Tabs.Panel>;

      const automatedEmailsPanel = <Tabs.Panel value="automated_emails">
        {automated_emails}
      </Tabs.Panel>;

      // result
      const selectedReferralSection = <>
        <Tabs defaultValue='main'>
          <Tabs.List>
            <Tabs.Tab value="main">Main</Tabs.Tab>
            <Tabs.Tab value="contact">Contact</Tabs.Tab>
            { !!referral?.provider_id && <Tabs.Tab value="provider">Provider</Tabs.Tab>}
            <Tabs.Tab value="billing">Billing</Tabs.Tab>
            {/* @ts-ignore */}
            {referral?.product_type?.title == "TCT" && <Tabs.Tab value="extensions">Extensions</Tabs.Tab>}
            {/* @ts-ignore */}
            {referral?.product_type?.title == "NMES" && <Tabs.Tab value="resupplies">Resupplies</Tabs.Tab>}
            <Tabs.Tab value="fulfillment">Fulfillment</Tabs.Tab>
            <Tabs.Tab value="automated_emails">Automated Emails</Tabs.Tab>
          </Tabs.List>
          {mainPanel}
          {contactPanel}
          { !!referral?.provider_id && providerPanel}
          {billingPanel}
          {extensionPanel}
          {resuppliesPanel}
          {fulfillmentPanel}
          {automatedEmailsPanel}
        </Tabs>
      </>
    // };

    // const selectedReferralSection = values?.id && <Tabs.Panel value="selectedReferral">
    //   {SelectedReferralComponent({
    //     // @ts-ignore
    //     referral: values,
    //     lookups,
    //     selectProps,
    //     forms
    //   })}
    // </Tabs.Panel>

    return <>
    {/* @ts-ignore <Title order={3}>`${values?.product_type?.title} Referral`</Title>} */}
      {selectedReferralSection}
      <pre>{JSON.stringify(referral,null,2)}</pre>
    </>;
};
