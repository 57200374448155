import { Table, Select, Grid } from "@mantine/core";
import { useState } from "react";
import { formatDate, formatTimeStamp } from "utilities";
import dayjs from "dayjs";  

export const ReferralHistory = ( props:any ) => {
  
  const {
    forms: {
      main: {
        values: patient = null,
        // setFieldValue: setFieldValueUponPatient = null,
      } = {}
    } = {}
  } = props;

  const referrals = patient?.referrals || [];

  const [ filterState, setFilterState ] = useState({
    authed_user_id: null,
    field: '', //could do a multi-select
    referral_id: null
  } as any);

  const records = referrals.reduce( (prev: any[], referral: any) => {
    const { 
      id: referral_id, referral_histories,
      // fields to nominally represent this referral
      rx_received_date
    } = referral;

    const product_type = referral?.product_type?.title || "";

    const referral_title = `${product_type} ${formatDate(rx_received_date)}`;

    const mapped = referral_histories.map( (x:any) => {
      const { 
        id,
        created_at, header, field, oldValue, newValue,
        authed_user_id, authed_user: { photoURL, display_name }
      } = x;
      return {
        id,
        referral_id,
        referral_title,
        created_at, header, field, oldValue, newValue,
        authed_user_id, photoURL, display_name
      }
    })
    prev.push(...mapped);
    return prev
  },[]);

  const selectData = records.reduce( (prev: any,record: any) => {
    const {
      referral_id, referral_title, 
      authed_user_id, display_name, photoURL,
      field, header
    } = record;

    const {
      referrals,
      users,
      fields
    } = prev;

    const hasThisReferral = referrals.some( 
      ({value}:{value:string}) => value == referral_id
    );
    if (!hasThisReferral) referrals.push(
      {value:referral_id, label: referral_title}
    );

    const hasThisUser = users.some(
      ({value}:{value:string}) => value == authed_user_id
    );
    if (!hasThisUser) users.push(
      {value:authed_user_id, label: display_name}
    );

    const hasThisField = fields.some(
      ({value}:{value:string}) => value == field
    );
    if (!hasThisField) fields.push(
      {value:field, label: header}
    );

    return {
      referrals,
      users,
      fields
    }
  }, {
    referrals: [],
    users: [],
    fields: []
  });

  const ReferralSelect = <Select
    mt="md"
    label="Filter by Referral"
    data={selectData.referrals}
    clearable searchable
    onChange={(val) => {
      setFilterState( (prev:any) => ({
        ...prev,
        referral_id: val
      }))
    }}
  />;

  const UserSelect = <Select
    mt="md"
    label="Filter by User"
    data={selectData.users}
    clearable searchable
    onChange={(val) => {
      setFilterState( (prev:any) => ({
        ...prev,
        authed_user_id: val
      }))
    }}
  />;

  const FieldSelect = <Select
    mt="md"
    label="Filter by Field"
    data={selectData.fields}
    clearable searchable
    onChange={(val) => {
      setFilterState( (prev:any) => ({
        ...prev,
        field: val
      }))
    }}
  />;

  const Header = <thead>
    <tr>
      <th>TIMESTAMP</th>
      <th>REFERRAL</th>
      <th>USER</th>
      <th>FIELD</th>
      <th>FROM</th>
      <th>TO</th>
    </tr>
  </thead>;

  const {
    authed_user_id: f_user,
    referral_id: f_ref,
    field: f_field
  } = filterState;

  const Rows = records
  .filter( (record:any) => {
    const { referral_id, authed_user_id, field } = record;

    // match all of the defined (i.e. non-NULL) filters
    const boolean = [
      f_user ? authed_user_id == f_user : null,
      f_ref ? referral_id == f_ref : null,
      f_field ? field == f_field : null 
    ]
    .filter( x => x != null)
    .every( x => x);

    return boolean

  })
  // @ts-ignore
  .sort(({created_at: a}, {created_at: b}) => {
    const aDay = dayjs(a);
    const bDay = dayjs(b);
    if (aDay.isAfter(bDay)){
        return -1
    }
    if (aDay.isBefore(bDay)){
        return 1
    }
    return 0
  })
  .map( (x:any) => {
    const { 
      id,
      referral_title,
      created_at, header, oldValue, newValue,
      photoURL, display_name
    } = x;
    return <tr key={id}> {/* maybe use id of change*/}
      <th>{formatTimeStamp(created_at)}</th>
      <th>{referral_title}</th>
      <th>{display_name}</th>
      <th>{header}</th>
      <th>{oldValue}</th>
      <th>{newValue}</th>
    </tr>
  });

  const Body = <tbody>
    {Rows}
  </tbody>;

  return <>
    <Grid>
      <Grid.Col span={2}>
        {ReferralSelect}
      </Grid.Col>
      <Grid.Col span={2}>
        {UserSelect}
      </Grid.Col>
      <Grid.Col span={2}>
        {FieldSelect}
      </Grid.Col>
    </Grid>
    <Table>
      {Header}
      {Body}
    </Table>
  </>
}