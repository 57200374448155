import React from "react";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination } from "@mantine/core";

import { List } from "components/ghettoList";

import { fields, resource } from './common'

import { ColumnSorter, ColumnFilter } from '../../../components';
import { useNavigation } from "@refinedev/core";

const columns: ColumnDef<any>[] = [
    { id: "title", accessorKey: "title", header: "Name", enableColumnFilter: false, enableSorting: false, },
];

export const TemplateList: React.FC = () => {

    const { edit } = useNavigation();

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { 
            setCurrent, 
            pageCount, 
            current,
            tableQueryResult: { data: tableData },
          },
      } = useTable({
        columns,

        refineCoreProps: {
            resource,

            metaData: {
                fields
            }
        }
    });

    return (
        <ScrollArea>
        <List>
            <Table highlightOnHover>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <Group spacing="xs" noWrap>
                                                <Box>
                                                    {flexRender(
                                                        header.column
                                                            .columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <Group spacing="xs" noWrap>
                                                    <ColumnSorter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                    <ColumnFilter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                </Group>
                                            </Group>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} onClick={() => edit("admin/template", row.original.id)}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    </ScrollArea>
    );
};
