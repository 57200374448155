import { HttpError, useList, useNavigation, useGetIdentity, useGo } from "@refinedev/core";
import { Create, useForm, useStepsForm, useSelect } from "@refinedev/mantine";
import { Button, Group, Radio, Select, Stepper, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { isAffectrixClientId } from "utilities";
// import { filteredInitialValues, resource } from "./common";

export const UserCreate: React.FC = () => {

  const {
    data: {
      // @ts-ignore
        claims: {
          "x-hasura-default-role": role = '',
          "x-hasura-client-id": client_id = ''
        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  const go = useGo()

  useEffect( () => {
    if (role && role != 'admin'){
      console.log('unauthorized')
      go({to:'/'});
    }
  }, [role])

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('admin/user');
        return
    }

    const {
      steps: {
        currentStep,
        gotoStep
      },
      insertListItem,
      removeListItem,
      values,
      setFieldValue,
      saveButtonProps,
      getInputProps, 
      errors
    } = useStepsForm<any>({
        initialValues: {
          displayName: "",
          email: "",
          role: "",
          // disabled: false,
          client_id: "",
          representative_id: ''
        },
        refineCoreProps: {
            resource: 'user',
            onMutationSuccess,
            redirect:false
        },
        validate(values) {
            const {
              representative_id
            } = values;

            if (currentStep == 1){

              if (isRepresentative == 'yes'){
                return {
                  // @ts-ignore
                  representative_id: representative_id?.length ? null : 'Please associate a representative, or indicate that this user is not a represnetative.'
                }
              }
            }
            return {}
        },
    });

    const { selectProps: clientSelectProps } = useSelect({
        resource: "clients",
        defaultValue: undefined,
        optionLabel: 'name',

        meta: {
            fields: ["id", "name"],
        },

        pagination: {
            mode: "server"
        },
        filters:[
          {
            operator: 'or',
            value: [
              {
                field: 'id',
                operator: 'null',
                value: !isAffectrixClientId(client_id)
              },
              {
                field: 'id',
                operator: 'eq',
                value: client_id
              }
            ]
          }
        ],
        queryOptions:{
          enabled: !!client_id
        }
    });

  //// START OF MAIN STEP

    const mainStep = <Stepper.Step label="Main Step">
    <TextInput
        mt={8}
        label="Display Name"
        placeholder="Display Name"
        {...getInputProps('displayName')}
        style={{ maxWidth: 300 }}
    />
    <TextInput
        mt={8}
        label="Email"
        placeholder="Email"
        {...getInputProps('email')}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="Role"
        placeholder="Pick One"
        {...getInputProps('role')}
        data={[
            { label: "User", value: "user" },
            { label: "Admin", value: "admin" },
        ]}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="Client"
        placeholder="Client"
        {...getInputProps('client_id')}
        {...clientSelectProps}
        style={{ maxWidth: 300 }}
    />
  </Stepper.Step>;

  //// START OF REPRESENTATIVE STEP

  const {
    data: {
      // @ts-ignore
      data: representativeLookup = []
    } = {}
  } = useList({
    resource: 'representative',
  
    meta:{
      fields: [ 'id', 'name'],
    },
  
    pagination: {
      pageSize: 1000
    }
  });

  const {
    steps: { 
      currentStep: currentStepOfRepresentativeCreate, 
      gotoStep: gotoStepOfRepresentativeCreate 
    },
    insertListItem: insertListItemOfRepresentativeCreate,
    removeListItem: removeListItemOfRepresentativeCreate,

    values: representativeCreateValues,
    getInputProps: representativeCreateGetInputProps,
    saveButtonProps: representativeCreateSaveButtonProps,
    reset: resetOfRepresentativeCreate,
    setFieldValue: setFieldValueUponRepresentativeCreate
} = useStepsForm({
  initialValues: {
    name: '',
    email: '',
    manager_email: ''
  },
  refineCoreProps: {
    action: 'create',
    redirect: false,
    resource: 'representative',
    metaData: {
      fields: [
        'id',
        'name'
      ]
    },
    onMutationSuccess({data}, variables, context, isAutoSave) {
        const { id: representative_id } = data;
        setFieldValue('representative_id', representative_id);
        setRepresentativeSectionValue('select');
    },
  }
})

const createRepresentativeForm = <>
  <br/>
  <Stepper
    active={currentStepOfRepresentativeCreate}
    onStepClick={gotoStepOfRepresentativeCreate}
  >
    <Stepper.Step
      label="First Step"
    >
      <TextInput
          mt={8}
          label="Name"
          withAsterisk
          placeholder="Name"
          {...representativeCreateGetInputProps('name')}
          style={{ maxWidth: 300 }}
      />
      <TextInput
          mt={8}
          label="Email"
          placeholder="Email"
          {...representativeCreateGetInputProps('email')}
          style={{ maxWidth: 300 }}
      />
      <TextInput
          mt={8}
          label="Manager Email"
          placeholder="Manager Email"
          {...representativeCreateGetInputProps('manager_email')}
          style={{ maxWidth: 300 }}
      />
      <Select 
        mt={8}
        label="Employment Type"
        {...representativeCreateGetInputProps('employment_type')}
        searchable={true}
        clearable={true}
        filterDataOnExactSearchMatch={true}
        data={[
          { value: '1099', label:'1099' },
          { value: 'W2', label:'W2' },
          { value: 'Manager', label:'Manager' },
        ]}
        style={{ maxWidth: 300 }}
      />
      <br/>
      <Button {...representativeCreateSaveButtonProps}>
        Save Representative
      </Button>
    </Stepper.Step>
  </Stepper>
</>;

  const [ isRepresentative, setIsRepresentative ] = useState('no');
  const [ representativeSectionValue, setRepresentativeSectionValue ] = useState('select');
  const representativeStep = <Stepper.Step label="Representative Step">
    <Radio.Group
        value={isRepresentative}
        onChange={setIsRepresentative}
        label='Is this user also a Representative?'
      >
      <Group mt='xs'>
        <Radio value="yes" label="Yes" 
        // disabled={ghettoProvider != null}
        />
        <Radio value="no" label="No" 
        // disabled={ghettoProvider != null}
        />
      </Group>
    </Radio.Group>
    { isRepresentative == 'yes' && <>
    <Radio.Group
        value={representativeSectionValue}
        onChange={setRepresentativeSectionValue}
        label='Select OR Create a Representative'
      >
      <Group mt='xs'>
        <Radio value="select" label="Select Existing Representative" 
        // disabled={ghettoProvider != null}
        />
        <Radio value="create" label="Create New Representative" 
        // disabled={ghettoProvider != null}
        />
      </Group>
    </Radio.Group>
    { representativeSectionValue == 'select' ? <>
      <Select
          mt={8}
          label="Merge Representative into this User"
          placeholder="No rep merged into this User"
          {...getInputProps(`representative_id`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          data={representativeLookup?.map(({id,name}) => ({value:id, label:name})) as any}
          style={{ maxWidth: 300 }}
        />
      </> : createRepresentativeForm }
    </>}
  </Stepper.Step>

  const lastStepIndex = 2;
    return (
      <>
        <Stepper
          active={currentStep}
          onStepClick={gotoStep}
        >
          {mainStep}
          {representativeStep}
          <Stepper.Step label='Save'>

          </Stepper.Step>
        </Stepper>
        <Group position="right" mt="xl">
            {currentStep !== 0 && (
                <Button
                    variant="default"
                    onClick={() => gotoStep(currentStep - 1)}
                >
                    Back
                </Button>
            )}
            {currentStep !== lastStepIndex && (
                <Button onClick={() => gotoStep(currentStep + 1)}>
                    Next step
                </Button>
            )}
            {currentStep === lastStepIndex && <Button {...saveButtonProps}>
              Create This User
            </Button>}
        </Group>
      </>
      
    );
};
