import { Analytics } from "pages/admin/analytics/list";
import { Route } from 'react-router-dom';

export const analytics = <Route
path='analytics'
>
  <Route
    index
    element={<Analytics/>}
  />
</Route>