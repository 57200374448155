import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Authenticated, Refine } from "@refinedev/core";
import { ErrorComponent } from "@refinedev/mantine"

import { ThemedLayoutV2 } from "components/layout";
import { ThemedHeaderV2 } from "components/layout/header";
import { ThemedSiderV2 } from "components/layout/sider";
import { ThemedTitleV2 } from "components/layout/title";

// COMPONENTS
import { Login } from "pages/login";
import { Dashboard } from "pages/dashboard";

// NESTED ROUTES
import { admin } from "./routes/admin";
import { directory } from "./routes/directory";
import { operations } from "./routes/operations";
import { fulfillment } from "./routes/fulfillment";
import { analytics } from "./routes/analytics";

// ROUTES SPECIFIC TO REPRESENTATIVE
import { representative } from "./routes/representative";

const login = <Route
  path='/login'
  element={<Login></Login>}
/>;

const dashboard = <>
  <Route index element={<Dashboard></Dashboard>} />
  <Route
    // index
    path='/dashboard'
    element={<Dashboard></Dashboard>}
  />
</>;

export const routes = <Routes>
  {login}
  <Route
    element={<Authenticated redirectOnFail="/login">
      <ThemedLayoutV2 Title={ThemedTitleV2}>
        <Outlet/>
      </ThemedLayoutV2>
    </Authenticated>}
  >
    {dashboard}
    {admin}
    {directory}
    {operations}
    {fulfillment}
    {analytics}
    
    {representative}
    <Route path="*" element={<ErrorComponent />} />
  </Route>
</Routes>