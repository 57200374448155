import React from "react";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination } from "@mantine/core";

import { List } from "components/ghettoList";

import { fields, resource } from './common'

import { ColumnSorter, ColumnFilter } from '../../../components';
import { useGo, useNavigation } from "@refinedev/core";

const columns: ColumnDef<any>[] = [
    // {
    //     id: "actions",
    //     header: "Actions",
    //     accessorKey: 'id',
    //     enableColumnFilter: false,
    //     enableSorting: false,
    //     cell: function render({ getValue }:any) {
    //       return (
    //           <Group spacing="xs" noWrap>
    //               <EditButton
    //                   hideText
    //                   recordItemId={getValue() as number}
    //               />
    //           </Group>
    //       );
    //     },
    //   },
    // { id: "client.name", accessorKey: "client.name", header: "Client", enableColumnFilter: false, enableSorting: false, },
    { id: "title", accessorKey: "title", header: "Name", enableColumnFilter: false, enableSorting: false, },
    // { id: "address", accessorKey: "address", header: "Address", enableColumnFilter: false, enableSorting: false, },
    { id: "phone_number", accessorKey: "phone_number", header: "Phone Number", enableColumnFilter: false, enableSorting: false, },
];

export const LocationList: React.FC = () => {

  const go = useGo();

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { 
            setCurrent, 
            pageCount, 
            current,
            tableQueryResult: { data: tableData },
          },
      } = useTable({
        columns,

        refineCoreProps: {
            resource,

            metaData: {
                fields
            },
            pagination: {
              pageSize: 50
            },

            sorters: {
              initial: [
                {
                  field: 'title',
                  order: 'asc'
                }
              ]
            },
        }
    });

    return (
        <ScrollArea>
        <List>
            <Table highlightOnHover>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <Group spacing="xs" noWrap>
                                                <Box>
                                                    {flexRender(
                                                        header.column
                                                            .columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <Group spacing="xs" noWrap>
                                                    <ColumnSorter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                    <ColumnFilter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                </Group>
                                            </Group>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id}
                            onClick={() => go({
                              to: `edit/${row.original.id}`
                            })}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    </ScrollArea>
    );
};
