import { 
  // createUserWithEmailAndPassword, 
  // sendPasswordResetEmail, 
  // signInWithEmailAndPassword, 
  // updateEmail, 
  // updatePassword,
  signInWithPopup, 
  getAuth, 
  signOut, 
  Auth, 
  getIdTokenResult,
  getIdToken,
  User as FirebaseUser, 
  GoogleAuthProvider,
  onAuthStateChanged,
  AuthProvider,
} from "firebase/auth";
import { FirebaseApp } from "@firebase/app";
import { IUser } from "../interfaces";
import { GraphQLClient, gql } from "@refinedev/hasura";
// import { firebaseApp } from "configuration/firebase";
import { AuthBindings } from "@refinedev/core";

const updateUserMutation = gql`
mutation MyMutation(
  $pk_columns: authed_user_pk_columns_input!, 
  $_set: authed_user_set_input!
) {
  update_authed_user_by_pk(pk_columns: $pk_columns, _set: $_set) {
    id
  }
}`;

// @ts-ignore
const constuctVariablesForUpdateUser = ({id,photoURL}) => ({
  "pk_columns": {"id": id},
  "_set": { "photoURL": photoURL}
});

const userQuery = gql`
query MyQuery($id: String_comparison_exp!) {
  authed_user(where: {id: $id}){
    email
    display_name
    photoURL
    metadata
    representative{
      id
      name
      email
      manager_email
      extension_link
      employment_type
      team
      query_these_ids
    }
  }
}`;

export class FirebaseAuth {

    auth: Auth;
    authActions;
    client: GraphQLClient | undefined;
    claims: any;
    authed_user: any;

    constructor (
      { authActions, firebaseApp, client }: 
      { authActions?: any, firebaseApp?: FirebaseApp, client?: GraphQLClient} = {}
    ) {
        this.auth = getAuth(firebaseApp);
        this.auth.useDeviceLanguage();

        this.client = client;

        this.claims = null;
        // additional metadata retrieved from Hasura instead of firebase
        this.authed_user = {};
        this.getClaims = this.getClaims.bind(this)

        this.getAuthProvider = this.getAuthProvider.bind(this);
        this.handleLogIn = this.handleLogIn.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);
        this.getUserIdentity = this.getUserIdentity.bind(this);
        this.handleCheckAuth = this.handleCheckAuth.bind(this);
        this.getPermissions = this.getPermissions.bind(this);

        this.parseClaims = this.parseClaims.bind(this)

        this.authActions = authActions || {};
    }

    public getClaims(){
      return this.claims || {}
    }

    private parseClaims( idToken:any){
      const {
        claims: {
          "https://hasura.io/jwt/claims": claims,
          picture
        }
      } = idToken
    
      return {...claims, picture}
    }

    public async handleLogOut() {
      this.client?.setHeader("Authorization",``);
      localStorage.setItem('userState', '');
      try {
        await signOut(this.auth);
        await this.authActions?.onLogout?.(this.auth);
        return {
          success: true,
          redirectPath: "/login"
        }
      } catch(e) {
        return {
          success: false,
          message: e,
          redirectPath: "/login"
        }
      }
        
    }

    public async handleLogIn() {
        await this.handleLogOut();
        try {
            if (this.auth) {

                //https://github.com/firebase/snippets-web/blob/486e5c67bcb895a00ccab90f1b20cefdba6c9e3a/snippets/auth-next/google-signin/auth_google_signin_popup.js#L8-L29
                //https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk

                const provider = new GoogleAuthProvider()

                const userCredential = await signInWithPopup(this.auth, provider);
                const userToken = await userCredential?.user?.getIdTokenResult?.();
                if (userToken) {
                    const {
                      token
                    } = userToken

                    this.claims = this.parseClaims(userToken);

                    this.client?.setHeader("Authorization",`Bearer ${token}`);

                    const {
                      claims: {
                        user_id,
                        picture
                      }
                    } = userToken

                    if (user_id && picture){
                      await this.client?.request(
                        updateUserMutation,
                        constuctVariablesForUpdateUser({id:user_id, photoURL:picture})
                      )
                    }

                    this.authActions?.onLogin?.(userCredential.user);
                    return {
                      success: true,
                      redirectPath: "/dashboard"
                    }
                } else {
                    return {
                      success: false,
                      message: 'No user token.'
                    };
                }
            } else {
                return {
                  success: false,
                  message: "Client side Auth not Loaded."
                };
            }
        } catch (error: any) {
            const { 
              code
            } = error
            let name = '';
            console.log(error);
            if (code.includes('auth/admin-restricted-operation')){
              name = `Login failed; User does not exist in system.  Contact system administrator`;
            } else if (code.includes('auth/user-disabled')){
              name = `Login failed; User is not allowed to log into the system.  Contact system administrator.`;
            } else {
              name = `Login failed; ${code}`;
            }
            return {
              success: false,
              message: name,
            };
        }
    }

    private async getUserIdentity(): Promise<IUser> {
        const user = this.auth?.currentUser;
        const claims = this.getClaims();

        const {
          "x-hasura-user-id": uid
        } = claims;
        const response = await this.client?.request(
          userQuery,
          {
            id: {
              _eq: uid
            }
          }
        );
        const { 
          // @ts-ignore
          authed_user: [
            authed_user = {}
          ]
        } = response;
        const thing = {
          claims,
          email: user?.email || "",
          name: user?.displayName || "",
          authed_user
        }
        return thing;
    }
    
    private getFirebaseUser(): Promise<FirebaseUser> {
        return new Promise<FirebaseUser>((resolve, reject) => {
            const unsubscribe = this.auth?.onAuthStateChanged(user => {

            unsubscribe();

            // @ts-ignore
            resolve(user)
            }, reject);
        });
    }

    private async handleCheckAuth() {
        // console.log('checkAuth')
        if (await this.getFirebaseUser()) {

            //refresh token
            const userToken = await this.auth.currentUser?.getIdTokenResult();
            if (userToken) {
              const {
                token
              } = userToken

              this.claims = this.parseClaims(userToken);
    
              this.client?.setHeader("Authorization",`Bearer ${token}`)
            }
          
            return {
              authenticated: true
            };
        } else {
          // probably log out
            return {
              authenticated: false
            };
        }
    }

    public async getPermissions(): Promise<any> {
      console.log('getPermissions')
        if (this.auth?.currentUser) {
            var idTokenResult = await getIdTokenResult(this.auth.currentUser);
            var idToken = await getIdToken(this.auth.currentUser);
            // console.log(`idtokenresult`)
            // console.log(idTokenResult)

            this.claims = this.parseClaims(idTokenResult);
            // this.claims = idTokenResult
            console.log(this.claims)

            return {
              idToken,
              idTokenResult
            }
        } else {
            return null;
        }
    }

    public getAuthProvider(): AuthBindings {
        return {
            login: this.handleLogIn,
            logout: this.handleLogOut,
            check: this.handleCheckAuth,
            onError: async (error:any) => {
              console.log(error);
              console.log(Object.entries(error));

              if (error?.response){
                // @ts-ignore
                const boolean = error.response.errors.some( x => {
                  const { message } = x;
                  return message == 'Could not verify JWT: JWTExpired'
                });
                if (boolean){
                  // this.handleLogOut();
                  return {
                    // redirectTo: '/login',
                    // // error: "Access token expired.  Please log in.",
                    // message: "Access token expired.  Please log in."
                    error: {
                      name: "Access has expired after one hour of inactivity.",
                      message: "Please refresh the page."

                    }
                  }
                }
              }
              
              return {

              }
            },
            getPermissions: this.getPermissions,
            getIdentity: this.getUserIdentity,

            // @ts-ignore
            getClaims: this.getClaims

            // register: () => Promise.resolve(),
            // forgotPassword: () => Promise.resolve(),
            // updatePassword: () => Promise.resolve(),
        };
    }
}
