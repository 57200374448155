import {
  HttpError,
  useResource,
  useNavigation,
  useOne,
  useList,
  useGetIdentity,
  useUpdate,
  useUpdateMany,
  useParsed,
  useInvalidate,
} from "@refinedev/core";
import {
  IconPencil,
  IconX,
  IconCheckbox,
  IconAlertCircle,
} from "@tabler/icons";
import {
  useForm,
  useSelect,
  EditButton,
  useStepsForm,
} from "@refinedev/mantine";
import { createStyles } from "@mantine/styles";
import {
  Switch,
  Container,
  Stepper,
  Step,
  Tooltip,
  Flex,
  SimpleGrid,
  Radio,
} from "@mantine/core";
import { useContext } from "react";
import { UserContext } from "contexts/userContext";

import _ from "lodash";

import {
  Select,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Avatar,
  Group,
  Paper,
  Button,
  Stack,
  ActionIcon,
  CloseButton,
  Divider,
  Grid,
  Chip,
  Anchor,
  Indicator,
  Title,
} from "@mantine/core";

import { Create as SwizzledCreate } from "components/crud/create";
import { Edit as SwizzledEdit } from "components/crud/edit";

import {
  datePickerValue,
  isAffectrixClientId,
  isVenousClientId,
} from "utilities";

import {
  fields,
  extended,
  resource,
  fieldsOfReferral,
  fieldsOfExtension,
  fieldsOfSubmission,
} from "./common";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker, DatePickerProps } from "@mantine/dates";

import { AddNote, Notes } from "components/notes";
// import { Questionnaires } from "components/questionnaires";
import { ReferralHistory } from "components/history/referrals";
import { PatientHistory } from "components/history/patient";
// @ts-ignore
import {
  AdminDebug,
  MaybeCategory,
  MaybeDocumentIndicator,
  // @ts-ignore
} from "utilities/index.tsx";

////

// @ts-ignore
function addMonths(date, months) {
  // Copy the date to avoid mutating the original
  const newDate = new Date(date);

  // Get the current month and year
  let currentMonth = newDate.getMonth();
  let currentYear = newDate.getFullYear();

  // Calculate the new month
  let newMonth = currentMonth + months;

  // Calculate the new year if the new month exceeds 11 (December)
  let newYear = currentYear + Math.floor(newMonth / 12);

  // Calculate the new month within the current year
  newMonth = ((newMonth % 12) + 12) % 12;

  // Get the number of days in the original month
  const daysInOriginalMonth = new Date(newYear, newMonth + 1, 0).getDate();

  // Check if the original date is greater than the number of days in the new month
  if (newDate.getDate() > daysInOriginalMonth) {
    // Set the date to the last day of the new month
    newDate.setDate(daysInOriginalMonth);
  }

  // Set the new month and year
  newDate.setMonth(newMonth);
  newDate.setFullYear(newYear);

  return newDate;
}

// @ts-ignore
function dayNum(rx_length, date_fit) {
  let day_num = 30;
  let thing = new Date(date_fit);
  if (/DAY/.test(rx_length)) {
    [day_num] = rx_length.split(" ");
    day_num = Number(day_num);
  } else if (/MONTH/.test(rx_length)) {
    let [mon_num] = rx_length.split(" ");
    mon_num = Number(mon_num);
    let start_date_new = addMonths(thing, mon_num);

    const timeDifference = start_date_new.getTime() - thing.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    day_num = Math.round(daysDifference);
  } else {
    console.log(`not handling ${rx_length}`);
    return;
  }
  return day_num;
}

export const PatientEdit: React.FC = () => {
  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

  const formatDate = (x: any) => dayjs(x).format("MM-DD-YY");

  const { id } = useResource();

  const { list } = useNavigation();

  const { mutate } = useUpdate();

  // const onMutationSuccess = (data: any, variables: any, context: any) => {
  //   list(`directory/${resource}`);
  // }

  const { data: { data: initialValues } = {} } = useOne<any>({
    resource,
    id,
    meta: {
      fields: [...fields, ...extended],
    },
  });

  const {
    data: {
      // @ts-ignore
      name,
      // @ts-ignore
      claims: {
        "x-hasura-user-id": uid = "",
        "x-hasura-client-id": clientId = "",
        picture = "",
      } = {},
    } = {},
  } = useGetIdentity({
    // v3LegacyAuthProviderCompatible: true
  });

  const {
    params: {
      view: viewFromQueryParam = "",
      extension: extensionFromQueryParam = "",
      referral: referralFromQueryParam = "",
    } = {},
  } = useParsed();

  const isUserOfAffectrix = isAffectrixClientId(clientId);
  const isUserOfVenous = isVenousClientId(clientId);

  const [prevPatientObj, setPrevPatientObj] = useState({} as any);

  useEffect(() => {
    if (initialValues){
      setPrevPatientObj(initialValues);
    }
    //console.log('useEffect');
    
  }, [initialValues]);

  // main patient edit form
  const main = useForm<any>({
    refineCoreProps: {
      resource,
      metaData: {
        fields: [...fields, ...extended],
      },
      onMutationSuccess: ({ data }, variables, context) => {
        setPrevPatientObj((prev: any) => ({
          ...prev,
          ...data,
        }));
      },
      redirect: false,
      autoSave: {
        enabled: true,
        // debounce: 1500,
      },
      queryOptions: {
        cacheTime: 1_000,
      },
    },
    initialValues: {
      ...initialValues,
      referrals: [],
      chart_url: "",
      patient_notes: [],
      template_for_patients: [],
      // answer_sets: []  // needs to be undefined so that the questionnaires component renders default UI
    },
    transformValues: (values) => {
      const {
        client,
        referrals,
        patient_notes,
        template_for_patients,
        id,
        patient_histories,
        ...rest
      } = values;
      // console.log(rest);
      const diff = Object.entries(rest).reduce((prev: any, curr: any) => {
        const [key, newValue] = curr;
        if (key) {
          const oldValue = prevPatientObj[key];

          if (!_.isEqual(newValue, oldValue)) {
            prev[key] = newValue;
          }
        }
        return prev;
      }, {});
      // console.log(diff);
      // console.log();

      return diff;
    },
  });

  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish, formLoading, redirect },
    getInputProps,
    values = [] as any[],
    setValues,
    setFieldValue,
    insertListItem,
    removeListItem,
  } = main;

  // @ts-ignore
  let interval;
  useEffect(() => {
    //  @ts-ignore
    if (uid && !!values?.meta && !interval) {
      interval = setInterval(() => {
        // console.log('interval tick')

        // set field value of meta
        setValues((prev: any) => {
          const prevMeta = prev?.meta || {};
          // filter out meta.userActivity
          const prevUserActivity = prevMeta?.userActivity || [];

          // @ts-ignore
          const userActivity = prevUserActivity
            // @ts-ignore
            .reduce((prev, curr) => {
              const isWithinFiveMinutes = dayjs()
                .subtract(5, "minute")
                .isBefore(curr.timestamp);
              if (curr.uid != uid && isWithinFiveMinutes) {
                prev.push(curr);
              }
              return prev;
            }, [])
            .concat([
              {
                uid,
                timestamp: new Date(),
                picture,
                name,
              },
            ]);

          console.log({
            prevUserActivity,
            userActivity,
          });

          return {
            ...prev,
            meta: {
              ...prevMeta,
              userActivity,
            },
          };
        });
      }, 30_000);
    }

    return () => {
      // @ts-ignore
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [uid, values]);

  const dp = datePickerValue(values);

  const mainSectionOfPatient = (
    <>
      <br></br>
      {/* Scroll AREA? TODO */}
      <Grid>
        <Grid.Col span={3}>
          {/* @ts-ignore */}
          {values?.chart_url && (
            // @ts-ignore
            <Anchor href={values?.chart_url} target="_blank">
              Link To Patient's Chart
            </Anchor>
          )}
          <TextInput
            mt={8}
            label="Name"
            placeholder="Name"
            {...getInputProps("name")}
            style={{ maxWidth: 300 }}
          />
          <DatePicker
            mt="md"
            label="Date Of Birth"
            placeholder="Date Of Birth"
            {...getInputProps("date_of_birth")}
            value={dp("date_of_birth")}
            withinPortal
            style={{ maxWidth: 300 }}
          />
          <Textarea
            mt={8}
            label="Patient Info"
            placeholder="Patient Info"
            {...getInputProps("patient_info")}
            minRows={4}
            style={{ maxWidth: 300 }}
          />
          <TextInput
            mt={8}
            label="Email"
            style={{ maxWidth: 300 }}
            {...getInputProps("email")}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          {/* <Select
          mt="md"
          label="Added to Collaborate?"
          placeholder="Added to Collaborate?"
          {...getInputProps("added_to_collaborate")}
          data={[
            {value: true as any, label: "Yes"},
            {value: false as any, label: "No"},
            {value: null as any, label: "-/-"}
          ]}
          style={{ maxWidth: 300 }}
        /> */}
        </Grid.Col>
      </Grid>
    </>
  );

  const useStyles = createStyles((theme) => ({
    comment: {
      padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    },

    body: {
      //   paddingLeft: rem(54),
      //   paddingTop: theme.spacing.sm,
      padding: theme.spacing.lg,
      fontSize: theme.fontSizes.sm,
    },

    content: {
      "& > p:last-child": {
        marginBottom: 0,
      },
    },
  }));

  const { classes } = useStyles();

  const {
    data: {
      // @ts-ignore
      data: rxLengthLookup = [],
    } = {},
  } = useList({
    resource: "rx_length",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      pageSize: 1000,
    },
  });

  const {
    data: {
      // @ts-ignore
      data: itemLookup,
    } = {},
  } = useList({
    resource: "selection",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      pageSize: 1000,
    },
  });

  const { data: { data: insuranceStatusLookup = [] } = {} } = useList({
    resource: "insurance_status",
    meta: {
      fields: ["id", "title"],
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const { data: { data: patientStatusLookup = [] } = {} } = useList({
    resource: "patient_status",
    meta: {
      fields: ["id", "title"],
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const { selectProps: clientSelectProps } = useSelect({
    resource: "clients",

    // defaultValue: queryResult?.data?.data.client_id,
    optionLabel: "name",

    meta: {
      fields: ["id", "name"],
    },

    pagination: {
      mode: "server",
    },
  });

  //productTypeSelectProps
  const { selectProps: productTypeSelectProps } = useSelect({
    resource: "product_type",

    // defaultValue: queryResult?.data?.data.client_id,
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: fitterSelectProps } = useSelect({
    resource: "fitter",

    // defaultValue: null,
    // queryOptions: {
    //     enabled: values?.patient_status_id !== null
    // },
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  // @ts-ignore
  const defaultInsurances = initialValues?.referrals?.reduce((prev, curr) => {
    const { insurance_id, bill_to_insurance_id } = curr;

    if ((insurance_id || bill_to_insurance_id) && !prev) prev = [];

    if (prev) {
      if (!prev.includes(insurance_id) && insurance_id) prev.push(insurance_id);
      if (!prev.includes(bill_to_insurance_id) && bill_to_insurance_id)
        prev.push(bill_to_insurance_id);
    }

    return prev;
  }, undefined);

  const { selectProps: insuranceSelectProps } = useSelect({
    resource: "insurance",
    optionLabel: "title",
    meta: {
      fields: ["id", "title"],
    },
    pagination: {
      mode: "server",
    },
    queryOptions: {
      enabled: initialValues?.referrals?.length > 0,
    },
    defaultValue: defaultInsurances,
  });

  const { selectProps: billToInsuranceSelectProps } = useSelect({
    resource: "insurance",
    optionLabel: "title",
    meta: {
      fields: ["id", "title"],
    },
    pagination: {
      mode: "server",
    },
    queryOptions: {
      enabled: initialValues?.referrals?.length > 0,
    },
    defaultValue: defaultInsurances,
  });

  const { selectProps: claimTypeSelectProps } = useSelect({
    resource: "claim_type",

    // defaultValue: values?.claim_type_id as BaseKey  || '',
    // queryOptions: {
    //     enabled: values?.claim_type_id !== null
    // },
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: documentStatusSelectProps } = useSelect({
    resource: "document_status",
    // defaultValue: values?.document_status_id as BaseKey || '',

    // queryOptions: {
    //     enabled: values?.document_status_id !== null
    // },
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: reauthStatusSelectProps } = useSelect({
    resource: "reauth_status",

    // defaultValue: values?.document_status_id as BaseKey || '',
    // queryOptions: {
    //     enabled: values?.document_status_id !== null
    // },
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: extensionStatusSelectProps } = useSelect({
    resource: "extension_status",
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: pickupStatusSelectProps } = useSelect({
    resource: "pickup_status",
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server",
    },
  });

  const { selectProps: locationSelectProps } = useSelect({
    resource: "location",
    optionLabel: "title",

    meta: {
      fields: ["id", "title"],
    },

    filters: [
      {
        field: "client_id",
        operator: "null",
        value: false,
      },
      {
        field: "code",
        operator: "in",
        value: ["AFFECTRIX", "PITT", "COLORADO HUB"],
      },
    ],

    pagination: {
      mode: "server",
    },
  });

  // @ts-ignore
  const defaultProviders = initialValues?.referrals?.reduce((prev, curr) => {
    const { provider_id, billing_provider_id } = curr;

    if ((provider_id || billing_provider_id) && !prev) prev = [];

    if (prev) {
      if (!prev.includes(provider_id) && !!provider_id) prev.push(provider_id);
      if (!prev.includes(billing_provider_id) && !!billing_provider_id)
        prev.push(billing_provider_id);
    }

    return prev;
  }, undefined);

  const { selectProps: referringProviderSelectProps } = useSelect({
    resource: "provider",
    optionLabel: "name",
    meta: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "provider_representatives_aggregate.count.predicate",
        operator: "gt",
        value: 0,
      },
    ],
    pagination: {
      mode: "server",
    },
    queryOptions: {
      enabled: initialValues?.referrals?.length > 0,
    },
    defaultValue: defaultProviders,
  });

  const { selectProps: billingProviderSelectProps } = useSelect({
    resource: "provider",
    optionLabel: "name",
    meta: {
      fields: ["id", "name"],
    },
    filters: [
      {
        field: "provider_representatives_aggregate.count.predicate",
        operator: "gt",
        value: 0,
      },
    ],
    pagination: {
      mode: "server",
    },
    queryOptions: {
      enabled: initialValues?.referrals?.length > 0,
    },
    defaultValue: defaultProviders,
  });

  // TODO injury sites TODO
  // editReferralSave
  const {
    values: editReferralValues,
    setValues: editReferralSetValues,
    getInputProps: getInputPropsForEditReferral,
    saveButtonProps: saveButtonPropsForEditReferral,
    reset: resetOfEditReferral,
    setFieldValue: setFieldValueUponEditReferral,
    insertListItem: insertListItemOfEditReferral,
    removeListItem: removeListItemOfEditReferral,
    refineCore: {
      setId: setIdOfEditReferral,
      id: idOfEditReferral,
      onFinish: onFinishOfEditReferral,
    },
  } = useForm({
    initialValues: {
      provider_id: null,
      provider: {
        name: "",
      },
      billing_provider_id: null,
      billing_provider: {
        name: "",
      },
      resupply_settings: {
        // start_date: null,
        // frequency: 60,
        // occurences: 6,
        // active: true
      },
    },
    refineCoreProps: {
      onMutationSuccess: ({ data }, variables, context) => {
        const { id: idOfThisReferral } = data;

        //  @ts-ignore
        const referralIndex = values?.referrals?.findIndex((x) => {
          const { id: idOfSomeReferral } = x;
          return idOfSomeReferral == idOfThisReferral;
        });
        if (referralIndex > -1) {
          // update referral data locally
          setFieldValue(`referrals.${referralIndex}`, data);
        }
      },
      autoSave: {
        enabled: true,
        debounce: 666,
      },
      action: "edit",
      resource: "referral",
      redirect: false,
      meta: {
        fields: fieldsOfReferral,
      },

      //TODO transform and maybe validation
      // @ts-ignore
    },
    transformValues: (aReferral) => {
      const {
        claim_type,
        client,
        document_status,
        equipment,

        extensions,
        resupplies,

        insurance,
        insurance_status,
        insurance_type,
        patient,
        patient_status,
        pickup_status,
        product,
        product_type,
        provider,
        billing_provider,
        reauth_status,
        referral_injury_sites,
        referral_notes,
        result_of_referral_automations,
        result_of_referral_events,
        rx_length,
        submissions,
        authed_user_who_updated,

        automated_email_status,

        id,
        referral_histories,
        autocrat_job_results,
        autocrat_job_status,

        ...rest
      } = aReferral as any;

      // @ts-ignore
      const referralMatch = values?.referrals.reduce((prev, curr) => {
        const some_referral_id = curr.id;
        if (some_referral_id == id) return curr;
        return prev;
      }, null);

      if (!referralMatch) return null;

      const diff = Object.entries(rest).reduce((prev: any, curr: any) => {
        const [key, newValue] = curr;
        const oldValue = referralMatch[key];
        if (!_.isEqual(newValue, oldValue)) {
          prev[key] = newValue;
        }
        return prev;
      }, {});

      if (diff.injury_sites){
        diff.product_title = [
          product_type.title,
          diff.injury_sites,
          rx_length?.title
        ].filter( x => x).join('-')
      }

      let rsSet = rest?.resupply_settings || {};
      let extset = rest?.extension_settings || {};

      if (Object.keys(diff).length > 0 && rest?.date_fit) {
        // resupplies
        if (
          Object.keys(rsSet).length > 0 &&
          product_type?.title == "NMES" &&
          rsSet?.start_date == null
        ) {
          if (!rsSet.occurences) {
            rsSet.occurences = 6;
          }
          const freq = Number(rsSet?.frequency) || 60;
          rsSet.frequency = freq;
          const parsedDate = new Date(rest?.date_fit);
          rsSet.start_date = dayjs(
            parsedDate.setDate(parsedDate.getDate() + 60)
          ).format("YYYY-MM-DD");
          diff.resupply_settings = rsSet;
        } else if (Object.keys(rsSet).length == 0) {
          // dumb fix
          delete diff.resupply_settings;
        }

        // extensions
        if (
          Object.keys(extset).length > 0 &&
          product_type?.title == "TCT" &&
          extset?.start_date == null &&
          rx_length?.title
        ) {
          const parsedDate = new Date(rest?.date_fit);

          const day_num = dayNum(rx_length?.title, parsedDate);

          if (day_num) {
            extset.start_date = dayjs(
              parsedDate.setDate(parsedDate.getDate() + day_num)
            ).format("YYYY-MM-DD");
            if (day_num > 90) {
              extset.occurences = 6;
            } else {
              extset.occurences = 3;
            }
            diff.resupply_settings = extset;
          }
        } else if (Object.keys(extset).length == 0) {
          // dumb fix
          delete diff.extension_settings;
        }
      }

      if (Object.keys(diff).length > 0) {
        diff.updated_by = uid;
      }

      return diff;
    },
  });

  const [once, setOnce] = useState(false);

  useEffect(() => {
    //console.log('useEffect');
    // @ts-ignore
    if (values?.referrals?.length > 0 && !once) {
      // @ts-ignore
      const match = values?.referrals?.reduce((prev, curr) => {
        const { id: idOfCurr } = curr;
        if (idOfCurr == referralFromQueryParam) {
          prev = curr;
        }
        return prev;
      }, null);

      if (match) {
        setIdOfEditReferral(referralFromQueryParam);
        editReferralSetValues(match);
        setOnce(true);
      }
    }
  }, [referralFromQueryParam, values]);

  const [extensionVisibility, setExtensionVisibility] = useState(false);
  // add extension form
  const {
    values: extensionValues,
    getInputProps: getInputPropsForExtension,
    onSubmit: onSubmitOfExtension,
    saveButtonProps: extensionSaveButtonProps,
    reset: resetOfExtension,
    setFieldValue: setFieldValueUponExtension,
  } = useForm({
    initialValues: {
      referral_id: initialValues?.referral_id,
      extended_to_date: null,
      extension_status_id: null,
      rx_expiration_date: null,
    },
    refineCoreProps: {
      onMutationSuccess: ({ data }, variables, context) => {
        const { referral_id } = data;
        // @ts-ignore
        const referralIndex = values?.referrals?.findIndex((x) => {
          const { id: someReferralId } = x;
          return someReferralId == referral_id;
        });
        if (referralIndex > -1) {
          insertListItem(`referrals.${referralIndex}.extensions`, data);
        }
        insertListItemOfEditReferral("extensions", data);
        resetOfExtension();
      },
      action: "create",
      resource: "extension",
      redirect: false,
      // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
      metaData: {
        fields: fieldsOfExtension,
      },
    },
    validate: (values) => {
      const { extension_status_id = "", extended_to_date } = values;
      return {
        extended_to_date: extended_to_date ? null : "Select a date",
        extension_status_id: extension_status_id?.length
          ? null
          : "Select a status",
      };
    },
  });

  useEffect(() => {
    //console.log('useEffect');
    // @ts-ignore
    extensionValues.referral_id = editReferralValues?.id;
  }, [editReferralValues]);

  const [resupplyVisibility, setResupplyVisibility] = useState(false);

  // add resupply form
  const {
    values: addResupplyValues,
    getInputProps: getInputPropsForAddResupply,
    onSubmit: onSubmitOfAddResupply,
    saveButtonProps: addResupplySaveButtonProps,
    reset: resetOfAddResupply,
    setFieldValue: setFieldValueUponAddResupply,
  } = useForm({
    initialValues: {
      referral_id: initialValues?.referral_id,
      resupply_date: null,
    },
    refineCoreProps: {
      onMutationSuccess: ({ data }, variables, context) => {
        // TODO resupplies ~> referrals.${indexOfMatchedReferral}.resupplies
        // AND ~> of the selected referral
        insertListItemOfEditReferral("resupplies", data);
        resetOfAddResupply();

        //  @ts-ignore
        const referralIndex = values?.referrals?.findIndex((x) => {
          const { id: idOfSomeReferral } = x;
          // @ts-ignore
          return idOfSomeReferral == editReferralValues.id;
        });
        if (referralIndex > -1) {
          // update referral data locally
          insertListItem(`referrals.${referralIndex}.resupplies`, data);
        }
      },
      action: "create",
      resource: "resupplies",
      redirect: false,
      // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
      metaData: {
        fields: ["id", "resupply_date", "referral_id"],
      },
    },
    validate: (values) => {
      const { resupply_date } = values;
      return {
        resupply_date: resupply_date ? null : "Select a date",
      };
    },
  });

  useEffect(() => {
    //console.log('useEffect');
    // @ts-ignore
    if (editReferralValues?.id) {
      // @ts-ignore
      addResupplyValues.referral_id = editReferralValues.id;
    }
  }, [editReferralValues]);

  const [submissionFormVisibility, setSubmisisonFormVisibility] =
    useState(false);

  // // Form of submissionValues
  const {
    values: submissionValues,
    getInputProps: getInputPropsForSubmission,
    onSubmit: onSubmitOfSubmission,
    saveButtonProps: submissionSaveButtonProps,
    reset: resetOfSubmission,
    setFieldValue: setFieldValueUponSubmission,
    removeListItem: removeListItemOfSubmission,
    insertListItem: insertListItemOfSubmission,
  } = useForm({
    initialValues: {
      referral_id: null,
      items: [""],
      submission_type: "",
      fitter_id: null,
      date_shipped: null,
      tracking_number: "",
      from_location_id: null,
    },
    refineCoreProps: {
      onMutationSuccess: ({ data }, variables, context) => {
        const { referral_id } = data;
        // @ts-ignore
        const referralIndex = values?.referrals?.findIndex((x) => {
          const { id: someReferralId } = x;
          return someReferralId == referral_id;
        });
        if (referralIndex > -1) {
          insertListItem(`referrals.${referralIndex}.submissions`, data);
        }
        insertListItemOfEditReferral("submissions", data);

        setSubmisisonFormVisibility(false);
        resetOfSubmission();

        // TODO figure out what
        // setFieldValue('submission_type',data.submission_type)
      },
      action: "create",
      resource: "submission",
      redirect: false,
      // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
      metaData: {
        fields: fieldsOfSubmission,
      },
    },
    // @ts-ignore
    transformValues: (values) => {
      const { submission_type } = values;

      if (submission_type == "shipment_request") {
        const {
          fitter_id,
          // DOI,  template  TODO
          // EMPLOYER, template WC only
          // ATTORNEY, tempate
          ...rest
        } = values;
        // @ts-ignore
        values = rest;
      }
      if (submission_type == "delivery_ticket") {
        const { date_shipped, tracking_number, ...rest } = values;
        // @ts-ignore
        values = rest;
      }

      // @ts-ignore
      if (["TCT", "SCOOTER"].includes(values.product_type?.title)) {
        // @ts-ignore
        const { serial_number, ...rest } = values;
        // @ts-ignore
        values = rest;
      } else {
        // @ts-ignore
        const { product_id, ...rest } = values;
        // @ts-ignore
        values = rest;
      }

      return values;
    },
    validate: (values) => {
      const { submission_type, fitter_id, items, from_location_id } = values;

      // @ts-ignore
      const itemsValid =
        items.length > 0 && items.every((item) => item.length > 0);

      if (submission_type == "delivery_ticket") {
        return {
          fitter_id: fitter_id ? null : "Select a fitter",
          items: itemsValid
            ? null
            : "Define the item(s) or remove the undefined",
        };
      }
      // shipment request
      return {
        items: itemsValid ? null : "Define the item(s) or remove the undefined",
        from_location_id: from_location_id
          ? null
          : "Select the location to ship from.",
      };
    },
  });

  useEffect(() => {
    //console.log('useEffect');
    // @ts-ignore
    submissionValues.referral_id = editReferralValues?.id;
  }, [editReferralValues]);

  // const [ selectedReferral, setSelectedReferral ] = useState(null);

  // @ts-ignore
  const selectedReferralTab = editReferralValues?.id && (
    <Tabs.Tab value="selectedReferral">
      {/* @ts-ignore */}
      {`${editReferralValues?.product_type?.title} ${formatDate(
        // @ts-ignore
        editReferralValues?.rx_received_date
      )}`}
    </Tabs.Tab>
  );

  //  //  //  //  //  //  //  //  //

  // extensions
  // @ts-ignore
  const extensions = editReferralValues?.extensions
    // @ts-ignore
    ?.sort((a, b) => {
      const { extended_to_date: aDate } = a;
      const { extended_to_date: bDate } = b;
      // @ts-ignore
      return new Date(aDate) - new Date(bDate);
    })
    // @ts-ignore
    ?.map((item, index) => {
      // TODO unify destructuring via resolving dumb error below
      const insurance_override = item?.insurance_override?.title;
      const reauth_lookup = item?.reauth_lookup?.title;
      const extension_status = item?.extension_status?.title;

      const {
        id,
        extended_to_date,

        // For whatever dumb reason, this destructuring code does not work
        // i.e. Cannot read properties of null (reading 'title')
        // even if there is a default value

        // insurance_override: { title: insurance_override = "" } ={ title: ""},
        // reauth_lookup: { title: reauth_lookup = ""} ={},
        rx_expiration_date,
      } = item;

      //TODO: LEFT MERGE INSURANCE OF REFERRAL TO OVERRIDE INSURANCE

      const anchor = (
        <Anchor href={`/operations/extension/edit/${id}`} target="_blank">
          Link
        </Anchor>
      );

      return (
        <tr key={id}>
          <td>{anchor}</td>
          <td>{extended_to_date}</td>
          <td>{extension_status}</td>
          <td>{insurance_override}</td>
          {/* <td>{reauth_lookup}</td> */}
          <td>{rx_expiration_date}</td>
        </tr>
      );
    });

  // @ts-ignore
  const resupply_dates = editReferralValues?.resupplies
    // @ts-ignore
    ?.sort((a, b) => {
      const { resupply_date: aDate } = a;
      const { resupply_date: bDate } = b;
      // @ts-ignore
      return new Date(aDate) - new Date(bDate);
      // @ts-ignore
    })
    // @ts-ignore
    ?.map((item, index) => {
      const { id, resupply_date } = item;
      const anchor = (
        <Anchor href={`/fulfillment/resupplies/edit/${id}`} target="_blank">
          Link
        </Anchor>
      );
      return (
        <tr key={id}>
          <td>{anchor}</td>
          <td>{formatDate(resupply_date)}</td>
        </tr>
      );
    });

  // @ts-ignore
  const resupply_items = editReferralValues?.resupply_items?.map(
    // @ts-ignore
    (item, index) => (
      <Group mt="xs">
        {/* TODO fix the lookup to not use mzResupplyLookupItemSelectProps */}
        <Select
          mt={8}
          label="Resupply Item"
          placeholder="Type in a Resupply Item."
          {...getInputPropsForEditReferral(`resupply_items.${index}`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // @ts-ignore
          data={
            itemLookup?.map(({ id, title }) => ({
              value: id,
              label: title,
            })) as any
          }
          style={{ maxWidth: 300 }}
        />
        <ActionIcon
          color="red"
          onClick={() => removeListItemOfEditReferral("resupply_items", index)}
        >
          <CloseButton
            // @ts-ignore
            size="1rem"
          />
        </ActionIcon>
      </Group>
    )
  );

  // @ts-ignore
  const itemsOfSubmissionForm = submissionValues.items.map((item, index) => (
    <Group>
      <Divider my="xs" label={`Item #${index + 1}`} />
      {/* TODO when moving logic of referral view to patient-referrals view, consider below */}
      {/* <Select
          mt={8}
          label="Product Type"
          {...getInputPropsForSubmission(`items.${index}.product_type`)}
          {...productTypeSelectProps2}
          style={{ maxWidth: 300 }}
        /> */}
      {/* @ts-ignore */}
      {/* {['SCOOTER','TCT'].includes(submissionValues?.items[index]?.product_type) && <>
          Product Lookup
        </>}  */}
      <Select
        mt={8}
        // label="Item"
        placeholder="Search for an Item."
        {...getInputPropsForSubmission(`items.${index}`)}
        searchable={true}
        clearable={true}
        filterDataOnExactSearchMatch={true}
        // @ts-ignore
        data={
          itemLookup?.map(({ id, title }) => ({
            value: id,
            label: title,
          })) as any
        }
        style={{ maxWidth: 300 }}
      />
      <ActionIcon
        color="red"
        onClick={() => removeListItemOfSubmission("items", index)}
      >
        <CloseButton
          // @ts-ignore
          size="1rem"
        />
      </ActionIcon>
    </Group>
  ));

  let labelForSubmission = "";
  switch (submissionValues.submission_type) {
    case "shipment_request":
      labelForSubmission = "Shipment Request";
      break;
    case "delivery_ticket":
      labelForSubmission = "Delivery Ticket";
      break;
  }

  const submissionFormVisibilityButton =
    labelForSubmission &&
    (!submissionFormVisibility ? (
      <Button onClick={() => setSubmisisonFormVisibility(true)}>
        Add a {labelForSubmission}
      </Button>
    ) : (
      <Button onClick={() => setSubmisisonFormVisibility(false)} color="red">
        Abort this {labelForSubmission}
      </Button>
    ));

  const submissionForm = submissionValues.submission_type &&
    submissionFormVisibility && (
      <>
        <Text fz="lg" fw="600">
          Add Another Submisison
        </Text>
        <Select
          mt={8}
          label="URGENCY"
          {...getInputPropsForSubmission("urgency")}
          style={{ maxWidth: 300 }}
          data={[
            { value: "SURGICAL", label: "SURGICAL" },
            { value: "IMEDIATE", label: "IMEDIATE" },
            { value: null as any, label: "-/-" },
          ]}
        />
        {/* @ts-ignore */}
        {["BONE STIM", "NMES", "TENS"].includes(
          // @ts-ignore
          editReferralValues?.product_type?.title
        ) && (
          <Radio.Group
            label="Is this a rental?"
            {...getInputPropsForSubmission("isRental")}
          >
            <Radio value="YES" label="YES" />
            <Radio value="NO" label="NO" />
          </Radio.Group>
        )}
        {labelForSubmission == "Shipment Request" && (
          <>
            <Select
              mt={8}
              label="Ship From"
              // placeholder="Client"
              {...getInputPropsForSubmission("from_location_id")}
              {...locationSelectProps}
              style={{ maxWidth: 300 }}
            />
            {/* <DatePicker
                mt="md"
                label="DATE SHIPPED"
                withinPortal
                {...getInputPropsForSubmission(`date_shipped`)}
                value={datePickerValue(submissionValues)(`date_shipped`)}
                style={{ maxWidth: 300 }}
            />
          <TextInput
              mt={8}
              label="TRACKING #"
              // type="date"
              // placeholder="Serial Number"
              {...getInputPropsForSubmission("tracking_number")}
              style={{ maxWidth: 300 }}
          /> */}
          </>
        )}
        {labelForSubmission == "Delivery Ticket" && (
          <>
            <Select
              mt={8}
              label="FITTER"
              {...getInputPropsForSubmission("fitter_id")}
              style={{ maxWidth: 300 }}
              {...fitterSelectProps}
              // data={productSelectProps.data}
            />
          </>
        )}
        <br />
        <Text>Items*</Text>
        {itemsOfSubmissionForm}
        <Group mt="md">
          <Button
            onClick={() => {
              insertListItemOfSubmission("items", "");
            }}
          >
            {/* @ts-ignore */}
            Add {itemsOfSubmissionForm.length > 0 ? "another " : ""}
            {/* @ts-ignore */}
            Item to this {editReferralValues.product_type?.title}{" "}
            {labelForSubmission}
          </Button>
        </Group>
        <br />
        <Divider />
        <br />
        {/* @ts-ignore */}
        <Button {...submissionSaveButtonProps}>
        {/* @ts-ignore */}
          Submit {editReferralValues.product_type?.title} {labelForSubmission}
        </Button>
      </>
    );

  // @ts-ignore
  const shipmentRequestSubmissions = editReferralValues?.submissions
  // @ts-ignore
    ?.filter((submission) => {
      return submission.submission_type == "shipment_request";
      // @ts-ignore
    })
    // @ts-ignore
    ?.map((thing, index) => {
      const submission_id = thing?.id;
      const urgency = thing?.urgency;
      const isRental = thing?.isRental || "N/A";
      const serial_number = thing?.serial_number;
      const date_shipped = thing?.date_shipped;
      const date_fit = thing?.editReferralValues?.date_fit;
      const tracking_number = thing?.tracking_number;

      let status = "READY TO SHIP";
      if (serial_number && date_shipped && date_fit && tracking_number) {
        status = "COMPLETE";
      } else if (
        serial_number &&
        date_shipped &&
        !date_fit &&
        tracking_number
      ) {
        status = "IN TRANSIT";
      }

      // @ts-ignore
      const items = thing?.items
      // @ts-ignore
        ?.map?.((item) => {
          // @ts-ignore
          const matchedItems = itemLookup.filter((x) => x.id == item);
          if (matchedItems.length == 1) {
            const [{ title: matchedItem }] = matchedItems;
            return matchedItem;
          }
          return "something";
        })
        .join(", ");

      const anchor = (
        <Anchor
          href={`/fulfillment/shipmentRequest/edit/${submission_id}`}
          target="_blank"
        >
          Click
        </Anchor>
      );
      return (
        <tr key={index}>
          <td>{anchor}</td>
          <td>{urgency}</td>
          <td>{isRental}</td>
          <td>{status}</td>
          <td>{date_shipped}</td>
          <td>{items}</td>
        </tr>
      );
    });

  // @ts-ignore
  const shipmentRequestSubmissionsTable =
  // @ts-ignore
    editReferralValues?.submissions?.length > 0 ? (
      <>
        <Text fz="lg" fw="600">
          Shipment Requests
        </Text>
        <Table>
          <thead>
            <tr>
              <th>LINK</th>
              <th>URGENCY</th>
              <th>IS RENTAL</th>
              <th>STATUS</th>
              <th>DATE FIT</th>
              <th>ITEMS</th>
            </tr>
          </thead>
          <tbody>{shipmentRequestSubmissions}</tbody>
        </Table>
      </>
    ) : (
      <Text color="dimmed" align="center">
        {/* @ts-ignore */}
        No Shipment Requests for this {
          // @ts-ignore 
          editReferralValues?.product_type?.title
        }{" "}
        referral.
      </Text>
    );

  // @ts-ignore
  const deliveryTicketSubmissions = editReferralValues?.submissions
  // @ts-ignore
    ?.filter((submission) => {
      return submission.submission_type == "delivery_ticket";
      // @ts-ignore
    })
    // @ts-ignore
    ?.map((thing, index) => {
      const urgency = thing?.urgency;
      const serial_number = thing?.serial_number;
      // const date_shipped = thing?.date_shipped;

      //TODO
      // @ts-ignore
      const date_fit = editReferralValues?.date_fit as String;
      const fitter = thing?.fitter?.title;
      // const tracking_number = thing?.tracking_number;

      // TODO audit
      let status = "NEEDS SERIAL #";
      if (serial_number && !date_fit) {
        status = "INCOMPLETE";
      } else if (serial_number && date_fit) {
        status = "COMPLETE";
      }

      // @ts-ignore
      const items = thing?.items
      // @ts-ignore
        ?.map?.((item) => {
          // @ts-ignore
          const matchedItems = itemLookup.filter((x) => x.id == item);
          if (matchedItems.length == 1) {
            const [{ title: matchedItem }] = matchedItems;
            return matchedItem;
          }
          return "something";
        })
        .join(", ");
      return (
        <tr key={index}>
          <td>{urgency}</td>
          <td>{status}</td>
          <td>{fitter}</td>
          <td>{date_fit}</td>
          <td>{items}</td>
        </tr>
      );
    });

  // @ts-ignore
  const deliveryTicketSubmissionsTable =
  // @ts-ignore
    editReferralValues?.submissions?.length > 0 ? (
      <>
        <Text fz="lg" fw="600">
          Delivery Tickets
        </Text>
        <Table>
          <thead>
            <tr>
              <th>URGENCY</th>
              <th>STATUS</th>
              <th>FITTER</th>
              <th>DATE FIT</th>
              <th>ITEMS</th>
            </tr>
          </thead>
          <tbody>{deliveryTicketSubmissions}</tbody>
        </Table>
      </>
    ) : (
      <Text color="dimmed" align="center">
        {/* @ts-ignore */}
        No Delivery Tickets for this {
          // @ts-ignore
          editReferralValues?.product_type?.title
        }{" "}
        referral.
      </Text>
    );

  // const [ extensionVisibility, setExtensionVisibility ] = useState(false);
  // const [ resupplyVisibility, setResupplyVisibility ] = useState(false);

  // TODO
  // const dp = datePickerValue(editReferralValues);

  const automated_emails = (
    <>
      {/* @ts-ignore */}
      <Switch
        size="lg"
        onLabel="SENT"
        offLabel="NOT"
        label="NEW"
        // @ts-ignore
        checked={editReferralValues?.automated_email_status?.newReferral}
      />
      {/* @ts-ignore */}
      <Switch
        size="lg"
        onLabel="SENT"
        offLabel="NOT"
        label="RTF"
        // @ts-ignore
        checked={editReferralValues?.automated_email_status?.readyToFit}
      />
      {/* @ts-ignore */}
      <Switch
        size="lg"
        onLabel="SENT"
        offLabel="NOT"
        label="USDL"
        // @ts-ignore
        checked={editReferralValues?.automated_email_status?.usdl}
      />
      {/* @ts-ignore */}
      <Switch
        size="lg"
        onLabel="SENT"
        offLabel="NOT"
        label="DD"
        // @ts-ignore
        checked={editReferralValues?.automated_email_status?.deadDeal}
      />
    </>
  );

  // !! PANELS OF REFERRAL !!

  const mainPanelOfSelectedReferral = (
    <Tabs.Panel value="main">
      <Group spacing={16}>
        <TextInput
          mt={8}
          label="Agreement"
          disabled
          {...getInputPropsForEditReferral("agreement")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <TextInput
          mt={8}
          label="Product"
          disabled
          {...getInputPropsForEditReferral("product_title")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Product Type"
          // placeholder="Client"
          {...getInputPropsForEditReferral("product_type_id")}
          {...productTypeSelectProps}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="RX Length"
          {...getInputPropsForEditReferral(`rx_length_id`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // @ts-ignore
          data={
            rxLengthLookup?.map(({ id, title }) => ({
              value: id,
              label: title,
            })) as any
          }
          style={{ maxWidth: 300, minWidth: 239 }}
        />
      </Group>
      <Group spacing={16}>
        <DatePicker
          mt="md"
          label="Rx Rec'd Date"
          placeholder="Rx Rec'd Date"
          withinPortal
          {...getInputPropsForEditReferral("rx_received_date")}
          value={datePickerValue(editReferralValues)("rx_received_date")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <DatePicker
          mt="md"
          label="Date of Surgery"
          placeholder="Date of Surgery"
          withinPortal
          {...getInputPropsForEditReferral("date_of_surgery")}
          value={datePickerValue(editReferralValues)("date_of_surgery") as any}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt="md"
          label="Condition"
          placeholder="Condition"
          {...getInputPropsForEditReferral("condition")}
          // @ts-ignore
          disabled={!!editReferralValues?.date_of_surgery}
          data={[
            { value: "C", label: "Chronic" },
            { value: "A", label: "Acute" },
            { value: null as any, label: "-/-" },
          ]}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <DatePicker
          mt="md"
          label="Rx Expiration Date"
          placeholder="Rx Expiration Date"
          withinPortal
          {...getInputPropsForEditReferral("rx_expiration_date")}
          value={datePickerValue(editReferralValues)("rx_expiration_date")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <DatePicker
          mt="md"
          label="Date Fit"
          // placeholder="TCT five day call"
          withinPortal
          // TODO figure out why new values can not be set (maybe a stray useEffect)
          {...getInputPropsForEditReferral("date_fit")}
          value={datePickerValue(editReferralValues)("date_fit")}
          style={{ maxWidth: 300, minWidth: 239 }}
          onChange={(value) => {
            // TODO
            if (value) {
              const aDate = dayjs(value);
              // @ts-ignore
              if (!editReferralValues?.five_day_call) {
                setFieldValueUponEditReferral(
                  "five_day_call",
                  aDate.add(5, "day").toISOString()
                );
              }
              // @ts-ignore
              if (!editReferralValues?.fourteen_day_call) {
                setFieldValueUponEditReferral(
                  "fourteen_day_call",
                  aDate.add(14, "day").toISOString()
                );
              }
              setFieldValueUponEditReferral("date_fit", aDate.toISOString());
            } else {
              setFieldValueUponEditReferral("date_fit", null);
            }
          }}
        />
      </Group>
    </Tabs.Panel>
  );

  const claimPanel = (
    <Tabs.Panel value="claim">
      <Group>
        <Select
          mt={8}
          label="Insurance"
          // placeholder="Client"
          {...getInputPropsForEditReferral("insurance_id")}
          {...insuranceSelectProps}
          // @ts-ignore
          // defaultValue={editReferralValues.insurance_id}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Bill to Insurance"
          // placeholder="Client"
          {...getInputPropsForEditReferral("bill_to_insurance_id")}
          {...billToInsuranceSelectProps}
          // onChange={(value) => {
          //   console.log(value)
          // }}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Claim Type"
          // placeholder="Client"
          {...getInputPropsForEditReferral("claim_type_id")}
          {...claimTypeSelectProps}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
      </Group>
      <Group>
        <DatePicker
          mt="md"
          label="Date of Injury"
          placeholder="Date of Injury"
          withinPortal
          {...getInputPropsForEditReferral("date_of_injury")}
          value={datePickerValue(editReferralValues)("date_of_injury")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <TextInput
          mt={8}
          label="Employer"
          {...getInputPropsForEditReferral("employer")}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
        />
        <TextInput
          mt={8}
          label="Attorney"
          {...getInputPropsForEditReferral("attorney")}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
        />
      </Group>
      <Group>
        <Select
          mt={8}
          label="Ver Notifications"
          placeholder="Ver Notifications"
          {...getInputPropsForEditReferral("column_b")}
          style={{ maxWidth: 300 }}
          data={[
            { value: 30 as any, label: "30" },
            { value: 20 as any, label: "20" },
            { value: 10 as any, label: "10" },
            { value: 5 as any, label: "5" },
            { value: null as any, label: "NONE" },
          ]}
        />
        <Select
          mt="md"
          label="Added to Collaborate?"
          placeholder="Added to Collaborate?"
          {...getInputPropsForEditReferral("added_to_collaborate")}
          data={[
            { value: true as any, label: "Yes" },
            { value: false as any, label: "No" },
            { value: null as any, label: "-/-" },
          ]}
          style={{ maxWidth: 300 }}
        />
        <Select
          mt={8}
          label="PO Status"
          {...getInputPropsForEditReferral("column_d")}
          style={{ maxWidth: 300, marginTop: 16 }}
          clearable
          data={[
            { value: "!", label: "!" },
            { value: "AC", label: "AC" },
            { value: "ACP", label: "ACP" },
            { value: "APP", label: "APPEAL" },
            { value: "ATTY", label: "ATTORNEY" },
            // {value: 'BV', label: 'BV'},
            { value: "CB", label: "CB" },
            { value: "G", label: "G" },
            { value: "RA", label: "RA" },
            { value: "P", label: "P" },
          ]}
        />
        <Select
          mt="md"
          label="AW VER"
          placeholder="AW VER"
          {...getInputPropsForEditReferral("aw_ver")}
          data={[
            { value: true as any, label: "Yes" },
            { value: false as any, label: "No" },
            { value: null as any, label: "-/-" },
          ]}
          style={{ maxWidth: 300 }}
        />
      </Group>
    </Tabs.Panel>
  );

  const contactPanel = (
    <Tabs.Panel value="contact">
      <Group>
        <Select
          mt="md"
          label="Initial Call Complete"
          placeholder="Initial Call Complete"
          {...getInputPropsForEditReferral("initial_call_complete")}
          data={[
            { value: true as any, label: "Yes" },
            { value: false as any, label: "No" },
            { value: null as any, label: "-/-" },
          ]}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt="md"
          label="Follow Up Call"
          placeholder="Follow Up Call"
          {...getInputPropsForEditReferral("follow_up_call")}
          data={[
            { value: "needed", label: "NEEDED" },
            { value: null as any, label: "-/-" },
          ]}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Document Status"
          // placeholder="Client"
          {...getInputPropsForEditReferral("document_status_id")}
          {...documentStatusSelectProps}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
        />
      </Group>
      <Group>
        <DatePicker
          mt="md"
          label="Five Day Call"
          // placeholder="TCT five day call"
          withinPortal
          {...getInputPropsForEditReferral("five_day_call")}
          value={datePickerValue(editReferralValues)("five_day_call")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Five Day Call Status"
          // type="date"
          // placeholder="Document Status"
          {...getInputPropsForEditReferral("five_day_call_status")}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
          data={[
            { value: true as any, label: "Completed" },
            { value: false as any, label: "Unsuccessful" },
            { value: null as any, label: "-/-" },
          ]}
        />
        <DatePicker
          mt="md"
          label="Fourteen Day Call"
          // placeholder="TCT fourteen day call"
          withinPortal
          {...getInputPropsForEditReferral("fourteen_day_call")}
          value={datePickerValue(editReferralValues)("fourteen_day_call")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
        <Select
          mt={8}
          label="Fourteen Day Call Status"
          // type="date"
          // placeholder="Document Status"
          {...getInputPropsForEditReferral("fourteen_day_call_status")}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
          data={[
            { value: true as any, label: "Completed" },
            { value: false as any, label: "Unsuccessful" },
            { value: null as any, label: "-/-" },
          ]}
        />
      </Group>
      <Group>
        {/* <Select
          mt={8}
          label="Survey Complete"
          // type="date"
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
          {...getInputPropsForEditReferral("survey_complete")}
          data={[
            { value: true as any, label: "Yes" },
            { value: false as any, label: "No" },
            { value: null as any, label: "-/-" },
          ]}
        /> */}
        <Select
          mt={8}
          label="Pickup Status"
          {...getInputPropsForEditReferral("pickup_status_id")}
          style={{ maxWidth: 300, minWidth: 239, marginTop: 16 }}
          {...pickupStatusSelectProps}
        />
        <DatePicker
          mt="md"
          label="Pickup Date"
          placeholder="Pickup Date"
          withinPortal
          {...getInputPropsForEditReferral("pickup_date")}
          value={datePickerValue(editReferralValues)("pickup_date")}
          style={{ maxWidth: 300, minWidth: 239 }}
        />
      </Group>
    </Tabs.Panel>
  );

  const {
    data: {
      // @ts-ignore
      data: injurySiteLookup,
    } = {},
  } = useList({
    resource: "injury_site",

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      pageSize: 1000,
    },
  });

  // @ts-ignore
  const injurySitePanel = !editReferralValues?.injury_sites_old ? (
    <Tabs.Panel value="injury_sites">
      {/* @ts-ignore */}
      {editReferralValues?.injury_sites?.map((item, index) => (
        <Group mt="xs">
          {/* <Select
                mt={8}
                label="Injury Site"
                placeholder="Type in an Injury Site."
                {...getInputPropsForEditReferral(`injury_sites.${index}`)}
                searchable={true}
                clearable={true}
                filterDataOnExactSearchMatch={true}
                data={injurySiteLookup?.map(({id,title}) => ({value:title, label:title})) as any}
                style={{ maxWidth: 300 }}
            /> */}
          <TextInput
            mt={8}
            label="Injury Site"
            placeholder="Type in an Injury Site."
            {...getInputPropsForEditReferral(`injury_sites.${index}`)}
            // searchable={true}
            // clearable={true}
            // filterDataOnExactSearchMatch={true}
            // data={injurySiteLookup?.map(({id,title}) => ({value:title, label:title})) as any}
            style={{ maxWidth: 300 }}
          />
          <ActionIcon
            color="red"
            onClick={() => removeListItemOfEditReferral("injury_sites", index)}
          >
            {/* @ts-ignore */}
            <CloseButton size="1rem" />
          </ActionIcon>
        </Group>
      ))}
      <Group mt="md">
        <Button
          onClick={() => {
            insertListItemOfEditReferral("injury_sites", "");
          }}
        >
          {/* @ts-ignore */}
          Add {editReferralValues?.injury_sites?.length > 0
            ? "another "
            : ""}{" "}
          Injury Site
        </Button>
      </Group>
    </Tabs.Panel>
  ) : (
    <Tabs.Panel value="injury_sites">
      <Textarea
        mt={8}
        label="Injury Sites"
        placeholder="Type in an Injury Site."
        {...getInputPropsForEditReferral(`injury_sites_old`)}
        // searchable={true}
        // clearable={true}
        // filterDataOnExactSearchMatch={true}
        // data={injurySiteLookup?.map(({id,title}) => ({value:title, label:title})) as any}
        style={{ maxWidth: 600 }}
        minRows={2}
      />
    </Tabs.Panel>
  );

  // @ts-ignore
  const icd10_codes_panel = editReferralValues?.icd10_codes?.length > -1 && (
    <Tabs.Panel value="icd10_codes">
      {/* @ts-ignore */}
      {editReferralValues?.icd10_codes?.map((item, index) => (
        <Group mt="xs">
          <TextInput
            mt={8}
            label="ICD 10 code"
            placeholder="Type in a Code."
            {...getInputPropsForEditReferral(`icd10_codes.${index}`)}
            // {...injurySiteProps}
            style={{ maxWidth: 300 }}
          />
          <ActionIcon
            color="red"
            onClick={() => removeListItemOfEditReferral("icd10_codes", index)}
          >
            <CloseButton
              // @ts-ignore
              size="1rem"
            />
          </ActionIcon>
        </Group>
      ))}
      <Group mt="md">
        <Button
          onClick={() => {
            insertListItemOfEditReferral("icd10_codes", "");
          }}
        >
          {/* @ts-ignore */}
          Add {editReferralValues?.icd10_codes?.length > 0
            ? "another "
            : ""}{" "}
          ICD-10 code
        </Button>
      </Group>
    </Tabs.Panel>
  );

  const providerPanel = (
    <Tabs.Panel value="provider">
      <Select
        mt="md"
        label="Referring Provider"
        placeholder="Provider"
        {...getInputPropsForEditReferral("provider_id")}
        {...referringProviderSelectProps}
        style={{ maxWidth: 300, minWidth: 239 }}
        // searchValue={provider}
        defaultValue={editReferralValues?.provider_id}
      />
      <Select
        mt="md"
        label="Billing Provider"
        placeholder="Provider"
        {...getInputPropsForEditReferral("billing_provider_id")}
        {...billingProviderSelectProps}
        style={{ maxWidth: 300, minWidth: 239 }}
        defaultValue={editReferralValues?.billing_provider_id}
      />
    </Tabs.Panel>
  );

  const extensionCRUD =
    extensionVisibility == false ? (
      <>
        <></>
        <Button onClick={() => setExtensionVisibility(true)}>
          Add Extension
        </Button>
        <br></br>
      </>
    ) : (
      <>
        <></>
        <Button
          onClick={() => {
            setExtensionVisibility(false);
            resetOfExtension();
          }}
        >
          Cancel Extension
        </Button>
        <SwizzledCreate
          saveButtonProps={{
            ...extensionSaveButtonProps,
            style: {
              maxWidth: 300,
              // @ts-ignore
              ta: "left",
            },
            children: <Text>Add Extension</Text>,
          }}
          breadcrumb={<></>}
          title={<></>}
          goBack={false}
        >
          <Stack>
            <Select
              mt={8}
              label="REAUTH STATUS"
              {...getInputPropsForSubmission("reauth_status_id")}
              {...reauthStatusSelectProps}
              style={{ maxWidth: 300 }}
            />

            <br />
            <DatePicker
              mt="md"
              label="Extension Date"
              placeholder="Extension Date"
              withinPortal
              {...getInputPropsForExtension("extended_to_date")}
              value={datePickerValue(extensionValues)("extended_to_date")}
              style={{ maxWidth: 300 }}
            />
            <Select
              mt={8}
              label="Extension Status"
              // placeholder="Client"
              {...getInputPropsForExtension("extension_status_id")}
              {...extensionStatusSelectProps}
              style={{ maxWidth: 300 }}
            />
            <DatePicker
              mt="md"
              label="Rx Expiration Date"
              placeholder="Rx Expiration Date"
              withinPortal
              {...getInputPropsForExtension("rx_expiration_date")}
              value={datePickerValue(extensionValues)("rx_expiration_date")}
              style={{ maxWidth: 300 }}
            />
          </Stack>
        </SwizzledCreate>
      </>
    );

  const extensionPanel = // @ts-ignore
  editReferralValues?.product_type?.title == "TCT" &&
    // @ts-ignore
    !!editReferralValues?.extension_settings && (
      <Tabs.Panel value="extensions">
        <Tabs>
          <Tabs.List>
            <Tabs.Tab value="extension_setting">Setting</Tabs.Tab>
            <Tabs.Tab value="instances">Instances</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="instances">
            {/* @ts-ignore */}
            {editReferralValues?.product_id ? (
              extensionCRUD
            ) : (
              <>
                <Text>
                  To add extensions, associate equipment to this referral via a
                  submission request
                </Text>
              </>
            )}
            {extensions?.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>Extension Date</th>
                    <th>Status</th>
                    <th>Insurance</th>
                    {/* <th>Reauth</th> */}
                    <th>Rx Expiration Date</th>
                  </tr>
                </thead>
                <tbody>{extensions}</tbody>
              </Table>
            ) : (
              <Text color="dimmed" align="center">
                No Extensions for this patient.
              </Text>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="extension_setting">
            <Grid>
              <Grid.Col span={4}>
                <DatePicker
                  mt={8}
                  label="Start Date"
                  withinPortal
                  {...getInputPropsForEditReferral(
                    "extension_settings.start_date"
                  )}
                  value={datePickerValue(editReferralValues)(
                    "extension_settings.start_date"
                  )}
                  style={{ maxWidth: 300 }}
                  excludeDate={(thing) => thing <= new Date()}
                />
                {/* <Select
                    mt={8}
                    label="Frequency"
                    style={{ maxWidth: 300 }}
                    {...getInputPropsForEditReferral("extension_settings.frequency")}
                    data={[
                      {value: 30 as any, label: "30 Days"},
                      {value: 55 as any, label: "55 Days"},
                      {value: 60 as any, label: "60 Days"},
                    ]}
                    // @ts-ignore
                    defaultValue={editReferralValues?.extension_settings?.frequency}
                  /> */}
                <TextInput
                  mt={8}
                  label="Occurences"
                  style={{ maxWidth: 300 }}
                  type="number"
                  {...getInputPropsForEditReferral(
                    "extension_settings.occurences"
                  )}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
      </Tabs.Panel>
    );

  // TODO implement resupply generation setting upon referral
  // Start, Frequeny, items, # of generations
  const resuppliesPanel = // @ts-ignore
  editReferralValues?.product_type?.title == "NMES" &&
    // @ts-ignore
    !!editReferralValues?.resupply_settings && (
      // &&
      // // @ts-ignore
      // Object.keys(editReferralValues?.resupply_settings).length > 0
      <Tabs.Panel value="resupplies">
        <Tabs>
          <Tabs.List>
            <Tabs.Tab value="resupply_setting">Settings</Tabs.Tab>
            <Tabs.Tab value="instances">Instances</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="resupply_setting">
            <Grid>
              <Grid.Col span={4}>
                <DatePicker
                  mt={8}
                  label="Start Date"
                  withinPortal
                  {...getInputPropsForEditReferral(
                    "resupply_settings.start_date"
                  )}
                  value={datePickerValue(editReferralValues)(
                    "resupply_settings.start_date"
                  )}
                  style={{ maxWidth: 300 }}
                  excludeDate={(thing) => thing <= new Date()}
                />
                <Select
                  mt={8}
                  label="Frequency"
                  style={{ maxWidth: 300 }}
                  {...getInputPropsForEditReferral(
                    "resupply_settings.frequency"
                  )}
                  data={[
                    { value: 30 as any, label: "30 Days" },
                    { value: 55 as any, label: "55 Days" },
                    { value: 60 as any, label: "60 Days" },
                  ]}
                  // @ts-ignore
                  defaultValue={
                    // @ts-ignore
                    editReferralValues?.resupply_settings?.frequency
                  }
                />
                <TextInput
                  mt={8}
                  label="Occurences"
                  style={{ maxWidth: 300 }}
                  type="number"
                  {...getInputPropsForEditReferral(
                    "resupply_settings.occurences"
                  )}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                {resupply_items?.length > 0 ? (
                  <Group mb="xs">
                    <Text weight={500}>Resupply Item(s)</Text>
                  </Group>
                ) : (
                  <Text color="dimmed" align="center">
                    No resupply items defined
                  </Text>
                )}

                {resupply_items}

                <Group mt="md">
                  <Button
                    disabled={resupply_items?.length >= 2 ? true : false}
                    onClick={() =>
                      insertListItemOfEditReferral("resupply_items", "")
                    }
                  >
                    Add Another Resupply Item
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
          <Tabs.Panel value="instances">
            <Table>
              <thead>
                <tr>
                  <th>Link</th>
                  <th>Resupply Date</th>
                </tr>
              </thead>
              <tbody>{resupply_dates}</tbody>
            </Table>
          </Tabs.Panel>
        </Tabs>
      </Tabs.Panel>
    );

  const automatedEmailsPanel = (
    <Tabs.Panel value="automated_emails">{automated_emails}</Tabs.Panel>
  );

  const jobsPanel = (
    <Tabs.Panel value="jobs">
      <Tabs
        onTabChange={(tab) => {
          if (tab == "shipment_requests") {
            setFieldValueUponSubmission("submission_type", "shipment_request");
          } else if (tab == "delivery_tickets") {
            setFieldValueUponSubmission("submission_type", "delivery_ticket");
          }
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="shipment_requests">Shipment Request(s)</Tabs.Tab>
          <Tabs.Tab value="delivery_tickets">
            Delivery Ticket For Fitter(s)
          </Tabs.Tab>
          {/* TODO implement */}
          {/* <Tabs.Tab value="automated_emails">Automated Emails</Tabs.Tab> */}
        </Tabs.List>

        {submissionForm}
        <br />
        <Tabs.Panel value="shipment_requests">
          <br />
          {submissionFormVisibilityButton}
          <br />
          <br />
          {shipmentRequestSubmissionsTable}
          <br />
          {/* // shipped through courier

            items get filled out first

            //once items are defined, and end user specifies the type of shipment Request (NMES|TCT|SCOOTER), and submits

            // then karen sees it in her queue
            SERIAL NUMBER (free-form v. lookup based on product type), DATE SHIPPED,	TRACKING NUMBER

            // also pops up in another queue
            DATE FIT

            //Produces a different delivery ticket FOR BILLING */}
        </Tabs.Panel>
        <Tabs.Panel value="delivery_tickets">
          <br />
          {submissionFormVisibilityButton}
          <br />
          <br />
          {deliveryTicketSubmissionsTable}
          <br />
          {/*
            // delivered via fitter

            // DATE FIT, FITTER, // ClAIM	TYPE,	EMPLOYER,	DOI, ATTORNEY

            // SERIAL NUMBER*/}
        </Tabs.Panel>
        {/* TODO REIMPLEMENT */}
        {/* {automatedEmailsPanel} */}
      </Tabs>
    </Tabs.Panel>
  );

  // result
  const selectedReferralConent = (
    <>
      <Tabs defaultValue="main">
        <Tabs.List>
          <Tabs.Tab value="main">Main</Tabs.Tab>
          <Tabs.Tab value="claim">Claim</Tabs.Tab>
          <Tabs.Tab value="contact">Contact</Tabs.Tab>
          <Tabs.Tab value="injury_sites">Injury Sites</Tabs.Tab>
          {/* <Tabs.Tab value="icd10_codes">ICD-10 Codes</Tabs.Tab> */}
          {/* @ts-ignore */}
          {<Tabs.Tab value="provider">Provider</Tabs.Tab>}
          {/* @ts-ignore */}
          {editReferralValues?.product_type?.title == "TCT" && (
            <Tabs.Tab value="extensions">Extensions</Tabs.Tab>
          )}
          {/* @ts-ignore */}
          {editReferralValues?.product_type?.title == "NMES" && (
            <Tabs.Tab value="resupplies">Resupplies</Tabs.Tab>
          )}
          <Tabs.Tab value="jobs">Jobs</Tabs.Tab>
        </Tabs.List>
        {mainPanelOfSelectedReferral}
        {claimPanel}
        {contactPanel}
        {injurySitePanel}
        {/* {icd10_codes_panel} */}
        {/* @ts-ignore */}
        {providerPanel}
        {extensionPanel}
        {resuppliesPanel}
        {jobsPanel}
      </Tabs>
    </>
  );

  //  //  //  //  //  //  //  //  //

  // @ts-ignore
  const selectedReferralSection = editReferralValues?.id && (
    <Tabs.Panel value="selectedReferral">
      {/* <Button {...saveButtonPropsForEditReferral}>Save This Referral</Button> */}
      {selectedReferralConent}
    </Tabs.Panel>
  );

  const today = new Date().getDate();
  const dayRenderer: DatePickerProps["renderDay"] = (date) => {
    const day = date.getDate();

    return (
      <Indicator size={6} color="red" offset={10} disabled={day !== today}>
        <div>{day}</div>
      </Indicator>
    );
  };

  // @ts-ignore
  const referrals = values?.referrals
    // @ts-ignore
    ?.sort(
      // @ts-ignore
      ({ rx_received_date: a, id: idA }, { rx_received_date: b, id: idB }) => {
        const aD = dayjs(a);
        const bD = dayjs(b);
        if (aD.isAfter(bD)) {
          return -1;
        }
        if (aD.isBefore(bD)) {
          return 1;
        }

        if (idA < idB) {
          return -1;
        } else {
          return 1;
        }
      }
    )
    // @ts-ignore
    ?.reduce((prev, curr, i, arr) => {
      // if first iteration
      if (i == 0) {
        prev = {
          gray: [],
          nonGray: [],
        };
      }

      const status = curr?.insurance_status?.title || "";

      if (/BILLED/.test(status)) prev.gray.push(curr);
      else prev.nonGray.push(curr);

      // if last element
      if (i + 1 == arr.length) return [...prev.nonGray, ...prev.gray];

      // else
      return prev;
    }, [])
    ?.map((referral: any) => {
      const { id } = referral;

      // TODO figure out why this hack has to be used rather then
      // just plain old destructuring
      const product_title = referral?.product_title;

      // @ts-ignore
      const date_of_surgery = referral?.date_of_surgery;
      // @ts-ignore
      const condition = referral?.condition;
      const date_rx_received = referral?.rx_received_date;

      const insurance = referral?.insurance?.title;
      const claim_type = referral?.claim_type?.title;

      const insurance_id = referral?.insurance_id;
      const bill_to_insurance_id = referral?.bill_to_insurance_id;
      const alertInsurance =
        insurance_id && insurance_id != bill_to_insurance_id;

      const pro = referral?.provider?.name;
      const rep = referral?.provider?.provider_representatives
        ?.map((x: any) => {
          const {
            representative: { name },
          } = x;
          return name;
        })
        .join(" / ");

      const provider_id = referral?.provider_id;
      const billing_provider_id = referral?.billing_provider_id;
      const alertProvider = provider_id && provider_id != billing_provider_id;

      const isBilled = /BILLED/.test(referral?.insurance_status?.title);
      const insUrgent = /URGENT/.test(referral?.insurance_status?.title);
      const patUrgent = /URGENT/.test(referral?.patient_status?.title);

      const insuranceStatusId = referral?.insurance_status_id;
      const insurance_status_select = (
        <Select
          mt={8}
          style={{ maxWidth: 250 }}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // @ts-ignore
          data={
            insuranceStatusLookup?.map(({ id, title }) => ({
              value: id,
              label: title,
            })) as any
          }
          defaultValue={insuranceStatusId}
          onChange={(value) => {
            if (value) {
              // //  @ts-ignore
              // const referralIndex = values?.referrals?.findIndex( x => {
              //   const {id: idOfSomeReferral} = x;
              //   return idOfSomeReferral == id
              // } )
              // if (referralIndex > -1){
              //   // update referral data locally
              //   setFieldValue(`referrals.${referralIndex}.insurance_status_id`, value)
              // }
              setIdOfEditReferral(referral.id);
              setFieldValueUponEditReferral("insurance_status_id", value);

              // mutate({
              //   resource: 'referral',
              //   values: {
              //     insurance_status_id: value
              //   },
              //   id
              // })
            }
          }}
        ></Select>
      );

      const patientStatusId = referral?.patient_status_id;
      const patient_status_select = (
        <Select
          mt={8}
          style={{ maxWidth: 250 }}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // @ts-ignore
          data={
            patientStatusLookup?.map(({ id, title }) => ({
              value: id,
              label: title,
            })) as any
          }
          defaultValue={patientStatusId}
          onChange={(value) => {
            if (value) {
              setIdOfEditReferral(referral.id);
              setFieldValueUponEditReferral("patient_status_id", value);
            }
          }}
        ></Select>
      );

      const next_call_date = dayjs(referral?.next_call_date as Date).isValid()
        ? dayjs(referral?.next_call_date as Date).toDate()
        : null;

      let thingg;
      if (date_of_surgery && dayjs(date_of_surgery as Date).isValid()) {
        thingg = `DOS: ${formatDate(date_of_surgery)}`;
      }

      let conditionMapped;
      if (condition == "A") conditionMapped = "ACUTE";
      if (condition == "C") conditionMapped = "CHRONIC";
      thingg = thingg || conditionMapped || "N/A";

      const nextCallDatePicker = (
        <DatePicker
          mt="md"
          style={{ maxWidth: 175 }}
          // valueFormat='MM-DD-YY'
          withinPortal
          defaultValue={next_call_date}
          renderDay={dayRenderer}
          disabled={id !== idOfEditReferral}
          onChange={(value) => {
            // setIdOfEditReferral(referral.id);
            editReferralSetValues((prev) => ({
              ...prev,
              next_call_date: value,
            }));
          }}
        />
      );

      const dates = (
        <Stack>
          <Text>REC: {formatDate(date_rx_received)}</Text>
          <Text>{thingg}</Text>
        </Stack>
      );

      const maybeDeadDeal = /USDL|TRICARE/.test(insurance) && claim_type.includes("P2A")

      const claim_column = (
        <Stack>
          <Flex>
            <Text>{insurance}</Text>
            {alertInsurance && (
              <Tooltip
                openDelay={500}
                label="Insurance and 'Bill to Insurance' is different"
              >
                <div>
                  <IconAlertCircle color="red" />
                </div>
              </Tooltip>
            )}
            {maybeDeadDeal && (
              <Tooltip
                openDelay={500}
                label="THIS IS AN AUTOMATIC DEAD DEAL"
              >
                <div>
                  <IconAlertCircle color="red" />
                </div>
              </Tooltip>
            )}
          </Flex>
          <Text>{claim_type}</Text>
        </Stack>
      );

      const pro_rep = (
        <Stack>
          <Flex>
            <Text>{pro}</Text>
            {alertProvider && (
              <Tooltip
                openDelay={500}
                label="Provider and 'Bill to Provider' is different"
              >
                <div>
                  <IconAlertCircle color="red" />
                </div>
              </Tooltip>
            )}
          </Flex>
          <Text>{rep}</Text>
        </Stack>
      );

      const incrementNextCallDate = (
        <Button>
          <IconCheckbox></IconCheckbox>
        </Button>
      );

      const onClick = () => {
        if (id !== idOfEditReferral) {
          setIdOfEditReferral(referral.id);
          editReferralSetValues((prev: any) => ({
            // ...prev, // ?? This should be removed?
            ...referral,
          }));
        }
      };

      return (
        <tr
          key={id}
          onClick={onClick}
          style={{
            // @ts-ignore // yellow
            background:
              isBilled && id !== idOfEditReferral
                ? "#D3D3D3"
                : id == idOfEditReferral
                ? "#ffff00"
                : "transparent",
          }}
        >
          <td>
            <Flex>
              {product_title}
              {MaybeCategory(referral)}
              {MaybeDocumentIndicator(referral)}
            </Flex>
          </td>
          <td>{dates}</td>
          <td>{claim_column}</td>
          <td>{pro_rep}</td>
          <td
            style={{
              background: !isBilled && patUrgent ? "red" : "transparent",
            }}
          >
            {patient_status_select}
          </td>
          <td
            style={{
              background: !isBilled && insUrgent ? "red" : "transparent",
            }}
          >
            {insurance_status_select}
          </td>
          <td>{nextCallDatePicker}</td>
          {/* <td>{incrementNextCallDate}</td> */}
          {/* <td>{editThisReferral}</td> */}
          {/* <td>{editButton}</td> */}
        </tr>
      );
    });

  const referralsTable =
    referrals?.length > 0 ? (
      <Table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Dates</th>
            <th>Claim</th>
            <th>Pro Rep</th>
            <th>Patient Status</th>
            <th>Insurance Status</th>
            <th>Next Call Date</th>
            {/* <th></th> */}
            {/* <th></th> */}
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>{referrals}</tbody>
      </Table>
    ) : (
      <Text color="dimmed" align="center">
        No Referrals for this patient.
      </Text>
    );

  // Questionaires
  // const questionaireTab = <Questionnaires
  //   forms={{main}}
  // />;

  // @ts-ignore
  const hasOldNotes = values?.referrals?.some( ref => {
    const { old_ver_notes, old_rep_com_notes } = ref;
    const combined = old_ver_notes.trim() + old_rep_com_notes.trim();
    return combined.length > 0
  });

  // @ts-ignore
  const oldNotes = hasOldNotes && values?.referrals?.reduce( (prev, curr) => {
    const {
      old_ver_notes, old_rep_com_notes,
    } = curr;
    const combined = old_ver_notes.trim() + old_rep_com_notes.trim();
    if (combined.length > 0){
      const product_type = curr?.product_type?.title;
      const date_rx_received = curr?.rx_received_date;
      const ref_title = `${product_type} ${formatDate(date_rx_received)}`;
      
      const frag = <Tabs defaultValue={`ver_${ref_title}`}>
      <Tabs.List>
        <Tabs.Tab value={`ver_${ref_title}`}>
          Verification and Billing
        </Tabs.Tab>
        <Tabs.Tab value={`rep_com_${ref_title}`}>Rep and Com</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={`ver_${ref_title}`}>
        <Textarea
          mt={8}
          label="Verification and Billing Notes"
          placeholder="Verification Notes"
          {...getInputProps("old_ver_notes")}
          minRows={8}
          style={{ maxWidth: 900 }}
        />
      </Tabs.Panel>
      <Tabs.Panel value="rep_com_notes">
        <Textarea
          mt={8}
          label="Rep Communication Notes"
          placeholder="Rep Communication Notes"
          {...getInputProps("old_rep_com_notes")}
          minRows={8}
          style={{ maxWidth: 900 }}
        />
      </Tabs.Panel>
    </Tabs>
    }
    
    return prev
  }, [])

  // @ts-ignore
  const oldNotesTab =
  // @ts-ignore
    hasOldNotes? (
      <Tabs.Tab value="old_notes">Prev Notes</Tabs.Tab>
    ) : null;

  // @ts-ignore
  const oldNotesTabPanel =
  // @ts-ignore
    values?.rep_communication_notes || values?.verification_notes ? (
      <Tabs.Panel value="old_notes">
        <Tabs defaultValue="verification_and_billing">
          <Tabs.List>
            <Tabs.Tab value="verification_and_billing">
              Verification and Billing
            </Tabs.Tab>
            <Tabs.Tab value="rep_com_notes">Rep and Com</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="verification_and_billing">
            <Textarea
              mt={8}
              label="Verification and Billing Notes"
              placeholder="Verification Notes"
              {...getInputProps("verification_notes")}
              minRows={8}
              style={{ maxWidth: 900 }}
            />
          </Tabs.Panel>
          <Tabs.Panel value="rep_com_notes">
            <Textarea
              mt={8}
              label="Rep Communication Notes"
              placeholder="Rep Communication Notes"
              {...getInputProps("rep_communication_notes")}
              minRows={8}
              style={{ maxWidth: 900 }}
            />
          </Tabs.Panel>
        </Tabs>
      </Tabs.Panel>
    ) : null;

  const forms = {
    main,
  };

  // @ts-ignore
  const hasAlert = values?.patient_notes.some((x) => {
    const {
      note: {
        id,
        content,
        created_at,
        authed_user: { id: uid, display_name, photoURL },
        metadata,
        // note_type: {
        //     code
        // }
      },
    } = x;

    const { note_tags: mNoteTags, acknowledgement = {} } = metadata;

    return (
      mNoteTags.some((y: string) => y.includes("ALERT")) &&
      Object.keys(acknowledgement).length == 0
    );
  });

  const notesSection = (
    <Notes
      patient_id={id}
      referrals={initialValues?.referrals}
      // @ts-ignore
      patient_notes={values?.patient_notes as any[]}
      forms={forms}
      main_accessor="patient_notes"
    />
  );

  const HistoryPanel = (
    <Tabs.Panel value="history">
      <Tabs>
        <Tabs.List>
          <Tabs.Tab value="referral">REFERRAL</Tabs.Tab>
          <Tabs.Tab value="patient">PATIENT</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="referral">
          <ReferralHistory forms={forms} />
        </Tabs.Panel>
        <Tabs.Panel value="patient">
          <PatientHistory forms={forms} />
        </Tabs.Panel>
      </Tabs>
    </Tabs.Panel>
  );

  // @ts-ignore
  const hasEquipment = values?.referrals.some((referral) => {
    const { id: some_referral_id } = referral;
    //  @ts-ignore
    const product_ref_id = referral?.product?.referral_id;
    return some_referral_id == product_ref_id;
  });

  // @ts-ignore
  const EquipmentPanel =
    hasEquipment &&
    // @ts-ignore
    values?.referrals.reduce((prev, curr) => {
      const { id: some_referral_id } = curr;
      //  @ts-ignore
      const product_ref_id = curr?.product?.referral_id;
      const serial_number = curr?.product?.serial_number;
      const product_id = curr?.product?.id;
      if (some_referral_id == product_ref_id) {
        prev.push(
          <Anchor
            href={`/fulfillment/product/edit/${product_id}`}
            target="_blank"
          >
            {serial_number}
          </Anchor>
        );
      }
      return prev;
    }, []);

  // console.log(EquipmentPanel)

  // @ts-ignore
  const latestUpdate = values?.referrals?.reduce((prev, curr) => {
    const { updated_at } = curr;
    const display_name = curr?.authed_user_who_updated?.display_name;
    const photoURL = curr?.authed_user_who_updated?.photoURL;

    if (updated_at && display_name && photoURL) {
      if (!prev) {
        return {
          updated_at,
          display_name,
          photoURL,
        };
      }

      const prev_at = prev.updated_at;
      if (dayjs(prev_at).isBefore(dayjs(updated_at))) {
        return {
          updated_at,
          display_name,
          photoURL,
        };
      }
    }

    return prev;
  }, null);

  // @ts-ignore
  const activeUsers = values?.meta?.userActivity || [];
  // console.log(activeUsers)
  const activeUserAvatars = activeUsers.map((user: any) => (
    <Avatar
      radius="md"
      // @ts-ignore
      src={user?.picture}
      imageProps={{ referrerPolicy: "no-referrer" }}
    />
  ));

  return (
    <>
      <SwizzledEdit
        saveButtonProps={
          // saveButtonProps
          <>Hi</>
        }
        breadcrumb={<></>}
        // TODO
        headerButtons={
          <></>
          // <Button>
          //   Next Patient
          // </Button>
        }
        title={<Text fw={700}>Patient Details</Text>}
      >
        <Grid>
          <Grid.Col span={4} style={{ outline: "2px solid" }}>
            <Text size="lg">
              {/* @ts-ignore */}
              {values?.name_and_dob}
            </Text>
            {/* <Button size="xs" leftIcon={<IconPencil ></IconPencil>}>
              Edit
            </Button> */}
          </Grid.Col>
          <Grid.Col span={4} style={{ outline: "2px solid" }}>
            <Textarea
              mt={8}
              // label="Patient Info"
              // placeholder="Patient Info"
              {...getInputProps("patient_info")}
              minRows={3}
              // style={{ maxWidth: 300 }}
            />
          </Grid.Col>
          <Grid.Col span={2} style={{ outline: "2px solid" }}>
            <Stack>
              {activeUserAvatars.length > 0 && (
                <Group>{activeUserAvatars}</Group>
              )}
              {/* <Text>Work Flow:</Text> */}
              {!!userState?.referralFilter?.title && (
                <Text>Filter View: {userState?.referralFilter?.title}</Text>
              )}
              {/* <Text>Current User:</Text> */}
              {!!latestUpdate && (
                <Stack>
                  <Flex>Updated by {latestUpdate?.display_name} on</Flex>
                  <Text>
                    {dayjs(latestUpdate?.updated_at).format("MM-DD-YY h:mm A")}
                  </Text>
                </Stack>
              )}
            </Stack>
          </Grid.Col>
        </Grid>
        {/* <br/>
        <Text>Click on a referral to edit</Text>
        <br/> */}
        <br />
        {referralsTable}
        <br />
        <Tabs defaultValue={"main"}>
          <Tabs.List>
            <Tabs.Tab value="main">Patient</Tabs.Tab>
            {selectedReferralTab}
            <Tabs.Tab value="notes">
              <Flex>
                Notes{" "}
                {hasAlert && (
                  <Tooltip openDelay={500} label="ACTIVE ALERT NOTE(S).">
                    <div>
                      <IconAlertCircle color="red" />
                    </div>
                  </Tooltip>
                )}
              </Flex>
            </Tabs.Tab>
            {/* @ts-ignore */}
            {/* {
                (
                  // @ts-ignore
                  values?.referrals?.length > 0 &&
                  false
                )
                && <Tabs.Tab value="questionaires">Questionaires</Tabs.Tab>} */}
            {oldNotesTab}
            <Tabs.Tab value="history">History</Tabs.Tab>
            {/*
                <Tabs.Tab value="surveys">Surveys</Tabs.Tab> */}
            {hasEquipment && <Tabs.Tab value="equipment">Equipment</Tabs.Tab>}
          </Tabs.List>
          <Tabs.Panel value="main">{mainSectionOfPatient}</Tabs.Panel>
          {selectedReferralSection}
          <Tabs.Panel value="notes">{notesSection}</Tabs.Panel>
          {/* <Tabs.Panel value="questionaires">
              {questionaireTab}
            </Tabs.Panel> */}
          {oldNotesTabPanel}
          {HistoryPanel}
          <Tabs.Panel value="equipment">{EquipmentPanel}</Tabs.Panel>
        </Tabs>
        {/* <pre>{JSON.stringify(patientNoteValues, null, 2)}</pre> */}
        {/* @ts-ignore */}
        {/* <pre>{JSON.stringify(values?.employers, null, 2)}</pre> */}
      </SwizzledEdit>
      {/* @ts-ignore */}
      {/* {AdminDebug(editReferralValues)} */}
      {/* <pre>{JSON.stringify(editReferralValues?.resupplies,null,2)}</pre> */}
      {/* <pre>{JSON.stringify(referralsOfPatientNotAssociatedToTemplate, null, 2)}</pre> */}
      {/* @ts-ignore */}
      {/* <pre>{JSON.stringify(values?.meta, null, 2)}</pre> */}
      {/* @ts-ignore */}

      {/* @ts-ignore */}
      {/* <pre>{JSON.stringify(values?.patient_notes, null, 2)}</pre> */}
    </>
  );
};
