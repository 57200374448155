import React, { useContext, useEffect } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { DateField } from "@refinedev/mantine";

import {
//

Box, Group, // List,
ScrollArea, Table, Pagination, Select, Textarea
} from "@mantine/core";

import dayjs from "dayjs";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';

import { fields, resource } from "./common";
import { useGetIdentity, useList, useGo } from "@refinedev/core";
import { isAffectrixClientId } from "utilities";

function renderDate({ getValue }:any) {
  return getValue() && dayjs(getValue()).isValid() ? (
      <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
      <>N/A</>
  );
}

const columns: ColumnDef<any>[] = [
  // { accessorFn: (row:any) => {
  //   const addedToColaborate = row?.patient?.added_to_collaborate ? "X" : "";
  //   const numberOfDaysToNotify = row?.column_b || "";
  //   return addedToColaborate || numberOfDaysToNotify;
  // }, id: 'patient.added_to_collaborate', header: 'X', enableColumnFilter: false, enableSorting: false,},
  // { accessorKey: 'column_d', id: 'column_d', header: '!', enableColumnFilter: false, enableSorting: false,},
  // { 
  //   // accessorFn: (row) => {
  //   //   return row?.agreement
  //   // },
  //   accessorKey: 'agreement',
  //   id: 'agreement', header: 'C', enableColumnFilter: false, enableSorting: false
  // },
  { accessorKey: 'patient.name_and_dob', id:'patient.name_and_dob', header: 'NAME', enableColumnFilter: true, enableSorting: false, meta: {
    filterOperator: 'contains'
  }, size: 300},
  {
    enableColumnFilter: false,
    accessorFn: (row:any) => {
      return row?.product_type?.title
    }, 
    id: 'product_type.title', header: 'PRODUCT', enableSorting:false, meta: {filterOperator: 'contains'}
  },
  {
    accessorKey: "rx_received_date",
    id: "rx_received_date",
    header: "REC'D",
      // minSize: 220,
      // size: 350,
    cell: renderDate,
    enableColumnFilter: false,
    enableSorting: false,
    size: 100
  },
  // { accessorKey: "status", id: "status", header: "STATUS", enableColumnFilter: false, enableSorting: false,},
  //TODO separate in DB, combine for view
  {
      accessorKey: "patient.date_of_surgery",
      id: "patient.date_of_surgery",
      header: "DOS",
        // minWidth: 220,
      cell: renderDate,
      enableColumnFilter: false,
      enableSorting: false,
      size: 100
  },
  { 
    accessorFn: (row:any) => {
        return [row?.insurance?.title, row?.claim_type?.title].filter(x=>x).join(' ')
    },
    // accessorKey: 'insurance.title', 
    id:'claim', header: 'CLAIM', enableColumnFilter: false, enableSorting: false, meta: {
        filterOperator: 'contains'
    }
  },
      { 
        accessorFn: (row) => {
            return row?.patient_status?.title
        },
        // accessorKey: 'patient_status.title', 
        id:'patient_status.title', header: 'PATIENT STATUS', enableColumnFilter: false, enableSorting: false},
  { 
      accessorFn: (row) => {
          return row?.insurance_status?.title
      },
      accessorKey: 'insurance_status.title',
      meta: {
          filterOperator: 'contains'
      },
      id:'insurance_status.title', header: 'INSURANCE STATUS', enableColumnFilter: false, enableSorting: false},
  
  // { accessorFn: (row) => {}, id:'insurance', header: 'Insurance', enableColumnFilter: false, enableSorting: false},
//   { accessorKey: 'rx_expiration_date', id:'rx_expiration_date', header: 'Rx EXP', enableColumnFilter: false, enableSorting: false, cell: renderDate,},
];

// similar to referral, 
// TODO implement filters of MZ SURVEY, MZ SURVEY FU

const filterSubset = [ 'NMES SURVEY FU', 'NMES SURVEY' ];

// modified to have the above subset
function RadioSelect(props:any) {

  const {
    setFilters,
    user_filters = [],
    filters = [],
    uid,

    currentUserState,
    setCurrentUserState
  //   tableFilters,
  } = props;

  // user_filters <~> filters
  const mappedFilters = filters.map( (x:any) => {
      const {
          // filter:{
              // value,
              title,
              filter
          // }
      } = x
      return {
          value: title,
          label: title,
          active_filter: filter
      }
  })
  .filter( (x:any) => {
    const {
      value
    } = x;
    return filterSubset.some( (y:any) => y == value )
  });

  // does not work
  // if (mappedFilters.length > 0){
  //   const [ {active_filter} ] = mappedFilters;
  //   setFilters( active_filter, 'replace')
  // }

  // const [value, setValue] = React.useState(null);

  // @ts-ignore
  let clientFilter = [];
  if (currentUserState?.clientId){
    clientFilter = [
      {
        field: 'client_id',
        operator: 'eq',
        value: currentUserState.clientId
      }
    ]
  }

  const handleChange = (selectValue:any) => {

      // setValue(selectValue);

      if (!selectValue){
        // @ts-ignore
          setFilters([...clientFilter], 'replace')
          
          setCurrentUserState({
            ...currentUserState,
            surveysFilter: undefined
          })
      }

      const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

      if (thing.length == 1){

          const [ {active_filter} ] = thing

          setFilters([
            ...active_filter,
            // @ts-ignore
            ...clientFilter
          ], 'replace' )

          setCurrentUserState({
            ...currentUserState,
            surveysFilter: {
              title: selectValue,
              active_filter,
            }
          })
      } else {

      }

  };

  return (
      <>
          <Select defaultValue={currentUserState?.surveysFilter?.title}
          label='Select a Filter'
          data={mappedFilters} onChange={handleChange} searchable clearable style={{ maxWidth: 300 }}>
              
          </Select>
      </>
  );
}

export const SurveyList: React.FC = () => {

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

    const go = useGo();

    const {
      data: {
        // @ts-ignore

          claims: {
          "x-hasura-user-id": uid = '',
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
        setCurrent,
        setFilters,
        filters,
        pageCount, 
        current,
        tableQueryResult: { data: tableData },
      },
  } = useTable({
      columns,

      refineCoreProps: {
          // initialPageSize: 10,
          resource,

          metaData: {
            fields
          },
          queryOptions: {
            // disable query if the user belongs to the AFX client and the client of the query is undefined
            enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
          },
          pagination: {
            pageSize: 50
          },
      }
  });

  const {
    data: {
        // @ts-ignore
        data: dataFilters = []
    } = {}
  } = useList({
      resource: 'filter',

      meta: {
          fields: [
              'title',
              'filter'
          ],
      },

      pagination:{
          pageSize: 100
      }
  })

  useEffect( () => {
    // @ts-ignore
    let filters = [];
    if(userState?.surveysFilter?.active_filter){
      filters = [...userState.surveysFilter.active_filter]
    }
    if (userState?.clientId){
      filters = [
        // @ts-ignore
        ...filters,
        {
          field: 'client_id',
          operator: 'eq',
          value: userState.clientId
        }
      ]
    }
    // @ts-ignore
    setFilters(filters, 'replace');
  }, [userState])

  return (
    <ScrollArea>
      <List>
          <RadioSelect 
            setFilters={setFilters} 
            // user_filters={user_filters} 
            // uid={uid}
            // tableFilters={filters}
            filters={dataFilters}

            currentUserState={userState}
            setCurrentUserState={setUserState}
            ></RadioSelect>
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            const width = header?.column?.columnDef?.size;
                            let style = {} as any;
                            if (width != 150){
                              style.width = width;
                            }
                              return (
                                  <th key={header.id} style={style}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {
                      return (
                          <tr key={row.id} onClick={() => go({
                            to: `/directory/patient/edit/${row.original.patient_id}`,
                          })}>
                              {row.getVisibleCells().map((cell) => {
                                  return (
                                      <td key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
}