import { HttpError, useGetIdentity, useNavigation, useGo } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";
import { useEffect } from "react";

export const PatientStatusCreate: React.FC = () => {

  const go = useGo();

    const {
      data: {
        // @ts-ignore
          claims: {
            "x-hasura-default-role": role = ''
          } = {}
      } = {}
    } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    useEffect( () => {
      if (role && role != 'admin'){
        console.log('unauthorized')
        go({to:'/'});
      }
    }, [role])

  const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('admin/status/patient');
    }

    const {
      saveButtonProps,
      getInputProps, 
      onSubmit,
      errors,
      values
  } = useForm<HttpError>({
      initialValues: {
          // ...filteredInitialValues,
      },
      refineCoreProps: {
          resource: "patient_status",
          metaData:{
              fields: [
                  'id',
              ]
          },
          onMutationSuccess
      },
      // validate: {
      //     //TODO
      //     client_id: (value:string) => 
      //         value.length < 10 ?
      //         "Please Select a Client" :
      //         null
      // }
  });

  return (
      <Create saveButtonProps={saveButtonProps}>
          <form onSubmit={(values) => { console.log(values) }}>
            <TextInput
                mt={8}
                label="Name"
                placeholder="Name"
                {...getInputProps('title')}
            />
          </form>
      </Create>
  );
}