import React, { useEffect, useContext }  from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination } from "@mantine/core";

import { List } from "components/ghettoList";

import { fields } from './common'

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';
import { useGetIdentity, useGo } from "@refinedev/core";
import { isAffectrixClientId } from "utilities";

const columns: ColumnDef<any>[] = [
    // {
    //     id: "actions",
    //     header: "Actions",
    //     accessorKey: 'id',
    //     enableColumnFilter: false,
    //     enableSorting: false,
    //     cell: function render({ getValue }:any) {
    //       return (
    //           <Group spacing="xs" noWrap>
    //               <EditButton
    //                   hideText
    //                   recordItemId={getValue() as number}
    //               />
    //           </Group>
    //       );
    //     },
    //   },
    // { id: "client.name", accessorKey: "client.name", header: "Client", enableColumnFilter: false, enableSorting: false, },
    { id: "name", accessorKey: "name", header: "Name", enableColumnFilter: true, enableSorting: false, meta: {
        filterOperator: 'contains'
    }},
];

export const ProviderList: React.FC = () => {

    const go = useGo();

    const {
      data: {
        // @ts-ignore

          claims: {
          // "x-hasura-user-id": uid,
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

        // @ts-ignore
        const { userState, setUserState } = useContext(UserContext);

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { 
            setCurrent, 
            pageCount, 
            current,
            filters,
            setFilters,
            tableQueryResult: { data: tableData },
          },
      } = useTable({
        columns,

        refineCoreProps: {
            resource: 'provider',

            pagination: {
              pageSize: 50
            },
            sorters: {
              initial: [
                {
                  field: 'name',
                  order: 'asc'
                }
              ]
            },
            metaData: {
                fields
            },
            queryOptions: {
              // disable query if the user belongs to the AFX client and the client of the query is undefined
              // enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
            }
        }
    });

      useEffect( () => {
        // @ts-ignore
        let filters = [];
        if (userState?.clientId){
          filters = [
            // @ts-ignore
            ...filters,
            // {
            //   field: 'client_id',
            //   operator: 'eq',
            //   value: userState.clientId
            // }
          ]
        }
    
        // @ts-ignore
        setFilters(filters, 'replace');
    
      }, [userState])  

    return (
        <ScrollArea>
        <List>
          {/* { clientId == '279a1514-9914-49b2-af74-027610efd80f' && <ClientSelect 
            setFilters={setFilters}
            filters={filters}
            table='provider'
            ></ClientSelect>
          } */}
            <Table highlightOnHover>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <Group spacing="xs" noWrap>
                                                <Box>
                                                    {flexRender(
                                                        header.column
                                                            .columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <Group spacing="xs" noWrap>
                                                    <ColumnSorter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                    <ColumnFilter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                </Group>
                                            </Group>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} onClick={() => go({
                              to: `edit/${row.original.id}`
                            })}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    </ScrollArea>
    );
};
