import { IconReportAnalytics } from "@tabler/icons";

const admin: any[] = [
  {
    name: 'admin',
    meta: {
      icon: <></>,
      label: 'ADMIN'
    }
  },
  {
    name: 'user',
    list: 'admin/user',
    edit: 'admin/user/edit/:id',
    create: 'admin/user/create',
    meta: {
      parent: 'admin',
      label: 'USERS'
    }
  },
  {
    name: 'clients',
    list: 'admin/clients',
    edit: 'admin/clients/edit/:id',
    create: 'admin/clients/create',
    meta: {
      parent: 'admin',
      label: 'CLIENTS'
    }
  },
  {
    name: 'template',
    list: 'admin/template',
    edit: 'admin/template/edit/:id',
    meta: {
      parent: 'admin',
      label: 'QUESTIONNAIRES'
    }
  },
  {
    name: 'status',
    meta: {
      icon: <></>,
      parent: 'admin',
      label: 'STATUSES'
    }
  },
  {
    name: 'equipmentStatus',
    list: 'admin/status/equipment',
    edit: 'admin/status/equipment/edit/:id',
    create: 'admin/status/equipment/create',
    meta: {
      parent: 'status',
      label: 'EQUIPMENT STATUSES',
    }
  },
  {
    name: 'patientStatus',
    list: 'admin/status/patient',
    edit: 'admin/status/patient/edit/:id',
    create: 'admin/status/patient/create',
    meta: {
      parent: 'status',
      label: 'PATIENT STATUSES',
    }
  },
  {
    name: 'insuranceStatus',
    list: 'admin/status/insurance',
    edit: 'admin/status/insurance/edit/:id',
    create: 'admin/status/insurance/create',
    meta: {
      parent: 'status',
      label: 'INSURANCE STATUSES',
    }
  },
  {
    name: 'listOfValues',
    meta: {
      icon: <></>,
      parent: 'admin',
      label: 'LIST OF VALUES'
    }
  },
  {
    name: 'claim_type',
    list: 'admin/listOfValues/claim_type',
    edit: 'admin/listOfValues/claim_type/edit/:id',
    create: 'admin/listOfValues/claim_type/create',
    meta: {
      parent: 'listOfValues',
      label: 'CLAIM TYPES',
    }
  },
  {
    name: 'injury_site',
    list: 'admin/listOfValues/injury_site',
    edit: 'admin/listOfValues/injury_site/edit/:id',
    create: 'admin/listOfValues/injury_site/create',
    meta: {
      parent: 'listOfValues',
      label: 'INJURY SITES',
    }
  },
];

const directory: any[] = [
  {
    name: 'directory',
    meta: {
      icon: <></>,
      label: 'DIRECTORY'
    }
  },
  {
    name: 'patient',
    list: '/directory/patient',
    edit: '/directory/patient/edit/:id',
    create: '/directory/patient/create',
    meta: {
      parent: 'directory',
      label: "PATIENTS"
    }
  },
  {
    name: 'provider',
    list: '/directory/provider',
    edit: '/directory/provider/edit/:id',
    create: '/directory/provider/create',
    meta: {
      parent: 'directory',
      label: "PROVIDERS"
    }
  },
  {
    name: 'representative',
    list: '/directory/representative',
    edit: '/directory/representative/edit/:id',
    create: '/directory/representative/create',
    meta: {
      parent: 'directory',
      label: 'REPS'
    }
  },
  {
    name: 'fitter',
    list: '/directory/fitter',
    edit: '/directory/fitter/edit/:id',
    create: '/directory/fitter/create',
    meta: {
      parent: 'directory',
      label: "FITTERS"
    }
  },
  {
    name: 'insurance',
    list: '/directory/insurance',
    edit: '/directory/insurance/edit/:id',
    create: '/directory/insurance/create',
    meta: {
      parent: 'directory',
      label: 'INSURANCES'
    }
  },
  {
    name: 'location',
    list: '/directory/location',
    edit: '/directory/location/edit/:id',
    create: '/directory/location/create',
    meta: {
      parent: 'directory',
      label: 'LOCATIONS'
    }
  },
];

const operations: any[] = [
  {
    name: 'operations',
    meta: {
      icon: <></>,
      label: 'OPERATIONS'
    }
  },
  {
    name: 'referral',
    list: '/operations/referral',
    edit: '/operations/referral/edit/:id',
    create: '/operations/referral/create',
    meta: {
      parent: 'operations',
      label: 'REFERRALS'
    }
  },
  // {
  //   name: 'surveys',
  //   list: '/operations/surveys',
  //   meta: {
  //     parent: 'operations',
  //     label: 'SURVEYS'
  //   }
  // },
  {
    name: 'extension',
    list: '/operations/extension',
    edit: '/operations/extension/edit/:id',
    // create: '/operations/extension/create',
    meta: {
      parent: 'operations',
      label: 'EXTENSIONS'
    }
  },
];

const fulfillment: any[] = [
  {
    name: 'fulfillment',
    meta: {
      icon: <></>,
      label: 'FULFILLMENT'
    }
  },
  {
    name: 'product',
    list: '/fulfillment/product',
    edit: '/fulfillment/product/edit/:id',
    create: '/fulfillment/product/create',
    meta: {
      parent: 'fulfillment',
      label: 'EQUIPMENT'
    }
  },
  {
    name: 'shipmentRequest',
    list: 'fulfillment/shipmentRequest',
    edit: 'fulfillment/shipmentRequest/edit/:id',
    meta: {
      parent: 'fulfillment',
      label: 'SHIPMENT REQUESTS'
    }
  },
  {
    name: 'resupplies',
    list: '/fulfillment/resupplies',
    edit: '/fulfillment/resupplies/edit/:id',
    meta: {
      parent: 'fulfillment',
      label: 'RESUPPLIES'
    }
  },
  {
    name: 'deliveryTicket',
    list: 'fulfillment/deliveryTicket',
    edit: 'fulfillment/deliveryTicket/edit/:id',
    meta: {
      parent: 'fulfillment',
      label: "DT'S FOR FITTERS"
    }
  },
];

const analytics = [
  {
    name: 'analytics',
    list: 'analytics',
    meta: {
      icon: <IconReportAnalytics/>,
      label: 'ANALYTICS',
    }
  },
]

const representative: any[] = [
  {
    name: 'rep',
    meta: {
      category: 'representative',
      label: 'REFERRALS',
      icon: <></>
    }
  },
  {
    name: 'all',
    list: 'rep/all',
    meta: {
      label: 'ALL',
      parent: 'rep'
    }
  },
  {
    name: 'verification',
    list: 'rep/verification',
    meta: {
      label: 'VERIFICATION',
      parent: 'rep'
    }
  },
  {
    name: 'incomplete',
    list: 'rep/incomplete',
    meta: {
      label: 'INCOMPLETE',
      parent: 'rep'
    }
  },
  {
    name: 'fitComplete',
    list: 'rep/fitComplete',
    meta: {
      label: 'FIT COMPLETE',
      parent: 'rep'
    }
  },
  {
    name: 'deadDeal',
    list: 'rep/deadDeal',
    meta: {
      label: 'DEAD DEAL',
      parent: 'rep'
    }
  },
  {
    name: 'referralView',
    show: ':id'
  }
];

export const resources: any[] = [
  ...admin,
  ...directory,
  ...operations,
  ...fulfillment,
  ...analytics,

  ...representative
]