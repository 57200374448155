import { HttpError } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { TextInput } from "@mantine/core";

export const InjurySiteCreate: React.FC = () => {
    const {
        saveButtonProps,
        getInputProps,
        values
    } = useForm< HttpError>();

    return (
      <Create saveButtonProps={saveButtonProps}>
        <TextInput
            mt={8}
            label="INJURY SITE"
            style={{ maxWidth: 300 }}
            {...getInputProps("title")}
        />
      </Create>
    );
};
