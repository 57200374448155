export const fields = [
	"id",
	"client_id",
  "title",
  "address",
  "phone_number",
  "fax_number",
  "email",
  "attention",
  "code"
];

export const resource = 'location';