import { Text, TextInput, Button, Image, Avatar, Chip, Checkbox } from "@mantine/core";
import { useGetIdentity, useLogout, useList } from "@refinedev/core";
import packagee from "../../../package.json"
import { useState } from "react";
import dayjs from "dayjs";

export const Dashboard: React.FC = () => {

  // const {
  //   data
  // } = useGetIdentity();

  // const isWebAdmin = true;

  // const { mutate: logout } = useLogout();
  // const [value, setValue] = useState([]as any);
  const {
    data: {
      // @ts-ignore
      data: meta = []
    } = {}
  } = useList({
      resource: 'meta',

      meta:{
        fields: [ 'settings' ],
      },

      pagination: {
        pageSize: 10
      }
  });

  const date = (
    meta.length == 1 && 
    meta[0].settings &&
    meta[0].settings.dataCurrentUpTo
  ) && meta[0].settings.dataCurrentUpTo;

  const uploadMessage = (
    date
  ) && <>
    <Text>Data was uploaded from the Affecto Tracker around {dayjs(date).format('MM-DD-YY h:mm A')}</Text>
  </>

  return <>
    <Text>Welcome to version {packagee.version}</Text>
    {uploadMessage}
    <Text>This is a pre-launch Version!!!</Text>
    <Text>Data within this system and the system itself are subject to change,</Text>
    <Text>until a launch version is certified!</Text>
    <Text>Until the switch-over to this system happens, do enter data into this system AND the current system</Text>
    {/* <pre>{JSON.stringify(data,null,2)}</pre> */}
    {/* <Checkbox label={<TextInput
      defaultValue="HCBS"
    >
    

    </TextInput>}></Checkbox> */}
  </>
}