import React, { useEffect } from "react";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { DeleteButton, EditButton } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination } from "@mantine/core";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter } from '../../../components';
import { useGo, useGetIdentity } from "@refinedev/core";

const columns: ColumnDef<any>[] = [
  {
      id: "actions",
      header: "Actions",
      accessorKey: 'id',
      enableColumnFilter: false,
      enableSorting: false,
      cell: function render({ getValue }:any) {
        return (
            <Group spacing="xs" noWrap>
                <EditButton
                    hideText
                    recordItemId={getValue() as number}
                />
                <DeleteButton
                    hideText
                    recordItemId={getValue() as number}
                />
            </Group>
        );
      },
    },
  { id: "title", accessorKey: "title", header: "Name", enableColumnFilter: false, enableSorting: false, },
];

export const ClientList: React.FC = () => {

  const go = useGo();

  const {
    data: {
      // @ts-ignore
        claims: {
          "x-hasura-default-role": role = ''

        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  useEffect( () => {
    if (role && role != 'admin'){
      console.log('unauthorized')
      go({to:'/'});
    }
  }, [role])

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
        setCurrent, 
        pageCount, 
        current,
      },
  } = useTable({
      columns,

      refineCoreProps: {
          resource: 'clients',

          metaData: {
              fields: [
                'id',
                'title'
              ]
          }
      }
  });

  return (
      <ScrollArea>
      <List>
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                              return (
                                  <th key={header.id}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {
                      return (
                          <tr key={row.id}>
                              {row.getVisibleCells().map((cell) => {
                                  return (
                                      <td key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
}