import { HttpError, useNavigation, useOne, useResource } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";

import { resource, fields } from './common'
import React from "react";

export const LocationEdit: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/location');
    }

    const { id } = useResource();

    const { 
        data: {
            data: initialValues = {}
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields
        }
    })

    const {
      values,
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      getInputProps
  } = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields
        },
        autoSave: {
          enabled: true,
        },
        onMutationSuccess
      },
      initialValues,
      transformValues: (values) => {
        const {
          id,
          ...rest
        } = values;

        return rest
      }
  });

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <form>
        <Tabs defaultValue={'first'}>
            <Tabs.List>
                <Tabs.Tab value="first">Main</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Name"
                    {...getInputProps('title')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Phone Number"
                    placeholder="Phone Number"
                    {...getInputProps('phone_number')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Fax Number"
                    placeholder="Fax Number"
                    {...getInputProps('fax_number')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Email"
                    placeholder="Email"
                    {...getInputProps('email')}
                    style={{ maxWidth: 300 }}
                />
                {/* TODO implement */}
                {/* <Textarea
                    mt={8}
                    label="Address"
                    placeholder="Address"
                    {...getInputProps('address')}
                    minRows={3}
                    style={{ maxWidth: 300 }}
                /> */}
            </Tabs.Panel>
        </Tabs>
        </form>
        <pre>{JSON.stringify(values,null,2)}</pre>
      </Edit>
    );
};
