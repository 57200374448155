import dayjs from 'dayjs'
import _ from 'lodash'

export const transformFieldsIntoInitialValues = (fields: string[]) =>
	fields.filter( field => field != 'id')
	.reduce( (prev, current) => {
		return {
			...prev,
			[current]: ""
		}
}, {})

export const datePickerValue = (values:any) => (field:string) => {
  const value = _.get(values, field);
  // console.log(values);
  // console.log(field);
  // console.log(value);
	if (field && value){
		return dayjs(value as Date).isValid() ? dayjs(value as Date).toDate() : null;
	} else {
		return null
	}
}

export const formatDate = (x: any) => dayjs(x).format('MM-DD-YY');

export const formatTimeStamp = (x: any) => dayjs(x).format('MM-DD-YY h:mm A');

export const isAffectrixClientId = (clientId:string) => clientId == '279a1514-9914-49b2-af74-027610efd80f';

export const VenousClientId = 'f6c9e36e-1267-4fd7-97e6-529e622d963c';

export const isVenousClientId = (clientId:string) => clientId == VenousClientId;