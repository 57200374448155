import React, { useContext, useEffect, useState }  from "react";
import {
  useGetIdentity,
  useActiveAuthProvider,
  pickNotDeprecated,
  useList
} from "@refinedev/core";
import { HamburgerMenu } from "./hamburgerMenu";
import {
  Avatar,
  Image,
  Flex,
  Header as MantineHeader,
  Select,
  Sx,
  Title,
  useMantineTheme,
} from "@mantine/core";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/mantine";
import { UserContext } from "contexts/userContext";
import { isAffectrixClientId, VenousClientId } from 'utilities';

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  isSticky,
  sticky,
}) => {
  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

  const {
    data,
    data: {
      // @ts-ignore

        claims: {
        // "x-hasura-user-id": uid,
        "x-hasura-client-id": client_id_of_user = ''
        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  const [ once, setOnce ] = useState(false);

  useEffect(() => {
    if (!userState?.clientId && !once && isAffectrixClientId(client_id_of_user)){
      setOnce(true);
      // @ts-ignore
      setUserState( x => ({
        ...x,
        clientId: 'f6c9e36e-1267-4fd7-97e6-529e622d963c'
      }))
    }
  }, [once, userState?.clientId, client_id_of_user])

  // if able to select from multiple clients (i.e. an AFX user), default to Venous
  const clientIdFromState = userState?.clientId;

  const {
    data: {
      // @ts-ignore
      data: clients = []
    } = {}
  } = useList({
      resource: 'clients',

      meta:{
        fields: [ 'id', 'title'],
      },

      pagination: {
        pageSize: 1000
      }
  });

  // if the user is not a member of Affectrix, show the title of the client
  // e.g. @Venous
  const client_title = clients.reduce( (prev, curr) => {
    const { id, title } = curr;
    if (id == client_id_of_user){
      prev = title;
    }
    return prev;
  }, '')
  

  const theme = useMantineTheme();

  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const borderColor =
    theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2];

  let stickyStyles: Sx = {};
  if (pickNotDeprecated(sticky, isSticky)) {
    stickyStyles = {
      position: `sticky`,
      top: 0,
      zIndex: 1,
    };
  };

  const clientSelectData = [
    // {id:null, title: '@All Clients'},
    ...clients?.map(({id,title}) => ({value:id, label:`@${title}`})) as any
  ];

  // select with on change if Affectrix, else title
  const ClientSwitch = isAffectrixClientId(client_id_of_user) ? 
  <Select
    clearable
    searchable={true}
    placeholder="Select a Client"
    filterDataOnExactSearchMatch={true}
    data={clientSelectData}
    defaultValue={clientIdFromState}
    onChange={(value) => {
      // console.log(value);
      return setUserState( (x:any) => ({
        ...x,
        clientId: value
      }))
    }}
    style={{ width: 150 }}
  /> : 
  ( client_title && <Title order={4}>
    @{client_title}
  </Title>)

  return (
    <MantineHeader
      zIndex={199}
      height={64}
      py={6}
      px="sm"
      sx={{
        borderBottom: `1px solid ${borderColor}`,
        ...stickyStyles,
      }}
    >
      <Flex
        align="center"
        justify="space-between"
        sx={{
          height: "100%",
        }}
      >
        <HamburgerMenu />
        <Flex align="center" gap="sm">
          {user?.name && (
            <Title order={4} data-testid="header-user-name">
              {user?.name}
            </Title>
          )}
          {user?.claims?.picture && (<Avatar
            radius="md"
            // @ts-ignore
            src={user?.claims?.picture}
            imageProps={{ referrerPolicy: "no-referrer" }}
          />)}
          { ClientSwitch}
        </Flex>
      </Flex>
    </MantineHeader>
  );
};
