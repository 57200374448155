export const list_fields = [
  'id',
  {
    product_type: [
      'id',
      'title',
      'qualities',
      'has_serial_number'
    ]
  },
  'equipment_status_id',
  {
    equipment_status: [ 'title' ]
  },
  'serial_number',
  'qualities',
  'location_id',
  {
    location: [ 'id', 'title' ]
  },
  'patient_id',
  {
    patient: [ 'id', 'name_and_dob', 'date_of_surgery', 'condition' ]
  },
  'referral_id',
  {
    referral: [
      'id', 'next_call_date', 'pickup_date',
    ]
  },
  'representative_id',
  {
    representative: [ 'id', 'name' ]
  },
  'provider_id',
  {
    provider: [ 'id', 'name' ]
  },
  'fitter_id',
  {
    fitter: [ 'id', 'title' ]
  },
  'entity'
];

const referral = [
  'id',
  'rx_received_date',
  'product_title',
  'pickup_date',
  {
    pickup_status: [
      'title'
    ]
  },
  {
    provider: [
      'name',
      'npi',
      // TODO contact info
      {
        provider_representatives: [ 
          {
            representative: [
              'name'
            ]
          }
        ]
      }
    ],
  },
  "patient_status_id",
  {
    patient_status: ['title']
  },

  "product_type_id",
  {
    product_type: ['title']
  },

  "insurance_id",
  {
    insurance: ['title']
  },

  "claim_type_id",
  {
    claim_type: ['title']
  },

  "insurance_status_id",
  {
    insurance_status: ['title']
  },

  {
    reauth_status: [ 'title' ]
  },
  "icd10_codes",

  "rx_date",
  // "date_of_injury" //TODO
  "five_day_call",
  "five_day_call_status",
  "fourteen_day_call",
  "fourteen_day_call_status",
  "equipment_id",
  {
    extensions: [
      'id',
      'extended_to_date',
      {
        extension_status: [ 'title' ]
      },
      {
        insurance_override: [ 'title' ]
      },
      'rx_expiration_date'
    ]
  },
];

const patient = [
  'id',
  "date_of_surgery",
  {
    referrals: referral
  },
  {
    patient_notes: [
      {
        note: [
          'id',
          'content',
          {
            authed_user: [
              'id',
              'display_name',
              'photoURL'
            ]
          },
          'metadata',
          "created_at"
        ]
      }
    ]
  },
  'patient_info'
];

export const specific_fields = [
  'patient_id',
  {
    patient,
  },
  'provider_id',
  {
    provider: [
      'id',
      'name'
    ]
  },
  'referral_id',
  {
    referral
  },
  'equipment_notes',
  'device_history'
];

export const columnsIdsToFilters = {
  DEFAULT: [
    'product_type.title',
    'serial_number',
    'equipment_status.title',
    'claimant',
    'entity_type',
  ],
  'NEXT CALL': [
    'product_type.title',
    'serial_number',
    'equipment_status.title',
    'entity_type',
    'claimant',
    'referral.next_call_date',
  ],
  'PICKUP NEEDED': [
    'product_type.title',
    'serial_number',
    'equipment_status.title',
    'entity_type',
    'claimant',
    'referral.pickup_date',
  ]
};

export const sortersToFilter = {
  DEFAULT: [],
  'NEXT CALL': [
    {
      field: 'referral.next_call_date',
      order: 'asc'
    }
  ],
  'PICKUP NEEDED': [
    {
      field: 'referral.pickup_date',
      order: 'asc'
    }
  ]
}

export const resource = 'product'