import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { PatientEdit, PatientList, PatientCreate } from "pages/directory/patients";
import { ProviderList, ProviderEdit, ProviderCreate } from "pages/directory/providers";
import { RepList, RepEdit, RepCreate } from "pages/directory/rep";
import { FitterEdit, FitterList, FitterCreate } from "pages/directory/fitter";
import { InsuranceList, InsuranceCreate, InsuranceEdit } from "pages/lookups/insurances";
import { LocationList, LocationCreate, LocationEdit } from "pages/directory/location";

const patient = <Route
  path='patient' 
>
  <Route
    index
    element={<PatientList></PatientList>}
  />
  <Route path='edit/:id' element={<PatientEdit/>}/>
  <Route path='create' element={<PatientCreate/>}/>
</Route>;

const provider = <Route
  path='provider' 
>
  <Route
    index
    element={<ProviderList/>}
  />
  <Route path='edit/:id' element={<ProviderEdit/>}/>
  <Route path='create' element={<ProviderCreate/>}/>
</Route>;

const representative = <Route
  path='representative' 
>
  <Route
    index
    element={<RepList/>}
  />
  <Route path='edit/:id' element={<RepEdit/>}/>
  <Route path='create' element={<RepCreate/>}/>
</Route>;

const fitter = <Route
  path='fitter' 
>
  <Route
    index
    element={<FitterList/>}
  />
  <Route path='edit/:id' element={<FitterEdit/>}/>
  <Route path='create' element={<FitterCreate/>}/>
</Route>;

const insurance = <Route
  path='insurance' 
>
  <Route
    index
    element={<InsuranceList/>}
  />
  <Route path='edit/:id' element={<InsuranceEdit/>}/>
  <Route path='create' element={<InsuranceCreate/>}/>
</Route>;

const location = <Route
  path='location' 
>
  <Route
    index
    element={<LocationList/>}
  />
  <Route path='edit/:id' element={<LocationEdit/>}/>
  <Route path='create' element={<LocationCreate/>}/>
</Route>;

export const directory = <Route
  path='directory'
>
  {patient}
  {provider}
  {representative}
  {fitter}
  {insurance}
  {location}
</Route>