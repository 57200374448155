import {
  HttpError,
  useResource,
  useNavigation,
  useOne,
  useGetIdentity,
  BaseKey,
  useList,
  useCustom,
} from "@refinedev/core";

import { useForm, useSelect, EditButton } from "@refinedev/mantine";
import { createStyles } from "@mantine/styles";
import { IconPencil, IconX, IconCheckbox } from "@tabler/icons";
import { Notes } from "components/notes";

import {
  Select,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Avatar,
  Group,
  Paper,
  Button,
  Stack,
  ActionIcon,
  CloseButton,
  Divider,
  Grid,
  Chip,
  Anchor
} from "@mantine/core";

import { Edit as SwizzledEdit } from 'components/crud/edit'
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Create as SwizzledCreate } from 'components/crud/create'

import { list_fields, resource, specific_fields } from './common'
import { DatePicker } from "@mantine/dates";
import { datePickerValue, isAffectrixClientId, isVenousClientId } from "utilities";
// @ts-ignore
import { AdminDebug } from "utilities/index.tsx";
import _ from "lodash";

export const ProductEdit: React.FC = () => {
  const { id } = useResource();

  const { list } = useNavigation();

  const formatDate = (x: any) => dayjs(x).format('MM-DD-YY');

  const {
    data: {
      // @ts-ignore
      claims: {
        "x-hasura-user-id": uid ='',
        "x-hasura-client-id": clientId = ''
      } = {}
    } = {}
  } = useGetIdentity({
    // v3LegacyAuthProviderCompatible: true
  });

  const {
		data: {
			data: initialValues = {}
		} = {}
	} = useOne<any>({
		resource,
		id,
		meta: {
      fields: [
        ...list_fields,
        ...specific_fields
      ]
		},
	})

  const {
    data: {
      data: locations
    } = {}
  } = useList<any>({
    resource: 'location',
    meta: {
      fields: [
        'id', 'title', 'code', 'client_id'
      ],
      filters:[
        {
          field: 'client_id',
          operator: 'null',
          value: true
        }
      ]
    },
  });

  const {
    data: {
      data: allLocations = []
    }={}
  } = useList<any>({
    resource: 'location',
    meta: {
      fields: [
        'id', 'title'
      ]
    },
    pagination: {
      pageSize: 1000
    }
  });

  const [ prevProduct, setPrevProduct ] = useState({} as any);

  useEffect( () => {
    if (Object.keys(prevProduct).length == 0 && Object.keys(initialValues).length > 0){
      setPrevProduct(initialValues);
    }
  }, [initialValues]);

  const targetMap = {
    representative_id: 'representative', provider_id: 'provider', fitter_id: 'fitter',
    location_id: 'location', patient_id: 'patient', referral_id: 'referral'
  }

  const main = useForm< HttpError>({
    refineCoreProps: {
      // warnWhenUnsavedChanges: true,
      resource,
      id,
      metaData: {
          fields: [
            ...list_fields,
            ...specific_fields
          ]
      },
      onMutationSuccess: ({data}: any, variables: any, context: any) => {
        // @ts-ignore
        const reduced = Object.keys(data).reduce((prev, aKey) => {
          // @ts-ignore
          const maybeValueOfKeyToNullify = targetMap[aKey];
          if (maybeValueOfKeyToNullify && data[aKey] == null){
            // @ts-ignore
            prev.push(maybeValueOfKeyToNullify)
          }
          return prev
        }, [])

        if (reduced.length > 0){
          // @ts-ignore
          const valuesToNullify = reduced.reduce((prev,curr) => ({
            ...prev, [curr]: null
          }), {});

          main.setValues({
            ...data, ...valuesToNullify
          })
        }
        // setPrevProduct((prev:any) => ({
        //   ...prev,
        //   ...data
        // }));
      },

      redirect: false,
      autoSave: {
        enabled: true,
      }
    },
    initialValues: {
      ...initialValues,
    },        // setPrevProduct( (prev:any) => ({
      //   ...prev,
      //   ...diff
      // }))
    transformValues: (values) => {
      let {
          location,
          product_type,
          equipment_status,
          patient,
          representative,
          provider,
          fitter,
          referral,
          id,
          ...rest
      } = values;

      const diff = Object.entries(rest).reduce( (prev:any, curr:any) => {
        const [ key, newValue ] = curr;
          if (key){
            const oldValue = prevProduct[key];

            if (!_.isEqual(newValue,oldValue)){
              prev[key] = newValue;
            }
          }
          return prev;
      }, {});

      if (Object.keys(diff).length == 0){
        return null;
      } else {
        const keys = Object.keys(diff);
        // const targetColumns = [
        //   'representative_id','provider_id','fitter_id',
        //   'location_id', 'patient_id', 'referral_id'
        // ];

        const matches = Object.keys(targetMap).filter( x => keys.includes(x));
        const shouldBeModified = matches.length > 0;

        if (shouldBeModified){

          // const assertion = matches.length == 1;
          // if ( !assertion ){
          //   console.log(`assertion violated: more then one column defined for target columns ${matches}`);
          // } else {
            const [ match ] = matches;

            if (diff[match]){
              const toBeNullifed = Object.entries(targetMap).filter( ([x]) => x != match);
              toBeNullifed.forEach( ([key, value]) => {
                diff[key] = null;
                initialValues[value] = null;
              })
            }
          // }
        }
        // console.log(diff);
        // console.log({
        //   ...prevProduct,
        //   ...diff
        // });
        // console.log();

        setPrevProduct( (prev:any) => ({
          ...prev,
          ...diff
        }))

        return diff
      }
    },
  });

  const {
    saveButtonProps: productSaveButtonProps,
    refineCore: { queryResult, onFinish, formLoading, redirect },
    getInputProps,
    values,
    insertListItem: productInsertListItem,
    removeListItem,
    setFieldValue
  } = main;

  // @ts-ignore
  const hasLocation = !!prevProduct?.location_id && !!prevProduct?.location?.title;
  // console.log(`prevProduct?.location_id: ${prevProduct?.location_id}`);
  // console.log(`prevProduct?.location?.title: ${prevProduct?.location?.title}`)
  // console.log(`hasLocation: ${hasLocation}`);


  // @ts-ignore
  const wasInCustody = hasLocation && locations?.some( ({id, client_id}) => prevProduct?.location_id == id && client_id);
  // console.log(`wasInCustody: ${wasInCustody}`);

  useEffect( () => {
    if (values?.location_id){
      const location = allLocations.reduce( (prev,curr) => {
        if(curr.id == values?.location_id) return curr;
        return prev;
      }, null);
      // @ts-ignore
      if (location) setPrevProduct(prev => ({
        ...prev,
        location
      }));
    }
  },[values?.location_id]);

  // @ts-ignore
  const hasReferral = !!(values?.patient?.id &&
  // @ts-ignore
  values?.patient?.referrals?.length > 0);

  // @ts-ignore
  const hasRepresentative = !!values?.representative?.id;

  // @ts-ignore
  const hasProvider = !!values?.provider?.id;

  // @ts-ignore
  const hasFitter = !!values?.fitter?.id;

  const { selectProps: mainLocationSelectProps } = useSelect({
    resource: "location",
    // @ts-ignore
    defaultValue: values?.location_id || undefined,
    queryOptions: {
      enabled: !! initialValues?.id
    },
    optionLabel: 'title',

    meta: {
      fields: ['id','title'],
    },

    filters: [
      {
        field: "client_id",
        operator: "null",
        value: false
      }
    ],

    pagination: {
      mode: "server"
    }
  })

// const { selectProps: patientSelectProps } = useSelect({
//   resource: "patient",
//   // @ts-ignore
//   defaultValue: values?.patient_id,
//   queryOptions: {
//     enabled: !! initialValues?.id
//   },

//   // queryOptions: {
//   //     enabled: values?.patient_status_id !== null
//   // },
//   optionLabel: 'name',

//   meta: {
//       fields: ["id", "name"],
//   },

//   pagination: {
//     mode: "server"
//   }
// });

  const { selectProps: representativeSelectProps } = useSelect({
    resource: "representative",
    // @ts-ignore
    defaultValue: values?.representative_id || undefined,
    queryOptions: {
      enabled: !! initialValues?.id
    },

    // queryOptions: {
    //     enabled: values?.patient_status_id !== null
    // },
    optionLabel: 'name',

    meta: {
        fields: ["id", "name"],
    },

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: providerSelectProps } = useSelect({
    resource: "provider",
    // @ts-ignore
    defaultValue: values?.provider_id || undefined,
    queryOptions: {
      enabled: !! initialValues?.id
    },
    optionLabel: 'name',

    meta: {
        fields: ["id", "name"],
    },

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: fitterSelectProps } = useSelect({
    resource: "fitter",
    queryOptions: {
      enabled: !! initialValues?.id
    },
    // @ts-ignore
    defaultValue: values?.fitter_id || undefined,
    optionLabel: 'title',

    meta: {
      fields: ["id", "title"],
    },

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: otherLocationSelectProps } = useSelect({
    resource: "location",
    queryOptions: {
      enabled: !!initialValues?.id
    },
    // @ts-ignore
    defaultValue: values?.location_id || undefined,
    optionLabel: 'title',

    meta: {
      fields: ['id','title'],
    },

    filters: [
      {
        field: "client_id",
        operator: "null",
        value: true
      }
    ],

    pagination: {
      mode: "server"
    }
  })

  const actionFields = wasInCustody ? (<>
    Assign to something
    <Tabs onTabChange={ (tab) => {
      // if (tab == 'patient'){
      //   // setFieldValue('patient_id', null);
      //   setFieldValue('representative_id', null);
      //   setFieldValue('provider_id', null);
      //   setFieldValue('fitter_id', null);
      //   setFieldValue('location_id', null);
      // } else if (tab == 'representative'){
      //   setFieldValue('patient_id', null);
      //   // setFieldValue('representative_id', null);
      //   setFieldValue('provider_id', null);
      //   setFieldValue('fitter_id', null);
      //   setFieldValue('location_id', null);
      // } else if (tab == 'provider'){
      //   setFieldValue('patient_id', null);
      //   setFieldValue('representative_id', null);
      //   // setFieldValue('provider_id', null);
      //   setFieldValue('fitter_id', null);
      //   setFieldValue('location_id', null);
      // } else if (tab == 'fitter'){
      //   setFieldValue('patient_id', null);
      //   setFieldValue('representative_id', null);
      //   setFieldValue('provider_id', null);
      //   // setFieldValue('fitter_id', null);
      //   setFieldValue('location_id', null);
      // } else if (tab == 'locations'){
      //   setFieldValue('patient_id', null);
      //   setFieldValue('representative_id', null);
      //   setFieldValue('provider_id', null);
      //   setFieldValue('fitter_id', null);
      //   // setFieldValue('location_id', null);
      // }
    }}>
      <Tabs.List>
        {/* <Tabs.Tab value='patient'>Patient</Tabs.Tab> */}
        <Tabs.Tab value='representative'>Representative</Tabs.Tab>
        <Tabs.Tab value='provider'>Provider</Tabs.Tab>
        <Tabs.Tab value='fitter'>Fitter</Tabs.Tab>
        <Tabs.Tab value='locations'>Locations</Tabs.Tab>
      </Tabs.List>
      {/* <Tabs.Panel value='patient'>
        <Select
        mt={8}
        label="Assign to Patient"
        {...getInputProps('patient_id')}
        {...patientSelectProps}
        style={{maxWidth:300}}/>
      </Tabs.Panel> */}
      <Tabs.Panel value='representative'>
        <Select
        mt={8}
        label="Assign to Representative"
        {...getInputProps('representative_id')}
        {...representativeSelectProps}
        style={{maxWidth:300}}/>
      </Tabs.Panel>
      <Tabs.Panel value='provider'>
        <Select
        mt={8}
        label="Assign to Provider"
        {...getInputProps('provider_id')}
        {...providerSelectProps}
        style={{maxWidth:300}}/>
      </Tabs.Panel>
      <Tabs.Panel value='fitter'>
        <Select
        mt={8}
        label="Assign to Fitter"
        {...getInputProps('fitter_id')}
        {...fitterSelectProps}
        style={{maxWidth:300}}/>
      </Tabs.Panel>
      <Tabs.Panel value='locations'>
        <Select
        mt={8}
        label="Assign to Location"
        {...getInputProps('location_id')}
        {...otherLocationSelectProps}
        style={{maxWidth:300}}/>
      </Tabs.Panel>
    </Tabs>
  </>) : (<>
    <Select
    mt={8}
    label="Return to Location"
    {...getInputProps(`location_id`)}
    {...mainLocationSelectProps}
    style={{ maxWidth: 300 }}
    ></Select>
  </>)

  const [ extensionVisibility, setExtensionVisibility ] = useState(false);

  // add extension form
  const {
    values: extensionValues,
    getInputProps: getInputPropsForExtension,
    onSubmit: onSubmitOfExtension,
    saveButtonProps: extensionSaveButtonProps,
    reset: resetOfExtension,
    setFieldValue: setFieldValueUponExtension,
    // refineCore: {
    //   setId: 
    // }
  } = useForm({
    initialValues: {
        // referral_id: initialValues.referral_id,
        extended_to_date: null,
        extension_status_id: null,
        rx_expiration_date: null,
    },
    refineCoreProps:{
        onMutationSuccess: ({data}, variables, context) => {
            // @ts-ignore
            values?.patient?.referrals?.forEach((referral, index) => {
              const {
                product_type: {
                  title
                }
              } = referral
              if (title == 'TCT'){
                productInsertListItem(`patient.referrals.${index}.extensions`,data)
              }
            });
            resetOfExtension()
        },
        action: "create",
        resource: "extension",
        redirect: false,
        // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
        metaData: {
            fields: [
                'id',
                'extended_to_date',
                {
                    extension_status: [ 'title' ]
                },
                {
                    insurance_override: [ 'title' ]
                },
                'rx_expiration_date'
                // {
                //     reauth_lookup: [ 'title' ]
                // },
            ]
        }
    },
    validate: (values) => {
        const {
            extension_status_id = '',
            extended_to_date
        } = values;
        return {
            extended_to_date: extended_to_date ? null : "Select a date",
            extension_status_id: extension_status_id?.length ? null : "Select a status"
        }
    }
  });

// useEffect( () => {
//   const maybeReferral
// }, [initialValues])

  // @ts-ignore
  const extensions = values?.referral?.extensions?.sort( (a,b) => {
    const {
      extended_to_date: aDate
    } = a;
    const {
        extended_to_date: bDate
    } = b;
    // @ts-ignore
    return new Date(bDate) - new Date(aDate)
    // @ts-ignore
  }).map( (item,index) => {
    // TODO unify destructuring via resolving dumb error below
    const insurance_override = item?.insurance_override?.title;
    const reauth_lookup = item?.reauth_lookup?.title;
    const extension_status = item?.extension_status?.title;

    const {
        id,
        extended_to_date,

        // For whatever dumb reason, this destructuring code does not work
        // i.e. Cannot read properties of null (reading 'title')
        // even if there is a default value

        // insurance_override: { title: insurance_override = "" } ={ title: ""},
        // reauth_lookup: { title: reauth_lookup = ""} ={},
        rx_expiration_date
    } = item;

    //TODO: LEFT MERGE INSURANCE OF REFERRAL TO OVERRIDE INSURANCE

    return (
      <tr key={id}>
          {/* <td>
              <EditButton
                  recordItemId={id}
                  resource='operations/extension'
              >
              </EditButton>

          </td> */}
          <td><Anchor href={`/operations/extension/edit/${id}`} target="_blank">
            {extended_to_date}
          </Anchor></td>
          <td>{extension_status}</td>
          {/* <td>{insurance_override}</td> */}
          {/* <td>{reauth_lookup}</td> */}
          {/* <td>{rx_expiration_date}</td> */}
      </tr>
    );
  })

  //for whatever reason, need to do this to ensure that the patient_id 
    //is defined upon the patient_note form
  useEffect(() => {
    const maybeReferralId = values?.referral_id;
    // @ts-ignore
    const maybeProductType = values?.referral?.product_type?.title;
    if(maybeReferralId && maybeProductType){
        // MAYBE GENERALIZE TODO
        // @ts-ignore
        if (maybeProductType == 'TCT') extensionValues.referral_id = maybeReferralId;
    }

  }, [values])

  // TODO

  // some fields
  const locationFields = hasLocation ? (<>
    Location Fields
  </>) : null;

  //hyper link
  const representativeFields = hasRepresentative ? (<>
    Representative Fields
  </>) : null;

  //hyper link
  const providerFields = hasProvider ? (<>
    Provider Fields
  </>) : null;

  // some fields
  const fitterFields = hasFitter ? (<>
    Fitter Fields
  </>) : null;

  // const someFields = referralFields || locationFields || representativeFields || providerFields || fitterFields;

  // console.log(data);

  const { selectProps: productStatusSelectProps } = useSelect({
    resource: "equipment_status",

    defaultValue: values?.equipment_status_id as BaseKey,
    queryOptions: {
      enabled: !!initialValues?.id
    },
    optionLabel: 'title',

    meta: {
      fields: ['id','title'],
    },

    pagination: {
      mode: "server"
    }
  })

  //implement EQP/extensions tab

  const { selectProps: productTypeSelectProps } = useSelect({
    resource: "product_type",

    // defaultValue: values?.product_type_id as BaseKey,
    // queryOptions: {
    //     enabled: values?.product_type_id !== null
    // },
    optionLabel: 'title',

    meta: {
        fields: ["id", "title"],
    },

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: extensionStatusSelectProps } = useSelect({
    resource: "extension_status",
    defaultValue: undefined,
    optionLabel: 'title',

    meta: {
        fields: ["id", "title"],
    },

    pagination: {
      mode: "server"
    }
  });

  const tabList = (
    <Tabs.List>
      <Tabs.Tab value="main">Main</Tabs.Tab>
      <Tabs.Tab value="equipment_notes">Equipment Notes</Tabs.Tab>
      <Tabs.Tab value="actions">Actions</Tabs.Tab>
      { hasReferral && ( <>
        <Tabs.Tab value='patient'>Patient</Tabs.Tab>
        <Tabs.Tab value="patient_notes">Notes</Tabs.Tab> 
        <Tabs.Tab value="extensions">Extensions</Tabs.Tab>
      </>
      )}
      { hasLocation && (<Tabs.Tab value="location">Location</Tabs.Tab>)}
      { hasRepresentative && (<Tabs.Tab value="representative">Rep</Tabs.Tab>)}
      { hasProvider && (<Tabs.Tab value="provider">Provider</Tabs.Tab>)}
      { hasFitter && (<Tabs.Tab value="fitter">Fitter</Tabs.Tab>)}
      <Tabs.Tab value="device_history">Device History</Tabs.Tab>
    </Tabs.List>
  );

  // @ts-ignore
  const additionalFields = values?.product_type?.has_serial_number == true && (
    <TextInput
        mt={8}
        label="Serial Number"
        placeholder="Serial Number"
        {...getInputProps('serial_number')}
        style={{ maxWidth: 300 }}
    />
  );

  const forms = {
    main
  }

  const notesSection = hasReferral && <Notes
      // @ts-ignore
      patient_id={initialValues?.patient_id}
      forms={forms}
      // @ts-ignore
      referrals={values?.patient?.referrals}
      // @ts-ignore
      patient_notes={values?.patient?.patient_notes}
      main_accessor='patient.patient_notes'
  />;

  const tabPanels = (<>
    <Tabs.Panel value="main">
      {/* @ts-ignore */}
      { values?.referral?.product_title && <TextInput
        mt={8}
        label="RX"
        disabled
        {...getInputProps("referral.product_title")}
        style={{ maxWidth: 300 }}
      />}
      {additionalFields}
      <Select
          mt={8}
          label="Status"
          placeholder="Status"
          // @ts-ignore
          defaultValue={values?.equipment_status_id}
          {...getInputProps('equipment_status_id')}
          {...productStatusSelectProps}
          style={{ maxWidth: 300 }}
        />
    </Tabs.Panel>
    <Tabs.Panel value="equipment_notes">
      <Textarea
          mt={8}
          label="Equipment Notes"
          placeholder="Equipment Notes"
          {...getInputProps('equipment_notes')}
          minRows={16}
          style={{ maxWidth: 900 }}
      />
    </Tabs.Panel>
    <Tabs.Panel value="actions">
      {actionFields}
    </Tabs.Panel>
    { hasReferral && <Tabs.Panel value='patient'>
      <TextInput
        disabled
        mt={8}
        label="Patient"
        {...getInputProps('patient.name_and_dob')}
        style={{ maxWidth: 400 }}
      />
      <Textarea
        disabled
        mt={8}
        label="Patient Info"
        {...getInputProps('patient.patient_info')}
        minRows={5}
        style={{ maxWidth: 400 }}
      />
    </Tabs.Panel>}
    { hasReferral && (<>
      <Tabs.Panel value="patient_notes">
        {notesSection}
      </Tabs.Panel>
      { /* 
        // @ts-ignore */
        values?.referral?.product_type?.title == "TCT" && <Tabs.Panel value="extensions">
        { extensionVisibility == false ? <>
            <></>
            <Button
            onClick={
                () => setExtensionVisibility(true)
            }>Add Extension</Button>
            <br></br>
        </>: <>
        <></>
            <Button
            onClick={
                () => {
                    setExtensionVisibility(false)
                    resetOfExtension()
                }
            }>Cancel Extension</Button>
            <SwizzledCreate 
                saveButtonProps={{
                    ...extensionSaveButtonProps,
                    style:{ 
                        maxWidth: 300 ,
                        // @ts-ignore
                        ta:'left'
                    },
                    children: <Text>Add Extension</Text>
                }}
                breadcrumb={<></>}
                title={<></>}
                goBack={false}
                
            >
                <Stack>  
                    <DatePicker
                        mt="md"
                        label="Extension Date"
                        placeholder="Extension Date"
                        withinPortal
                        {...getInputPropsForExtension("extended_to_date")}
                        value={datePickerValue(extensionValues)('extended_to_date')}
                        style={{ maxWidth: 300 }}
                    />
                    <Select
                        mt={8}
                        label="Extension Status"
                        // placeholder="Client"
                        {...getInputPropsForExtension('extension_status_id')}
                        {...extensionStatusSelectProps}
                        style={{ maxWidth: 300 }}
                    />
                    <DatePicker
                        mt="md"
                        label="Rx Expiration Date"
                        placeholder="Rx Expiration Date"
                        withinPortal
                        {...getInputPropsForExtension("rx_expiration_date")}
                        value={datePickerValue(extensionValues)('rx_expiration_date')}
                        style={{ maxWidth: 300 }}
                    />
                </Stack>
            </SwizzledCreate>
        </>}
        {extensions?.length > 0 ? (
            <Table>
                <thead>
                    <tr>
                        {/* <th>Edit</th> */}
                        <th>Extension Date</th>
                        {/* <th>Status</th> */}
                        {/* <th>Insurance</th> */}
                        {/* <th>Reauth</th> */}
                        {/* <th>Rx Expiration Date</th> */}
                    </tr>
                </thead>
                <tbody>{extensions}</tbody>
            </Table>
            ) : (
                <Text color="dimmed" align="center">
                    No Extensions for this patient.
                </Text>
            )}
        </Tabs.Panel>}
    </>)}
    {/* @ts-ignore */}
    { values?.location?.title && (
      <Tabs.Panel value="location">
        <TextInput
          disabled
          mt={8}
          label="Location"
          {...getInputProps('location.title')}
          style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>
    )}
    { hasRepresentative && (
      <Tabs.Panel value="representative">
        <TextInput
          disabled
          mt={8}
          label="Representative"
          {...getInputProps('representative.name')}
          style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>
    )}
    { hasProvider && (
      <Tabs.Panel value="provider">
        <TextInput
          disabled
          mt={8}
          label="Provider"
          {...getInputProps('provider.name')}
          style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>
    )}
    { hasFitter && (
      <Tabs.Panel value="fitter">
        <TextInput
          disabled
          mt={8}
          label="Fitter"
          {...getInputProps('fitter.name')}
          style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>
    )}
    <Tabs.Panel value="device_history">
      <Textarea
          mt={8}
          label="Device History"
          placeholder="Device History"
          {...getInputProps('device_history')}
          minRows={8}
          style={{ maxWidth: 900 }}
      />
    </Tabs.Panel>
  </>);

  // @ts-ignore
  const tt = (xy) => {
    const {
      representative_id, representative, 
      provider_id, provider, 
      fitter_id, fitter,
      location_id, location, 
      patient_id, 
      referral_id
    } = xy;

    const pat_id = xy?.patient?.id;
    const ref_id = xy?.referral?.id;

    return {
      representative_id, representative, 
      provider_id, provider, 
      fitter_id, fitter,
      location_id, location, 
      patient_id, patient: { id: pat_id }, 
      referral_id, referral: { id: ref_id }
    }
  }

  return (
    <>
    <SwizzledEdit saveButtonProps={productSaveButtonProps}>
      {/* @ts-ignore */}
      <Text>{values?.product_type?.title} {values?.patient && `assigned to ${values?.patient?.name_and_dob}`}</Text>
      <Tabs defaultValue='main'>
        {tabList}
        {tabPanels}
      </Tabs>
      {/* {someFields} */}
      
    </SwizzledEdit>
    {/* <pre>{JSON.stringify(extensionValues, null, 2)}</pre> */}
    {AdminDebug(extensionValues)}
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
    {/* <Grid>
      {AdminDebug(tt(prevProduct))}
      {AdminDebug(tt(values))}
      {AdminDebug(tt(initialValues))}
    </Grid> */}
    </>
  )
}