
import { HttpError, useGetIdentity, useList, useNavigation } from "@refinedev/core";
import { Create, SaveButton, useStepsForm, useSelect } from "@refinedev/mantine";

import {
    Select,
    Tabs,
    Textarea,
    TextInput,
    Text,
    Button,
    Group,
    ActionIcon,
    CloseButton,
    Stepper,
    Divider,
    Stack,
} from "@mantine/core";

import { fields, resource } from "./common";


export const ProviderCreate: React.FC = () => {
    const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('directory/provider');
  }

  const {
    data: {
      // @ts-ignore

        claims: {
        // "x-hasura-user-id": uid,
        "x-hasura-client-id": client_id = ''
        } = {}
    } = {}
} = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });
  
    const {
        saveButtonProps,
        getInputProps, 
        errors,
        steps: { currentStep, gotoStep },
        values,
        insertListItem,
        removeListItem
    } = useStepsForm({
        initialValues: {
            // ...filteredInitialValues,
            provider_representatives: {
                data: []
            },
            addresses: [],
            contacts: [],
            client_id
        },
        refineCoreProps: {
            resource,
            metaData:{
                fields: [
                    'id'
                ]
            },
            onMutationSuccess
        },
        // @ts-ignore
        validate: (values) => {
            const {
                // @ts-ignore
                name,
                provider_representatives:{
                    data
                },

            } = values;

            if (currentStep === 0) {
                return {
                    name: name?.length ? null : "Please enter a name."
                }
            } else if (currentStep === 1){
                return {}
            } else if (currentStep === 3){
                const hasValidReps = data.filter( 
                    ({representative_id}) => representative_id
                ).length > 0
                return {
                    data: data?.length && hasValidReps ? null : "Please select a rep for this provider."
                }
            }
        }
    });

    const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ]
    .map( state => ({value: state, label:state}));

    // @ts-ignore
    const addresses = values?.addresses?.map( (address, index) => {
        const {
            lines,
            city,
            state,
            postalCode,
            stateOrProvinceCode,
            countryCode,
        } = address

        return (
            <Stack>
                <br/>
                <Divider></Divider>
                <TextInput
                        mt={8}
                        label="Name"
                        placeholder="e.g. Practice, Hospital, etc."
                        {...getInputProps(`addresses.${index}.name`)}
                        style={{ maxWidth: 300 }}
                    />
                <Textarea
                    label={ index == 0 ? "Primary address" : `Alternative address #${index}`}
                    {...getInputProps(`addresses.${index}.lines`)}
                    style={{ maxWidth: 500 }}
                    >
                </Textarea>
                <Group>
                    <TextInput
                        mt={8}
                        label="City"
                        {...getInputProps(`addresses.${index}.city`)}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Postal Code"
                        {...getInputProps(`addresses.${index}.postalCode`)}
                        style={{ maxWidth: 300 }}
                    />
                    <Select
                        mt={8}
                        label="State"
                        {...getInputProps(`addresses.${index}.stateOrProvinceCode`)}
                        searchable={true}
                        style={{ maxWidth: 100 }}
                        data={states}
                    />
                    <ActionIcon color="red" onClick={() => removeListItem('addresses', index)}>
                        {/* @ts-ignore */}
                        <CloseButton size="1rem">
                        </CloseButton>
                    </ActionIcon>
                </Group>
                <br/>
            </Stack>
        )
    })

    // @ts-ignore
    const contacts = values?.contacts?.map( (contact, index) => {

        return (
            <Stack>
                <br/>
                <Divider></Divider>
                <Group>
                    <TextInput
                        mt={8}
                        label="Contact Name"
                        {...getInputProps(`contacts.${index}.name`)}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Contact via"
                        placeholder="phone number, email, fax, etc."
                        {...getInputProps(`contacts.${index}.method`)}
                        style={{ maxWidth: 300 }}
                    />
                </Group>
                <Textarea
                    label="Contact Notes"
                    {...getInputProps(`contacts.${index}.notes`)}
                    style={{ maxWidth: 500 }}
                    >
                </Textarea>
                <br/>
            </Stack>
        )
    })

    const {
      data: {
        // @ts-ignore
        data: representativeLookup
      } = {}
    } = useList({
        resource: 'representative',

        meta:{
          fields: [ 'id', 'name'],
        },

        pagination: {
          pageSize: 1000
        }
    });

    const representatives = values.provider_representatives.data.map( (item, index) => (
        <Group key={index} mt="xs">
            <Select
                mt={8}
                label="Representative"
                placeholder="Select rep"
                {...getInputProps(`provider_representatives.data.${index}.representative_id`)}
                searchable={true}
                clearable={true}
                filterDataOnExactSearchMatch={true}
                data={representativeLookup?.map(({id,name}) => ({value:id, label:name})) as any}
                style={{ maxWidth: 300 }}
            />
            <ActionIcon color="red" onClick={() => removeListItem('provider_representatives.data', index)}>
                {/* @ts-ignore */}
                <CloseButton size="1rem" />
            </ActionIcon>
        </Group>
    ))

    return (
        <Create saveButtonProps={saveButtonProps}
            footerButtons={
                <Group position="right" mt="xl">
                    {currentStep !== 0 && (
                        <Button
                            variant="default"
                            onClick={() => gotoStep(currentStep - 1)}
                        >
                            Back
                        </Button>
                    )}
                    {currentStep !== 3 && (
                        <Button onClick={() => gotoStep(currentStep + 1)}>
                            Next step
                        </Button>
                    )}
                    {currentStep === 3 && <
                      // @ts-ignore
                      SaveButton {...saveButtonProps} />}
                </Group>
            }>
            <Stepper
                active={currentStep}
                onStepClick={gotoStep}
                breakpoint="sm"
            >
                <Stepper.Step
                    label="First Step"
                    description="Provider"
                    // allowStepSelect={currentStep > 0}
                >
                    <TextInput
                        mt={8}
                        label="Name"
                        withAsterisk
                        placeholder="Name"
                        {...getInputProps('name')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Specialty"
                        placeholder="Specialty"
                        {...getInputProps('specialty')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="NPI"
                        placeholder="NPI"
                        {...getInputProps('npi')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Extension Direct Send"
                        placeholder="Extension Direct Send"
                        {...getInputProps('ext_direct_send')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Survey Link"
                        placeholder="Survey Link"
                        {...getInputProps('survey_link')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Survey Direct Send"
                        placeholder="Survey Direct Send"
                        {...getInputProps('survey_direct_send')}
                        style={{ maxWidth: 300 }}
                    />
                </Stepper.Step>
                <Stepper.Step
                    label="Second Step"
                    description="Contact"
                >
                    {/* {addresses?.length == 0 ? (
                    <Text>Please add at least one address.</Text>
                    ) : (<><br/></>)}
                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('addresses', {})
                        }
                        >
                        Add An Address
                        </Button>
                    </Group>
                    {addresses} */}
                    <Textarea
                      mt={8}
                      label="Address"
                      placeholder="Address"
                      {...getInputProps('specialty')}
                      minRows={4}
                      style={{ maxWidth: 300 }}
                    />
                    <TextInput
                      mt={8}
                      label="Phone"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('phone')}
                    />
                    <TextInput
                      mt={8}
                      label="Fax"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('fax')}
                    />
                    <Textarea
                      mt={8}
                      label="Office Contact"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('office_contact')}
                      minRows={2}
                    />
                    <Textarea
                      mt={8}
                      label="Other Address"
                      placeholder="Other Address"
                      {...getInputProps('specialty')}
                      minRows={4}
                      style={{ maxWidth: 300 }}
                    />
                </Stepper.Step>
                <Stepper.Step
                    label="Third Step"
                    description="Notes"
                    // allowStepSelect={currentStep > 1}
                >
                    {/* {contacts?.length == 0 ? (
                    <Text>Please add at least one way to contact this provider.</Text>
                    ) : (<><br/></>)}
                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('contacts', {})
                        }
                        >
                        Add A Contact
                        </Button>
                    </Group>
                    {contacts} */}
                    <Textarea
                      mt={8}
                      label="Notes"
                      placeholder="Notes"
                      {...getInputProps('notes')}
                      minRows={4}
                      style={{ maxWidth: 300 }}
                    />
                </Stepper.Step>
                <Stepper.Step
                    label="Fourth Step"
                    description="Rep(s)"
                >
                    {representatives.length > 0 ? (
                        <Group mb="xs">
                        <Text weight={500} size="sm" 
                        // sx={{ flex: 1 }}
                        >
                            Rep(s)
                        </Text>
                        </Group>
                    ) : (
                        <Text color="dimmed" align="center">
                        No Reps assigned.  Please select at least one to continue.
                        </Text>
                    )}

                    {representatives}

                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('provider_representatives.data', { representative_id : undefined })
                        }
                        >
                        Add Representative
                        </Button>
                    </Group>
                </Stepper.Step>
            </Stepper>

                {/* <Tabs defaultValue={'first'}>
                    <Tabs.List>
                        <Tabs.Tab value="first">Main</Tabs.Tab>
                        <Tabs.Tab value="second">Referrals</Tabs.Tab>
                        <Tabs.Tab value="third">Representative(s)</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                    </Tabs.Panel>
                    <Tabs.Panel value="second">
                        
                    </Tabs.Panel>
                    <Tabs.Panel value="third">
                        <TextInput
                            mt={8}
                            label="Alt Phone Number"
                            placeholder="Alt Phone Number"
                            {...getInputProps('phone_number_alt')}
                        />
                        <Select
                            mt={8}
                            label="Alt Phone Number Type"
                            placeholder="Pick One"
                            {...getInputProps('phone_number_alt_type')}
                            data={[
                                { label: "Personal", value: "personal" },
                                { label: "Business", value: "business" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                        <Textarea
                            mt={8}
                            label="Alternate Address"
                            placeholder="Alternate Address"
                            {...getInputProps('address_alt')}
                            minRows={3}
                        />
                        <Select
                            mt={8}
                            label="Alternate Address Type"
                            placeholder="Pick One"
                            {...getInputProps('address_alt_type')}
                            data={[
                                { label: "Home", value: "home" },
                                { label: "Commercial", value: "commercial" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                    </Tabs.Panel>
                </Tabs> */}
                
                
                <br/>
                <br/>
            {/* </form> */}
            {/* // @ts-ignore */}
            <pre>{JSON.stringify(values, null, 2)}</pre>
        </Create>
    );
};
