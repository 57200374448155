import dayjs from "dayjs";

export const list_fields = [
  "id",
	"column_b",
	"column_d",

  "insurance_status_updated_at",

  "rx_date",
  "rx_received_date",
  "fourteen_day_call",

  'agreement',

  {
    client: [
      'name'
    ]
  },

  'added_to_collaborate',
  'date_of_surgery',
  'condition',

  'product_title',

	"patient_id",
  {
		patient: [
			'name',
			'date_of_birth',
      'name_and_dob',
      'meta',

      // 'added_to_collaborate',

      "initial_call_complete",
      // "date_of_surgery",
      "surgery",
      "next_call_date",
      // "date_fit",
      "condition",
		]
	},
  {
    provider: [
      'name',
      {
				provider_representatives: [ 
					{
						representative: [
							'name'
						]
					}
				]
			}
    ]
  },

  "patient_status_id",
	{
		patient_status: ['title']
	},

	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	{
		reauth_status: [ 'title' ]
	},
]

export const detail_fields = [
	"id",
	"column_b",
	"column_d",

  'agreement',
  'do_not_mz_rs',  // TODO add to referral view

  {
    client: [
      'name'
    ]
  },

  'product_title',

	"patient_id",
	{
		patient: [
			'name',
			'date_of_birth',
      'name_and_dob',
			'template_answers',
			'patient_info',

      'added_to_collaborate',

      "initial_call_complete",
      "date_of_surgery",
      "surgery",
      "next_call_date",
      // "date_fit",
      "condition",

			// TODO patient contact
			'rep_communication_notes',
  		'verification_notes',
			{
				patient_notes: [
					{
						note: [
              'id',
							'content',
							'created_at',
							{
								authed_user: [
                  'id',
									'display_name',
									'photoURL'
								]
							},
							// {
							// 	note_type: ['code']
							// }
              'metadata'
						]
					}
				]
			},
      {
        referrals: [
          'id', 'product_title',
          'rx_received_date',
          {
            product_type: [ 'id', 'title']
          },
          {
            "rx_length": [ 'title' ]
          },
          {
            "insurance": [ 'title' ]
          },
          {
            "claim_type": [ 'title' ]
          },
          {
            "insurance_status": [ 'title' ]
          },
          {
            "patient_status": [ 'title' ]
          },
        ]
      }
		]
	},

  {
    "submissions": [
      'id', 'submission_type', 'items', 'date_shipped', 'tracking_number', 'fitter_id', {
        fitter: ['title']
      }, 'urgency'
    ]
  },

	"patient_status_id",
	{
		patient_status: ['title']
	},

	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	{
		reauth_status: [ 'title' ]
	},

	"provider_id",
	{
		provider: [
			'name',
			'npi',
			// TODO contact info
			{
				provider_representatives: [ 
					{
						representative: [
							'name'
						]
					}
				]
			}
		],
	},

	{
		extensions: [
			'id',
			'extended_to_date',
			{
				extension_status: [ 'title' ]
			},
			{
				insurance_override: [ 'title' ]
			},
			'rx_expiration_date'
		]
	},
	{
		resupplies: [
			'id',
			'resupply_date',
		]
	},
	'resupply_items',

	"injury_sites",
	"icd10_codes",

	"survey_complete",
	
	"rx_date",
  "rx_received_date",

	"rx_expiration_date",

	"date_fit",
	"five_day_call",
	"five_day_call_status",
	"fourteen_day_call",
	"fourteen_day_call_status",
	"equipment_id",
  "automated_email_status"
];

export const columnsIdsToFilters = {
  DEFAULT: [
    'patient.name_and_dob',
    'product_type.title',
    'rx_received_date',
    'date_of_surgery',
    'claim',
    'patient_status.title',
    'insurance_status.title'
  ],
  'NMES SURVEY': [
    'patient.name_and_dob',
    'product_type.title',
    'rx_received_date',
    'date_of_surgery',
    'fourteen_day_call',
    'claim',
    'patient_status.title',
    'insurance_status.title'
  ]
};

export const sortersToFilter = {
  DEFAULT: [
    {
      field: 'created_at',
      order: 'desc'
    }
  ],
  'LITIGATION VERIFICATION': [
    {
      field: 'rx_received_date',
      order: 'asc'
    }
  ],
  'NMES SURVEY': [
    {
      field: 'fourteen_day_call',
      order: 'asc'
    }
  ]
};

export const dynamicFiltersThunk = () => ({
  'DEFAULT': [
    {
      operator: 'or',
      value: [
        {
          field: 'next_call_date',
          operator: 'lte',
          value: new Date()
        },
        {
          field: 'next_call_date',
          operator: null,
          value: true
        }
      ]
    }
  ],
  'NMES SURVEY': [
    {
      operator: 'and',
      value: [
        {
          field: 'fourteen_day_call',
          operator: 'lt',
          value: dayjs().add(1,'days')
        }
      ]
    }
  ]
});

// @ts-ignore
export const filterToView = (filter) => {
  if ([
    ''
  ].includes(filter)){
    return 'patient';

  } else if ([
    ''
  ].includes(filter)){
    return 'extension';

  } else if ([
    ''
  ].includes(filter)){
    return 'resupply'

  } else if ([
    'NMES SURVEY',
    'NMES SURVEY FU',
  ].includes(filter)){
    return 'survey';

  }
  return 'referral'
}

// @ts-ignore
export const getUrl = ({view, patient_id, referral_id}) => {
  const to = `/directory/patient/edit/${patient_id}`;
  const query = { referral: referral_id, view };
  return {
    to, query
  }
}

// @ts-ignore
export const generateLink = ({row, filter}) => {
  const patient_id = row.original.patient_id;
  const referral_id = row.original.id;
  const view = filterToView(filter);
  return getUrl({view, patient_id, referral_id});
}

export const resource = 'referral'