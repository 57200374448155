import React, { useContext, useEffect } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton, DateField } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination, Select } from "@mantine/core";

import dayjs from 'dayjs';

import { List } from "components/ghettoList";

import { fields } from './common'

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';
import { useGetIdentity, useList, useGo } from "@refinedev/core";

// @ts-ignore
import { renderDate } from 'utilities/index.tsx'
import { isAffectrixClientId } from "utilities";

const columns: ColumnDef<any>[] = [
    { id: "urgency", accessorFn: (row) => row?.urgency || ' ', header: "URGENCY", enableColumnFilter: true, enableSorting: false },

    { id: 'status', header:'STATUS', accessorFn: (row) => {
      const serial_number = row?.serial_number;
      const date_shipped = row?.date_shipped;
      const date_fit = row?.referral?.date_fit;
      const tracking_number = row?.tracking_number;

      let status = 'READY TO SHIP';
      if ( serial_number && date_shipped && date_fit && tracking_number){
        status = 'COMPLETE'
      } else if ( serial_number && date_shipped && !date_fit && tracking_number ){
        status = 'IN TRANSIT'
      }

      return status
    }, enableColumnFilter: false, enableSorting: false},

    { id: 'referral.patient.name_and_dob', accessorFn: (row) => row?.referral.patient.name_and_dob, enableColumnFilter: true, enableSorting: false, header: 'Name', meta: {
      filterOperator: 'contains'
  }},

    // { id: "referral.claim_type.title", accessorFn: (row) => {
    //   return row.referral?.claim_type?.title
    // }, header: "CLAIM TYPE", enableColumnFilter: false, enableSorting: false, },

    { id: "product.serial_number", accessorFn: (row) => {
      return row.product?.serial_number || row.serial_number
    }, header: "SERIAL NUMBER", enableColumnFilter: false, enableSorting: false },

    { id: "date_shipped", cell: renderDate, accessorFn: (row) => {
      return row?.referal?.date_shipped
    }, header: "DATE SHIPPED", enableColumnFilter: false, enableSorting: false },

    { id: "referral.date_fit", cell: renderDate, accessorFn: (row) => {
      return row.referral?.date_fit
    }, header: "DATE DME REC'D", enableColumnFilter: false, enableSorting: false, },
    { id: "received", accessorKey: "received", header: "RECEIVED?", enableColumnFilter: false},

    // // maybe define as computed fields upon referral (i.e. sourced from template)
    // { id: "referral.employer", accessorKey: "referral.employer", header: "EMPLOYER", enableColumnFilter: false, enableSorting: false, },
    // { id: "referral.doi", accessorKey: "referral.doi", header: "DOI", enableColumnFilter: false, enableSorting: false, },
    // { id: "referral.attorney", accessorKey: "referral.attorney", header: "ATTY", enableColumnFilter: false, enableSorting: false, },
    
    { id: "items", header: '# of ITEM(S)',
        accessorFn: (row) => {
            const number = row?.items?.length;
            return number;
        }, enableColumnFilter: false
    },
];

// FILTERS TODO

// default -- show all

// ready to ship -- if any of the columns of SERIAL NUMBER	DATE SHIPPED	TRACKING NUMBER are not filled

// in transit -- if above columns are filled, but not DATE rec'd

// completed -- all columns filled out

const ghettoDataFilters = [
  {
    title: 'READY TO SHIP',
    filter: [
      // {
      //   operator: 'or',
      //   value: [
          {
            field: 'date_shipped',
            operator: 'null',
            value: true
          },
      //   ]
      // },
    ]
  },
  {
    title: 'IN TRANSIT',
    filter: [
      {
        field: 'date_shipped',
        operator: 'null',
        value: false
      },
      {
        field: 'referral.date_fit',
        operator: 'null',
        value: true
      }
    ]
  },
  {
    title: 'COMPLETE',
    filter: [
      {
        field: 'date_shipped',
        operator: 'null',
        value: false
      },
      {
        field: 'referral.date_fit',
        operator: 'null',
        value: true
      }
    ]
  }
].map( x => {
  let { filter } = x;
  // filter.push({
  //   field: 'active',
  //   operator: 'eq',
  //   // @ts-ignore
  //   value: true
  // });
  return {
    ...x,
    filter
  }
});

function RadioSelect(props:any) {

  const {
    setFilters,
    user_filters = [],
    filters = [],
    uid,

    currentUserState,
    setCurrentUserState
  //   tableFilters,
  } = props;

  // @ts-ignore
  // const maybeFiltersForThisUser = filters.filter( x => {
  //   const {
  //     table
  //   } = x;
  //   return table == 'product'
  // })
  // // @ts-ignore
  // .some( filter => {
  //   const { user_filters } = filter;
  //   // @ts-ignore
  //   const userHasThisFilter = user_filters.some( user_filter => {
  //     const { authed_user: {id}} = user_filter;
  //     return id == uid
  //   })

  //   return userHasThisFilter
  // })

  // user_filters <~> filters
  const mappedFilters = filters
  // @ts-ignore
  // .filter( x => {
  //   const {
  //     table
  //   } = x;
  //   return table == 'product'
  // })
  // @ts-ignore
  // .filter( filter => {
  //   const noFiltersAssignedToThisUser = !maybeFiltersForThisUser;
  //   if (noFiltersAssignedToThisUser){
  //     return true
  //   }

  //   const { user_filters } = filter;
  //   // @ts-ignore
  //   const userHasThisFilter = user_filters.some( user_filter => {
  //     const { authed_user: {id}} = user_filter;
  //     return id == uid
  //   })

  //   return userHasThisFilter
  // })
  .map( (x:any) => {
      const {
          // filter:{
              // value,
              title,
              filter
          // }
      } = x
      return {
          value: title,
          label: title,
          active_filter: filter
      }
      // @ts-ignore
  }).sort( (a,b) => {
    const {
      label: la
    } = a;
    const {
      label: lb
    } = b;
    return la > lb ? 1 : -1
  })

  const [value, setValue] = React.useState(null);

  // @ts-ignore
  let clientFilter = [];
  if (currentUserState?.clientId){
    console.log(currentUserState.clientId)
    clientFilter = [
      {
        field: 'referral.client_id',
        operator: 'eq',
        value: currentUserState.clientId
      }
    ]
  }

  const handleChange = (selectValue:any) => {

      setValue(selectValue);

      if (!selectValue){
          // @ts-ignore
          setFilters([...clientFilter], 'replace');

          setCurrentUserState({
            ...currentUserState,
            shipmentRequestFilter: undefined
          })
      }

      const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

      if (thing.length == 1){

          const [ {active_filter} ] = thing

          setFilters([
            ...active_filter,
            // @ts-ignore
            ...clientFilter
          ], 'replace' );

          setCurrentUserState({
            ...currentUserState,
            shipmentRequestFilter: {
              title: selectValue,
              active_filter,
            }
          })
      } else {
          // console.log('dumb')
          // console.log('')
      }

  };

  return (
      <>
          <Select defaultValue={currentUserState?.shipmentRequestFilter?.title}
          label='Select a filter'
          data={mappedFilters} onChange={handleChange} searchable clearable style={{ maxWidth: 300 }}>
              
          </Select>
      </>
  );
}

export const ShipmentRequestList: React.FC = () => {

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

    const go = useGo();

    const {
      data: {
        // @ts-ignore

          claims: {
          "x-hasura-user-id": uid = '',
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { 
            setCurrent,
            setFilters,
            filters,
            pageCount, 
            current,
            tableQueryResult: { data: tableData },
          },
      } = useTable({
        columns,

        refineCoreProps: {
            resource: 'submission',

            metaData: {
                fields
            },

            filters: {
                permanent: [
                  {
                    field: 'submission_type',
                    operator: 'eq',
                    value: 'shipment_request'
                  }
                ]
            },
            queryOptions: {
              // disable query if the user belongs to the AFX client and the client of the query is undefined
              enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
            },
            pagination: {
              pageSize: 50
            },
        }
    });

    //   const {
    //     data: {
    //         // @ts-ignore
    //         data: dataFilters = []
    //     } = {}
    // } = useList({
    //     resource: 'filter',
    //     metaData: {
    //         fields: [
    //             'title',
    //             'filter',
    //             'table',
    //             {
    //               user_filters: [
    //                 {
    //                   authed_user: [
    //                     'id', 'email', 'display_name'
    //                   ]
    //                 }
    //               ]
    //             }
    //         ],
    //         // TODO does not work
    //         // sorters:[
    //         //   {
    //         //     field: 'title',
    //         //     order: 'asc'
    //         //   }
    //         // ]
    //     },
    //     config: {
    //       filters: [
    //         {
    //           field: 'table',
    //           operator: 'eq',
    //           value: 'product'
    //         }
    //       ],
    //         pagination:{
    //             pageSize: 100
    //         }
    //     }
    // })

    useEffect( () => {
      // @ts-ignore
      let filters = [];
      if(userState?.shipmentRequestFilter?.active_filter){
        // setFilters(userState.shipmentRequestFilter.active_filter, 'replace');
        filters = [...userState.shipmentRequestFilter.active_filter];
      }
      if (userState?.clientId){
        filters = [
          // @ts-ignore
          ...filters,
          {
            field: 'referral.client_id',
            operator: 'eq',
            value: userState.clientId
          }
        ]
      }
  
      // @ts-ignore
      setFilters(filters, 'replace');
    }, [userState])

    return (
        <ScrollArea>
        <List>
            {/* { clientId == '279a1514-9914-49b2-af74-027610efd80f' && <ClientSelect 
            setFilters={setFilters}
            filters={filters}
            table='shipmentRequest'
            ></ClientSelect>} */}
            <RadioSelect 
            setFilters={setFilters} 
            // user_filters={user_filters} 
            uid={uid}
            // tableFilters={filters}
            filters={ghettoDataFilters}

            currentUserState={userState}
            setCurrentUserState={setUserState}
            ></RadioSelect>
            <Table highlightOnHover>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <Group spacing="xs" noWrap>
                                                <Box>
                                                    {flexRender(
                                                        header.column
                                                            .columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <Group spacing="xs" noWrap>
                                                    <ColumnSorter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                    <ColumnFilter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                </Group>
                                            </Group>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} onClick={() => go({
                              to: `edit/${row.original.id}`
                            })}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    </ScrollArea>
    );
};
