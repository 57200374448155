import { HttpError, useResource, useNavigation, useOne, useGetIdentity, useGo } from "@refinedev/core";
import { Edit, useForm, useSelect, EditButton } from "@refinedev/mantine";
import { createStyles } from "@mantine/styles";

import {
    Select,
    Table,
    Tabs,
    Textarea,
    TextInput,
    Text,
    Avatar,
    Group,
    Paper,
    Button,
    Stack,
} from "@mantine/core";
import { useEffect } from "react";

export const InsuranceStatusEdit: React.FC = () => {

  const go = useGo();

    const {
      data: {
        // @ts-ignore
          claims: {
            "x-hasura-default-role": role = ''

          } = {}
      } = {}
    } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    useEffect( () => {
      if (role && role != 'admin'){
        console.log('unauthorized')
        go({to:'/'});
      }
    }, [role])

  const { id } = useResource();

  const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('admin/status/insurance');
  }

	const {
		data: {
			data: initialValues
		} = {}
	} = useOne<any>({
		resource: "insurance_status",
		id,
		meta: {
				fields: ['id', 'title']
		},
	})

    // const {
    //     data: {
    //         claims: {
    //         "x-hasura-user-id": uid
    //         }
    //     }
    // } = useGetIdentity();

    const {
        saveButtonProps,
        refineCore: { queryResult, onFinish, formLoading, redirect },
        getInputProps,
        values = ([] as any[]),
        insertListItem
    } = useForm< HttpError>({
        refineCoreProps: {
            resource: 'insurance_status',
            id,
            metaData: {
                fields: ['id', 'title']
            },
            onMutationSuccess,
            redirect: false
        },
        initialValues,
    });

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <TextInput
              mt={8}
              label="Name"
              placeholder="Name"
              {...getInputProps('title')}
          />
        <pre>{JSON.stringify(values, null, 2)}</pre>
      </Edit>
    );
};
