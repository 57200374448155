import { routes, resources } from './router'

import { Refine } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6";
import { BrowserRouter } from "react-router-dom";

import { notificationProvider, LightTheme, DarkTheme } from "@refinedev/mantine";
import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider, Global } from "@mantine/core";

import { hasuraClient } from "./dataProviders"

import { firebaseAuthProvider } from "configuration/firebase";

import { UserProvider } from "contexts/userContext";

import accessControl from "providers/accessControl";

const blank: React.FC = () => (<>To be implemented</>)

export const App = () => {

  return (
    <MantineProvider theme={LightTheme} withNormalizeCSS withGlobalStyles>
      <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
      <NotificationsProvider position="top-right">
        {/* <ClientProvider> */}
        <BrowserRouter>
          <UserProvider>
            <Refine
              routerProvider={routerProvider}
              dataProvider={hasuraClient}
              accessControlProvider={accessControl}
              authProvider={firebaseAuthProvider}

              options={{
                disableTelemetry: true
              }}

              // Sider={Sider}

              notificationProvider={notificationProvider}
              // ReadyPage={ReadyPage}
              // catchAll={<ErrorComponent />}
              
              resources={resources}
            >
              {routes}
            </Refine>
          </UserProvider>
        </BrowserRouter>   
        {/* </ClientProvider> */}
      </NotificationsProvider>
    </MantineProvider>
  );
};