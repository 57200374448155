import {
    BaseKey,
    HttpError,
    useGetIdentity,
    useList,
    useNavigation,
    useOne,
    useResource,
} from "@refinedev/core";
import { useForm, useSelect, EditButton } from "@refinedev/mantine";

import {
Tabs,
Textarea,
Select, TextInput, Group, Title, Anchor
} from "@mantine/core";

import { Edit as SwizzledEdit } from 'components/crud/edit';
import { Notes } from "components/notes";
import { DatePicker } from '@mantine/dates';

import { fields, extendedFields, resource } from './common'
import React from "react";
import { datePickerValue } from "utilities";

export const ExtensionEdit: React.FC = () => {

  const { list } = useNavigation();

  const { id } = useResource();

  const { 
      data: {
          data: initialValues
      } = {}
  } = useOne({
      resource,
      id,
      meta:{
          fields: [ ...fields, ...extendedFields ]
      }
  });

  let automationRan = true;
  if (initialValues?.autocrat_job_status || initialValues?.autocrat_job_results){
    const bool0 = initialValues?.autocrat_job_status?.generated_extension;
    const bool1 = Object.keys(initialValues?.autocrat_job_results).length > 0;
    automationRan = bool0 || bool1;
  }

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('operations/extension');
  }

  // TODO implement extensions edit

  // @ts-ignore
  // const referralFields = values?.referral

    const main = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields: [ ...fields, ...extendedFields]
        },
        onMutationSuccess,
        autoSave: {
          enabled: true,
        }
      },
      initialValues,

      transformValues: (values) => {
        const {
          extension_status,
          insurance_override,
          reauth_lookup,
          referral,
          provider,
          id,
          ...rest
        } = values;
        return rest
      },
  });

  const {
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      insertListItem,
      getInputProps,
      values,
      setFieldValue
  } = main;

  const { selectProps: extensionStatusSelectProps } = useSelect({
      resource: "extension_status",
      defaultValue: undefined,
      optionLabel: 'title',

      meta: {
          fields: ["id", "title"],
      },

      pagination: {
          mode: "server"
      }
  });

  const { selectProps: providerSelectProps } = useSelect({
    resource: "provider",
    optionLabel: 'name',

    meta: {
        fields: ["id", "name"],
    },

    pagination: {
        mode: "server"
    }
});

  const { selectProps: productTypeSelectProps } = useSelect({
      resource: "product_type",

      // TODO default product_type
      // @ts-ignore
      defaultValue: values?.referral?.product_type_id as BaseKey,

      // queryOptions: {
      //     enabled: values?.product_type_id !== null
      // },
      optionLabel: 'title',

      meta: {
          fields: ["id", "title"],
      },

      pagination: {
          mode: "server"
      }
  });

  const {
    data: {
      // @ts-ignore
      data: extensionstatusLookup
    } = {}
  } = useList({
      resource: 'extension_status',

      meta:{
        fields: [ 'id', 'title', 'code'],
      },

      pagination: {
        pageSize: 1000
      }
  });

  const {
    data: {
      // @ts-ignore
      data: extensionStatusLookup = []
    } = {}
  } = useList({
      resource: 'extension_status',

      meta:{
        fields: [ 'id', 'title'],
      },

      pagination: {
        pageSize: 1000
      }
  });

  const mappedExtStatus = extensionStatusLookup.map(({id,title}) => ({value: id, label: title}));

  const runArray = [
    {
      value:"RUN TCT RX EXT",
      product_type: [ 'TCT' ]
    },
    {
      value:"RUN COLD THERAPY RX EXT",
      product_type: [ 'COLD THERAPY' ]
    },
    {
      value:"SOTEREANOS",
      product_type: [ 'TCT' ]
    }
  ]
  // @ts-ignore
  .filter( x => {
    const { product_type } = x;
    // @ts-ignore
    const boolean = product_type.includes(values?.referral?.product_type?.title);
    return boolean
  })
  .map(({value}) => ({value, label:value}))

  const showRxDays = Boolean(values?.extension_status_id) && extensionstatusLookup?.some(x=>{
    // console.log(x)
    const { title, id } = x;
    // console.log(id == values?.extension_status_id)
    // console.log(!title.includes('LIFETIME'))
    return id == values?.extension_status_id && !title.includes('LIFETIME') && !title.includes('NO EXTENSION')
  });

  // @ts-ignore
  const icd10_codes = values?.referral?.icd10_codes?.map((item, index) => (
    <Group mt="s">
      <TextInput
          mt={8}
          disabled
          // label="ICD 10 code"
          // placeholder="Type in a Code."
          // {...getInputProps(`icd10_codes.${index}`)}
          value={item}
          // {...injurySiteProps}
          style={{ maxWidth: 300 }}
      />
      {/* <ActionIcon color="red" onClick={() => removeListItem('icd10_codes', index)}>
        <CloseButton 
        // @ts-ignore
        size="1rem" />
      </ActionIcon> */}
    </Group>
  ));

  // @ts-ignore
  const referralsOfPatient = [{
    // @ts-ignore
    id: values?.referral?.id,
    // @ts-ignore
    product_type: values?.referral?.product_type,
    // @ts-ignore
    rx_received_date: values?.referral?.rx_received_date
  }];

  const forms = {
    main
  }

  const notesSection = <Notes
      // @ts-ignore
      patient_id={initialValues?.referral?.patient_id}
      forms={forms}
      referrals={referralsOfPatient}
      // @ts-ignore
      patient_notes={values?.referral?.patient?.patient_notes}
      main_accessor='referral.patient.patient_notes'
  />;

  const anchor = <Anchor 
    href={`/directory/patient/edit/${initialValues?.referral?.patient_id}?referral=${initialValues?.referral_id}`}
    target="_blank">
    Link
  </Anchor>;

  return <>
  <SwizzledEdit  saveButtonProps={saveButtonProps}>
    <Tabs defaultValue='main'>
      <Tabs.List>
        <Tabs.Tab value="main">Main</Tabs.Tab>
        <Tabs.Tab value="referral">Referral</Tabs.Tab>
        <Tabs.Tab value="patient_notes">Patient Notes</Tabs.Tab>
        {initialValues?.referral?.product_id && <Tabs.Tab value="equipment">Equipment</Tabs.Tab>}
      </Tabs.List>
      <Tabs.Panel value="main">
        <TextInput
          disabled
          mt={8}
          label="Patient"
          {...getInputProps('referral.patient.name_and_dob')}
          style={{ maxWidth: 300 }}
        />
        <Textarea
          disabled
          mt={8}
          label="Patient Info"
          {...getInputProps('referral.patient.patient_info')}
          minRows={5}
          style={{ maxWidth: 300 }}
        />
        <DatePicker
            mt="md"
            label="Extension Date"
            placeholder="Extension Date"
            withinPortal
            {...getInputProps("extended_to_date")}
            value={datePickerValue(values)('extended_to_date')}
            style={{ maxWidth: 300 }}
        />
        <Select
          mt={8}
          label="ACTIVE"
          {...getInputProps('active')}
          style={{ maxWidth: 300 }}
          data={[
            {value: true, label:'ACTIVE'},
            {value: false, label: 'INACTIVE'}
          ] as any}
        />
        <Select
            mt={8}
            label="Extension Status"
            // placeholder="Client"
            {...getInputProps('extension_status_id')}
            // {...extensionStatusSelectProps}
            //@ts-ignore
            data={mappedExtStatus}
            style={{ maxWidth: 300 }}
        />
        { showRxDays && <TextInput 
          mt={8}
          label="Number of Days for Extension"
          {...getInputProps('rx_length_days')}
          style={{ maxWidth: 300 }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          // disabled={extensionstatusLookup?.some(x=>{
          //   console.log(x)
          //   const { title, id } = x;
          //   return title.includes('LIFETIME') && id == values?.extension_status_id
          // })}
        /> }
        
        {/* <Switch
          size='lg' label='IS LIFETIME?' onLabel='YES' offLabel='NO'
          // @ts-ignore
          checked={values?.lifetime}
          onChange={(e)=>setFieldValue(
            'lifetime', 
            !values?.lifetime
          )}
        /> */}
        <DatePicker
            mt="md"
            label="Billing Expiration Date"
            placeholder="Billing Expiration Date"
            withinPortal
            {...getInputProps("rx_expiration_date")}
            value={datePickerValue(values)('rx_expiration_date')}
            style={{ maxWidth: 300 }}
        />
        <TextInput
          mt={8}
          label="Referring Provider"
          disabled
          // @ts-ignore
          value={values?.referral?.provider?.name}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          mt={8}
          label="Billing Provider"
          disabled
          // @ts-ignore
          value={values?.referral?.billing_provider?.name}
          style={{ maxWidth: 300 }}
        />
        {/* <Select 
          mt={8}
          withAsterisk
          label="Billing Provider"
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          {...getInputProps("billing_provider_id")}
          {...providerSelectProps}
          style={{ maxWidth: 300 }}
        /> */}
        <Select
          mt={8}
          label="WHICH JOB TO RUN"
          {...getInputProps(`run`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // defaultValue={itemId}
          data={runArray}
          // value={matchedTitle}
          style={{ maxWidth: 300 }}
          disabled={automationRan}
      />
      {automationRan && <>Automation already ran<br/></>}
      <br/>
      </Tabs.Panel>
      <Tabs.Panel value="referral">
        <br/>
        {anchor}
        <TextInput
          disabled
          mt={8}
          label="Claim Type"
          {...getInputProps('referral.claim_type.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Rx Length"
          {...getInputProps('referral.rx_length.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Insurance"
          {...getInputProps('referral.insurance.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Insurance Status"
          {...getInputProps('referral.insurance_status.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Patient Status"
          {...getInputProps('referral.patient_status.title')}
          style={{ maxWidth: 300 }}
        />
        <br/>
        <Title order={6}>ICD 10 Codes</Title>
        {icd10_codes}
      </Tabs.Panel>
      <Tabs.Panel value="patient_notes">
        {notesSection}
      </Tabs.Panel>
      <Tabs.Panel value="equipment">
        <Anchor 
          href={`/fulfillment/product/edit/${initialValues?.referral?.product_id}`}
          target="_blank">
          Link to {initialValues?.referral?.product?.serial_number}
        </Anchor>
      </Tabs.Panel>
    </Tabs>
  </SwizzledEdit>
  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
  </>;
}