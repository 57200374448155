import { HttpError, useResource, useNavigation, useList, useGetIdentity, useGo } from "@refinedev/core";
import { Edit, useForm, useStepsForm, useSelect, DeleteButton } from "@refinedev/mantine";
import { Button, Group, Radio, Select, Stepper, Tabs, Textarea, TextInput, TransferList } from "@mantine/core";

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";
import { isAffectrixClientId } from "utilities";

const user_filter_fields = [
  'authed_user_uid',
  {
    filter: [
      'id',
      'table',
      'title'
    ]
  }
]

export const UserEdit: React.FC = () => {

  const {
    data: {
      // @ts-ignore
        claims: {
          "x-hasura-default-role": role = '',
          "x-hasura-client-id": client_id = ''
        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  const go = useGo()

  useEffect( () => {
    if (role && role != 'admin'){
      console.log('unauthorized')
      go({to:'/'});
    }
  }, [role])

  const { id } = useResource();

  const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('admin/user');
  }

    const {
        saveButtonProps,
        refineCore: { queryResult, onFinish, formLoading, redirect },
        getInputProps,
        values,
        setFieldValue
    } = useForm< HttpError>({
        refineCoreProps: {
            resource: 'user',
            id,
            metaData: {
                fields: [
                  'id',
                  'displayName',
                  'email',
                  'role',
                  {
                    'metadata': [ 'lastSignInTime', 'lastRefreshTime', 'creationTime' ]
                  },
                  'disabled',
                  'client_id',
                  'representative_id'
                ]
            },
            onMutationSuccess,
            redirect: false
        },
        initialValues: {
            displayName: "",
            email: "",
            role: "",
            disabled: false,
            client_id: "",
            representative_id: null
        },
    });

    const { selectProps: clientSelectProps } = useSelect({
        resource: "clients",

        // defaultValue: queryResult?.data?.data.client_id,
        optionLabel: 'name',

        meta: {
          fields: ["id", "name"],
        },

        pagination: {
            mode: "server"
        },
        filters:[
          {
            operator: 'or',
            value: [
              {
                field: 'id',
                operator: 'null',
                value: !isAffectrixClientId(client_id)
              },
              {
                field: 'id',
                operator: 'eq',
                value: client_id
              }
            ]
          }
        ],
        queryOptions:{
          enabled: !!client_id
        }
    });

    const {
      data: {
        // @ts-ignore
        data: representativeLookup = []
      } = {}
    } = useList({
      resource: 'representative',
    
      meta:{
        fields: [ 'id', 'name'],
      },
    
      pagination: {
        pageSize: 1000
      }
    });

  const datePickerValue = (field:string) =>
        dayjs(values[field] as Date).isValid() ? dayjs(values[field] as Date).toDate() : undefined

  const mainSection = <>
    <TextInput
        mt={8}
        label="Display Name"
        placeholder="Display Name"
        {...getInputProps('displayName')}
        style={{ maxWidth: 300 }}
    />
    <TextInput
        mt={8}
        label="Email"
        placeholder="Email"
        {...getInputProps('email')}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="Role"
        placeholder="Pick One"
        {...getInputProps('role')}
        data={[
            { label: "User", value: "user" },
            { label: "Admin", value: "admin" },
        ]}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="Disabled?"
        placeholder="Pick One"
        {...getInputProps('disabled')}
        data={[
            { label: "Enabled", value: false as any },
            { label: "Disabled", value: true as any },
        ]}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="Client"
        placeholder="Client"
        {...getInputProps('client_id')}
        {...clientSelectProps}
        style={{ maxWidth: 300 }}
    />
  </>;
  
  const {
    steps: { 
      currentStep: currentStepOfRepresentativeCreate, 
      gotoStep: gotoStepOfRepresentativeCreate 
    },
    insertListItem: insertListItemOfRepresentativeCreate,
    removeListItem: removeListItemOfRepresentativeCreate,

    values: representativeCreateValues,
    getInputProps: representativeCreateGetInputProps,
    saveButtonProps: representativeCreateSaveButtonProps,
    reset: resetOfRepresentativeCreate,
    setFieldValue: setFieldValueUponRepresentativeCreate
} = useStepsForm({
  initialValues: {
    name: '',
    email: '',
    manager_email: ''
  },
  refineCoreProps: {
    action: 'create',
    redirect: false,
    resource: 'representative',
    metaData: {
      fields: [
        'id',
        'name'
      ]
    },
    onMutationSuccess({data}, variables, context, isAutoSave) {
        const { id: representative_id } = data;
        setFieldValue('representative_id', representative_id);
        setRepresentativeSectionValue('select');
    },
  }
})

const createRepresentativeForm = <>
  <br/>
  <Stepper
    active={currentStepOfRepresentativeCreate}
    onStepClick={gotoStepOfRepresentativeCreate}
  >
    <Stepper.Step
      label="First Step"
    >
      <TextInput
          mt={8}
          label="Name"
          withAsterisk
          placeholder="Name"
          {...representativeCreateGetInputProps('name')}
          style={{ maxWidth: 300 }}
      />
      <TextInput
          mt={8}
          label="Email"
          placeholder="Email"
          {...representativeCreateGetInputProps('email')}
          style={{ maxWidth: 300 }}
      />
      <TextInput
          mt={8}
          label="Manager Email"
          placeholder="Manager Email"
          {...representativeCreateGetInputProps('manager_email')}
          style={{ maxWidth: 300 }}
      />
      <Select 
        mt={8}
        label="Employment Type"
        {...representativeCreateGetInputProps('employment_type')}
        searchable={true}
        clearable={true}
        filterDataOnExactSearchMatch={true}
        data={[
          { value: '1099', label:'1099' },
          { value: 'W2', label:'W2' },
          { value: 'Manager', label:'Manager' },
        ]}
        style={{ maxWidth: 300 }}
      />
      <br/>
      <Button {...representativeCreateSaveButtonProps}>
        Save Representative
      </Button>
    </Stepper.Step>
  </Stepper>
</>;

const [ representativeSectionValue, setRepresentativeSectionValue ] = useState('select');
  const representativeSection = <>
    <Radio.Group
        value={representativeSectionValue}
        onChange={setRepresentativeSectionValue}
        label='Select OR Create a Provider'
      >
      <Group mt='xs'>
        <Radio value="select" label="Select Existing Representative" 
        // disabled={ghettoProvider != null}
        />
        <Radio value="create" label="Create New Representative" 
        // disabled={ghettoProvider != null}
        />
      </Group>
    </Radio.Group>
    { representativeSectionValue == 'select' ? <>
      <Select
          mt={8}
          label="Merge Representative into this User"
          placeholder="No rep merged into this User"
          {...getInputProps(`representative_id`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          data={representativeLookup?.map(({id,name}) => ({value:id, label:name})) as any}
          style={{ maxWidth: 300 }}
      />
    </> : createRepresentativeForm }
    
  </>

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <Tabs>
          <Tabs.List defaultValue='main'>
            <Tabs.Tab value='main'>Main</Tabs.Tab>
            <Tabs.Tab value='representative'>Representative</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value='main'>
            {mainSection}
          </Tabs.Panel>
          <Tabs.Panel value='representative'>
            {representativeSection}
          </Tabs.Panel>
          {/* <Tabs.Panel value='filters'> */}
              {/* {filters} */}
              {/* <pre>{JSON.stringify(userFilterLookup,null,2)}</pre>
              <pre>{JSON.stringify(filterLookup,null,2)}</pre> */}
              {/* <pre>{JSON.stringify(reducedInitialUserFilterValue,null,2)}</pre> */}
          {/* </Tabs.Panel> */}
        </Tabs>
        <br/>
        <br/>
        <pre>{JSON.stringify(values,null,2)}</pre>
      </Edit>
    );
};
