export const fields = [
	"id",
  "column_b",
  "column_d",
  "agreement",
	"patient_id",
	{
		patient: [
			'name',
      'name_and_dob',
      'added_to_collaborate',
			'date_of_birth',
      'date_of_surgery'
		]
	},

	"patient_status_id",
	{
		patient_status: ['title']
	},

  "product_title",
	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	"provider_id",
	{
		provider: [
			'name',
			'npi',
		],
	},
	
	"rx_date",
	"five_day_call",
	"five_day_call_status",
	"fourteen_day_call",
	"fourteen_day_call_status",
];

export const extendedFields = [];

export const resource = 'referral';