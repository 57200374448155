export const fields = [
	"id",
  "meta",
	"client_id",
  "chart_id",
  "chart_url",
  {
    "client": ['name', 'title']
  },
  // "date_of_surgery",
  "surgery",
  "initial_call_complete",
  "added_to_collaborate",
  "next_call_date",
  // "date_fit",
  // "condition",

  "patient_info",

  "name_and_dob",

  "date_of_birth",
  "name",

  "phone_numbers",
  "fax_number",
  "email",

  "addresses",

  "phone_numbers",

  'attorneys',

  'employers',

  'rep_communication_notes',
  'verification_notes'
];

export const fieldsOfExtension = [
  'id',
  'extended_to_date',
  'referral_id',
  {
    extension_status: [ 'title' ]
  },
  {
    insurance_override: [ 'title' ]
  },
  'rx_expiration_date'
];

export const fieldsOfSubmission = [
  'id', 'submission_type', 'items', 'date_shipped', 'tracking_number', 'fitter_id', {
    fitter: ['title']
  }, 'urgency', 'is_rental',
  'referral_id'
];

export const fieldsOfReferralHistory = [
  'created_at',
  'header',
  'field',
  'oldValue',
  'newValue',
  'authed_user_id',
  {
    'authed_user': [
      'photoURL',
      'display_name'
    ]
  }
];

// TODO import fields from referral-detail-view
export const fieldsOfReferral = [
	"id",
	"column_b",
	"column_d",

  'old_ver_notes',
  'old_rep_com_notes',

  'agreement',
  'do_not_mz_rs',  // TODO add to referral view
  'verification_notes_color',
  'product_id',

  'pickup_date',

  'added_to_collaborate',
  'date_of_surgery',
  'condition',

  'aw_ver',

  'follow_up_call',
  'added_to_collaborate',

  'extension_settings',
  'date_of_historical_categorization',
  'historical_categorization',

  {
    product: [
      'id',
      'serial_number',
      'referral_id',
    ]
  },

  'attorney',
  'employer',
  'date_of_injury',

  'pickup_status_id',

  'updated_at',
  'updated_by',
  {
    authed_user_who_updated: [
      'display_name', 'photoURL'
    ]
  },

  'document_status_id',
  {
    'document_status': [
      'title'
    ]
  },

  {
    client: [
      'name'
    ]
  },

  'product_title',

	"patient_id",

  {
    "submissions": fieldsOfSubmission
  },

	"patient_status_id",
	{
		patient_status: ['title']
	},

	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},
  "bill_to_insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	{
		reauth_status: [ 'title' ]
	},

	"provider_id",
	{
		provider: [
			'name',
			'npi',
			// TODO contact info
			{
				provider_representatives: [ 
					{
						representative: [
							'name'
						]
					}
				]
			}
		],
	},
  "billing_provider_id",
  {
    billing_provider: [
      'name',
      'npi'
    ]
  },

	{
		extensions: fieldsOfExtension
	},
  "resupply_settings",
	{
		resupplies: [
			'id',
			'resupply_date',
		]
	},
  'rx_length_id',
  {
    'rx_length': [ 'title' ]
  },
	'resupply_items',

	"injury_sites",
  "injury_sites_old",
	"icd10_codes",

	"survey_complete",
	
	"rx_date",
  "rx_received_date",

	"rx_expiration_date",

  "next_call_date",

	"date_fit",
	"five_day_call",
	"five_day_call_status",
	"fourteen_day_call",
	"fourteen_day_call_status",
	"equipment_id",
  "automated_email_status",
  {
    referral_histories: fieldsOfReferralHistory
  },
  "autocrat_job_status",
  "autocrat_job_results"
];

export const fieldsOfPatientHistory = [
  'created_at',
  'header',
  'field',
  'oldValue',
  'newValue',
  'authed_user_id',
  {
    'authed_user': [
      'photoURL',
      'display_name'
    ]
  }
];

export const extended = [
  {
    "referrals": fieldsOfReferral
  },
  {
    "patient_histories": fieldsOfPatientHistory
  },
  {
    "patient_notes": [
      {
        "note": [
          'id',
          'content',
          {
            "authed_user": [ 'id', 'display_name', 'photoURL' ]
          },
          'metadata',
          "created_at"
        ]
      }
    ]
  },
  "answer_sets"
]

export const resource = 'patient'