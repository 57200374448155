import React, { useState, useContext } from "react";
import { TextInput, Menu, ActionIcon, Stack, Group } from "@mantine/core";
import { IconFilter, IconX, IconCheck } from "@tabler/icons";
import { Column } from "@tanstack/react-table";
import { UserContext } from '../../contexts/userContext';

export const ColumnFilter: React.FC<{ column: Column<any, any> }> = ({
    column,
}) => {
    const [state, setState] = useState(null as null | { value: any });

    // console.log(column.columnDef?.meta);
    // // @ts-ignore
    // const { userState, setUserState } = useContext(UserContext);

    if (!column.getCanFilter()) {
        return null;
    }

    const open = () =>
        setState({
            value: column.getFilterValue(),
        });

    const close = () => setState(null);

    const change = (value: any) => setState({ value });

    const clear = () => {
        column.setFilterValue(undefined);
        close();
    };

    const save = () => {
        if (!state) return;
        column.setFilterValue(state.value);
        close();
    };

    const renderFilterElement = () => {
        const FilterComponent = (column.columnDef?.meta as any)?.filterElement;

        if (!FilterComponent && !!state) {
            return (
                <TextInput
                    autoComplete="off"
                    autofocus
                    // @ts-ignore
                    placeholder={column?.columnDef?.header}
                    value={state.value}
                    onChange={(e) => change(e.target.value)}
                    onKeyUp={(e) => {
                      if (e.key == 'Enter'){
                        save()
                      }
                    }}
                />
            );
        }

        return <FilterComponent value={state?.value} onChange={change} />;
    };

    return (
      <>
        <Menu
            opened={!!state}
            position="bottom"
            withArrow
            transition="scale-y"
            shadow="xl"
            onClose={close}
            width="256px"
            withinPortal
        >
            <Menu.Target>
                <ActionIcon
                    size="xs"
                    onClick={open}
                    variant={column.getIsFiltered() ? "light" : "transparent"}
                    color={column.getIsFiltered() ? "primary" : "gray"}
                >
                    <IconFilter size={18} />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                {!!state && (
                    <Stack p="xs" spacing="xs">
                        {renderFilterElement()}
                    </Stack>
                )}
            </Menu.Dropdown>
        </Menu>
        {column.getIsFiltered() && 
          <ActionIcon
              size="md"
              color="gray"
              variant="outline"
              onClick={clear}
          >
              <IconX size={18} />
          </ActionIcon>}
        {/* {state?.value} */}
      </>
    );
};