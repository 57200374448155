import { initializeApp } from "@firebase/app";
import { FirebaseAuth } from "providers/firebase/auth";
import { client } from "dataProviders/hasura"

const firebaseConfig = {

  apiKey: "AIzaSyDqN43CsOLtTacBGuSn9g_KNCsJkrJvpLw",

  authDomain: "boxwood-veld-339521.firebaseapp.com",

  projectId: "boxwood-veld-339521",

  storageBucket: "boxwood-veld-339521.appspot.com",

  messagingSenderId: "341456183910",

  appId: "1:341456183910:web:115e3fb6d02b728518c3a7"

};

export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = new FirebaseAuth({client});

export const firebaseAuthProvider = firebaseAuth.getAuthProvider()