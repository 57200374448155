import React, { useContext, useEffect } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { DateField } from "@refinedev/mantine";

import {
//

Box, Group, // List,
ScrollArea, Table, Pagination, Select, Textarea
} from "@mantine/core";

import dayjs from "dayjs";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';

import { fields, resource } from "./common";
import { useGetIdentity, useList, useGo} from "@refinedev/core";
import { isAffectrixClientId } from "utilities";
import { initial } from "lodash";

function renderDate({ getValue }:any) {
  return dayjs(getValue()).isValid() ? (
      <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
      <>N/A</>
  );
}

const columns: ColumnDef<any>[] = [
  { accessorKey: 'referral.patient.name_and_dob', id:'referral.patient.name_and_dob', header: 'Name', enableColumnFilter: true, enableSorting: false, meta: {
    filterOperator: 'contains'
  }},
  { accessorKey: 'extended_to_date', id: 'extended_to_date', header: 'EXT DATE', enableColumnFilter: false, enableSorting: false, cell: renderDate,},
  { accessorFn: (row) => row?.extension_status?.title, id: 'extension_status.title', header: 'STATUS', enableColumnFilter: false, enableSorting: false,},
  { accessorFn: (row) => row?.extension_status?.code, id: 'extension_status.code', header: 'CODE', enableColumnFilter: false, enableSorting: false,},
  // { accessorFn: (row) => {}, id:'insurance', header: 'Insurance', enableColumnFilter: false, enableSorting: false},
//   { accessorKey: 'rx_expiration_date', id:'rx_expiration_date', header: 'Rx EXP', enableColumnFilter: false, enableSorting: false, cell: renderDate,},
  { accessorFn: (row) => row?.referral?.reauth_status?.title, id:'reauth_lookup.title', header: 'RE-AUTH', enableColumnFilter: false, enableSorting: false,},
  { accessorFn: (row) => row?.referral?.fourteen_day_call, id:'referral.fourteen_day_call', header: '14 DAY CALL', enableColumnFilter: false, enableSorting: false, cell: renderDate},
];

const ghettoDataFilters = [
    {
        title: 'RED (within 5 days)',
        filter: [
            {
                field: 'extended_to_date',
                operator: 'gte',
                value: dayjs().subtract(5, 'day').format('YYYY-MM-DD')
            },
            {
              field: "extension_status.code",
              operator: 'niregex',
              value: 'C|B',
            }
        ],
    },
    {
        title: 'ORANGE (within 14 days)',
        filter: [
            {
                field: 'extended_to_date',
                operator: 'gte',
                value: dayjs().subtract(14, 'day').format('YYYY-MM-DD')
            },
            {
              field: "extension_status.code",
              operator: 'niregex',
              value: 'C|B',
            }
        ]
    },
    {
      title: 'COMM RA',
      filter: [
        {
          field: 'extension_status.code',
          operator: 'eq',
          value: 'C'
        },
        {
          field: 'referral.reauth_status.title',
          operator: 'eq',
          value: 'COMM/YES'
        }
      ]
    },
    {
      title: 'RX EXT - TO BE BILLED',
      filter: [
        // {
        //   //TODO
        //   field: 'referral.rx_expiration_date_color',
              // value: 'light purple'
        // }
        {
          operator: 'or',
          value: [
            {
              operator: 'and',
              value: [
                {
                  field: 'extension_status.code',
                  operator: 'iregex',
                  value: 'G|LG'
                },
                {
                  field: 'referral.reauth_status.title',
                  operator: 'contains',
                  value: 'YES'
                }
              ]
            },
            {
              field: 'extension_status.code',
              operator: 'contains',
              value: 'C'
            }
          ]
        },
      ]
    },
    {
      title: 'BLANK RE-AUTH',
      // TODO figure out what to do
      filter: [
        {
          // maybe referral.patient.equipment_status.title
          // field: 'equipment_status.title',
          field: 'referral.patient.products.equipment_status.title',
          operator: 'iregex',
          value: '1.1|1.2|1.3|3.1'
        },
        {
          field: 'referral.reauth_status_id',
          operator: 'null',
          value: true
        }
      ]
    },
    {
      title: 'AUTO RE-AUTH',
      filter: [
        {
          field: 'extension_status.code',
          operator: 'iregex',
          value: 'C|R'
        },
        {
          field: 'referral.reauth_status.title',
          operator: 'eq',
          value: 'AUTO/YES'
        }
      ]
    },
    {
      title: 'EXTENSION RECEIVED (C)',
      filter: [
        {
          field: 'extension_status.code',
          operator: 'eq',
          value: 'C'
        },
        {
          field: 'referral.reauth_status.title',
          operator: 'eq',
          value: 'WC/YES'
        }
      ]
    },
    {
      title: 'COMPLETE',
      filter: [
        {
          field: 'extension_status.code',
          operator: 'eq',
          value: 'C'
        }
      ]
    },
    {
      title: 'NMES RE-AUTH',
      filter: [
        {
          field: 'product_type.title',
          operator: 'eq',
          value: 'NMES'
        },
        {
          field: 'extension_status.code',
          operator: 'eq',
          value: 'C'
        }
      ]
    },
].map( x => {
  let { filter } = x;
  filter.push({
    field: 'active',
    operator: 'eq',
    // @ts-ignore
    value: true
  });
  return {
    ...x,
    filter
  }
});

function RadioSelect(props:any) {

    let {
      setFilters,
      user_filters = [],
      filters = [],
      uid,
      currentUserState,
      setCurrentUserState
    //   tableFilters,
    } = props;

    // // @ts-ignore
    // const maybeFiltersForThisUser = filters.filter( x => {
    //   const {
    //     table
    //   } = x;
    //   return table == 'extension'
    // })
    // // @ts-ignore
    // .some( filter => {
    //   const { user_filters } = filter;
    //   // @ts-ignore
    //   const userHasThisFilter = user_filters.some( user_filter => {
    //     const { authed_user: {id}} = user_filter;
    //     return id == uid
    //   })

    //   return userHasThisFilter
    // })

    // user_filters <~> filters
    const mappedFilters = filters
    // @ts-ignore
    // .filter( x => {
    //   const {
    //     table
    //   } = x;
    //   return table == 'extension'
    // })
    // @ts-ignore
    // .filter( filter => {
    //   // const noFiltersAssignedToThisUser = !maybeFiltersForThisUser;
    //   // if (noFiltersAssignedToThisUser){
    //   //   return true
    //   // }

    //   const { user_filters } = filter;
    //   // @ts-ignore
    //   const userHasThisFilter = user_filters.some( user_filter => {
    //     const { authed_user: {id}} = user_filter;
    //     return id == uid
    //   })

    //   return userHasThisFilter
    // })
    .map( (x:any) => {
        const {
            // filter:{
                // value,
                title,
                filter
            // }
        } = x
        return {
            value: title,
            label: title,
            active_filter: filter
        }
    })
    // @ts-ignore
    .sort( (a,b) => {
      const {
        label: la
      } = a;
      const {
        label: lb
      } = b;
      return la > lb ? 1 : -1
    })

    const [value, setValue] = React.useState(null);

    // @ts-ignore
    let clientFilter = [];
    if (currentUserState?.clientId){
      console.log(currentUserState.clientId)
      clientFilter = [
        {
          field: 'referral.client_id',
          operator: 'eq',
          value: currentUserState.clientId
        }
      ]
    }

    const handleChange = (selectValue:any) => {

        setValue(selectValue);

        if (!selectValue){
            // @ts-ignore
            setFilters([...clientFilter], 'replace');

            setCurrentUserState({
              ...currentUserState,
              extensionFilter: undefined
            })
        }

        const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

        if (thing.length == 1){

            const [ {active_filter} ] = thing

            setFilters([
              ...active_filter,
              // @ts-ignore
              ...clientFilter
            ], 'replace' );

            setCurrentUserState({
              ...currentUserState,
              extensionFilter: {
                title: selectValue,
                active_filter,
              }
            })
        } else {
            
        }

    };

    return (
        <>
            <Select defaultValue={currentUserState?.extensionFilter?.title}
            label='Select a filter'
            data={mappedFilters} onChange={handleChange} searchable clearable style={{ maxWidth: 300 }}>
                
            </Select>
        </>
    );
  }

export const ExtensionList: React.FC = () => {

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

    const go = useGo();

    const {
      data: {
        // @ts-ignore

          claims: {
          "x-hasura-user-id": uid = '',
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
        setCurrent,
        setFilters,
        filters,
        pageCount, 
        current,
        tableQueryResult: { data: tableData },
      },
  } = useTable({
    columns,
    
    refineCoreProps: {
      // initialPageSize: 10,
      resource,
      sorters: {
        initial: [
          {
            field: "extended_to_date",
            order: 'asc'
          }
        ]
      },
      metaData: {
        fields
      },
      queryOptions: {
        // disable query if the user belongs to the AFX client and the client of the query is undefined
        enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
      },
      pagination: {
        pageSize: 50
      },
    }
  });

  let {
    data: {
        // @ts-ignore
        data: dataFilters = []
    } = {}
} = useList({
    resource: 'filter',

    meta: {
        fields: [
            'title',
            'filter',
            'table',
            'transform',
            {
              user_filters: [
                {
                  authed_user: [
                    'id', 'email', 'display_name'
                  ]
                }
              ]
            }
        ],
    },

    filters: [
      {
        field: 'table',
        operator: 'eq',
        value: 'extension'
      }
    ],

    pagination:{
        pageSize: 100
    }
  })

  // @ts-ignore
  const newdataFilters = dataFilters.map( original => {
    const matches = ghettoDataFilters.filter( x => x.title == original.title);
    if (matches.length ==1){
      const [match] = matches;
      return {
        ...original,
        ...match
      }
    }
    return original
  })

  useEffect( () => {
    // @ts-ignore
    let filters = [];
    if (userState?.extensionFilter?.active_filter){
      // setFilters(userState.extensionFilter.active_filter, 'replace');
      filters = [...userState?.extensionFilter?.active_filter];
    }
    if (userState?.clientId){
      filters = [
        // @ts-ignore
        ...filters,
        {
          field: 'referral.client_id',
          operator: 'eq',
          value: userState.clientId
        }
      ]
    }

    // @ts-ignore
    setFilters(filters, 'replace');
  }, [userState])

  // console.log(newdataFilters)

  return (
    <ScrollArea>
      <List>
          <RadioSelect 
            setFilters={setFilters} 
            // user_filters={user_filters} 
            uid={uid}
            // tableFilters={filters}
            filters={ghettoDataFilters}

            currentUserState={userState}
            setCurrentUserState={setUserState}
            ></RadioSelect>
          {/* <RadioSelect setFilters={setFilters} user_filters={user_filters} uid={uid}></RadioSelect> */}
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                              return (
                                  <th key={header.id}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {
                    const patient_id = row.original?.referral?.patient?.id;
                      return (
                          <tr key={row.id} onClick={() => go({
                            to: `edit/${row.original.id}`,
                            //  to: `/directory/patient/edit/${patient_id}`,
                            // query: {
                            //   extension: row.original.id,
                            //   view: 'extension'
                            // }
                          })}>
                              {row.getVisibleCells().map((cell) => {
                                  return (
                                      <td key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
}