import { createContext, useState, useEffect } from "react";

export const UserContext = createContext({});

export const UserProvider = (props: any) => {

  const [ userState, setUserState ] = useState(() => {
    try {
      let local = localStorage.getItem('userState');
      let initialUserState = JSON.parse(local as string);
      return initialUserState;
    } catch(e){
      console.log(e);
      return {}
    }
  });

  // setter
  useEffect( () => {
    // @ts-ignore
    localStorage.setItem('userState', JSON.stringify(userState))
  }, [userState])

  return <UserContext.Provider value={{userState, setUserState}} {...props} />;
}