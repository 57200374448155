import { firebaseAuthProvider } from "configuration/firebase";

type CanParams = {
  resource?: string;
  action: string;
  params?: any;
};

type CanReturnType = {
  can: boolean;
  reason?: string;
}

export default {
  can: async ({ resource, action, params }: CanParams) => {

    const {
      "x-hasura-default-role" : role
      // @ts-ignore
    } = firebaseAuthProvider.getClaims();

    if (resource?.includes('admin') && role != 'admin'){
      return {
        can: false,
        reason: "Unauthorized",
      };
    }

    // or you can access directly *resource object
    // const resourceName = params?.resource?.name;
    // const anyUsefulOption = params?.resource?.options?.yourUsefulOption;
    // if (resourceName === "posts" && anyUsefulOption === true && action === "edit") {
    //     return Promise.resolve({
    //         can: false,
    //         reason: "Unauthorized",
    //     });
    // }

    return { can: true };
  },
}