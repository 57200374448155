
import { GraphQLClient } from "@refinedev/hasura";

import dataProvider from 'ghetto/providers/hasura';

import { liveProvider } from "@refinedev/hasura";

const API_URL = "https://hasura-0.test0.affectodme.com/v1/graphql";
// const API_URL = 'http://localhost:8080/v1/graphql'
export const client = new GraphQLClient(API_URL);
// client.setHeaders({
//   "x-hasura-admin-secret": "myadminsecretkey"
// })  

const resourcesByUUID = [
  'filter',
  'survey',
  'product',
  'product_status',
  'resupplies',
  'fitter',
  'submission',
  'claim_type'
]

export const hasuraClient = dataProvider(client,{
  idType: (resource) => {

    if (resourcesByUUID.includes(resource)){
      return 'uuid'
    }

    return "String"
  }
});