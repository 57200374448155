export const provider_representatives = [
  "id",
  "provider_id",
  "representative_id",
  {
    representative: [ 'name', 'rep' ]
  }
];

export const fields = [
	"id",
  "name",
  "npi",
  "practice_name",
  "specialty",
  "client_id",
  {
    client: ["name"]
  },

  "office_contact",
  "survey_direct_send",
  'ext_direct_send',
  "survey_link",

  "phone_numbers",

  "fax_numbers",
  "emails",
  "addresses",

  "contacts",

  {
    provider_representatives
  },
  {
    referrals: [
      'id',
      {
        "product_type": [ 'title' ]
      },
      {
        "rx_length": [ 'title' ]
      },
      {
        "insurance": [ 'title' ]
      },
      {
        "claim_type": [ 'title' ]
      },
      {
        "insurance_status": [ 'title' ]
      },
      {
        "patient_status": [ 'title' ]
      },
    ]
  }
  // 'last_referral' //could compute this as an aggregate that returns
];

export const resource = 'provider';