import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ReferralListForReps, ReferralShow } from "pages/representative";

export const representative = <Route
  path='rep'
>
  {/* <Route
    index
    element={<ReferralListForReps/>}
  /> */}
  <Route
    path='verification'
    element={<ReferralListForReps/>}
  />
  <Route
    path='incomplete'
    element={<ReferralListForReps/>}
  />
  <Route
    path='fitComplete'
    element={<ReferralListForReps/>}
  />
  <Route
    path='deadDeal'
    element={<ReferralListForReps/>}
  />
  <Route
    path='all'
    element={<ReferralListForReps/>}
  />
  <Route
    path='referralView/:id'
    element={<ReferralShow/>}
  />
</Route>