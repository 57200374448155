export const fields = [
  'id',
  'submission_type',
  'urgency',
  'date_shipped',
  'tracking_number',
  'serial_number',
  'shipper',
  'items',
  'received',
  'location_id',
  'run',
  'fitter_id',
  {
    'fitter': ['id','title']
  },
  {
    'referral': [
      'id',
      'date_fit',
      {
        'claim_type': [ 'title' ]
      },
      {
        'patient': [ 'name_and_dob', 'patient_info' ]
      },
      'product_type_id',
      {
        'product_type': [ 'title' ]
      }
    ]
  },
  'product_id',
  {
    'product': [ 'serial_number' ]
  },
];

export const extended_fields = [
  'id',
  'submission_type',
  'urgency',
  'is_rental',
  'date_shipped',
  'tracking_number',
  'serial_number',
  'shipper',
  'items',
  'received',
  'location_id',
  'run',
  'fitter_id',
  {
    'fitter': ['id','title']
  },
  'referral_id',
  {
    'referral': [
      'id',
      'date_fit',
      {
        'claim_type': [ 'title' ]
      },
      'patient_id',
      {
        'patient': [
          'id',
          'name_and_dob', 
          'patient_info',
          {
            "patient_notes": [
              {
                "note": [
                  'id',
                  'content',
                  {
                    "authed_user": [ 'id', 'display_name', 'photoURL' ]
                  },
                  'metadata',
                  "created_at"
                ]
              }
            ]
          },
          'answer_sets'
        ]
      },
      'product_type_id',
      {
        'product_type': [ 'title' ]
      }
    ]
  },
  'product_id',
  {
    'product': [ 'serial_number' ]
  },
  'autocrat_job_status',
  'autocrat_job_results'
]

export const resource = 'submission'