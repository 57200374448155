// @ts -nocheck
import { ColumnDef, flexRender } from "@tanstack/react-table";
import dayjs from "dayjs";
import { DateField } from "@refinedev/mantine";
import { useList, useGetIdentity, useGo, useCustom, useResource } from "@refinedev/core";
import { useTable } from "@refinedev/react-table";
import { Box, Grid, Group, Pagination, ScrollArea, Select, Table } from "@mantine/core";
import { List } from "components/ghettoList";
import { ColumnFilter, ColumnSorter } from "components";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "contexts/userContext";

const fields = [
  "id",
	"column_b",
	"column_d",

  "rx_date",
  "rx_received_date",

  'agreement',

  {
    client: [
      'name'
    ]
  },

  'product_title',

	"patient_id",
  {
		patient: [
			'name',
			'date_of_birth',
      'name_and_dob',

      'added_to_collaborate',

      "initial_call_complete",
      "date_of_surgery",
      "surgery",
      "next_call_date",
      // "date_fit",
      "condition",
		]
	},
  {
    provider: [
      'id',
      'name'
    ]
  },

  "patient_status_id",
	{
		patient_status: ['title']
	},

	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	{
		reauth_status: [ 'title' ]
	},
];

function renderDate(thing:any) {
  const { getValue } = thing
  return getValue() && dayjs(getValue()).isValid() ? (
      <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
      <>N/A</>
  );
}

const columns: ColumnDef<any>[] = [
  { 
    accessorKey: 'patient.name_and_dob', id:'patient.name_and_dob', header: 'Name', enableColumnFilter: true, enableSorting: true, 
    meta: {
      filterOperator: 'contains'
    }
  },
  { 
    accessorKey: 'provider.name', id:'provider.name', header: 'Provider', enableColumnFilter: true, enableSorting: false, 
    meta: {
      filterOperator: 'contains'
    },
    accessorFn: (row) => {
      return row?.provider?.name
    },
  },
  { 
    accessorFn: (row) => {
        return row?.patient_status?.title
    },
    // accessorKey: 'patient_status.title', 
    id:'patient_status.title', header: 'Patient Status', enableColumnFilter: false, enableSorting: true
  },
  { 
    accessorFn: (row) => {
        return row?.insurance_status?.title
    },
    accessorKey: 'insurance_status.title',
    meta: {
        filterOperator: 'contains'
    },
    id:'insurance_status.title', header: 'Insurance Status', enableColumnFilter: true, enableSorting: true
  },
  {
    accessorKey: "rx_received_date",
    id: "rx_received_date",
    header: "REC'D",
      // minSize: 220,
      // size: 350,
    cell: renderDate,
    enableColumnFilter: false,
    enableSorting: true,
  },
  {
    accessorKey: "date_of_surgery",
    id: "date_of_surgery",
    header: "DOS",
      // minWidth: 220,
    accessorFn: (row) => {
      let dos = row?.patient?.date_of_surgery;
      if (dos && dayjs(dos).isValid() ){
        dos = dayjs(dos).format('MM-DD-YY');
      }
      const condition = row?.patient?.condition;
      return dos || condition || 'N/A'
    },
    // cell: renderDate,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    id: 'product_type.title', header: 'Product', enableSorting:false, meta: {filterOperator: 'contains'},
    accessorFn: (row) => {
      return row?.product_type?.title
    },
  },
  { 
    accessorFn: (row) => {
        return row?.claim_type?.title
    },
    meta: {
        filterOperator: 'contains'
    },
      id:'claim_type.title', header: 'Claim Type', enableColumnFilter: true, enableSorting: false
  },
];

function RepSelect(props:any) {
  const {
    setSelectedRep,
    selectedRep,
    reps,
    representative,
    defaultValue
  } = props;

  const name = representative?.name;

  // @ts-ignore
  const { setUserState } = useContext(UserContext);

  const handleChange = (selectedValue:any) => {

    if (selectedValue){
      setSelectedRep(selectedValue);
    }
    setUserState((prev:any) => ({
      ...prev,
      selectedRepId: selectedValue
    }))
    
  }

  if ([
    'ROYLE (M1-11)', 
    // 'MCLAUGHLIN (M1-85)'
    'CHAZ (M1-21)'
  ].includes(name)){
    const [
      royleSelect = [],
      mcSelect = []
    ] = [
      'ROYLE (M1-11)',
      // 'MCLAUGHLIN (M1-85)'
      'CHAZ (M1-21)'
    ].map( manager => {
      const matches = reps.filter( (rep:any) => rep.name == manager);
      if (matches.length == 1){
        const [{query_these_ids}] = matches;
        const selectData = query_these_ids.map( (id: any) => {
          const repMatches = reps.filter( (rep:any) => {
            const rep_id = rep?.id;
            return rep_id == id
          });
          if (repMatches.length == 1){
            const repMatch = repMatches[0];
            const value = repMatch?.id;
            const label = repMatch?.name;
            return { value, label }
          }
          return undefined
        // @ts-ignore
        }).filter( x => x)
        // @ts-ignore
        .sort((a,b) => {
          const { label: aL } = a;
          const { label: bL } = b;
          if ( aL < bL ) return -1;
          if ( aL > bL ) return 1;
          return 0;
        });
        return selectData
      } else {
        // console.warn('manager select somehow broke');
      }
    });

    const Royle = <Grid.Col span={3}>
      <Select
        label='Team Royle'
        data={royleSelect}
        onChange={handleChange}
        defaultValue={defaultValue}
        searchable clearable
        style={{ maxWidth: 300 }}
      />
    </Grid.Col>;

    const Chaz = <Grid.Col span={3}>
      <Select
        label="Team Chaz"
        data={mcSelect}
        onChange={handleChange}
        defaultValue={defaultValue}
        searchable clearable
        style={{ maxWidth: 300 }}
      />
    </Grid.Col>;

    return name == 'ROYLE (M1-11)' ? <Grid>
      {Royle}
      {Chaz}
    </Grid>
    : <Grid>
      {Chaz}
      {Royle}
    </Grid>
  }

  const possibleIds = representative?.query_these_ids || [];

  if (possibleIds.length > 1){
    const selectData = possibleIds.map( (id: any) => {
      const repMatches = reps.filter( (rep:any) => {
        const rep_id = rep?.id;
        return rep_id == id
      });
      if (repMatches.length == 1){
        const repMatch = repMatches[0];
        const value = repMatch?.id;
        const label = repMatch?.name;
        return { value, label }
      }
      return undefined
    // @ts-ignore
    }).filter( x => x);
  
    return <Select
      label='Select a Rep'
      data={selectData}
      onChange={handleChange}
      defaultValue={defaultValue}
      searchable clearable
      style={{ maxWidth: 300 }}
    >
    </Select>
  }

  return null
  
}

export const ReferralListForReps = () => {

  const { resource } = useResource();

  // @ts-ignore
  const { name: nameOfResource } = resource;

  const go = useGo();

  // @ts-ignore
  const { userState } = useContext(UserContext);

  const {
    data: {
      // @ts-ignore
      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {},
      // @ts-ignore
      authed_user = {}
    } = {},
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  const {
    data: {
      data: repList = []
    } = {}
  } = useList({
    resource: 'representative',
    meta: {
      fields: [
        'id',
        'name',
        'query_these_ids'
      ],
      
      filters: [
        {
          field: 'client_id',
          operator: 'eq',
          value: clientId
        }
      ],
    },
    queryOptions: {
      enabled: !!clientId
    },
    pagination: {
      pageSize: 1000
    }
  });

  // given the rep_id of the associated authed_user, return a select and ids

  const representative_id = authed_user?.representative?.id;
  const representative = authed_user?.representative;

  const defaultValue = userState?.selectedRepId || representative_id;

  const [ selectedRep, setSelectedRep ] = useState(null);
  const [ shouldQuery, setShouldQuery ] = useState(false);

  const {
    // @ts-ignore

    getHeaderGroups, getRowModel, refineCore: { 
        setCurrent,
        setFilters,
        filters,
        pageCount,
        current,
        tableQueryResult: { 
          data: tableData,
          refetch
        },
      },
  } = useTable({
    //
    columns,

    refineCoreProps: {
      initialPageSize: 50,
      resource: 'referral',

      metaData: {
        fields,
      },
      queryOptions: {
        enabled: shouldQuery,
        staleTime: 500
      },
      sorters: {
        initial: [
          {
            field: 'patient_status.title',
            order: 'asc'
          },
          {
            field: 'insurance_status.title',
            order: 'asc'
          },
          {
            field: 'patient.name_and_dob',
            order: 'asc'
          },
          {
            field: 'rx_received_date',
            order: 'desc'
          },
        ]
      }
    },

  });

  useEffect( () => {
    let filtersToSet: any[] = [];
    if (defaultValue && !selectedRep){
      filtersToSet.push({
        field: 'provider.provider_representatives.representative_id',
        operator: 'eq',
        value: defaultValue
      });
    } else if (selectedRep){
      filtersToSet.push({
        field: 'provider.provider_representatives.representative_id',
        operator: 'eq',
        value: selectedRep
      })
    }

    if (nameOfResource && (!!defaultValue || !!selectedRep)){
      if (nameOfResource == 'verification'){
        filtersToSet.push({
          field: 'patient_status.title',
          operator: 'iregex',
          // (1.1) URGENT|(4.1) PENDING|(7.3) SCHEDULED - VIRTUAL|(7.4) SCHEDULED - IN HOME|(7.5) SHIPPED|READY TO FIT
          // value: '(1.1)|(4.1)|(7.3)|(7.4)|(7.5)|READY TO FIT',
          value: 'URGENT|PENDING|SCHEDULED - VIRTUAL|SCHEDULED - IN HOME|SHIPPED|READY TO FIT'
        });
      } else if (nameOfResource == 'incomplete'){
        filtersToSet.push({
          // field: 'patient_status.title',
          // operator: 'iregex',
          // // (5.1) INCOMPLETE|(5.2) INCOMPLETE: NEED MD/DO RX|(5.3) INCOMPLETE: NEED ATTY LIEN
          // // value: '(5.1)|(5.2)|(5.3)',
          // value: 'INCOMPLETE|INCOMPLETE: NEED MD/DO RX|INCOMPLETE: NEED ATTY LIEN'
          operator: 'or',
          value: [
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE: NEED MD/DO RX"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE: NEED ATTY LIEN"
            },
          ]
        })
      } else if (nameOfResource == 'fitComplete'){
        filtersToSet.push({
          // field: 'patient_status.title',
          // operator: 'iregex',
          // // (8.1) FIT|FIT/INCOMPLETE|RETURNED|PATIENT DEMO|CLINIC DEMO
          // // PATIENT DEMO => PATIENT DEMO|PATIENT DEMO - KID|PATIENT DEMO - EMPLOYEE|PATIENT DEMO - SHIPPED
          // value: '(8.1) FIT|FIT/INCOMPLETE|RETURNED|PATIENT DEMO|CLINIC DEMO'
          operator: 'or',
          value: [
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "FIT"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "FIT/INCOMPLETE"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "RETURNED"
            },
            {
              field: "patient_status.title",
              operator: 'iregex',
              value: "PATIENT DEMO|CLINIC DEMO"
            },
          ]
        })
      } else if (nameOfResource == 'deadDeal'){
        filtersToSet.push({
          field: 'patient_status.title',
          operator: 'iregex',
          // (9.1) DEAD DEAL - INCOMPLETE|(9.2) DEAD DEAL - PATIENT DECLINED|(9.3) DEAD DEAL - UNREACHBLE|(9.4) DEAD DEAL - INSURANCE|(9.5) DEAD DEAL - PROVIDER / REP|(9.6) DEAD DEAL - MEDICAL
          value: 'DEAD DEAL - INCOMPLETE|DEAD DEAL - PATIENT DECLINED|DEAD DEAL - UNREACHBLE|DEAD DEAL - INSURANCE|DEAD DEAL - PROVIDER / REP|DEAD DEAL - MEDICAL'
        })
      } else if (nameOfResource == 'all'){
        filtersToSet.push({
          operator: 'or',
          value: [
            {
              field: 'patient_status.title',
              operator: 'iregex',
              value: 'URGENT|PENDING|SCHEDULED - VIRTUAL|SCHEDULED - IN HOME|SHIPPED|READY TO FIT'
            },

            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE: NEED MD/DO RX"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "INCOMPLETE: NEED ATTY LIEN"
            },

            {
              field: "patient_status.title",
              operator: 'eq',
              value: "FIT"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "FIT/INCOMPLETE"
            },
            {
              field: "patient_status.title",
              operator: 'eq',
              value: "RETURNED"
            },
            {
              field: "patient_status.title",
              operator: 'iregex',
              value: "PATIENT DEMO|CLINIC DEMO"
            },

            {
              field: 'patient_status.title',
              operator: 'iregex',
              // (9.1) DEAD DEAL - INCOMPLETE|(9.2) DEAD DEAL - PATIENT DECLINED|(9.3) DEAD DEAL - UNREACHBLE|(9.4) DEAD DEAL - INSURANCE|(9.5) DEAD DEAL - PROVIDER / REP|(9.6) DEAD DEAL - MEDICAL
              value: 'DEAD DEAL - INCOMPLETE|DEAD DEAL - PATIENT DECLINED|DEAD DEAL - UNREACHBLE|DEAD DEAL - INSURANCE|DEAD DEAL - PROVIDER / REP|DEAD DEAL - MEDICAL'
            },
          ]
        })
      }else {
        console.warn(`nameOfResource, '${nameOfResource}', not implemented.`)
      }
      if (filtersToSet.length > 0){
        setFilters(filtersToSet, 'replace');
        setShouldQuery(true)
      }
    }
  }, [ nameOfResource, defaultValue, selectedRep ]);

  console.log(userState?.selectedRepId)

  return <ScrollArea>
    
    <List title="">
        <RepSelect
          reps={repList}
          representative={representative}
          selectedRep={selectedRep}
          setSelectedRep={setSelectedRep}
          defaultValue={defaultValue}
        />
        <Table highlightOnHover>
            <thead>
                {getHeaderGroups().map((headerGroup:any) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header:any) => {
                            return (
                                <th key={header.id}>
                                    {!header.isPlaceholder && (
                                        <Group spacing="xs" noWrap>
                                            <Box>
                                                {flexRender(
                                                    header.column
                                                        .columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                            </Box>
                                            <Group spacing="xs" noWrap>
                                                <ColumnSorter
                                                    column={
                                                        header.column
                                                    }
                                                />
                                                <ColumnFilter
                                                    column={
                                                        header.column
                                                    }
                                                />
                                            </Group>
                                        </Group>
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {getRowModel().rows.length > 0 ? getRowModel().rows.map((row:any) => {
                    return (
                        <tr key={row.id} onClick={() => {
                          go({
                            to: `/rep/referralView/${row.original.id}`,
                          });
                        }
                          }>
                            {row.getVisibleCells().map((cell:any) => {
                              const cDef = cell?.column?.columnDef?.id || '';
                              //column.columnDef.id
                              let isUrgent = false;
                              if (['patient_status','insurance_status'].some( x => cDef.includes(x))){
                                isUrgent = /URGENT/.test(cell.getValue() as string);
                              }
                                return (
                                    <td 
                                    key={cell.id}
                                    style={{
                                      background: isUrgent ? 'red' : 'transparent'
                                    }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                }) : <>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </>}
            </tbody>
        </Table>
        <br />
        <Pagination
            position="right"
            total={pageCount}
            page={current}
            onChange={setCurrent}
        />
    </List>
  </ScrollArea>
}