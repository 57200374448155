import { HttpError } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { TextInput } from "@mantine/core";

export const InsuranceCreate: React.FC = () => {
    const {
        saveButtonProps,
        getInputProps,
        values
    } = useForm< HttpError>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Insurance"
                    placeholder="Insurance"
                    {...getInputProps("title")}
                />
                <br/>
                <br/>
            </form>
            <pre>{JSON.stringify(values,null,2)}</pre>
        </Create>
    );
};
