import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from "@refinedev/mantine";

import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Container,
  Image,
  Space,
} from "@mantine/core";

import {
  useIsAuthenticated,
  useGo,
  useLogin
} from '@refinedev/core';

import logo from './affecto_22.jpg';
import { GoogleIcon } from 'components';


export function Login(props: PaperProps) {

  const { mutate: login } = useLogin();

  const form = useForm();

  const {
    data
  } = useIsAuthenticated();

  const go = useGo();

  // jank fix
  if (data?.authenticated){
    go({
      to: '/dashboard'
    });
  }

  return (
    <Paper radius="md" p="xl" withBorder {...props}>
      <Container size={420} my={40}>
        <Image src={logo}>
        </Image>

        <br></br>
        <Divider label="Login Portal" labelPosition="center" my="lg" />
        <br></br>

        <form onSubmit={form.onSubmit((values) => {
          login(values);
        })}>
          <Group position="center" mt="xl">
            <Button type="submit" variant="outline" color="blue" leftIcon={<GoogleIcon/>}>
              <Text color="black">
                Sign in via Google
              </Text>
            </Button>
          </Group>
        </form>
      </Container>
      
    </Paper>
  );
}