import { HttpError, useOne, useResource } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";

import { resource, fields } from './common'
import React from "react";

export const TemplateEdit: React.FC = () => {

  const { id } = useResource();

    const { 
        data: {
            data: initialValues
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields
        }
    })

    const {
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      getInputProps,
      values
  } = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields
        },
      },
      initialValues,
  });

//   const { selectProps: clientSelectProps } = useSelect({
//     resource: "clients",
//     defaultValue: queryResult?.data?.data.client_id,
//     optionLabel: 'name',
//     metaData: {
//       fields: ["id", "name"],
//     },
//   });

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <form>
        <Tabs defaultValue={'first'}>
                    <Tabs.List>
                        <Tabs.Tab value="first">Main</Tabs.Tab>
                        <Tabs.Tab value="second">Contact</Tabs.Tab>
                        <Tabs.Tab value="third">Alt Contact</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                    </Tabs.Panel>
                    <Tabs.Panel value="second">
                    </Tabs.Panel>
                    <Tabs.Panel value="third">

                    </Tabs.Panel>
                </Tabs>
        </form>
        <pre>{JSON.stringify(values,null,2)}</pre>
      </Edit>
    );
};
