import { HttpError, useGetIdentity, useNavigation, useOne } from "@refinedev/core";
import { Create, useForm, SaveButton, useSelect, useStepsForm } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput, Group, Button, Stepper } from "@mantine/core";

export const ProductCreate: React.FC = () => {

  const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
      list('fulfillment/product');
      return
  }

  const {
      saveButtonProps,
      getInputProps, 
      errors,
      setFieldValue,
      steps: { currentStep, gotoStep },
      values,
  } = useStepsForm<HttpError>({
      initialValues: {
        location_id: null,
        product_type_id: null,
        product_status_id: null
      },
      refineCoreProps: {
          resource: 'product',
          onMutationSuccess,
          redirect:false
      },
      validate: (values:any) => {
          const {
            product_type_id
          } = values;

          return {
            product_type_id: product_type_id?.length ? null : "Please select a type for this product.",
          }
      }
  });

  const {
    data: {
      data: product_type
    } = {}
  } = useOne({
    resource: "product_type",
    // @ts-ignore
    id: values?.product_type_id || '',
    meta: {
      fields: [
        'id',
        'has_serial_number'
      ]
    },
    queryOptions: {
      enabled: values?.product_type_id != null
    }
  })

  const fields = product_type?.has_serial_number == true && (
    <TextInput
        mt={8}
        label="Serial Number"
        placeholder="Serial Number"
        {...getInputProps('serial_number')}
        style={{ maxWidth: 300 }}
    />
  )

  const { selectProps: productTypeSelectProps } = useSelect({
    resource: "product_type",
    defaultValue: undefined,
    optionLabel: 'title',

    meta: {
      fields: ['id','title'],
    },

    pagination: {
      mode: "server"
    }
  })

  const { selectProps: locationSelectProps } = useSelect({
    resource: "location",
    defaultValue: undefined,
    optionLabel: 'title',

    meta: {
      fields: ['id','title'],
    },

    filters: [
      {
        field: "client_id",
        operator: "null",
        value: false
      }
    ],

    pagination: {
      mode: "server"
    }
  })

  return (
    <Create saveButtonProps={saveButtonProps}
      footerButtons={
        <Group position="right" mt="xl">
            {currentStep < 1 && (
                <Button
                    variant="default"
                    onClick={() => gotoStep(currentStep - 1)}
                >
                    Back
                </Button>
            )}
            {currentStep !== 3 && (
                <Button onClick={() => gotoStep(currentStep + 1)}>
                    Next step
                </Button>
            )}
            {currentStep === 3 && <
            // @ts-ignore
            SaveButton {...saveButtonProps} />}
        </Group>
      }>
        <Stepper
          active={currentStep}
          onStepClick={gotoStep}
          breakpoint="sm">
            <Stepper.Step
              label="First Step"
              description="Product Type"
              allowStepSelect={false}>
                <Select
                  mt={8}
                  label="Product Type"
                  {...getInputProps('product_type_id')}
                  {...productTypeSelectProps}
                  style={{ maxWidth: 300 }}
                ></Select>
            </Stepper.Step>
            <Stepper.Step
              label="Second Step"
              description="Product Details">
                {fields}
            </Stepper.Step>
            <Stepper.Step
              label="Third Step"
              description="Location">
              <Select
              mt={8}
              label="Location"
              style={{ maxWidth: 300 }}
              {...getInputProps('location_id')}
              {...locationSelectProps}
            ></Select>
            </Stepper.Step>
        </Stepper>
      <pre>{JSON.stringify(values,null,2)}</pre>
    </Create>
  )
}