import { HttpError, useList, useNavigation, useOne, useResource } from "@refinedev/core";
import { useForm } from "@refinedev/mantine";

import {
// Edit,
Tabs, 
// Typography,
Textarea, //
Select, TextInput, Group, Divider, Chip,
Button, Paper, Avatar, Text, Title,
} from "@mantine/core";
import { Notes } from "components/notes";

import { DatePicker } from '@mantine/dates';

import { Edit as SwizzledEdit } from 'components/crud/edit';

import { extended_fields, resource } from './common';
import React from "react";
import { datePickerValue} from "utilities";
// @ts-ignore
import { AdminDebug } from "utilities/index.tsx";

import dayjs from "dayjs";

export const ResupplyEdit: React.FC = () => {

  const { list } = useNavigation();

  const { id } = useResource();

  const { 
      data: {
          data: initialValues
      } = {}
  } = useOne({
      resource,
      id,
      meta:{
          fields: extended_fields
      }
  });

    const onMutationSuccess = (data: any, variables: any, context: any) => {
      list('operations/resupplies');
  }

    const main = useForm< HttpError>({
      refineCoreProps: {
        // warnWhenUnsavedChanges: true,
        resource,
        id,
        metaData: {
          fields: extended_fields
        },
        onMutationSuccess,
        autoSave: {
          enabled: true,
        },
        redirect: false
      },
      initialValues,
      transformValues: (values) => {
        const {
          id,
          referral,
          ...rest
        } = values;

        return rest
      }
  });

  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish, formLoading },
    getInputProps,
    values,
    insertListItem,
    setFieldValue
} = main;

  const {
    data: {
      // @ts-ignore
      data: itemLookup
    } = {}
  } = useList({
    resource: 'selection',

    meta:{
      fields: [ 'id', 'title'],
    },

    pagination: {
      pageSize: 1000
    }
  });

  // @ts-ignore
  const referralsOfPatient = [{
    // @ts-ignore
    id: values?.referral?.id,
    // @ts-ignore
    product_type: values?.referral?.product_type,
    // @ts-ignore
    rx_received_date: values?.referral?.rx_received_date
  }];

    const forms = {
      main
    }  

    const notesSection = <Notes
      // @ts-ignore
      patient_id={values?.referral?.patient?.id}
      forms={forms}
      referrals={referralsOfPatient}
      // @ts-ignore
      patient_notes={values?.referral?.patient?.patient_notes}
      main_accessor='referral.patient.patient_notes'
  />;

  // @ts-ignore
  const resupplyItems = values?.referral?.resupply_items?.map( (itemId, index) => {

    return (<>
      <Divider my="xs" label={`Item #${index+1}`} style={{ maxWidth: 300 }}/>
      {/* TODO when moving logic of referral view to patient-referrals view, consider below */}
      {/* <Select
        mt={8}
        label="Product Type"
        {...getInputPropsForSubmission(`items.${index}.product_type`)}
        {...productTypeSelectProps2}
        style={{ maxWidth: 300 }}
      /> */}
      {/* @ts-ignore */}
      {/* {['SCOOTER','TCT'].includes(submissionValues?.items[index]?.product_type) && <>
        Product Lookup
      </>}  */}
      <Select
          mt={8}
          // label="Item"
          placeholder="Search for an Item."
          {...getInputProps(`referral.resupply_items.${index}`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          // defaultValue={itemId}
          data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
          // value={matchedTitle}
          style={{ maxWidth: 300 }}
          disabled
      />
      {/* <ActionIcon color="red" onClick={() => removeListItem('items', index)}>
        <CloseButton 
        // @ts-ignore
        size="1rem" />
      </ActionIcon> */}
    </>)
  })

  // fields similar to SR or DT
  const things = <>
    <Select
      mt={8}
      label="SHIPPER"
      {...getInputProps('shipper')}
      style={{ maxWidth: 300 }}
      data={[
        {value:'FEDEX', label:'FEDEX'},
        {value:'USPS', label:'USPS'},
        {value:null as any, label:'-/-'}
      ]}
    />
    <DatePicker
          mt="md"
          label="DATE SHIPPED"
          withinPortal
          {...getInputProps(`date_shipped`)}
          value={datePickerValue(values)(`date_shipped`)}
          style={{ maxWidth: 300 }}
      />
    <TextInput
        mt={8}
        label="TRACKING #"
        // type="date"
        // placeholder="Serial Number"
        {...getInputProps("tracking_number")}
        style={{ maxWidth: 300 }}
    />
    <TextInput
        mt={8}
        label="SENT BY"
        // type="date"
        // placeholder="Serial Number"
        {...getInputProps("sent_by")}
        style={{ maxWidth: 300 }}
    />
    <Select
        mt={8}
        label="RECEIVED"
        {...getInputProps('received')}
        style={{maxWidth: 300}}
        data={[
          {value:'YES', label:'YES'},
          {value:'RETURNED', label:'RETURNED'},
          {value:'WRONG ADDRESS', label:'WRONG ADDRESS'},
          {value:null as any, label:'-/-'}
        ]}
      />
  </>;

  const delivery_ticket_for_billing = initialValues?.autocrat_job_status?.delivery_ticket_for_billing || false;

  const generatedAutomation = delivery_ticket_for_billing ? <>
    <Text>Generated "DELIVERY TICKET FOR BILLING"</Text>
  </> : <>
    <Text>Did not generate "DELIVERY TICKET FOR BILLING"</Text>
  </>

  return <><SwizzledEdit saveButtonProps={saveButtonProps}>
    <Tabs defaultValue='main'>
      <Tabs.List>
        <Tabs.Tab value="main">Main</Tabs.Tab>
        <Tabs.Tab value="patient_notes">Patient Notes</Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="main">
        <br/>
        {generatedAutomation}
        <TextInput
          disabled
          mt={8}
          label="Patient"
          {...getInputProps('referral.patient.name_and_dob')}
          style={{ maxWidth: 300 }}
        />
        <Textarea
          disabled
          mt={8}
          label="Patient Info"
          {...getInputProps('referral.patient.patient_info')}
          minRows={5}
          style={{ maxWidth: 300 }}
        />
        {things}
        <Textarea
          mt={8}
          label="Patient Info"
          {...getInputProps(`referral.patient.patient_info`)}
          minRows={4}
          style={{ maxWidth: 300 }}
        />
        {resupplyItems}
        <Select
          mt={8}
          label="ACTIVE"
          {...getInputProps('active')}
          style={{ maxWidth: 300 }}
          data={[
            {value: true, label:'ACTIVE'},
            {value: false, label: 'INACTIVE'}
          ] as any}
        />
        <DatePicker
          mt="md"
          label="Resupply Date"
          placeholder="Resupply Date"
          withinPortal
          // @ts-ignore
          disabled={dayjs(values?.resupply_date).add(1,'day').isBefore(dayjs())}
          {...getInputProps("resupply_date")}
          value={datePickerValue(values)('resupply_date')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Insurance"
          {...getInputProps('referral.insurance.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Claim Type"
          {...getInputProps('referral.claim_type.title')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          disabled
          mt={8}
          label="Product"
          {...getInputProps('referral.product_title')}
          style={{ maxWidth: 300 }}
        />
      </Tabs.Panel>
      <Tabs.Panel value="patient_notes">
        {notesSection}
      </Tabs.Panel>
    </Tabs>
  </SwizzledEdit>
  {/* @ts-ignore */}
  {AdminDebug(values)}
  </>
}