import { Table, Select, Grid } from "@mantine/core";
import { useState } from "react";
import { formatDate, formatTimeStamp } from "utilities";
import dayjs from "dayjs";

export const PatientHistory = ( props:any ) => {

  const {
    forms: {
      main: {
        values: patient = null,
        // setFieldValue: setFieldValueUponPatient = null,
      } = {}
    } = {}
  } = props;

  const patient_histories = patient?.patient_histories || [];

  const [ filterState, setFilterState ] = useState({
    authed_user_id: null,
    field: '', //could do a multi-select
  } as any);

  const records = patient_histories.map( (hist:any) => {
    const {
      id,
      created_at, header, field, oldValue, newValue,
      authed_user_id, authed_user: { photoURL, display_name }
    } = hist;
    return {
      id,
      created_at, header, field, oldValue, newValue,
      authed_user_id, photoURL, display_name
    }
  });

  const selectData = records.reduce( (prev: any,record: any) => {
    const {
      authed_user_id, display_name, photoURL,
      field, header
    } = record;

    const {
      users,
      fields
    } = prev;

    const hasThisUser = users.some(
      ({value}:{value:string}) => value == authed_user_id
    );
    if (!hasThisUser) users.push(
      {value:authed_user_id, label: display_name}
    );

    const hasThisField = fields.some(
      ({value}:{value:string}) => value == field
    );
    if (!hasThisField) fields.push(
      {value:field, label: header}
    );

    return {
      users,
      fields
    }
  }, {
    users: [],
    fields: []
  });

  const UserSelect = <Select
    mt="md"
    label="Filter by User"
    data={selectData.users}
    clearable searchable
    onChange={(val) => {
      setFilterState( (prev:any) => ({
        ...prev,
        authed_user_id: val
      }))
    }}
  />;

  const FieldSelect = <Select
    mt="md"
    label="Filter by Field"
    data={selectData.fields}
    clearable searchable
    onChange={(val) => {
      setFilterState( (prev:any) => ({
        ...prev,
        field: val
      }))
    }}
  />;

  const Header = <thead>
    <tr>
      <th>TIMESTAMP</th>
      <th>USER</th>
      <th>FIELD</th>
      <th>FROM</th>
      <th>TO</th>
    </tr>
  </thead>;

  const {
    authed_user_id: f_user,
    field: f_field
  } = filterState;

  const Rows = records
  .filter( (record:any) => {
    const { authed_user_id, field } = record;

    // match all of the defined (i.e. non-NULL) filters
    const boolean = [
      f_user ? authed_user_id == f_user : null,
      f_field ? field == f_field : null 
    ]
    .filter( x => x != null)
    .every( x => x);

    return boolean
  })
  // @ts-ignore
  .sort(({created_at: a}, {created_at: b}) => {
    const aDay = dayjs(a);
    const bDay = dayjs(b);
    if (aDay.isAfter(bDay)){
        return -1
    }
    if (aDay.isBefore(bDay)){
        return 1
    }
    return 0
  })
  .map( (x:any) => {
    const { 
      id,
      created_at, header, oldValue, newValue,
      photoURL, display_name
    } = x;
    return <tr key={id}> {/* maybe use id of change*/}
      <th>{formatTimeStamp(created_at)}</th>
      <th>{display_name}</th>
      <th>{header}</th>
      <th>{oldValue}</th>
      <th>{newValue}</th>
    </tr>
  });

  const Body = <tbody>
    {Rows}
  </tbody>;

  return <>
    <Grid>
      <Grid.Col span={2}>
        {UserSelect}
      </Grid.Col>
      <Grid.Col span={2}>
        {FieldSelect}
      </Grid.Col>
    </Grid>
    <Table>
      {Header}
      {Body}
    </Table>
  </>
}