export const fields = [
  "id",
  "extended_to_date",
  "extension_status_id",
  {
    extension_status: ['title','code']
  },
  {
    insurance_override: ['title']
  },
  'rx_expiration_date',
  'rx_length_days',
  // 'lifetime',
  'run',
  {
    referral: [
      'fourteen_day_call',
      {
        insurance: ['title']
      },
      {
        reauth_status: ['title']
      },
      {
        patient: [
          'id',
          'name',
          'date_of_birth',
          'name_and_dob'
        ]
      }
    ]
  }
];

export const extendedFields = [
  'active',
  'referral_id',
  {
    referral: [
      'id',
      'product_id',
      {
        product: [ 'id', 'serial_number' ]
      },
      'patient_id',
      {
        patient: [
          'id',
          'name',
          "date_of_birth",
          'name_and_dob',
          'patient_info',
          {
            "patient_notes": [
              {
                "note": [
                  'id',
                  'content',
                  {
                    "authed_user": [ 'id', 'display_name', 'photoURL' ]
                  },
                  'metadata',
                  "created_at"
                ]
              }
            ]
          },
        ]
      },
      {
        provider: [
          'name',
          'npi',
          // TODO contact info
          {
            provider_representatives: [ 
              {
                representative: [
                  'name'
                ]
              }
            ]
          }
        ],
      },
      {
        billing_provider: [
          'name',
          'npi',
          // TODO contact info
          {
            provider_representatives: [ 
              {
                representative: [
                  'name'
                ]
              }
            ]
          }
        ],
      },
      "patient_status_id",
      {
        patient_status: ['title']
      },

      "product_type_id",
      {
        product_type: ['title']
      },

      "insurance_id",
      {
        insurance: ['title']
      },

      "claim_type_id",
      {
        claim_type: ['title']
      },

      "insurance_status_id",
      {
        insurance_status: ['title']
      },

      {
        reauth_status: [ 'title' ]
      },
      "icd10_codes",

      "rx_received_date",

      "rx_date",
      // "date_of_surgery", //TODO where should DOS be located on
      // "date_of_injury" //TODO
      "five_day_call",
      "five_day_call_status",
      "fourteen_day_call",
      "fourteen_day_call_status",
      "equipment_id",
    ]
  },
  'autocrat_job_status',
  'autocrat_job_results',
  'billing_provider_id',
  {
    provider: [
      'name',
      'npi',
      // TODO contact info
      {
        provider_representatives: [ 
          {
            representative: [
              'name'
            ]
          }
        ]
      }
    ],
  },
]

export const resource = 'extension'