import React, { useEffect } from "react";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { DeleteButton, EditButton } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination, Avatar } from "@mantine/core";
import { useGo, useGetIdentity } from "@refinedev/core";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter } from '../../../components';

const columns: ColumnDef<any>[] = [
  {
      id: "actions",
      header: "Actions",
      accessorKey: 'id',
      enableColumnFilter: false,
      enableSorting: false,
      cell: function render({ getValue }:any) {
        return (
            <Group spacing="xs" noWrap>
                <EditButton
                    hideText
                    recordItemId={getValue() as number}
                />
                <DeleteButton
                    hideText
                    recordItemId={getValue() as number}
                />
            </Group>
        );
      },
    },
  { id: "name", accessorKey: "displayName", header: "Name", enableColumnFilter: false, enableSorting: false, },
  { id: "photoURL", accessorKey: "photoURL", header: "Avatar", 
    cell: function render({ getValue }:any) {
      return (
        <>
          {/* @ts-ignore */}
          <Avatar size={40} src={getValue()} radius={30} referrerPolicy="no-referrer"></Avatar>
        </>   
      );
    }, enableColumnFilter: false, enableSorting: false
  },
  { id: "email", accessorKey: "email", header: "Email", enableColumnFilter: false, enableSorting: false, },
  { id: "role", accessorKey: "role", header: "Role", enableColumnFilter: false, enableSorting: false, },
  { id: "lastSignInTime", accessorKey: "metadata.lastSignInTime", header: "Last Sign In", enableColumnFilter: false, enableSorting: false, },
  { id: "lastRefreshTime", accessorKey: "metadata.lastRefreshTime", header: "Last Refresh", enableColumnFilter: false, enableSorting: false, },
  { id: "creationTime", accessorKey: "metadata.creationTime", header: "Date Created", enableColumnFilter: false, enableSorting: false, },
  { id: "disabled", accessorKey: "disabled", header: "Disabled", enableColumnFilter: false, enableSorting: false, },
  { id: "client", accessorKey: "client.name", header: "Client", enableColumnFilter: false, enableSorting: false, }
];

export const UserList: React.FC = () => {

  const go = useGo();

  const {
    data: {
      // @ts-ignore
        claims: {
          "x-hasura-default-role": role = ''
        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  useEffect( () => {
    if (role && role != 'admin'){
      console.log('unauthorized')
      go({to:'/'});
    }
  }, [role])

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
        setCurrent, 
        pageCount, 
        current,
      },
  } = useTable({
      columns,

      refineCoreProps: {
          resource: 'user',

          metaData: {
              fields: [
                'id',
                'displayName',
                'email',
                'role',
                'photoURL',
                {
                  'metadata': [ 'lastSignInTime', 'lastRefreshTime', 'creationTime' ]
                },
                'disabled',
                {
                  'client': [ 'name' ]
                }
              ]
          },

          pagination: {
              pageSize: 50
          }
      }
  });

  return (
      <ScrollArea>
      <List>
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                              return (
                                  <th key={header.id}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {
                      return (
                          <tr key={row.id} onClick={() => go({
                            to: `edit/${row.original.id}`
                          })}>
                              {row.getVisibleCells().map((cell) => {
                                  return (
                                      <td key={cell.id}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
}