import { HttpError, useNavigation, useGetIdentity, useGo } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, TextInput } from "@mantine/core";
import { useEffect } from "react";

export const ClientCreate: React.FC = () => {

    const go = useGo();

    const {
      data: {
        // @ts-ignore
          claims: {
            "x-hasura-default-role": role = ''

          } = {}
      } = {}
    } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    useEffect( () => {
      if (role && role != 'admin'){
        console.log('unauthorized')
        go({to:'/'});
      }
    }, [role])

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('admin/clients');
        return
    }

    const {
        saveButtonProps,
        getInputProps, 
        errors
    } = useForm<HttpError>({
        initialValues: {
            // ...filteredInitialValues,
        },
        refineCoreProps: {
            resource: 'clients',
            onMutationSuccess,
            redirect:false
        },
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <TextInput
                    mt={8}
                    label="Title"
                    placeholder="Uppercase"
                    {...getInputProps('title')}
                />
                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Lowercase"
                    {...getInputProps('name')}
                />
                <br/>
                <br/>
            </form>
        </Create>
    );
};
