import {
  Box,
  Button,
  Grid,
  Group,
  List,
  Modal,
  Pagination,
  Stack,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { ColumnFilter, ColumnSorter } from "components";
import { useState } from "react";
import { useExport, useModal } from "@refinedev/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import { DateField } from "@refinedev/mantine";
import _ from "lodash";

function renderDate(thing: any) {
  const { getValue } = thing;
  return getValue() && dayjs(getValue()).isValid() ? (
    <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
    <>N/A</>
  );
}

export const Analytics: React.FC = () => {
  // TODO the rest of the columns
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "agreement",
      id: "agreement",
      header: "C",
      enableColumnFilter: false,
      enableSorting: false,
      meta: { visible: false },
    },
    {
      accessorFn: (row: any) => {
        const addedToColaborate = row?.patient?.added_to_collaborate ? "X" : "";
        const numberOfDaysToNotify = row?.column_b || "";
        return addedToColaborate || numberOfDaysToNotify;
      },
      id: "patient.added_to_collaborate",
      header: "X",
      enableColumnFilter: false,
      enableSorting: false,
      meta: { visible: false },
    },
    {
      accessorKey: "column_d",
      id: "column_d",
      header: "!",
      enableColumnFilter: false,
      enableSorting: false,
      meta: { visible: false },
    },
    {
      accessorKey: "patient.name_and_dob",
      id: "patient.name_and_dob",
      header: "Name",
      enableColumnFilter: true,
      enableSorting: false,
      meta: {
        filterOperator: "contains",
        visible: true,
      },
    },
    // PRO REP TEAM
    {
      accessorFn: (row: any) => {
        const pro = row?.provider?.name;
        const rep = row?.provider?.provider_representatives
          ?.map((x: any) => {
            const {
              representative: { name },
            } = x;
            return name;
          })
          .join(" / ");
        const thing = pro && rep ? `${pro}\r\n${rep}` : "";
        return thing;
      },
      // @ts-ignore
      cell: (thing) => {
        const {
          row: { original },
        } = thing;
        // @ts-ignore
        const pro = original?.provider?.name;
        // @ts-ignore
        const rep = original?.provider?.provider_representatives
          ?.map((x: any) => {
            const {
              representative: { name },
            } = x;
            return name;
          })
          .join(" / ");
        if (pro && rep) {
          return (
            <Stack>
              <Text>{pro}</Text>
              <Text>{rep}</Text>
            </Stack>
          );
        }
        console.log(thing);
        return "";
      },
      id: "referral.pro_rep",
      header: "PRO REP",
      enableSorting: false,
      enableColumnFilter: false,
      meta: {
        visible: false,
      },
    },
    {
      accessorFn: (row: any) => {
        return row?.insurance?.title;
      },
      // accessorKey: 'insurance.title',
      id: "insurance.title",
      header: "Insurance",
      enableColumnFilter: true,
      enableSorting: false,
      meta: {
        filterOperator: "contains",
        visible: true,
      },
    },
    {
      accessorFn: (row: any) => {
        return row?.claim_type?.title;
      },
      meta: {
        filterOperator: "contains",
        visible: true,
      },
      id: "claim_type.title",
      header: "Claim Type",
      enableColumnFilter: true,
      enableSorting: false,
    },
    {
      accessorFn: (row: any) => {
        return row?.insurance_status?.title;
      },
      accessorKey: "insurance_status.title",
      meta: {
        filterOperator: "contains",
        visible: true,
      },
      id: "insurance_status.title",
      header: "Insurance Status",
      enableColumnFilter: true,
      enableSorting: false,
    },
    {
      accessorKey: "rx_received_date",
      id: "rx_received_date",
      header: "REC'D",
      // minSize: 220,
      // size: 350,
      cell: renderDate,
      enableColumnFilter: false,
      enableSorting: true,
      meta: { visible: true },
    },
    {
      accessorFn: (row: any) => {
        return row?.patient_status?.title;
      },
      // accessorKey: 'patient_status.title',
      id: "patient_status.title",
      header: "Patient Status",
      enableColumnFilter: true,
      enableSorting: false,
      meta: {
        filterOperator: "contains",
        visible: true,
      },
    },
    {
      accessorKey: "date_of_surgery",
      id: "date_of_surgery",
      header: "DOS",
      // minWidth: 220,
      accessorFn: (row: any) => {
        let dos = row?.patient?.date_of_surgery;
        if (dos && dayjs(dos).isValid()) {
          dos = dayjs(dos).format("MM-DD-YY");
        }
        const condition = row?.patient?.condition;
        return dos || condition || "N/A";
      },
      meta: { visible: true },
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: "product_title",
      id: "product_title",
      header: "Product",
      enableSorting: false,
      meta: {
        filterOperator: "contains",
        visible: true,
      },
    },
    {
      accessorKey: "patient.patient_info",
      id: "patient.patient_info",
      header: "Patient Info",
      enableSorting: false,
      meta: {
        visible: false,
      },
    },
    {
      accessorKey: "patient.email",
      id: "patient.email",
      header: "Email",
      enableSorting: false,
      meta: {
        visible: false,
      },
    },
    {
      accessorFn: (row: any) => {
        return row?.document_status?.title;
      },
      id: "document_status.title",
      header: "Document Status",
      enableColumnFilter: true,
      enableSorting: false,
      meta: {
        filterOperator: "contains",
        visible: false,
      },
    },
    //    rep com notes
    //    alert notes
    //    rep alert
    //    verification  notes
    {
      accessorKey: "date_fit",
      id: "date_fit",
      header: "Date Fit",
      cell: renderDate,
      enableColumnFilter: false,
      enableSorting: true,
      meta: { visible: true },
    },
    //  SUBMISSION
    // serial number
    // date shipped
    // tracking number

    // pickup date
    // 14 day call
    // survey complete

    //  PATIENT QUESTIONNAIRE
    // fitter
    // employer
    // doi
    // attorney
  ];

  const [columnState, setColumnState] = useState(columns);

  // const {
  //   visible: columnModalVisible,
  //   show: showColumnModal,
  //   close: closeColumnModal
  // } = useModal();
  const [
    columnModalVisible,
    { open: openColumnModal, close: closeColumnModal },
  ] = useDisclosure(false);

  const fields = [
    "id",
    "column_b",
    "column_d",

    "rx_date",
    "rx_received_date",

    "agreement",

    {
      client: ["name"],
    },

    "product_title",
    "patient_id",
    {
      patient: [
        "name",
        "date_of_birth",
        "name_and_dob",
        "patient_info",
        "email",

        "added_to_collaborate",

        "initial_call_complete",
        "date_of_surgery",
        "surgery",
        "next_call_date",
        // "date_fit",
        "condition",
      ],
    },

    "provider_id",
    {
      provider: [
        "name",
        // TODO contact info
        {
          provider_representatives: [
            {
              representative: ["name"],
            },
          ],
        },
      ],
    },
    "billing_provider_id",
    {
      billing_provider: [
        "name",
        // TODO contact info
        {
          provider_representatives: [
            {
              representative: ["name"],
            },
          ],
        },
      ],
    },

    {
      patient_status: ["title"],
    },
    "insurance_id",
    {
      insurance: ["title"],
    },
    "claim_type_id",
    {
      claim_type: ["title"],
    },

    "insurance_status_id",
    {
      insurance_status: ["title"],
    },
    "document_status_id",
    {
      document_status: ["title"],
    },
    "date_fit",
  ];

  const [fieldsState, setFieldsState] = useState(fields);

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: {
      setCurrent,
      setFilters,
      filters,
      pageCount,
      current,
      tableQueryResult: { data: tableData },
    },
  } = useTable({
    // @ts-ignore
    columns: columnState.filter((x) => x?.meta?.visible),

    refineCoreProps: {
      // initialPageSize: 10,
      resource: "referral",

      metaData: {
        fields: fieldsState,
      },
      sorters: {
        initial: [
          {
            field: "created_at",
            order: "desc",
          },
        ],
      },
      queryOptions: {
        // disable query if the user belongs to the AFX client and the client of the query is undefined
        // enabled: shouldQuery,
      },
      pagination: {
        pageSize: 50,
      },
    },
  });

  // Export Button
  const { triggerExport, isLoading } = useExport({
    resource: "referral",
    mapData: (item) => {
      // @ts-ignore
      const reduced = columns.reduce((prev, curr) => {
        // @ts-ignore
        const { meta, accessorKey, accessorFn, header } = curr;
        // @ts-ignore
        const visible = meta?.visible;
        if (visible && header) {
          if (accessorKey) {
            // @ts-ignore
            prev[header] = _.get(item, accessorKey) || "";
          } else if (accessorFn) {
            // @ts-ignore
            prev[header] = accessorFn(item) || "";
          }
        }
        return prev;
      }, {});
      return reduced;
    },
    filters,
    pageSize: 50,
    meta: {
      fields,
    },
  });

  return (
    <List>
      <Grid>
        <Grid.Col span={2}>
          <Button onClick={openColumnModal}>Columns</Button>
          <Modal
            opened={columnModalVisible}
            onClose={closeColumnModal}
            title="Columns"
          >
            {columnState.map((x, i) => {
              // console.log('x',x);
              return (
                <Switch
                  px={2}
                  //@ts-ignore
                  label={x.header}
                  //@ts-ignore
                  checked={x.meta?.visible}
                  //@ts-ignore
                  onChange={(e) =>
                    setColumnState((prev) => {
                      const mapped = prev.map((y) => {
                        if (x.header == y.header) {
                          // console.log('y',y)
                          // console.log(e?.currentTarget?.checked)
                          // console.log(!!e?.currentTarget?.checked)
                          return {
                            ...y,
                            meta: {
                              ...y.meta,
                              // @ts-ignore
                              visible: !y.meta?.visible,
                            },
                          };
                        } else {
                          return y;
                        }
                      });
                      return mapped;
                    })
                  }
                ></Switch>
              );
            })}
          </Modal>
          {/* <pre>{JSON.stringify(columnState,null,2)}</pre> */}
        </Grid.Col>
        <Grid.Col span={2}>
          <Button onClick={triggerExport} disabled={isLoading}>
            Download
          </Button>
        </Grid.Col>
      </Grid>
      <Table highlightOnHover>
        <thead>
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    {!header.isPlaceholder && (
                      <Group spacing="xs" noWrap>
                        <Box>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Box>
                        <Group spacing="xs" noWrap>
                          <ColumnSorter column={header.column} />
                          <ColumnFilter column={header.column} />
                        </Group>
                      </Group>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                // onClick={() => {
                //   go({
                //     to: `/directory/patient/edit/${row.original.patient_id}`,
                //     query: {
                //       referral: row.original.id
                //     }
                //   });
                // }
                // }
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <br />
      <Pagination
        position="right"
        total={pageCount}
        page={current}
        onChange={setCurrent}
      />
    </List>
  );
};
