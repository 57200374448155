import React, { useEffect, useContext, useState } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton, DateField } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination } from "@mantine/core";

import dayjs from 'dayjs';

import { List } from "components/ghettoList";

import { fields } from './common'

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';
import { useGetIdentity, useGo } from "@refinedev/core";
import { isAffectrixClientId } from "utilities";

function renderDate({ getValue }:any) {
    return dayjs(getValue()).isValid() ? (
        <DateField format="MM-DD-YY" value={getValue()} />
    ) : (
        <>N/A</>
    );
}

const columns: ColumnDef<any>[] = [
    // {
    //     id: "actions",
    //     header: "Actions",
    //     accessorKey: 'id',
    //     enableColumnFilter: false,
    //     enableSorting: false,
    //     cell: function render(thing) {
    //     const { getValue }:any = thing
    //     console.log(thing)
    //       return (
    //           <Group spacing="xs" noWrap>
    //               <EditButton
    //                   hideText
    //                   recordItemId={getValue() as number}
    //               />
    //           </Group>
    //       );
    //     },
    //   },
    { id: "client.title", accessorFn: (row) => {
        // console.log(row.client)
        return row?.client?.title
    }, header: "Client", enableColumnFilter: false, enableSorting: false, },
    { id: "name_and_dob", accessorKey: "name_and_dob", header: "Name", enableColumnFilter: true, enableSorting: true, 
    meta: {
        filterOperator: 'contains'
    }},
    // { id: "date_of_birth", accessorKey: "date_of_birth", header: "DoB", enableColumnFilter: false, enableSorting: false, },
];

export const PatientList: React.FC = () => {

    const go = useGo();

    // @ts-ignore
    const { userState, setUserState } = useContext(UserContext);

    const {
      data: {
        // @ts-ignore

          claims: {
          // "x-hasura-user-id": uid,
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    const [ shouldQuery, setShouldQuery ] = useState(false);

    const {
        getHeaderGroups,
        getRowModel,
        refineCore: { 
            setCurrent, 
            pageCount, 
            current,
            filters,
            setFilters,
            tableQueryResult: { data: tableData },
          },
      } = useTable({
        columns,

        refineCoreProps: {
            resource: 'patient',

            pagination: {
              pageSize: 50
            },

            sorters: {
              initial: [
                {
                  field: 'name_and_dob',
                  order: 'asc'
                }
              ]
            },

            metaData: {
                fields
            },
            queryOptions: {
              // disable query if the user belongs to the AFX client and the client of the query is undefined
              enabled: shouldQuery
            }
        }
    });

    useEffect( () => {
      setShouldQuery(false);
      // @ts-ignore
      let filtersToSet = [];
      if (userState?.clientId){
        filtersToSet = [
          // @ts-ignore
          ...filtersToSet,
          {
            field: 'client_id',
            operator: 'eq',
            value: userState.clientId
          }
        ];
        // @ts-ignore
        setFilters(filtersToSet, 'merge');
      } else {
        setFilters( (prev:any) => {
          const newFilters = prev.filter(({field}:any) => field != 'client_id')
          // @ts-ignore
          return [...newFilters, ...filtersToSet]
        })
      }
  
    }, [userState, clientId]);

    useEffect( () => {
      if (clientId){
        // behavior specific to AFX/STRIVE users
        if (isAffectrixClientId(clientId)){
          const hasName = filters.some( ({field,value}:any) => 
            field == 'name_and_dob' &&
            value.length > 0
          );
          const hasClient = filters.some( ({field,value}:any) => 
            field == 'client_id' &&
            value.length > 0
          );
          if (hasName || hasClient){
            setShouldQuery(true);
          }
        } else {
          setShouldQuery(true);
        }
      }
      
    }, [filters, clientId]);

    return (
        <ScrollArea>
        <List resource="patient" canCreate={false}>
          {/* { clientId == '279a1514-9914-49b2-af74-027610efd80f' && <ClientSelect 
            setFilters={setFilters}
            filters={filters}
            table='patient'
            ></ClientSelect>
          } */}
            <Table highlightOnHover>
                <thead>
                    {getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <Group spacing="xs" noWrap>
                                                <Box>
                                                    {flexRender(
                                                        header.column
                                                            .columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <Group spacing="xs" noWrap>
                                                    <ColumnSorter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                    <ColumnFilter
                                                        column={
                                                            header.column
                                                        }
                                                    />
                                                </Group>
                                            </Group>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {getRowModel().rows.map((row) => {
                        return (
                            <tr key={row.id} onClick={() => go({
                              to: `edit/${row.original.id}`
                            })}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <br />
            <Pagination
                position="right"
                total={pageCount}
                page={current}
                onChange={setCurrent}
            />
        </List>
    </ScrollArea>
    );
};
