import React, { useContext, useEffect } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton, ShowButton, DateField, TextField } from "@refinedev/mantine";
import { useLocalStorage } from "@mantine/hooks";

import {
//

Box, Group, // List,
ScrollArea, Table, Pagination, Select, Textarea
} from "@mantine/core";

import dayjs from "dayjs";

import { List } from "components/ghettoList";

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';

import { fields, resource } from "./common";
import { useGetIdentity, useGo } from "@refinedev/core";
import { isAffectrixClientId } from "utilities";

function renderDate({ getValue }:any) {
  return dayjs(getValue()).isValid() ? (
      <DateField format="MM-DD-YY" value={getValue()} />
  ) : (
      <>N/A</>
  );
}

const columns: ColumnDef<any>[] = [
  { accessorKey: 'referral.patient.name_and_dob', id:'referral.patient.name_and_dob', header: 'Patient Name', enableColumnFilter: true, enableSorting: false, meta: { filterOperator: 'contains' } },
  { accessorKey: 'resupply_date', id: 'resupply_date', header: 'RESUPPLY DATE', enableColumnFilter: false, enableSorting: false, cell: renderDate,},
  // { accessorKey: 'referral.patient.date_of_birth', id:'referral.patient.date_of_birth', header: 'DoB', enableColumnFilter: false, enableSorting: false, cell: renderDate},
  { accessorFn: (row) => {
    return row?.referral?.insurance?.title
}, id:'referral.insurance.title', header: 'Insurance', enableColumnFilter: false, enableSorting: false},
  { accessorKey: 'referral.claim_type.title', id:'referral.claim_type.title', header: 'Type', enableColumnFilter: false, enableSorting: false},
  { accessorFn: (row) => {
    return row?.referral?.product_title
    }, id:'referral.product_title', header: 'Product', enableColumnFilter: false, enableSorting: false},
  { id: "product.serial_number", accessorFn: (row) => {
    return row.product?.serial_number || row.serial_number
  }, header: "SERIAL NUMBER", enableColumnFilter: false, enableSorting: false },
  { id: "resupply_items", accessorFn: (row) => {
    return row?.referral?.resupply_items_materialized.join(', ') || ""
  }, header: "ITEMS", enableColumnFilter: false, enableSorting: false},
  { id: "date_shipped", accessorKey: "date_shipped", header: "DATE SHIPPED", enableColumnFilter: false, enableSorting: false, cell: renderDate, },
  { id: "received", accessorKey: "received", header: "RECEIVED?", enableColumnFilter: false},
];

const dataFilters = [
    {
        title: 'RESUPPLY PAST DUE',
        filter: [
            {
                field: 'resupply_date',
                operator: 'lt',
                value: dayjs().add(1, 'day').format('YYYY-MM-DD')
            },
        ]
    },
    {
        title: 'UPCOMING RESUPPLY',
        filter: [
            {
                field: 'resupply_date',
                operator: 'gte',
                value: dayjs().add(1, 'day').format('YYYY-MM-DD')
            },
        ]
    },
    {
      title: "PRE-CHECK",
      filter: [
        {
          field: 'resupply_date',
          operator: 'gte',
          value: dayjs().add(-2, 'day').format('YYYY-MM-DD')
        },
        {
          field: 'resupply_date',
          operator: 'lte',
          value: dayjs().add(20, 'day').format('YYYY-MM-DD')
        },
      ]
    }
];

function RadioSelect(props:any) {

    const {
      setFilters,
      user_filters = [],
      filters = [],
      uid,
      currentUserState,
      setCurrentUserState
    //   tableFilters,
    } = props;

    // user_filters <~> filters
    const mappedFilters = filters.map( (x:any) => {
        const {
            // filter:{
                // value,
                title,
                filter
            // }
        } = x
        return {
            value: title,
            label: title,
            active_filter: filter
        }
    })

    const [value, setValue] = React.useState(null);

    // @ts-ignore
    let clientFilter = [];
    if (currentUserState?.clientId){
      console.log(currentUserState.clientId)
      clientFilter = [
        {
          field: 'referral.client_id',
          operator: 'eq',
          value: currentUserState.clientId
        }
      ]
    }

    const handleChange = (selectValue:any) => {

        setValue(selectValue);

        if (!selectValue){
            // @ts-ignore
            setFilters([...clientFilter], 'replace');

            setCurrentUserState({
              ...currentUserState,
              resuppliesFilter: undefined
            })
        }

        const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

        if (thing.length == 1){

            const [ {active_filter} ] = thing

            setFilters([
              ...active_filter,
              // @ts-ignore
              ...clientFilter
            ], 'replace' );

            setCurrentUserState({
              ...currentUserState,
              resuppliesFilter: {
                title: selectValue,
                active_filter,
              }
            })
        } else {
            
        }

    };

    return (
        <>
            <Select defaultValue={currentUserState?.resuppliesFilter?.title}
            label='Select a filter'
            data={mappedFilters} onChange={handleChange} searchable clearable style={{ maxWidth: 300 }}>
                
            </Select>
        </>
    );
  }

export const ResupplyList: React.FC = () => {

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

    const go = useGo();

    const {
      data: {
        // @ts-ignore

          claims: {
          "x-hasura-user-id": uid = '',
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
        setCurrent,
        setFilters,
        filters,
        pageCount, 
        current,
        tableQueryResult: { data: tableData },
      },
  } = useTable({
      columns,

      refineCoreProps: {
          // initialPageSize: 10,
          resource,

          metaData: {
            fields
          },
          queryOptions: {
            // disable query if the user belongs to the AFX client and the client of the query is undefined
            enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
          },
          pagination: {
            pageSize: 50
          },
      }
  });

  useEffect( () => {
    // @ts-ignore
    let filters = [];
    if(userState?.resuppliesFilter?.active_filter){
      // setFilters(, 'replace');
      filters = [...userState.resuppliesFilter.active_filter];
    }
    if (userState?.clientId){
      filters = [
        // @ts-ignore
        ...filters,
        {
          field: 'referral.client_id',
          operator: 'eq',
          value: userState.clientId
        }
      ]
    }

    // @ts-ignore
    setFilters(filters, 'replace');
  }, [userState])

  return (
    <ScrollArea>
      <List>
        {/* { clientId == '279a1514-9914-49b2-af74-027610efd80f' && <ClientSelect 
        setFilters={setFilters}
        filters={filters}
        table='extension'
        ></ClientSelect>} */}
        <RadioSelect 
            setFilters={setFilters} 
            // user_filters={user_filters} 
            // uid={uid}
            // tableFilters={filters}
            filters={dataFilters}

            currentUserState={userState}
            setCurrentUserState={setUserState}
            ></RadioSelect>
          {/* <RadioSelect setFilters={setFilters} user_filters={user_filters} uid={uid}></RadioSelect> */}
          <Table highlightOnHover>
              <thead>
                  {getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                              return (
                                  <th key={header.id}>
                                      {!header.isPlaceholder && (
                                          <Group spacing="xs" noWrap>
                                              <Box>
                                                  {flexRender(
                                                      header.column
                                                          .columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                              </Box>
                                              <Group spacing="xs" noWrap>
                                                  <ColumnSorter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                                  <ColumnFilter
                                                      column={
                                                          header.column
                                                      }
                                                  />
                                              </Group>
                                          </Group>
                                      )}
                                  </th>
                              );
                          })}
                      </tr>
                  ))}
              </thead>
              <tbody>
                  {getRowModel().rows.map((row) => {
                    const resupply_date = dayjs(row?.original?.resupply_date);
                    const insurance = row?.original?.referral?.insurance?.title;
                    const claim_type = row?.original?.referral?.claim_type?.title;

                    const received = row?.original?.received;

                    const today = dayjs();
                    const dateToCompare = today.add(-2, 'day');

                    let row_color = 'transparent';

                    // "PRE-CHECK"
                    if (resupply_date.isBefore(dateToCompare)){
                      row_color = '#f2f2f2'; // #light grey
                    } else if (
                      insurance.includes('/PO') ||
                      /AUTO|COMMERCIAL|SBT/.test(claim_type)
                    ){
                      row_color = 'yellow';
                    }

                    // "MZ RESUPPLY"
                    else if (received == 'YES'){
                      row_color = '#f2f2f2'; // #light grey
                    }
                    else if (/WRONG ADDRESS|RETURNED/.test(received)){
                      row_color = 'gray';
                    }

                    const patient_id = row?.original?.referral?.patient?.id;
                      return (
                          <tr key={row.id} onClick={() => go({
                            to: `edit/${row.original.id}`,
                            // to: `/directory/patient/edit/${patient_id}`,
                            // query: {
                            //   resupply: row.original.id,
                            //   view: 'resupply'
                            // }
                          })}
                          style={{
                            background: row_color
                          }}>
                              {row.getVisibleCells().map((cell) => {

                                // conditional formatting of row
                                // if resupply_date is less than now, then light gray
                                // else if insurance contains /PO, then yellow
                                // else if claim type contains AUTO|COMMERCIAL|SBT, then yellow
                                
                                // conditional formatting of row
                                // if recieved is yes, then light gray
                                // else if recieved is returned or wrong adress, then dark grey

                                // conditional formatting of item #1 column
                                // if first resupply item =regexmatch($I2,"QTY 2|PACK")
                                let cellColor = 'transparent';
                                const identifier = cell?.column?.id;
                                if (identifier == 'resupply_items'){
                                  if (/QTY 2|PACK/.test(cell.getValue() as string)){
                                    cellColor = 'magenta';
                                  }
                                }


                                  return (
                                      <td key={cell.id}
                                      style={{
                                        background: cellColor
                                      }}>
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </td>
                                  );
                              })}
                          </tr>
                      );
                  })}
              </tbody>
          </Table>
          <br />
          <Pagination
              position="right"
              total={pageCount}
              page={current}
              onChange={setCurrent}
          />
      </List>
  </ScrollArea>
  );
}