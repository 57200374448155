export const resource = 'resupplies'

export const fields = [
  "id",
  "resupply_date",
  'date_shipped',
  'tracking_number',
  'received',
  'shipper',
  'active',
  {
    referral: [
      'id',
      'resupply_items',
      'resupply_items_materialized',
      {
        insurance: [ 'id', 'title']
      },
      {
        claim_type: [ 'id', 'title']
      },
      'patient_id',
      {
        patient: [
          'id',
          'name',
          'name_and_dob',
          'date_of_birth',
          'patient_info'
        ]
      },
      {
        product_type: [
          'id',
          'title'
        ]
      },
      'product_title'
    ]
  }
];

const extended_patient = [
  {
    "patient_notes": [
      {
        "note": [
          'id',
          'content',
          {
            "authed_user": [ 'id', 'display_name', 'photoURL' ]
          },
          'metadata',
          "created_at"
        ]
      }
    ]
  },
];

export const extended_fields = [
  "id",
  "resupply_date",
  "active",
  'date_shipped',
  'tracking_number',
  'received',
  'shipper',
  'autocrat_job_results',
  'autocrat_job_status',
  'sent_by',
  {
    referral: [
      'id',
      'resupply_items',
      {
        insurance: [ 'id', 'title']
      },
      {
        claim_type: [ 'id', 'title']
      },
      {
        patient: [
          'id',
          'name',
          'name_and_dob',
          'date_of_birth',
          'patient_info',
          ...extended_patient
        ]
      },
      {
        product_type: [
          'id',
          'title'
        ]
      },
      'product_title'
    ]
  }
];