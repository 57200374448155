import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { 
  DeliveryTicketCreate, DeliveryTicketList, DeliveryTicketEdit,
  ShipmentRequestCreate, ShipmentRequestList, ShipmentRequestEdit
} from "pages/fulfillment";

import { ResupplyEdit, ResupplyList } from "pages/fulfillment";
import { ProductCreate, ProductEdit, ProductList } from "pages/fulfillment/product";

const product = <Route
  path='product'
>
  <Route
    index
    element={<ProductList></ProductList>}
  />
  <Route path='edit/:id' element={<ProductEdit/>}/>
  <Route path='create' element={<ProductCreate/>}/>
</Route>;

const shipmentRequest = <Route
  path='shipmentRequest'
>
  <Route
    index
    element={<ShipmentRequestList></ShipmentRequestList>}
  />
  <Route path='edit/:id' element={<ShipmentRequestEdit/>}/>
</Route>;

const deliveryTicket = <Route
  path='deliveryTicket'
>
  <Route
    index
    element={<DeliveryTicketList></DeliveryTicketList>}
  />
  <Route path='edit/:id' element={<DeliveryTicketEdit/>}/>
</Route>;

const resupplies = <Route
  path='resupplies'
>
  <Route
    index
    element={<ResupplyList></ResupplyList>}
  />
  <Route path='edit/:id' element={<ResupplyEdit/>}/>
</Route>;

export const fulfillment = <Route
  path='fulfillment'
>
  {product}
  {shipmentRequest}
  {deliveryTicket}
  {resupplies}
</Route>;