
import { HttpError, useGetIdentity, useNavigation } from "@refinedev/core";
import { SaveButton, Create, useForm, useSelect, useStepsForm } from "@refinedev/mantine";

import {
    ActionIcon,
    Button,
    CloseButton,
    Divider,
    Grid,
    Group,
    Select,
    Stack,
    Stepper,
    Tabs,
    Text,
    Textarea,
    TextInput,
} from "@mantine/core";

import { resource } from "./common";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";

export const PatientCreate: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list(`directory/${resource}`);
    }

    const {
      data: {
        // @ts-ignore

          claims: {
          // "x-hasura-user-id": uid,
          "x-hasura-client-id": clientId = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    const {
        saveButtonProps,
        getInputProps, 
        onSubmit,
        errors,
        steps: { currentStep, gotoStep },
        values,
        removeListItem,
        insertListItem
    } = useStepsForm<HttpError>({
        initialValues: {
            // ...filteredInitialValues,
            name: '',
            client_id: clientId,
            phone_numbers: [],
            addresses: [],
            attorneys: [],
            employers: [],
            date_of_birth_user_entered: '',
            email: '',
        },
        refineCoreProps: {
            resource,
            metaData:{
                fields: [
                    'id',
                ]
            },
            onMutationSuccess
        },
        validate: (values) => {
          const {
            date_of_birth_user_entered,
            name
          } = values;

          // @ts-ignore
          const date = new Date(date_of_birth_user_entered);

          const [ aYear, aMonth, aDay ] = [
            String(Number(String(date.getFullYear()).slice(2,4))),
            String(date.getMonth() + 1),
            String(date.getDate())
          ];

          // @ts-ignore
          const [ bMonth, bDay, bYear ] = date_of_birth_user_entered
          // @ts-ignore
          .split('-').map( x => String(Number(x)));

          const isValidDate = aYear == bYear && aMonth == bMonth && aDay == bDay;

          // console.log(`${aYear} == ${bYear} && ${aMonth} == ${bMonth} && ${aDay} == ${bDay}`)

          if (currentStep==0){
            return {
              // @ts-ignore
              name: name.length > 5 ? null : "Please enter a name.",
              date_of_birth_user_entered: isValidDate ? null : "Please enter a valid date in the format of MM-DD-YY."
            }
          }
          return {

          }
        },
        transformValues: (values) => {
          let {
            date_of_birth_user_entered,
            ...rest
          } = values;

          // @ts-ignore
          rest.date_of_birth = new Date(date_of_birth_user_entered);

          // @ts-ignore
          rest.name = rest.name.toUpperCase()

          return rest;
        }
    });

    const { selectProps: clientSelectProps } = useSelect({
        resource: "clients",
        defaultValue: undefined,
        optionLabel: 'name',

        meta: {
            fields: ["id", "name"],
        },

        pagination: {
            mode: "server"
        }
    });

    // const datePickerValue = (field:string) =>
    //     dayjs(values[field] as Date).isValid() ? dayjs(values[field] as Date).toDate() : undefined

        // const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ]
        // .map( state => ({value: state, label:state}));
    
        // // @ts-ignore
        // const attorneys = values?.attorneys?.map( (attorney, index) => {
        //     return (
        //         <Stack>
        //             <br/>
        //             <Divider label={`Attorney #${index + 1}`}></Divider>
        //             <Group>
        //                 <TextInput
        //                     mt={8}
        //                     label="Contact"
        //                     {...getInputProps(`attorneys.${index}.contact`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Phone"
        //                     {...getInputProps(`attorneys.${index}.phone_number`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Fax Number"
        //                     {...getInputProps(`attorneys.${index}.fax_number`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //             </Group>
        //             <Textarea
        //                 label="Address Lines"
        //                 {...getInputProps(`attorneys.${index}.address.lines`)}
        //                 style={{ maxWidth: 500 }}
        //                 >
        //             </Textarea>
        //             <Group>
        //                 <TextInput
        //                     mt={8}
        //                     label="City"
        //                     {...getInputProps(`attorneys.${index}.address.city`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Postal Code"
        //                     {...getInputProps(`attorneys.${index}.address.postalCode`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <Select
        //                     mt={8}
        //                     label="State"
        //                     {...getInputProps(`attorneys.${index}.address.stateOrProvinceCode`)}
        //                     searchable={true}
        //                     style={{ maxWidth: 100 }}
        //                     data={states}
        //                 />
        //                 <ActionIcon color="red" onClick={() => removeListItem('attorneys', index)}>
        //                     {/* @ts-ignore */}
        //                     <CloseButton size="1rem">
        //                     </CloseButton>
        //                 </ActionIcon>
        //             </Group>
        //             <br/>
        //         </Stack>
        //     )
        // })
    
        // // @ts-ignore
        // const employers = values?.employers?.map( (employer, index) => {
        //     return (
        //         <Stack>
        //             <br/>
        //             <Divider label={`Employer #${index + 1}`}></Divider>
        //             <Group>
        //                 <TextInput
        //                     mt={8}
        //                     label="Contact"
        //                     {...getInputProps(`employers.${index}.contact`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Phone"
        //                     {...getInputProps(`employers.${index}.phone_number`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Fax Number"
        //                     {...getInputProps(`employers.${index}.fax_number`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //             </Group>
        //             <Textarea
        //                 label="Address Lines"
        //                 {...getInputProps(`employers.${index}.address.lines`)}
        //                 style={{ maxWidth: 500 }}
        //                 >
        //             </Textarea>
        //             <Group>
        //                 <TextInput
        //                     mt={8}
        //                     label="City"
        //                     {...getInputProps(`employers.${index}.address.city`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Postal Code"
        //                     {...getInputProps(`employers.${index}.address.postalCode`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <Select
        //                     mt={8}
        //                     label="State"
        //                     {...getInputProps(`employers.${index}.address.stateOrProvinceCode`)}
        //                     searchable={true}
        //                     style={{ maxWidth: 100 }}
        //                     data={states}
        //                 />
        //                 <ActionIcon color="red" onClick={() => removeListItem('employers', index)}>
        //                     {/* @ts-ignore */}
        //                     <CloseButton size="1rem">
        //                     </CloseButton>
        //                 </ActionIcon>
        //             </Group>
        //             <br/>
        //         </Stack>
        //     )
        // })
    
        // // @ts-ignore
        // const addresses = values?.addresses?.map( (address, index) => {
        //     const {
        //         lines,
        //         city,
        //         state,
        //         postalCode,
        //         stateOrProvinceCode,
        //         countryCode,
        //     } = address
    
        //     return (
        //         <Stack>
        //             <br/>
        //             <Divider></Divider>
        //             <Textarea
        //                 label={ index == 0 ? "Primary address" : `Alternative address #${index}`}
        //                 {...getInputProps(`addresses.${index}.lines`)}
        //                 style={{ maxWidth: 500 }}
        //                 >
        //             </Textarea>
        //             <Group>
        //                 <TextInput
        //                     mt={8}
        //                     label="City"
        //                     {...getInputProps(`addresses.${index}.city`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <TextInput
        //                     mt={8}
        //                     label="Postal Code"
        //                     {...getInputProps(`addresses.${index}.postalCode`)}
        //                     style={{ maxWidth: 300 }}
        //                 />
        //                 <Select
        //                     mt={8}
        //                     label="State"
        //                     {...getInputProps(`addresses.${index}.stateOrProvinceCode`)}
        //                     searchable={true}
        //                     style={{ maxWidth: 100 }}
        //                     data={states}
        //                 />
        //                 <ActionIcon color="red" onClick={() => removeListItem('addresses', index)}>
        //                     {/* @ts-ignore */}
        //                     <CloseButton size="1rem">
        //                     </CloseButton>
        //                 </ActionIcon>
        //             </Group>
        //             <br/>
        //         </Stack>
        //     )
        // })
    
        // // @ts-ignore
        // const phone_numbers = values?.phone_numbers?.map( (phone_number, index) => {
    
        //     return (
        //         <Group mt="xs" key={index}>
        //         <TextInput
        //             mt={8}
        //             label={index == 0 ? `Primary Phone number` : `Alternative number #${index}`}
        //             {...getInputProps(`phone_numbers.${index}`)}
        //             // {...injurySiteProps}
        //             style={{ maxWidth: 300 }}
        //         />
        //         <ActionIcon color="red" onClick={() => removeListItem('phone_numbers', index)}>
        //           <CloseButton 
        //           // @ts-ignore
        //           size="1rem" />
        //         </ActionIcon>
        //       </Group>
        //     )
        // })

    return (
        <Create saveButtonProps={saveButtonProps}
            footerButtons={
                <Group position="right" mt="xl">
                    {currentStep !== 0 && (
                        <Button
                            variant="default"
                            onClick={() => gotoStep(currentStep - 1)}
                        >
                            Back
                        </Button>
                    )}
                    {currentStep !== 1 && (
                        <Button onClick={() => gotoStep(currentStep + 1)}>
                            Next step
                        </Button>
                    )}
                    {currentStep === 1 && <
                    // @ts-ignore
                    SaveButton {...saveButtonProps} />}
                </Group>
            }>
            <Stepper
                active={currentStep}
                onStepClick={gotoStep}
                breakpoint="sm"
            >
                <Stepper.Step
                    label="First Step"
                    description="Main"
                >
                    <TextInput
                        mt={8}
                        label="Name"
                        placeholder="Name"
                        {...getInputProps('name')}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                      mt="md"
                      label="Date Of Birth (MM-DD-YY)"
                      placeholder="Date Of Birth"
                      {...getInputProps("date_of_birth_user_entered")}
                      style={{ maxWidth: 300 }}
                    />
                    <Select
                        mt={8}
                        label="Client"
                        placeholder="Client"
                        {...getInputProps('client_id')}
                        style={{ maxWidth: 300 }}
                        {...clientSelectProps}
                    />
                </Stepper.Step>
                <Stepper.Step
                    label="Second Step"
                    description="Contact"
                >
                  <Textarea 
                    mt={8}
                    label="Patient Info"
                    placeholder="Patient Info"
                    {...getInputProps('patient_info')}
                    minRows={4}
                    style={{ maxWidth: 300 }}
                  />
                  <TextInput
                      mt={8}
                      label="Email"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('email')}
                  />
                  <TextInput
                      mt={8}
                      label="Employer"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('employer')}
                  />
                  <TextInput
                      mt={8}
                      label="Attorney"
                      style={{ maxWidth: 300 }}
                      {...getInputProps('attorney')}
                  />
                </Stepper.Step>
                {/* <Stepper.Step
                    label="Third Step"
                    description="Address(es)"
                >
                    {addresses?.length == 0 ? (
                        <Text>Please add at least one address.</Text>
                    ) : (<><br/></>)}
                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('addresses', {})
                        }
                        >
                        Add An Address
                        </Button>
                    </Group>
                    {addresses}
                </Stepper.Step>
                <Stepper.Step
                    label="Fourth Step"
                    description="Attorney(s)"
                >
                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('attorneys', {address:{}})
                        }
                        >
                        Add An Attorney
                        </Button>
                    </Group>
                    {attorneys}
                </Stepper.Step>
                <Stepper.Step
                    label="Fifth Step"
                    description="Employers(s)"
                >
                    <Group mt="md">
                        <Button
                        onClick={() =>
                            insertListItem('employers', {address:{}})
                        }
                        >
                        Add An Employer
                        </Button>
                    </Group>
                    {employers}
                </Stepper.Step> */}
            </Stepper>
        </Create>
    );
};
