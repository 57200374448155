import { HttpError, useOne, useResource } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/mantine";
import { TextInput } from "@mantine/core";

import { fields, resource } from './common'
import React from "react";

export const InjurySiteEdit: React.FC = () => {

  const { id } = useResource();

    const { 
        data: {
            data: initialValues
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields
        }
    })

    const {
      saveButtonProps,
      getInputProps
  } = useForm< HttpError>({
      refineCoreProps: {
          metaData: {
              fields
          },
      },
      initialValues
  });

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <TextInput
          mt={8}
          label="INJURY SITE"
          {...getInputProps('title')}
          style={{ maxWidth: 300 }}
        />
      </Edit>
    );
};
