import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { UserList, UserEdit, UserCreate, ClientList, ClientCreate, ClientEdit } from "pages/admin";
import { TemplateEdit, TemplateList, } from 'pages/admin/template';
import { 
  EquipmentStatusCreate, EquipmentStatusList, EquipmentStatusEdit,
  InsuranceStatusCreate, InsuranceStatusList, InsuranceStatusEdit,
  PatientStatusCreate, PatientStatusList, PatientStatusEdit
} from "pages/admin/status";
import { ClaimTypeList, ClaimTypeCreate, ClaimTypeEdit } from "pages/lookups/claimTypes";
import { InjurySiteList, InjurySiteCreate, InjurySiteEdit } from "pages/lookups/injurySites"
import { CanAccess } from "@refinedev/core"

const user = <Route
  path='user'
>
  <Route
    index
    element={<UserList></UserList>}
  />
  <Route path='edit/:id' element={<UserEdit/>}/>
  <Route path='create' element={<UserCreate/>}/>
</Route>;

const clients = <Route
  path='clients'
>
  <Route
    index
    element={<ClientList></ClientList>}
  />
  <Route path='edit/:id' element={<ClientEdit/>}/>
  <Route path='create' element={<ClientCreate/>}/>
</Route>;

const template = <Route
  path='template'
>
  <Route
    index
    element={<TemplateList></TemplateList>}
  />
  <Route path='edit/:id' element={<TemplateEdit/>}/>
</Route>;

const status = <Route
  path='status'
>
  <Route
    path='equipment'
  >
    <Route
      index
      element={<EquipmentStatusList></EquipmentStatusList>}
    />
    <Route path='edit/:id' element={<EquipmentStatusEdit/>}/>
    <Route path='create' element={<EquipmentStatusCreate/>}/>
  </Route>
  <Route
    path='patient'
  >
    <Route
      index
      element={<PatientStatusList></PatientStatusList>}
    />
    <Route path='edit/:id' element={<PatientStatusEdit/>}/>
    <Route path='create' element={<PatientStatusCreate/>}/>
  </Route>
  <Route
    path='insurance'
  >
    <Route
      index
      element={<InsuranceStatusList></InsuranceStatusList>}
    />
    <Route path='edit/:id' element={<InsuranceStatusEdit/>}/>
    <Route path='create' element={<InsuranceStatusCreate/>}/>
  </Route>
</Route>;

const listOfValues = <Route
  path='listOfValues'
>
  <Route
    path='claim_type'
  >
    <Route
      index
      element={<ClaimTypeList></ClaimTypeList>}
    />
    <Route path='edit/:id' element={<ClaimTypeEdit/>}/>
    <Route path='create' element={<ClaimTypeCreate/>}/>
  </Route>
  <Route
    path='injury_site'
  >
    <Route
      index
      element={<InjurySiteList></InjurySiteList>}
    />
    <Route path='edit/:id' element={<InjurySiteEdit/>}/>
    <Route path='create' element={<InjurySiteCreate/>}/>
  </Route>
</Route>;

export const admin = <Route
  path='admin'
  element={<CanAccess>
    <Outlet></Outlet>
  </CanAccess>}
>
  {user}
  {clients}
  {template}
  {status}
  {listOfValues}
</Route>