import React, { useContext, useEffect, useState } from "react";
import { UserContext } from '../../../contexts/userContext';
import { useTable } from "@refinedev/react-table";
import { ColumnDef, flexRender } from "@tanstack/react-table";
import { EditButton, DateField } from "@refinedev/mantine";
import { Box, Group, ScrollArea, Table, Pagination, Select, Grid } from "@mantine/core";

import dayjs from 'dayjs';

import { List } from "components/ghettoList";

import { list_fields, columnsIdsToFilters, sortersToFilter } from './common'

import { ColumnSorter, ColumnFilter, ClientSelect } from '../../../components';
import { useGetIdentity, useList, useGo } from "@refinedev/core";
import { isAffectrixClientId } from "utilities";

function renderDate({ getValue }:any) {
    return dayjs(getValue()).isValid() ? (
        <DateField format="MM-DD-YY" value={getValue()} />
    ) : (
        <>N/A</>
    );
}

const columns: ColumnDef<any>[] = [
  { id: "product_type.title", accessorKey: "product_type.title", header: "Product", enableColumnFilter: false, enableSorting: false, },
  { id: "serial_number", accessorKey: "serial_number", header: "Serial Number", enableColumnFilter: true, enableSorting: false, meta: {
    filterOperator: 'contains'
  }},
  { id: "equipment_status.title", 
      accessorFn: (row) => row?.equipment_status?.title, header: "Status", enableColumnFilter: false, enableSorting: false, },
  { id: "referral.next_call_date", accessorFn: (row) => {
    const next_call_date = row?.referral?.next_call_date || 'N/A';
    return next_call_date
    },header: "Next Call Date", enableColumnFilter: false, enableSorting: false
  },
  // pickup_date
  { id: "referral.pickup_date", accessorFn: (row) => {
    const pickup_date = row?.referral?.pickup_date || 'N/A';
    return pickup_date
    },header: "Pickup Date", enableColumnFilter: false, enableSorting: false
  },
  { id: "claimant", header: 'Name',
      accessorFn: (row) => {
        const location = row?.location?.title;
        const patient = row?.patient?.name_and_dob;
        const representative = row?.representative?.name;
        const provider = row?.provider?.name;
        const fitter = row?.fitter?.title;
        const entity = row?.entity;
        const claimant = location || patient || representative || provider || fitter || entity;
        return claimant;
      }, enableColumnFilter: false
  },
  // associated to type
  { id: "entity_type", accessorFn: (row) => {
      const isLocation = row?.location?.id ? 'location' : null;
      const isPatient = row?.patient?.id ? 'patient' : null;
      const isRepresentative = row?.representative?.id ? 'rep' : null;
      const isProvider = row?.provider?.id ? 'provider' : null;
      const isFitter = row?.fitter?.id ? 'fitter' : null;
      const entity = isLocation || isPatient || isRepresentative || isProvider || isFitter || '?';
      return entity
      }, enableColumnFilter: false
  },
];

function RadioSelect(props:any) {

  const {
    setFilters,
    user_filters = [],
    filters = [],
    uid,
    maybeFiltersForThisUser = false,
    showAll = false,
    currentUserState,
      setCurrentUserState
  //   tableFilters,
  } = props;

  // user_filters <~> filters
  const mappedFilters = filters
  // @ts-ignore
  .filter( x => {
    const {
      table
    } = x;
    return table == 'product'
  })
  // @ts-ignore
  .filter( filter => {
    const noFiltersAssignedToThisUser = !maybeFiltersForThisUser;
    if (noFiltersAssignedToThisUser){
      return true
    }

    const { user_filters } = filter;
    // @ts-ignore
    const userHasThisFilter = user_filters.some( user_filter => {
      const { authed_user: {id}} = user_filter;
      return id == uid
    })

    return userHasThisFilter || showAll
  })
  .map( (x:any) => {
      const {
          // filter:{
              // value,
              title,
              filter
          // }
      } = x
      return {
          value: title,
          label: title,
          active_filter: filter
      }
      // @ts-ignore
  }).sort( (a,b) => {
    const {
      label: la
    } = a;
    const {
      label: lb
    } = b;
    return la > lb ? 1 : -1
  })

  const [value, setValue] = React.useState(null);

  // @ts-ignore
  let clientFilter = [];
  if (currentUserState?.clientId){
    clientFilter = [
      {
        field: 'client_id',
        operator: 'eq',
        value: currentUserState.clientId
      }
    ]
  }

  const handleChange = (selectValue:any) => {

      setValue(selectValue);

      if (!selectValue){
          // @ts-ignore
          setFilters([...clientFilter], 'replace');

          setCurrentUserState({
            ...currentUserState,
            productFilter: undefined
          })
      }

      const thing = mappedFilters.filter( ({value}:any) => value == (selectValue)) || [{active_filter: false}];

      if (thing.length == 1){

          const [ {active_filter} ] = thing

          setFilters([
            ...active_filter,
            // @ts-ignore
            ...clientFilter
          ], 'replace' );

          setCurrentUserState({
            ...currentUserState,
            productFilter: {
              title: selectValue,
              active_filter,
            }
          })
      } else {

      }

  };

  return (
      <>
          <Select defaultValue={currentUserState?.productFilter?.title}
          label={ showAll ? 'All Filters' : 'Filters assigned to User' } data={mappedFilters} onChange={handleChange} searchable clearable style={{ maxWidth: 300 }}>
              
          </Select>
      </>
  );
}

export const ProductList: React.FC = () => {

  const [ columnState, setColumnState ] = useState(columns);

  // @ts-ignore
  const { userState, setUserState } = useContext(UserContext);

  const go = useGo();

  const {
    data: {
      // @ts-ignore

      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  const [ shouldQuery, setShouldQuery ] = useState(false);

  const {
    getHeaderGroups,
    getRowModel,
    refineCore: { 
      setCurrent,
      filters,
      setFilters,
      setSorters,
      pageCount, 
      current,
      tableQueryResult: { data: tableData },
    },
  } = useTable({
    // @ts-ignore
    columns: columnState.filter( x => x?.meta?.visible),

    refineCoreProps: {
      resource: 'product',

      metaData: {
          fields: list_fields
      },
      queryOptions: {
        // disable query if the user belongs to the AFX client and the client of the query is undefined
        enabled: !(isAffectrixClientId(clientId) && userState?.clientId == null)
      },
      pagination: {
        pageSize: 50
      },
    }
  });

  const {
    data: {
        // @ts-ignore
        data: dataFilters = []
    } = {}
  } = useList({
    resource: 'filter',

    meta: {
      fields: [
        'title',
        'filter',
        'table',
        {
          user_filters: [
            {
              authed_user: [
                'id', 'email', 'display_name'
              ]
            }
          ]
        }
      ],
        // TODO does not work
        // sorters:[
        //   {
        //     field: 'title',
        //     order: 'asc'
        //   }
        // ]
    },

    filters: [
      {
        field: 'table',
        operator: 'eq',
        value: 'product'
      }
    ],

    pagination:{
        pageSize: 100
    }
  })

  useEffect( () => {
    // disable until construction of query is complete
    setShouldQuery(false);

    const currentFilter = userState?.productFilter?.title;

    //@ts-ignore
    const columnsForCurrentFilter = columnsIdsToFilters[currentFilter] || columnsIdsToFilters['DEFAULT'];
    // @ts-ignore
    setColumnState(prev => prev.map((col) => {
      // @ts-ignore
      const visible = columnsForCurrentFilter.some( colId => col.id == colId);
      return {
        ...col,
        meta: {
          ...col.meta,
          visible
        }
      }
    }));

      // @ts-ignore
    let filters = [];
    if(userState?.productFilter?.active_filter){
      // setFilters(userState.productFilter.active_filter, 'replace');
      filters = [...userState?.productFilter?.active_filter]
    }
    if (userState?.clientId){
      filters = [
        // @ts-ignore
        ...filters,
        {
          field: 'client_id',
          operator: 'eq',
          value: userState.clientId
        }
      ]
    }

    // @ts-ignore
    setFilters(filters, 'replace');

    // SORTERS
    // @ts-ignore
    const sortersForCurrentFilter = sortersToFilter[currentFilter] || sortersToFilter['DEFAULT'];
    setSorters(sortersForCurrentFilter);


  }, [userState, clientId]);

  useEffect( () => {
    if (clientId){
      // behavior specific to AFX/STRIVE users
      if (isAffectrixClientId(clientId)){
        const hasClient = filters.some( ({field,value}:any) => 
          field == 'client_id' &&
          value.length > 0
        );
        if (hasClient){
          setShouldQuery(true);
        }
      } else {
        setShouldQuery(true);
      }
    }
  }, [filters,clientId])

  // @ts-ignore
  const maybeFiltersForThisUser = dataFilters.filter( x => {
    const {
      table
    } = x;
    return table == 'product'
  })
  // @ts-ignore
  .some( filter => {
    const { user_filters } = filter;
    // @ts-ignore
    const userHasThisFilter = user_filters.some( user_filter => {
      const { authed_user: {id}} = user_filter;
      return id == uid
    })

    return userHasThisFilter
  })

  return (
    <ScrollArea>
    <List>
    {/* { clientId == '279a1514-9914-49b2-af74-027610efd80f' && <Grid>
        <Grid.Col span={4}>
          <ClientSelect 
          setFilters={setFilters}
          filters={filters}
          table='product'
          ></ClientSelect>
        </Grid.Col>
      </Grid>} */}
      <Grid>
        {maybeFiltersForThisUser && <Grid.Col span={4}>
          <RadioSelect 
          setFilters={setFilters} 
          // user_filters={user_filters} 
          uid={uid}
          // tableFilters={filters}
          filters={dataFilters}
          maybeFiltersForThisUser
          currentUserState={userState}
          setCurrentUserState={setUserState}
          ></RadioSelect>
        </Grid.Col>}
        <Grid.Col span={4}>
          <RadioSelect 
          setFilters={setFilters} 
          // user_filters={user_filters} 
          uid={uid}
          // tableFilters={filters}
          filters={dataFilters}
          showAll={true}

          currentUserState={userState}
          setCurrentUserState={setUserState}
          ></RadioSelect>
        </Grid.Col>
      </Grid>
      <Table highlightOnHover>
          <thead>
              {getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                          return (
                              <th key={header.id}>
                                  {!header.isPlaceholder && (
                                      <Group spacing="xs" noWrap>
                                          <Box>
                                              {flexRender(
                                                  header.column
                                                      .columnDef
                                                      .header,
                                                  header.getContext(),
                                              )}
                                          </Box>
                                          <Group spacing="xs" noWrap>
                                              <ColumnSorter
                                                  column={
                                                      header.column
                                                  }
                                              />
                                              <ColumnFilter
                                                  column={
                                                      header.column
                                                  }
                                              />
                                          </Group>
                                      </Group>
                                  )}
                              </th>
                          );
                      })}
                  </tr>
              ))}
          </thead>
          <tbody>
              {getRowModel().rows.map((row) => {
                  // rep com notes
                  // pu needed => light red
                  // pu scheduled => light green

                  // next call date
                  // if actions needed /EXT|RX EXT|LIFETIME/ => light orange

                  // provider
                  // /SOTERANOS/ => ???

                  // patient info
                  // claim type /MI AUTO/
                  return (
                      <tr key={row.id} onClick={() => go({
                        to: `edit/${row.original.id}`
                      })}>
                          {row.getVisibleCells().map((cell) => {
                              return (
                                  <td key={cell.id}>
                                      {flexRender(
                                          cell.column.columnDef.cell,
                                          cell.getContext(),
                                      )}
                                  </td>
                              );
                          })}
                      </tr>
                  );
              })}
          </tbody>
      </Table>
      <br />
      <Pagination
          position="right"
          total={pageCount}
          page={current}
          onChange={setCurrent}
      />
    </List>
  </ScrollArea>
  );
};
