import { HttpError, useResource, useNavigation, useGo, useGetIdentity } from "@refinedev/core";
import { Edit, useForm, useSelect, DeleteButton } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";

import React, { useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mantine/dates";

export const ClientEdit: React.FC = () => {

  const go = useGo();

  const {
    data: {
      // @ts-ignore
        claims: {
          "x-hasura-default-role": role = ''

        } = {}
    } = {}
  } = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

  useEffect( () => {
    if (role && role != 'admin'){
      console.log('unauthorized')
      go({to:'/'});
    }
  }, [role])

  const { id } = useResource();

  const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('admin/user');
  }

    const {
        saveButtonProps,
        refineCore: { queryResult, onFinish, formLoading, redirect },
        getInputProps,
        values,
        
    } = useForm< HttpError>({
        refineCoreProps: {
            resource: 'clients',
            id,
            metaData: {
                fields: [
                  'id',
                  'name',
                  'title'
                ]
            },
            onMutationSuccess,
            redirect: false
        },
        initialValues: {
            name: "",
            title: ""
        },
    });

  const datePickerValue = (field:string) =>
        dayjs(values[field] as Date).isValid() ? dayjs(values[field] as Date).toDate() : undefined

    return (
      <Edit saveButtonProps={saveButtonProps}>
        <form>
            <TextInput
                  mt={8}
                  label="Title"
                  placeholder="Uppercase"
                  {...getInputProps('title')}
              />
              <TextInput
                  mt={8}
                  label="Name"
                  placeholder="Lowercase"
                  {...getInputProps('name')}
              />
            <br/>
            <br/>
        </form>
      </Edit>
    );
};
