import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ReferralCreate, ReferralEdit, ReferralList } from "pages/operations";
import { ExtensionList, ExtensionEdit } from "pages/operations/extensions";
import { SurveyList } from "pages/operations/surveys/list";

const referral = <Route
  path='referral' 
>
  <Route
    index
    element={<ReferralList></ReferralList>}
  />
  <Route path='edit/:id' element={<ReferralEdit/>}/>
  <Route path='create' element={<ReferralCreate/>}/>
</Route>;

const extension = <Route
  path='extension'
>
  <Route
    index
    element={<ExtensionList></ExtensionList>}
  />
  <Route path='edit/:id' element={<ExtensionEdit/>}/>
</Route>;

const survey = <Route
  path='surveys'
>
  <Route
    index
    element={<SurveyList></SurveyList>}
  />
  {/* <Route path='edit/:id' element={<SurveyEdit/>}/> */}
</Route>;

export const operations = <Route
  path='operations'
>
  {referral}
  {extension}
  {survey}
</Route>