import { HttpError, useNavigation, useOne, useResource } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";
import { Edit as SwizzledEdit } from 'components/crud/edit';

import { fields, resource } from './common'
import React from "react";

export const RepEdit: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/representative');
    }

  const { id } = useResource();

    const { 
        data: {
            data: initialValues
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields
        }
    })

    const {
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      getInputProps,
      values
  } = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields
        },
        onMutationSuccess,
        autoSave: {
          enabled: true,
        }
      },
      initialValues,

    transformValues: (values) => {
        const {
            client,
        provider_representatives,
        representative_teams,
        providers,
        team,
        id,
        ...rest
        } = values
        return rest
    },
  });

//   const { selectProps: clientSelectProps } = useSelect({
//     resource: "clients",
//     defaultValue: queryResult?.data?.data.client_id,
//     optionLabel: 'name',
//     metaData: {
//       fields: ["id", "name"],
//     },
//   });

    const { selectProps: teamSelectProps } = useSelect({
        resource: "team",
        defaultValue: undefined,
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    // TODO port the contact and address sections of provider and patient to reps
    // also modify schema too

    return (
      <SwizzledEdit saveButtonProps={saveButtonProps}>
        <form>
          <Tabs defaultValue={'first'}>
            <Tabs.List>
              <Tabs.Tab value="first">Main</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
              <TextInput
                mt={8}
                label="Name"
                placeholder="Name"
                {...getInputProps('rep')}
                style={{ maxWidth: 300 }}
              />
              <TextInput
                mt={8}
                label="Email"
                placeholder="Email"
                {...getInputProps('email')}
                style={{ maxWidth: 300 }}
              />
              <Select
                mt={8}
                label="Manager Email"
                style={{ maxWidth: 300 }}
                {...getInputProps('manager_email')}
                clearable
                data={[
                  "mroyle@matchonemedical.com",
                  "francism@matchonemedical.com",
                  "joeh@matchonemedical.com",
                  "chazj@matchonemedical.com",
                ]}
              />
              <Select
                mt={8}
                label="Employment Type"
                style={{ maxWidth: 300 }}
                {...getInputProps('employment_type')}
                clearable
                data={[
                  { label: '1099', value: "1099" },
                  { label: 'W2', value: "W2" },
                  { label: 'Manager', value: "Manager" },
                ]}
              />
              <TextInput
                mt={8}
                label="Extension Link"
                placeholder="Extension Link"
                {...getInputProps('extension_link')}
                style={{ maxWidth: 300 }}
              />
              <TextInput
                mt={8}
                label="Rep Sort"
                placeholder="Rep Sort"
                {...getInputProps('sort')}
                style={{ maxWidth: 300 }}
                type="number"
              />
            </Tabs.Panel>
          </Tabs>
        </form>
        {/* <pre>{JSON.stringify(values,null,2)}</pre> */}
      </SwizzledEdit>
    );
};
