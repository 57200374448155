import { HttpError, useNavigation, useResource, useOne } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";

import { fields, resource } from './common'
import React from "react";

export const FitterEdit: React.FC = () => {

    const { id } = useResource();

    const { 
        data: {
            data: initialValues = {}
        } = {}
    } = useOne({
        resource,
        id,
        meta:{
            fields
        }
    })

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/fitter');
    }

    const {
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      getInputProps
  } = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields
        },
        autoSave: {
          enabled: true,
        },
        onMutationSuccess
      },
      initialValues,
      transformValues: (values) => {
        const {
          id,
          ...rest
        } = values;

        return rest
      }
  });

    return (
      <Edit saveButtonProps={{}}>
        <form>
        <Tabs defaultValue={'first'}>
            <Tabs.List>
                <Tabs.Tab value="first">Main</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Name"
                    {...getInputProps('title')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Email"
                    placeholder="Email"
                    {...getInputProps('email')}
                    style={{ maxWidth: 300 }}
                />
            </Tabs.Panel>
        </Tabs>
        </form>
      </Edit>
    );
};
