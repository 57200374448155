import { HttpError, useGetIdentity, useNavigation } from "@refinedev/core";
import { Create, useStepsForm, SaveButton, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput, Group, Button } from "@mantine/core";
import { resource } from "./common";


export const RepCreate: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/representative');
        return
    }

    const {
      data: {
        // @ts-ignore

          claims: {
          // "x-hasura-user-id": uid,
          "x-hasura-client-id": client_id = ''
          } = {}
      } = {}
  } = useGetIdentity({
        // v3LegacyAuthProviderCompatible: true
    });

    const {
        saveButtonProps,
        getInputProps, 
        errors,
        steps: { currentStep, gotoStep },
        values,
    } = useStepsForm<HttpError>({
        initialValues: {
            // ...filteredInitialValues,
            client_id,
            provider_representatives: {
                data: []
            }
        },
        refineCoreProps: {
            resource,
            onMutationSuccess,
            redirect:false
        },
        validate: (values:any) => {
            const {
                rep,
                // team_id
            } = values;

            return {
                // team_id: team_id?.length ? null : "Please select a team.",
                rep: rep?.length > 0 ? null : "Please define the rep."
            }
        }
    });

    // const { selectProps: clientSelectProps } = useSelect({
    //     resource: "clients",
    //     defaultValue: undefined,
    //     optionLabel: 'name',
    //     metaData: {
    //         fields: ["id", "name"],
    //     },
    // });
    const { selectProps: teamSelectProps } = useSelect({
        resource: "team",
        defaultValue: undefined,
        optionLabel: 'title',

        meta: {
            fields: ["id", "title"],
        },

        pagination: {
            mode: "server"
        }
    });

    return (
        <Create saveButtonProps={saveButtonProps}
            footerButtons={
                <Group position="right" mt="xl">
                    {/* {currentStep !== 0 && (
                        <Button
                            variant="default"
                            onClick={() => gotoStep(currentStep - 1)}
                        >
                            Back
                        </Button>
                    )}
                    {currentStep !== 3 && (
                        <Button onClick={() => gotoStep(currentStep + 1)}>
                            Next step
                        </Button>
                    )} */}
                    { <
                      // @ts-ignore
                      SaveButton {...saveButtonProps} />}
                </Group>
            }>
            <form>
                <Tabs defaultValue={'first'}>
                    <Tabs.List>
                        <Tabs.Tab value="first">Main</Tabs.Tab>
                        <Tabs.Tab value="second">Contact</Tabs.Tab>
                        <Tabs.Tab value="third">Alt Contact</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                        <TextInput
                            mt={8}
                            label="Name*"
                            placeholder="Name"
                            {...getInputProps('rep')}
                        />
                        <TextInput
                            mt={8}
                            label="Manager Email*"
                            placeholder="Manager Email"
                            {...getInputProps('manager_email')}
                        />
                        {/* <Select
                            mt={8}
                            label="Team*"
                            placeholder="Team*"
                            {...getInputProps('team_id')}
                            {...teamSelectProps}
                        /> */}
                    </Tabs.Panel>
                    <Tabs.Panel value="second">
                        <TextInput
                            mt={8}
                            label="Phone Number"
                            placeholder="Phone Number"
                            {...getInputProps('phone_number_main')}
                        />
                        <Select
                            mt={8}
                            label="Phone Number Type"
                            placeholder="Pick One"
                            {...getInputProps('phone_number_main_type')}
                            data={[
                                { label: "Personal", value: "personal" },
                                { label: "Business", value: "business" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                        <TextInput
                            mt={8}
                            label="Fax Number"
                            placeholder="Fax Number"
                            {...getInputProps('fax_number')}
                        />
                        <TextInput
                            mt={8}
                            label="Email*"
                            placeholder="Email"
                            {...getInputProps('email')}
                        />
                        <Textarea
                            mt={8}
                            label="Address"
                            placeholder="Address"
                            {...getInputProps('address_main')}
                            minRows={3}
                        />
                        <Select
                            mt={8}
                            label="Address Type"
                            placeholder="Pick One"
                            {...getInputProps('address_main_type')}
                            data={[
                                { label: "Home", value: "home" },
                                { label: "Commercial", value: "commercial" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="third">
                        <TextInput
                            mt={8}
                            label="Alt Phone Number"
                            placeholder="Alt Phone Number"
                            {...getInputProps('phone_number_alt')}
                        />
                        <Select
                            mt={8}
                            label="Alt Phone Number Type"
                            placeholder="Pick One"
                            {...getInputProps('phone_number_alt_type')}
                            data={[
                                { label: "Personal", value: "personal" },
                                { label: "Business", value: "business" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                        <Textarea
                            mt={8}
                            label="Alternate Address"
                            placeholder="Alternate Address"
                            {...getInputProps('address_alt')}
                            minRows={3}
                        />
                        <Select
                            mt={8}
                            label="Alternate Address Type"
                            placeholder="Pick One"
                            {...getInputProps('address_alt_type')}
                            data={[
                                { label: "Home", value: "home" },
                                { label: "Commercial", value: "commercial" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                    </Tabs.Panel>
                </Tabs>
            </form>
        </Create>
    );
};
