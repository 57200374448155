import { GraphQLClient } from "@refinedev/hasura";
import * as gql from "gql-query-builder";
import { CrudOperators, CrudFilters, CrudSorting, DataProvider, CrudFilter } from "@refinedev/core";
import setWith from "lodash/setWith";
import set from "lodash/set";

// export type HasuraSortingType = Array<{ field: string; order: "asc" | "desc" }>;

// export type GenerateSortingType = {
//     (sorting?: CrudSorting): HasuraSortingType | undefined;
// };

const generateNestedSorting = (sorter:any) => {
  if (!sorter) {
      return undefined;
  }

  const { field, order } = sorter;

  const fieldsArray = field.split(".");

  return {
    ...(setWith({}, fieldsArray, order, Object))
  };
};

const generateSorting = (sorters:any[] | undefined) => {
  if (!sorters){
    return undefined
  }

  return sorters.map(generateNestedSorting)
}

    export type HasuraFilterCondition =
        | "_and"
        | "_not"
        | "_or"
        | "_eq"
        | "_gt"
        | "_gte"
        | "_lt"
        | "_lte"
        | "_neq"
        | "_in"
        | "_nin"
        | "_like"
        | "_nlike"
        | "_ilike"
        | "_nilike"
        | "_is_null"
        | "_similar"
        | "_nsimilar"
        | "_regex"
        | "_iregex"
        
        | "_niregex";

    const hasuraFilters: Record<CrudOperators, HasuraFilterCondition | undefined> =
        {
            eq: "_eq",
            ne: "_neq",
            lt: "_lt",
            gt: "_gt",
            lte: "_lte",
            gte: "_gte",
            in: "_in",
            nin: "_nin",
            contains: "_ilike",
            ncontains: "_nilike",
            containss: "_like",
            ncontainss: "_nlike",
            null: "_is_null",
            or: "_or",
            and: "_and",
            between: undefined,
            nbetween: undefined,
            nnull: "_is_null",
            startswith: "_iregex",
            nstartswith: "_iregex",
            endswith: "_iregex",
            nendswith: "_iregex",
            startswiths: "_similar",
            nstartswiths: "_nsimilar",
            endswiths: "_similar",
            nendswiths: "_nsimilar",
            // @ts-ignore
            iregex:"_iregex",
            niregex: "_niregex"
        };

    export const handleFilterValue = (operator: CrudOperators, value: any) => {
        switch (operator) {
            case "contains":
            case "containss":
            case "ncontains":
            case "ncontainss":
                return `%${value}%`
            case "startswiths":
            case "nstartswiths":
                return `${value}%`;
            case "endswiths":
            case "nendswiths":
                return `%${value}`;
            case "startswith":
                return `^${value}`;
            case "nstartswith":
                return `^(?!${value})`;
            case "endswith":
                return `${value}$`;
            case "nendswith":
                return `(?<!${value})$`;
            case "nnull":
                return false;
            // @ts-ignore
            case "iregex":
            // @ts-ignore
            case "niregex":
                //validate regex
                // return value
            default:
                return value;
        }
    };

    const generateNestedFilterQuery = (filter: CrudFilter): any => {
        const { operator } = filter;

        if (operator !== "or" && operator !== "and" && "field" in filter) {
            const { field, value } = filter;

            const hasuraOperator = hasuraFilters[filter.operator];
            if (!hasuraOperator) {
                throw new Error(`Operator ${operator} is not supported`);
            }

            const fieldsArray = field.split(".");
            const fieldsWithOperator = [...fieldsArray, hasuraOperator];
            const filteredValue = handleFilterValue(operator, value);

            return {
                ...setWith({}, fieldsWithOperator, filteredValue, Object),
            };
        }

        return {
            [`_${operator}`]: filter.value.map((f) => generateNestedFilterQuery(f)),
        };
    };

    export const generateFilters: any = (filters?: CrudFilters) => {
        if (!filters) {
            return undefined;
        }

        const nestedQuery = generateNestedFilterQuery({
            operator: "and",
            value: filters,
        });

        return nestedQuery;
    };

type IDType = "uuid" | "Int" | "String" | "Numeric";

export type HasuraDataProviderOptions = {
    idType?: IDType | ((resource: string) => IDType);
};

const dataProvider = (
    client: GraphQLClient,
    options?: HasuraDataProviderOptions,
): Required<DataProvider> => {
    const { idType } = options ?? {};

    const getIdType = (resource: string) => {
        if (typeof idType === "function") {
            return idType(resource);
        }
        return idType ?? "uuid";
    };

    return {
        getOne: async ({ resource, id, metaData }) => {
            const operation = `${metaData?.operation ?? resource}_by_pk`;

            const { query, variables } = gql.query({
                operation,
                variables: {
                    id: { 
                        value: id, 
                        type: getIdType(resource), 
                        required: true 
                    },
                    ...metaData?.variables,
                },
                fields: metaData?.fields,
            });

            const response = await client.request(query, variables);

            return {
              // @ts-ignore
                data: response[operation],
            };
        },

        getMany: async ({ resource, ids, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const { query, variables } = gql.query({
                operation,
                fields: metaData?.fields,
                variables: metaData?.variables ?? {
                    where: {
                        type: `${operation}_bool_exp`,
                        value: {
                            id: {
                                _in: ids,
                            },
                        },
                    },
                },
            });

            const result = await client.request(query, variables);

            return {
              // @ts-ignore
                data: result[operation],
            };
        },

        getList: async ({
            resource,
            sort,
            filters,
            hasPagination = true,
            pagination = { current: 1, pageSize: 10 },
            metaData,
        }) => {
            const { current = 1, pageSize: limit = 10 } = pagination ?? {};

            const hasuraSorting = generateSorting(sort);
            const hasuraFilters = generateFilters(filters);

            const operation = metaData?.operation ?? resource;

            const aggregateOperation = `${operation}_aggregate`;

            const hasuraSortingType = `[${operation}_order_by!]`;
            const hasuraFiltersType = `${operation}_bool_exp`;

            const { query, variables } = gql.query([
                {
                    operation,
                    fields: metaData?.fields,
                    variables: {
                        ...(hasPagination
                            ? {
                                  limit,
                                  offset: (current - 1) * limit,
                              }
                            : {}),
                        ...(hasuraSorting && {
                            order_by: {
                                value: hasuraSorting,
                                type: hasuraSortingType,
                            },
                        }),
                        ...(hasuraFilters && {
                            where: {
                                value: hasuraFilters,
                                type: hasuraFiltersType,
                            },
                        }),
                    },
                },
                {
                    operation: aggregateOperation,
                    fields: [{ aggregate: ["count"] }],
                    variables: {
                        where: {
                            value: hasuraFilters,
                            type: hasuraFiltersType,
                        },
                    },
                },
            ]);

            const result = await client.request(query, variables);

            return {
              // @ts-ignore
                data: result[operation],
                // @ts-ignore
                total: result[aggregateOperation].aggregate.count,
            };
        },

        create: async ({ resource, variables, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const insertOperation = `insert_${operation}_one`;
            const insertType = `${operation}_insert_input`;

            const { query, variables: gqlVariables } = gql.mutation({
                operation: insertOperation,
                variables: {
                    object: {
                        type: insertType,
                        value: variables,
                        required: true,
                    },
                },
                fields: metaData?.fields ?? ["id", ...Object.keys(variables || {})],
            });

            const response = await client.request(query, gqlVariables);

            return {
              // @ts-ignore
                data: response[insertOperation],
            };
        },

        createMany: async ({ resource, variables, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const insertOperation = `insert_${operation}`;
            const insertType = `[${operation}_insert_input!]`;

            const { query, variables: gqlVariables } = gql.mutation({
                operation: insertOperation,
                variables: {
                    objects: {
                        type: insertType,
                        value: variables,
                        required: true,
                    },
                },
                fields: [
                    {
                        returning: metaData?.fields ?? ["id"],
                    },
                ],
            });

            const response = await client.request(query, gqlVariables);

            return {
              // @ts-ignore
                data: response[insertOperation]["returning"],
            };
        },

        update: async ({ resource, id, variables, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const updateOperation = `update_${operation}_by_pk`;

            const pkColumnsType = `${operation}_pk_columns_input`;
            const setInputType = `${operation}_set_input`;

            const {
                //@ts-ignore
                _append = {}
            } = variables

            let thing = {}
            if (Object.keys(_append).length > 0){
                thing = {
                    _append: {
                        type: `${operation}_append_input`,
                        value: _append
                    }
                }
            } 
            
            thing = {
                ...thing,
                _set: {
                    type: setInputType,
                    value: variables,
                    required: true,
                }
            }
            

            const { query, variables: gqlVariables } = gql.mutation({
                operation: updateOperation,
                variables: {
                    pk_columns: {
                        type: pkColumnsType,
                        value: {
                            id: id,
                        },
                        required: true,
                    },
                    ...thing,
                },
                fields: metaData?.fields ?? ["id"],
            });

            const response = await client.request(query, gqlVariables);

            return {
              // @ts-ignore
                data: response[updateOperation],
            };
        },
        updateMany: async ({ resource, ids, variables, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const updateOperation = `update_${operation}`;

            const whereType = `${operation}_bool_exp`;
            const setInputType = `${operation}_set_input`;

            const { query, variables: gqlVariables } = gql.mutation({
                operation: updateOperation,
                variables: {
                    where: {
                        type: whereType,
                        value: {
                            id: {
                                _in: ids,
                            },
                        },
                        required: true,
                    },
                    _set: {
                        type: setInputType,
                        value: variables,
                        required: true,
                    },
                },
                fields: [
                    {
                        returning: metaData?.fields ?? ["id"],
                    },
                ],
            });

            const response = await client.request(query, gqlVariables);

            return {
              // @ts-ignore
                data: response[updateOperation]["returning"],
            };
        },

        deleteOne: async ({ resource, id, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const deleteOperation = `delete_${operation}_by_pk`;

            const { query, variables } = gql.mutation({
                operation: deleteOperation,
                variables: {
                    id: { 
                        value: id, 
                        type: getIdType(resource), 
                        required: true 
                    },
                    ...metaData?.variables,
                },
                fields: metaData?.fields ?? ["id"],
            });

            const response = await client.request(query, variables);

            return {
              // @ts-ignore
                data: response[deleteOperation],
            };
        },

        deleteMany: async ({ resource, ids, metaData }) => {
            const operation = metaData?.operation ?? resource;

            const deleteOperation = `delete_${operation}`;

            const whereType = `${operation}_bool_exp`;

            const { query, variables } = gql.mutation({
                operation: deleteOperation,
                fields: [
                    {
                        returning: metaData?.fields ?? ["id"],
                    },
                ],
                variables: metaData?.variables ?? {
                    where: {
                        type: whereType,
                        required: true,
                        value: {
                            id: {
                                _in: ids,
                            },
                        },
                    },
                },
            });

            const result = await client.request(query, variables);

            return {
              // @ts-ignore
                data: result[deleteOperation]["returning"],
            };
        },

        getApiUrl: () => {
            throw new Error(
                "getApiUrl method is not implemented on refine-hasura data provider.",
            );
        },

        custom: async ({ url, method, headers, meta }) => {
            let gqlClient = client;

            // if (url) {
            //     gqlClient = new GraphQLClient(url, { headers });
            // }

            if (meta) {
                if (meta.custom){
                  if (meta.query){
                    console.log('wtf')
                    const response = await client.request(
                      meta.query,
                      meta.variables
                    )
                    // @ts-ignore
                    const data = Object.values(response);
                    return { data }
                  }
                }
                if (meta.operation) {
                    if (method === "get") {
                        const { query, variables } = gql.query({
                            operation: meta.operation,
                            fields: meta.fields,
                            variables: meta.variables,
                        });

                        const response = await gqlClient.request(
                            query,
                            variables,
                        );

                        return {
                          // @ts-ignore
                            data: response[meta.operation],
                        };
                    } else {
                        const { query, variables } = gql.mutation({
                            operation: meta.operation,
                            fields: meta.fields,
                            variables: meta.variables,
                        });

                        const response = await gqlClient.request(
                            query,
                            variables,
                        );

                        return {
                          // @ts-ignore
                            data: response[meta.operation],
                        };
                    }
                } else {
                    throw Error("GraphQL operation name required.");
                }
            } else {
                throw Error(
                    "GraphQL need to operation, fields and variables values in metaData object.",
                );
            }
        },
    };
};

export default dataProvider;