
import { HttpError, useNavigation, useOne, useResource } from "@refinedev/core";
import { EditButton, useForm, useSelect } from "@refinedev/mantine";

import {
    ActionIcon,
    Anchor,
    CloseButton,
    Group,
    Select,
    Table,
    Text,
    Tabs,
    Textarea,
    TextInput,
    Stack,
    Divider,
    Button,
} from "@mantine/core";

import { Edit as SwizzledEdit } from 'components/crud/edit';

import { fields, provider_representatives, resource } from './common'
import React, { useEffect, useState } from "react";

export const ProviderEdit: React.FC = () => {

    const { list } = useNavigation();

  const onMutationSuccess = (data: any, variables: any, context: any) => {
    list('directory/provider');
  }

  const { id } = useResource();

  const {
        data: {
            data: initialValues
        } = {}
    } = useOne<any>({
        resource,
        id,
        meta: {
            fields
        },
    })

    const {
      saveButtonProps,
      refineCore: { queryResult, onFinish, formLoading },
      getInputProps,
      values,
      removeListItem,
      insertListItem
  } = useForm< HttpError>({
      refineCoreProps: {
        resource,
        id,
        metaData: {
          fields
        },
        onMutationSuccess,
        autoSave: {
          enabled: true,
        }
      },
      initialValues,

        transformValues: (values) => {
            const {
            addresses,
            client,
            phones,
            provider_representatives,
            referrals,
            representative,
            id,
            ...rest
            } = values
            return rest
        },
  });

  const { selectProps: clientSelectProps } = useSelect({
      resource: "clients",
      defaultValue: queryResult?.data?.data.client_id || undefined,
      optionLabel: 'name',

      meta: {
        fields: ["id", "name"],
      },

      pagination: {
          mode: "server"
      }
  });

  const { selectProps: representativeProps } = useSelect({
      resource: 'representative',
      defaultValue: undefined,
      optionLabel: 'rep',

      meta: {
          fields: ['id', 'rep'],
      },
      filters: [
        {
          field: "id",
          operator: "nin",
          // @ts-ignore
          value: initialValues?.provider_representatives?.map(({representative_id}) => representative_id)
        }
      ],

      pagination: {
          mode: "server"
      }
  })

  // @ts-ignore
  const representatives = values?.provider_representatives?.map( (item, index) => (
    <tr key={item.id}>
    <td>
        <EditButton
            recordItemId={item.representative_id}
            resource='directory/representative'>
        </EditButton>
    </td>
    <td>{item.representative.rep}</td>
    </tr>
  ));

  const [inAddView, setAddView] = useState(false);

  const listView = representatives?.length > 0 ? (
    <Table>
      <thead>
        <tr>
          <th>Edit</th>
          <th>Rep</th>
        </tr>
      </thead>
      <tbody>{representatives}</tbody>
    </Table>
    ) : (
    <Text color="dimmed" align="center">
      No Representatives assigned to this provider.
    </Text>
  );

  // associateRepToProviderForm
  const {
    values: valuesOfCreateAssociation,
    saveButtonProps: saveButtonPropsOfCreateAssociation,
    reset: resetOfCreateAssociation,
    setFieldValue: setFieldValueOfCreateAssociation,
    getInputProps: getInputPropsOfCreateAssociation
  } = useForm({
    initialValues: {
      representative_id: null
    },
    refineCoreProps: {
      onMutationSuccess({data}, variables, context, isAutoSave) {
        insertListItem("provider_representatives", data);
        // reset of associateRepToProviderForm()
        setFieldValueOfCreateAssociation("representative_id", null);
        setAddView(false)
      },
      action: "create",
      resource: "provider_representative",
      redirect: false,
      metaData: {
        fields: provider_representatives
      }
    },
    validate: (values) => {
      const {
        provider_id, representative_id
      } = values as any;

      return {
        provider_id: !provider_id ? "Provider is undefined" : null,
        representative_id: !representative_id ? "Select a representative" : null
      }
    }
  });

  useEffect( () => {
    if (initialValues?.id){
      setFieldValueOfCreateAssociation("provider_id", initialValues?.id);
    }
  }, [initialValues])

  const addView = <>
    <Select
      mt={8}
      label="Representative"
      searchable={true}
      clearable={true}
      {...getInputPropsOfCreateAssociation("representative_id")}
      {...representativeProps}
      style={{ maxWidth: 300 }}
    />
    <Button
    {...saveButtonPropsOfCreateAssociation}
    >
      Associate Rep to This Provider
    </Button>
  </>

  const representativesSection = <>
    <Button onClick={() => setAddView(!inAddView)}>
      { inAddView ? "Cancel" : "Associate another Rep"}
    </Button>
    { !inAddView ? listView : addView }
  </>;

    // @ts-ignore
  const referrals = values?.referrals?.map( (referral:any) => {
    const { id } = referral

    const product_type = referral?.product_type?.title;
    const rx_length = referral?.rx_length?.title;
    const insurance = referral?.insurance?.title;
    const claim_type = referral?.claim_type?.title;
    const insurance_status = referral?.insurance_status?.title;
    const patient_status = referral?.patient_status?.title;

    return (
        <tr key={id}>
            <td>
                <EditButton
                    recordItemId={id}
                    resource='operations/referral'
                >
                </EditButton>
            </td>
            <td>{product_type}</td>
            <td>{rx_length}</td>
            <td>{insurance}</td>
            <td>{claim_type}</td>
            <td>{insurance_status}</td>
            <td>{patient_status}</td>
        </tr>
    );
  })

  const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ]
    .map( state => ({value: state, label:state}));

  // @ts-ignore
  const addresses = values?.addresses?.map( (address, index) => {
    const {
        lines,
        city,
        state,
        postalCode,
        stateOrProvinceCode,
        countryCode,
    } = address

    return (
        <Stack>
            <br/>
            <Divider></Divider>
            <TextInput
                    mt={8}
                    label="Name"
                    placeholder="e.g. Practice, Hospital, etc."
                    {...getInputProps(`addresses.${index}.name`)}
                    style={{ maxWidth: 300 }}
                />
            <Textarea
                label={ index == 0 ? "Primary address" : `Alternative address #${index}`}
                {...getInputProps(`addresses.${index}.lines`)}
                style={{ maxWidth: 500 }}
                >
            </Textarea>
            <Group>
                <TextInput
                    mt={8}
                    label="City"
                    {...getInputProps(`addresses.${index}.city`)}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Postal Code"
                    {...getInputProps(`addresses.${index}.postalCode`)}
                    style={{ maxWidth: 300 }}
                />
                <Select
                    mt={8}
                    label="State"
                    {...getInputProps(`addresses.${index}.stateOrProvinceCode`)}
                    searchable={true}
                    style={{ maxWidth: 100 }}
                    data={states}
                />
                <ActionIcon color="red" onClick={() => removeListItem('addresses', index)}>
                    {/* @ts-ignore */}
                    <CloseButton size="1rem">
                    </CloseButton>
                </ActionIcon>
            </Group>
            <br/>
        </Stack>
    )
})

    // @ts-ignore
    const contacts = values?.contacts?.map( (contact, index) => {

        return (
            <Stack>
                <br/>
                <Divider></Divider>
                <Group>
                    <TextInput
                        mt={8}
                        label="Contact Name"
                        {...getInputProps(`contacts.${index}.name`)}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                        mt={8}
                        label="Contact via"
                        placeholder="phone number, email, fax, etc."
                        {...getInputProps(`contacts.${index}.method`)}
                        style={{ maxWidth: 300 }}
                    />
                </Group>
                <Textarea
                    label="Contact Notes"
                    {...getInputProps(`contacts.${index}.notes`)}
                    style={{ maxWidth: 500 }}
                    >
                </Textarea>
                <br/>
            </Stack>
        )
    })

    return (
    <>
      <SwizzledEdit saveButtonProps={saveButtonProps}>
        <form>
        <Tabs defaultValue={'first'}>
            <Tabs.List>
                <Tabs.Tab value="first">Provider</Tabs.Tab>
                {/* <Tabs.Tab value="addresses">Address(es)</Tabs.Tab>
                <Tabs.Tab value="contacts">Contact(s)</Tabs.Tab> */}
                <Tabs.Tab value="third">Rep(s)</Tabs.Tab>
                <Tabs.Tab value="fourth">Referral(s)</Tabs.Tab>
                <Tabs.Tab value="notes">Notes</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="first">
                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="Name"
                    {...getInputProps('name')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Specialty"
                    placeholder="Specialty"
                    {...getInputProps('specialty')}
                    style={{ maxWidth: 300 }}
                />
                {/* TODO address, fax, and shit */}
                <TextInput
                    mt={8}
                    label="NPI"
                    placeholder="NPI"
                    {...getInputProps('npi')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Extension Direct Send"
                    placeholder="Extension Direct Send"
                    {...getInputProps('ext_direct_send')}
                    style={{ maxWidth: 300 }}
                />
                <TextInput
                    mt={8}
                    label="Survey Link"
                    placeholder="Survey Link"
                    {...getInputProps('survey_link')}
                    style={{ maxWidth: 300 }}
                />
                <br/>
                <Anchor 
                    href={`https://drive.google.com/drive/folders/${values?.survey_link}`}
                    target="_blank">
                    Survey Link
                </Anchor>
                <TextInput
                    mt={8}
                    label="Survey Direct Send"
                    placeholder="Survey Direct Send"
                    {...getInputProps('survey_direct_send')}
                    style={{ maxWidth: 300 }}
                />
            </Tabs.Panel>
            <Tabs.Panel value="addresses">
                {addresses?.length == 0 ? (
                    <Text>Please add at least one address.</Text>
                ) : (<><br/></>)}
                <Group mt="md">
                    <Button
                    onClick={() =>
                        insertListItem('addresses', {})
                    }
                    >
                    Add An Address
                    </Button>
                </Group>
                {addresses}
            </Tabs.Panel>
            <Tabs.Panel value="contacts">
                {contacts?.length == 0 ? (
                <Text>Please add at least one way to contact this provider.</Text>
                ) : (<><br/></>)}
                <Group mt="md">
                    <Button
                    onClick={() =>
                        insertListItem('contacts', {})
                    }
                    >
                    Add A Contact
                    </Button>
                </Group>
                {contacts}
            </Tabs.Panel>
            <Tabs.Panel value="third">
              {representativesSection}
            </Tabs.Panel>
            <Tabs.Panel value="fourth">
                {referrals?.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>Edit</th>
                                <th>Product</th>
                                <th>Rx Length</th>
                                <th>Insurance</th>
                                <th>Claim Type</th>
                                <th>Insurance Status</th>
                                <th>Patient Status</th>
                            </tr>
                        </thead>
                        <tbody>{referrals}</tbody>
                    </Table>
                ) : (
                    <Text color="dimmed" align="center">
                        No Referrals for this provider.
                    </Text>
                )}
                
            </Tabs.Panel>
            <Tabs.Panel value="notes">
                <Textarea
                    mt={8}
                    label="Notes"
                    {...getInputProps('notes')}
                    minRows={3}
                />
            </Tabs.Panel>
        </Tabs>
        </form>
      </SwizzledEdit>
    </>
    );
};
