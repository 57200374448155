export const fields = [
  "id",
  "active",
  "additional_email",

  {
    client: [ 'title' ]
  },
  "email",
  "extension_link",
  "manager_email",
  "employment_type",
  "name",
  "rep",
  "notes",
  "sort",

  "team_id",
  // {
  //   team: [
  //     // 'title', //TODO: switch name to title
  //     'name'
  //   ]
  // },
  {
    representative_teams: [
      {
        team: [ 'title' ]
      }
    ]
  },
  {
    provider_representatives: [ 
      "provider_id",
      {
        provider: [ "name" ]
      }
     ]
  },
]

export const resource = 'representative'
