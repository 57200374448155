import dayjs from 'dayjs'
import { DateField } from "@refinedev/mantine";
import { useGetIdentity } from '@refinedev/core';
import { 
  IconSquareNumber1, IconSquareNumber2, IconSquareNumber3,
  IconHexagonLetterD, IconHexagonLetterS
} from "@tabler/icons";
import { isAffectrixClientId } from 'utilities';
import { Badge, Button, Tooltip } from "@mantine/core";

export const renderDate = ({ getValue }: any) => 
getValue() && dayjs(getValue()).isValid() ? (
  <DateField format="MM-DD-YY" value={getValue()} />
) : (
  <>N/A</>
);

export const AdminDebug = (values:any) => {
  const {
    data,
    data: {
      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {},
    } = {}
  } = useGetIdentity<any>({
      // v3LegacyAuthProviderCompatible: true
  });
  return (
    uid == 'Ri3uQKjG4dZSuyuk4jmjk3RT7eq2' ||
    uid == 'WpsrIwWXvURpgLfv2fGYXTBRAAy2'
    ) && (<>
    <pre>{JSON.stringify(values, null, 2)}</pre>
  </>)
};

export const MaybeCategory = (referral:any) => {

  const verification_notes_color = referral?.historical_categorization || referral?.verification_notes_color || '';

  const maybeCategory = [
    ['white', <Tooltip openDelay={500} label="Category 1"><div><IconSquareNumber1/></div></Tooltip>],
    ['sandybrown', <Tooltip openDelay={500} label="Category 2"><div><IconSquareNumber2 color='orange'/></div></Tooltip>],
    ['darksalmon', <Tooltip openDelay={500} label="Category 3"><div><IconSquareNumber3 color='red'/></div></Tooltip>]
    // @ts-ignore
  ].reduce((prev,curr) => {
    if (!prev){
      const [ color, component ] = curr;
      if (color == verification_notes_color){
        return component
      }
    }
    return prev;
  },null);

  return <>
    {maybeCategory}
  </>
};

export const MaybeDocumentIndicator = (referral:any) => {
  const document_status = referral?.document_status?.title || '';
  const insurance = referral?.insurance?.title || '';
  const claim_type = referral?.claim_type?.title || '';

  let Letter = null;
  let text = '';
  if (
    !document_status.includes(`REC'D`) &&
    !document_status.includes(`X`)
  ){
    if (document_status.includes('SENT')){
      Letter = IconHexagonLetterS;
      text = 'Document(s) Sent';
    } else {
      Letter = IconHexagonLetterD;
      text = 'Document(s) Needed';
    }
  }

  let color = '';
  if (
    /ATTORNEY|ATTY/.test(insurance)
  ){
    color = 'red';
  } else if (
    /PA WC\/LITIGATION/.test(claim_type)
  ){
    color = 'cyan';
  } else if (
    /MI AUTO|LITIGATION/.test(claim_type)
  ){
    color = 'red';
  }

  if (color && Letter){
    return <Tooltip openDelay={500} label={text}><div><Letter color={color}/></div></Tooltip>
  }
  return null

}