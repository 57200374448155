import { HttpError, useNavigation } from "@refinedev/core";
import { Create, useForm } from "@refinedev/mantine";
import { Tabs, TextInput } from "@mantine/core";
import { resource } from "./common";


export const FitterCreate: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/fiter');
    }

    const {
        saveButtonProps,
        getInputProps, 
        errors
    } = useForm<HttpError>({
        initialValues: {
            title: null,
            email: null
        },
        refineCoreProps: {
            onMutationSuccess,
            resource
        }
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <Tabs defaultValue={'first'}>
                    <Tabs.List>
                        <Tabs.Tab value="first">Main</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                        <TextInput
                            mt={8}
                            label="Name"
                            placeholder="Name"
                            {...getInputProps('title')}
                            style={{ maxWidth: 300 }}
                        />
                        <TextInput
                            mt={8}
                            label="Email"
                            placeholder="Email"
                            {...getInputProps('email')}
                            style={{ maxWidth: 300 }}
                        />
                    </Tabs.Panel>
                </Tabs>            
                <br/>
                <br/>
            </form>
        </Create>
    );
};
