import {
  useResource, useOne, useGetIdentity, useBack
} from "@refinedev/core";
import { Grid, Title, Text, Textarea, Tabs, TextInput, ActionIcon, Group } from "@mantine/core";
import dayjs from "dayjs";
import { Notes } from "components/notes";
import { IconArrowLeft } from "@tabler/icons";
import { useForm } from "@refinedev/mantine";
import { useEffect } from "react";

const fields = [
	"id",
	"column_b",
	"column_d",

  'agreement',
  'do_not_mz_rs',  // TODO add to referral view

  {
    client: [
      'name'
    ]
  },

  'product_title',

	"patient_id",
	{
		patient: [
			'name',
			'date_of_birth',
      'name_and_dob',
			'template_answers',
			'patient_info',

      'added_to_collaborate',

      "initial_call_complete",
      "date_of_surgery",
      "surgery",
      "next_call_date",
      // "date_fit",
      "condition",

			// TODO patient contact
			'rep_communication_notes',
  		'verification_notes',
			{
				patient_notes: [
					{
						note: [
              'id',
							'content',
							'created_at',
							{
								authed_user: [
                  'id',
									'display_name',
									'photoURL'
								]
							},
							// {
							// 	note_type: ['code']
							// }
              'metadata'
						]
					}
				]
			},
      {
        referrals: [
          'id', 'product_title',
          'rx_received_date',
          {
            product_type: [ 'id', 'title']
          },
          {
            "rx_length": [ 'title' ]
          },
          {
            "insurance": [ 'title' ]
          },
          {
            "claim_type": [ 'title' ]
          },
          {
            "insurance_status": [ 'title' ]
          },
          {
            "patient_status": [ 'title' ]
          },
        ]
      }
		]
	},

  {
    "submissions": [
      'id', 'submission_type', 'items', 'date_shipped', 'tracking_number', 'fitter_id', {
        fitter: ['title']
      }, 'urgency'
    ]
  },

	"patient_status_id",
	{
		patient_status: ['title']
	},

	"product_type_id",
	{
		product_type: ['title']
	},

	"insurance_id",
	{
		insurance: ['title']
	},

	"claim_type_id",
	{
		claim_type: ['title']
	},

	"insurance_status_id",
	{
		insurance_status: ['title']
	},

	{
		reauth_status: [ 'title' ]
	},

	"provider_id",
	{
		provider: [
			'name',
			'npi',
			// TODO contact info
			{
				provider_representatives: [ 
					{
						representative: [
							'name'
						]
					}
				]
			}
		],
	},

	{
		extensions: [
			'id',
			'extended_to_date',
			{
				extension_status: [ 'title' ]
			},
			{
				insurance_override: [ 'title' ]
			},
			'rx_expiration_date'
		]
	},
	{
		resupplies: [
			'id',
			'resupply_date',
		]
	},
	'resupply_items',

	"injury_sites",
	"icd10_codes",

	"survey_complete",
	
	"rx_date",
  "rx_received_date",

	"rx_expiration_date",

	"date_fit",
	"five_day_call",
	"five_day_call_status",
	"fourteen_day_call",
	"fourteen_day_call_status",
	"equipment_id",
  "automated_email_status"
];

export const ReferralShow: React.FC = () => {

  const {
    data: {
      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {},
      authed_user = {}
    } = {}
  } = useGetIdentity<any>({
      // v3LegacyAuthProviderCompatible: true
  });

  const formatDate = (x: any) => dayjs(x).isValid() ? 
  dayjs(x).format('MM-DD-YY') :
  '';

  const { id } = useResource();

  const { 
      data: {
          data: initialValues
      } = {}
  } = useOne({
      resource: 'referral',
      id,
      meta:{
          fields
      }
  });

  const main = useForm<any>({
    refineCoreProps: {
      resource: 'referral',
      metaData: {
        fields,
      },
      redirect: false,
    },
    initialValues: {
      patient: {
        referrals: [],
        patient_notes: []
      },
      ...initialValues,
    }
  });

  const { values, setValues } = main;

  useEffect( () => {
    if (initialValues){
      setValues(initialValues);
    }
  }, [initialValues])

  const referralPanel = <Tabs.Panel value="referral">
  <TextInput
      mt={8}
      label="Product"
      value = {initialValues?.product_title}
      style={{ maxWidth: 300 }}
  />
  <TextInput
      mt={8}
      label="Product Type"
      value = {initialValues?.product_type?.title}
      style={{ maxWidth: 300 }}
  />
  <TextInput
      mt={8}
      label="Insurance"
      value={initialValues?.insurance?.title}
      style={{ maxWidth: 300 }}
  />
  <TextInput
      mt={8}
      label="Claim Type"
      value={initialValues?.claim_type?.title}
      style={{ maxWidth: 300 }}
  />
  <TextInput
      mt="md"
      label="Rx Rec'd Date"
      value={formatDate(initialValues?.rx_received_date)}
      style={{ maxWidth: 300 }}
  />
  <TextInput
    mt={8}
    label="RX Length"
    value={initialValues?.rx_length_title}
    style={{ maxWidth: 300 }}
  />
  <TextInput
      mt="md"
      label="Rx Expiration Date"
      value={formatDate(initialValues?.rx_expiration_date)}
      style={{ maxWidth: 300 }}
  />
  <TextInput
      mt="md"
      label="Date Fit"
      value={formatDate(initialValues?.date_fit)}
      style={{ maxWidth: 300 }}
  />
  {/* TODO */}
  <TextInput
      mt="md"
      label="Date of Injury"
      value={formatDate("date_of_injury")}
      style={{ maxWidth: 300 }}
  />
  </Tabs.Panel>;

  let chip_settings = {};
  const employment_type = authed_user?.representative?.employment_type;
  if (employment_type == '1099'){
    chip_settings = {
      noteTypes: [
        {
          id: "COMM", title: 'Communication'
        },
      ]
    };
  };

  const notesPanel = <Tabs.Panel value="notes">
    <Notes
      is_rep={true}

      patient_id={initialValues?.patient_id}
      
      // @ts-ignore
      referrals={values?.patient?.referrals}
      // @ts-ignore
      patient_notes={values?.patient?.patient_notes as any[]}
      
      chip_settings={chip_settings}

      forms={{main}}
      main_accessor='patient.patient_notes'
    />
  </Tabs.Panel>;

  // @ts-ignore
  const oldNotesTab = (initialValues?.patient?.rep_communication_notes || initialValues?.patient?.verification_notes) && 
    <Tabs.Tab value="old_notes">
      Prev Notes
    </Tabs.Tab>;

  // @ts-ignore
  const oldNotesPanel = (initialValues?.patient?.rep_communication_notes || initialValues?.patient?.verification_notes) && 
    <Tabs.Panel value="old_notes">
      <Tabs defaultValue='verification_and_billing'>
        <Tabs.List>
          <Tabs.Tab value='verification_and_billing'>Verification and Billing</Tabs.Tab>
          <Tabs.Tab value="rep_com_notes">Rep and Com</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='verification_and_billing'>
          <Textarea
              mt={8}
              disabled
              label="Verification and Billing Notes"
              placeholder="Verification Notes"
              value={initialValues?.patient?.verification_notes}
              minRows={8}
              style={{ maxWidth: 900 }}
          />
        </Tabs.Panel>
        <Tabs.Panel value='rep_com_notes'>
          <Textarea
              mt={8}
              disabled
              label="Rep Communication Notes"
              placeholder="Rep Communication Notes"
              value={initialValues?.patient?.rep_communication_notes}
              minRows={8}
              style={{ maxWidth: 900 }}
          />
        </Tabs.Panel>
      </Tabs>
    </Tabs.Panel>;

  const back = useBack();
  
  const buttonBack = <Group spacing="xs">
    <ActionIcon
        onClick={back}
    >
      <IconArrowLeft />
    </ActionIcon>
    <Title
          order={3}
          transform="capitalize"
      >
      Back
    </Title>
  </Group>;

  return <>
    {buttonBack}
    <br/>
    <Grid>
      <Grid.Col span={4} style={{"outline": "2px solid"}}>
        <Text size='lg'>
          {/* @ts-ignore */}
          {initialValues?.patient?.name_and_dob}
        </Text>
      </Grid.Col>
      <Grid.Col span={4} style={{"outline": "2px solid"}}>
        <Textarea
          mt={8}
          value={initialValues?.patient?.patient_info}
          minRows={4}
        />
      </Grid.Col>
    </Grid>
    <br/>
    <Tabs defaultValue='referral'>
      <Tabs.List>
        <Tabs.Tab value='referral'>Referral</Tabs.Tab>
        <Tabs.Tab value='notes'>Notes</Tabs.Tab>
        {oldNotesTab}
      </Tabs.List>
      {referralPanel}
      {notesPanel}
      {oldNotesPanel}
    </Tabs>
    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
  </>
}