import { HttpError, useNavigation } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/mantine";
import { Select, Tabs, Textarea, TextInput } from "@mantine/core";
import { resource } from "./common";


export const LocationCreate: React.FC = () => {

    const { list } = useNavigation();

    const onMutationSuccess = (data: any, variables: any, context: any) => {
        list('directory/location');
    }

    const {
        saveButtonProps,
        getInputProps, 
        errors
    } = useForm<HttpError>({
        initialValues: {
            // ...filteredInitialValues,
        },
        refineCoreProps: {
            onMutationSuccess,
            resource
        }
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <form>
                <Tabs defaultValue={'first'}>
                    <Tabs.List>
                        <Tabs.Tab value="first">Main</Tabs.Tab>
                        <Tabs.Tab value="second">Contact</Tabs.Tab>
                        <Tabs.Tab value="third">Alt Contact</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="first">
                        <TextInput
                            mt={8}
                            label="Name"
                            placeholder="Name"
                            {...getInputProps('name')}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="second">
                        <TextInput
                            mt={8}
                            label="Phone Number"
                            placeholder="Phone Number"
                            {...getInputProps('phone_number_main')}
                        />
                        <Select
                            mt={8}
                            label="Phone Number Type"
                            placeholder="Pick One"
                            {...getInputProps('phone_number_main_type')}
                            data={[
                                { label: "Personal", value: "personal" },
                                { label: "Business", value: "business" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                        <TextInput
                            mt={8}
                            label="Fax Number"
                            placeholder="Fax Number"
                            {...getInputProps('fax_number')}
                        />
                        <TextInput
                            mt={8}
                            label="Email"
                            placeholder="Email"
                            {...getInputProps('email')}
                        />
                        <Textarea
                            mt={8}
                            label="Address"
                            placeholder="Address"
                            {...getInputProps('address_main')}
                            minRows={3}
                        />
                        <Select
                            mt={8}
                            label="Address Type"
                            placeholder="Pick One"
                            {...getInputProps('address_main_type')}
                            data={[
                                { label: "Home", value: "home" },
                                { label: "Commercial", value: "commercial" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="third">
                        <TextInput
                            mt={8}
                            label="Alt Phone Number"
                            placeholder="Alt Phone Number"
                            {...getInputProps('phone_number_alt')}
                        />
                        <Select
                            mt={8}
                            label="Alt Phone Number Type"
                            placeholder="Pick One"
                            {...getInputProps('phone_number_alt_type')}
                            data={[
                                { label: "Personal", value: "personal" },
                                { label: "Business", value: "business" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                        <Textarea
                            mt={8}
                            label="Alternate Address"
                            placeholder="Alternate Address"
                            {...getInputProps('address_alt')}
                            minRows={3}
                        />
                        <Select
                            mt={8}
                            label="Alternate Address Type"
                            placeholder="Pick One"
                            {...getInputProps('address_alt_type')}
                            data={[
                                { label: "Home", value: "home" },
                                { label: "Commercial", value: "commercial" },
                                { label: "Other", value: "other" },
                            ]}
                        />
                    </Tabs.Panel>
                </Tabs>
                
                
                <br/>
                <br/>
            </form>
        </Create>
    );
};
